<div class="block block-tile large">
    <div class="inner">
        <h3>Vergleich</h3>
        <div class="orange-chart-wrap" [hidden]="!showDiagrams">
            <div [chart]="chart" id="chart-compare-large"></div>
            <a href="javascript:;" angulartics2On="click" angularticsCategory="Screens" angularticsAction="screen_view"
               angularticsLabel="screen: Vergleich - Statisch; previous_screen: Übersicht"
               (click)="firstDetailViewAnalytics()" title="Details anzeigen" class="compare-details icon-arrow-rt">
                <span>Details anzeigen</span>
            </a>
        </div>
        <h4 *ngIf="trend.percent > 0 && trend.percent < 9999"><strong>{{ trend.percent }}
            % {{ trend.direction > 0 ? 'mehr' : 'weniger' }} </strong> als im {{ trend.monthname }}</h4>

        <div class="error orange" [hidden]="showDiagrams"></div>
        <div class="error-text" [hidden]="showDiagrams">Daten sind noch <br/>nicht verfügbar</div>
    </div>
</div>
