import {Component, OnInit, OnDestroy} from '@angular/core';
import {Title} from '@angular/platform-browser';

import {ToastrService} from 'ngx-toastr';

import {ApiService} from '../services/api.service';
import {Globals} from '../services/globals.service';
import {MockDataService} from '../mock-data.service';

import {UserService} from '../user.service';
import {getProviderIconClass, getProviderMenuName} from '../ProivderUtil';
import {static_links} from '../constants';

@Component({
    selector: 'iona-app',
    templateUrl: './household.component.html',
    viewProviders: [],
    providers: [Globals]
})

export class HouseholdComponent implements OnInit, OnDestroy {

    refresh: any = [];

    /**
     * Anbieter-Daten
     */
    provider: number = 0;
    providerclass: string = '';
    providername: string = '';
    privacyLink = '';
    providerMenuName: string = null;
    username: string = null;
    fullname: string = null;

    /**
     * Haushalts-Daten
     */
    household: any = {
        Attributes: {
            PropertyType: null,
            PropertySize: null,
            PropertyAge: null,
            Occupants: null,
            SpaceHeating: null,
            WaterHeating: null,
            OvenHeating: null,
            GrillHeating: null,
            StoveHeating: null
        },
        Appliances: []
    };

    appliances: any = [];

    version: string = '0.0.0';
    changelog: any = [];
    changelog_visible: boolean = false;

    constructor(private _titleService: Title,
                private _notification: ToastrService,
                public _apiService: ApiService,
                private _globals: Globals,
                private _mockData: MockDataService,
                private _userService: UserService) {
    }

    ngOnInit() {
        this._titleService.setTitle('Mein Haushalt | iONA');

        this.getVersion();
        this.getChangelog();

        this.username = this._userService.getActiveUserName();
        this.providername = this._userService.getActiveUserProvider();
        this.privacyLink = static_links.privacy[this.providername];
        if (this.providername === null || this.providername === undefined) {
            console.log('provider not determined yet');
        }

        this.providerclass = getProviderIconClass(this.providername);
        this.providerMenuName = getProviderMenuName(this.providername);

        if (this._apiService.isDemoMode()) {
            this.getMockInitialize();
            this.getMockProfile();
            return;
        }

        /* DE SCRUBIFY THIS */
        this._apiService.checkToken();
        this._apiService.checkMeterStatus();

        this.refresh = setInterval(() => {
            this._apiService.checkToken();
        }, 10000);
        /* END DE SCRUBIFY THIS */

        this.getInitialize();
        this.getProfile();

    }

    ngOnDestroy() {
        clearInterval(this.refresh);
    }


    /**
     * Get initialize data
     */
    private getInitialize(): void {
        this._apiService.getInitialization().subscribe(
            (data: any) => {
                if ('data' in data) {
                    if ('profile' in data.data) {
                        this.processContractData(data);
                    }
                }
            }
        );
    }

    /**
     * Get Mock initialize data
     */
    private getMockInitialize(): void {
        this._mockData.getInitialize().subscribe(
            (data) => {
                this.processContractData(data);
            }
        );
    }

    /**
     * Get User Profile
     */
    private getProfile(): void {
        this._apiService.getProfile().subscribe(
            (data: any) => {
                if ('data' in data) {
                    this.processProfileData(data);
                }
            }
        );
    }

    /**
     * Get Mock user profile
     */
    private getMockProfile(): void {
        this._mockData.getProfileAttributes().subscribe(
            (data) => {
                if ('data' in data) {
                    this.processProfileData(data);
                }

            }
        );

    }

    /**
     * Setting ändern
     *
     * @param setting
     * @param value
     * @param type
     */
    setSetting(setting: string, value: string, type: string) {
        switch (type) {
            case 'Attributes': {
                this.household.Attributes[setting] = value;

                break;
            }
            case 'Appliances': {
                this.household.Appliances[setting] = parseInt(value, 10);

                break;
            }
        }
    }

    /**
     * neue Settings speichern
     */
    saveSettings() {
        let errors: any = [];

        // Prüfen der eingegebenen Daten

        if ((this.household.Appliances['A.04'] > 0) && (this.household.Attributes.OvenHeating === 'OH.00')) {
            errors.push('Ihre Angaben zum Backofen sind widersprüchlich!');
        } else if ((this.household.Appliances['A.04'] === 0) && (this.household.Attributes.OvenHeating !== 'OH.00')) {
            errors.push('Ihre Angaben zum Backofen sind widersprüchlich!');
        }

        if ((this.household.Appliances['A.05'] > 0) && (this.household.Attributes.GrillHeating === 'GH.00')) {
            errors.push('Ihre Angaben zum Grill sind widersprüchlich!');
        } else if ((this.household.Appliances['A.05'] === 0) && (this.household.Attributes.GrillHeating !== 'GH.00')) {
            errors.push('Ihre Angaben zum Grill sind widersprüchlich!');
        }

        if ((this.household.Appliances['A.06'] > 0) && (this.household.Attributes.StoveHeating === 'STH.00')) {
            errors.push('Ihre Angaben zum Herd sind widersprüchlich!');
        } else if ((this.household.Appliances['A.06'] === 0) && (this.household.Attributes.StoveHeating !== 'STH.00')) {
            errors.push('Ihre Angaben zum Herd sind widersprüchlich!');
        }

        console.log('household', JSON.stringify(this.household));

        if (errors.length > 0) {
            for (let error of errors) {
                this._notification.warning(error);
            }
        } else {
            // Wenn Onboarding-Prozess - Google Analytics Event
            if (this._globals.getIsOnboarding()) {
                this._apiService._analytics.eventTrack.next({
                    action: 'onboarding_profile_end',
                    properties: {
                        category: 'Onboarding'
                    }
                });
                this._globals.resetIsOnboarding();
            }

            if (!this._apiService.isDemoMode()) {


                this._apiService.setProfile(this.household).subscribe(
                    (response) => {
                        this._notification.success('Ihre Angaben zum Haushalt wurden übernommen!');
                    },
                    (error) => {
                        this._notification.error('Hoppla, da ist etwas schief gelaufen.', 'Fehler');
                        const error_obj = error.error;
                        if (error_obj.error.code === 180) {
                            console.log(error);
                            console.log(error_obj.error.message);
                        }
                    }
                );
            } else {
                this._notification.info('Die Angaben zum Haushalt können im Demo-Modus nicht verändert werden!');
            }
        }
    }

    /**
     * Gerät zufügen
     *
     * @param appliance
     */
    subAmount(appliance: string) {
        if (this.household.Appliances[appliance] > 0) {
            this.household.Appliances[appliance] -= 1;
        }
    }

    /**
     * Gerät entfernen
     *
     * @param appliance
     */
    addAmount(appliance: string) {
        if (this.household.Appliances[appliance] === null || this.household.Appliances[appliance] === undefined) {
            this.household.Appliances[appliance] = 0;
        }
        if (this.household.Appliances[appliance] < 99) {
            this.household.Appliances[appliance] += 1;
        }
    }

    /**
     * Ist dieses Gerät ausgewählt?
     */
    isApplianceSelected() {
        for (const appliance of this.appliances) {
            if (this.household.Appliances[appliance] > 0) {
                return true;
            }
        }

        return false;
    }


    /**
     * Get Current app version
     */
    getVersion() {
        this._apiService.getChangelog().subscribe(
            (data: any) => {
                if (0 in data) {
                    this.version = data[0].version;
                }
            }
        );
    }

    /**
     * Get changelog
     */
    getChangelog() {
        this._apiService.getChangelog().subscribe(
            (data: any) => {
                this.changelog = data;
            }
        );
    }

    /**
     * Display the last changelog
     */
    showChangelog() {
        this.changelog_visible = true;
    }

    /**
     * Hide changelog
     */
    hideChangelog() {
        this.changelog_visible = false;
    }

    /**
     * Process contract data
     * @param data
     */
    private processContractData(data): void {
        if (this.username === 'Demo') {
            this.fullname = 'Frau Maxi Mustermann';
        } else {
            this.fullname = data.data.profile.customer_name;
        }
    }

    /**
     * Process Profile information
     * @param data
     */
    private processProfileData(data): void {
        if ('Attributes' in data.data) {
            this.household.Attributes = {
                PropertyType: data.data.Attributes.PropertyType,
                PropertySize: data.data.Attributes.PropertySize,
                PropertyAge: data.data.Attributes.PropertyAge,
                Occupants: data.data.Attributes.Occupants,
                SpaceHeating: data.data.Attributes.SpaceHeating,
                WaterHeating: data.data.Attributes.WaterHeating,
                OvenHeating: data.data.Attributes.OvenHeating,
                GrillHeating: data.data.Attributes.GrillHeating,
                StoveHeating: data.data.Attributes.StoveHeating
            };
        }


        if ('Appliances' in data.data) {
            this.household.Appliances = data.data.Appliances;

            this.appliances = Object.keys(this.household.Appliances);

            setTimeout(function() {
                let elements: any = $('.grid .box');
                elements.matchHeight();
            }, 100);
        }
    }

}
