/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./widget.consumption-alert.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/common";
import * as i5 from "angulartics2";
import * as i6 from "../../services/api.service";
import * as i7 from "@angular/common/http";
import * as i8 from "@angular/router";
import * as i9 from "ngx-toastr";
import * as i10 from "../../user.service";
import * as i11 from "../../overlay/overlay.service";
import * as i12 from "../../services/globals.service";
import * as i13 from "./widget.consumption";
import * as i14 from "../../mock-data.service";
var styles_ConsumptionAlertWidget = [i0.styles];
var RenderType_ConsumptionAlertWidget = i1.ɵcrt({ encapsulation: 0, styles: styles_ConsumptionAlertWidget, data: {} });
export { RenderType_ConsumptionAlertWidget as RenderType_ConsumptionAlertWidget };
function View_ConsumptionAlertWidget_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "Verbrauchs-Alarm aktiviert"], ["class", "alert-image"], ["src", "assets/images/consumption-alert-images/above/ic-abovevalue.png"], ["title", "Verbrauchs-Alarm aktiviert"]], null, null, null, null, null))], null, null); }
function View_ConsumptionAlertWidget_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "Verbrauchs-Alarm deaktiviert"], ["class", "alert-image"], ["src", "assets/images/consumption-alert-images/below/ic-belowvalue.png"], ["title", "Verbrauchs-Alarm deaktiviert"]], null, null, null, null, null))], null, null); }
function View_ConsumptionAlertWidget_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "consumption-alert-info m-t-s"], ["fxLayout", "row"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "since p-r-s"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Maximum der letzten 7 Tage "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "power"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.power; _ck(_v, 6, 0, currVal_1); }); }
export function View_ConsumptionAlertWidget_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "block block-tile large"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Verbrauchsalarm"])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "figure", [["class", "alert-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConsumptionAlertWidget_1)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["alt", 2]], null, 0, null, View_ConsumptionAlertWidget_2)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConsumptionAlertWidget_3)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 3, "a", [["angulartics2On", "click"], ["angularticsAction", "screen_view"], ["angularticsCategory", "Screens"], ["angularticsLabel", "screen: Verbrauchs-Alarm; previous_screen: \u00DCbersicht"], ["class", "consumption-details icon-arrow-rt"], ["href", "javascript:;"], ["title", "Details anzeigen"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.firstDetailViewAnalytics() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 1064960, null, 0, i5.Angulartics2On, [i1.ElementRef, i5.Angulartics2, i1.Renderer2], { angulartics2On: [0, "angulartics2On"], angularticsAction: [1, "angularticsAction"], angularticsCategory: [2, "angularticsCategory"], angularticsLabel: [3, "angularticsLabel"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Details anzeigen"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.currentZone === _co.ZONE_HYPERACTIVE); var currVal_1 = i1.ɵnov(_v, 7); _ck(_v, 6, 0, currVal_0, currVal_1); var currVal_2 = (_co.showSinceInfo && _co.showPowerInfo); _ck(_v, 9, 0, currVal_2); var currVal_3 = "click"; var currVal_4 = "screen_view"; var currVal_5 = "Screens"; var currVal_6 = "screen: Verbrauchs-Alarm; previous_screen: \u00DCbersicht"; _ck(_v, 11, 0, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_ConsumptionAlertWidget_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "consumption-alert-widget", [], null, null, null, View_ConsumptionAlertWidget_0, RenderType_ConsumptionAlertWidget)), i1.ɵprd(139776, null, i6.ApiService, i6.ApiService, [i7.HttpClient, i8.Router, i9.ToastrService, i5.Angulartics2, i10.UserService, i11.OverlayService]), i1.ɵprd(512, null, i12.Globals, i12.Globals, []), i1.ɵdid(3, 245760, null, 0, i13.ConsumptionAlertWidget, [i6.ApiService, i12.Globals, i14.MockDataService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var ConsumptionAlertWidgetNgFactory = i1.ɵccf("consumption-alert-widget", i13.ConsumptionAlertWidget, View_ConsumptionAlertWidget_Host_0, {}, {}, []);
export { ConsumptionAlertWidgetNgFactory as ConsumptionAlertWidgetNgFactory };
