<div id="container">
    <aside class="sidebar lft">
        <a href="#" id="hamburger" title="Menü" class="mobi">
            <span class="burger">
                <i class="top"></i>
                <i class="middle"></i>
                <i class="bottom"></i>
            </span>
            <span class="text"> Menü</span>
        </a>
        <div class="blackbox">
            <div class="version">
                <a href="javascript:;" (click)="showChangelog()">{{ version }}</a>
            </div>
            <div [class.demo]="username === 'Demo'" class="logo">
                <a href="#/" title="iONA">
                    <span>iONA</span>
                </a>
            </div>
        </div>
        <div class="block block-menu">
            <nav class="inner">
                <ul class="clearfix">
                    <li><a href="#/" title="Übersicht">Übersicht</a></li>
                    <li><a href="#/meine-daten" title="Meine Daten">Meine Daten</a></li>
                    <li><a href="#/mein-haushalt" title="Mein Haushalt">Mein Haushalt</a></li>
                    <li><a href="#/mein-kundenkonto" angulartics2On="click" angularticsCategory="Meine Daten"
                           angularticsAction="my_account_enter"
                           title="{{ providerMenuName }}">{{ providerMenuName }}</a></li>
                    <li><a href="#/einstellungen" title="Einstellungen">Einstellungen</a></li>
                    <li><a href="#/kontakt" title="Kontakt">Kontakt</a></li>
                    <li><a href="javascript:;"
                           title="{{ _apiService.isDemoMode() ? 'Demo-Modus verlassen' : 'Logout' }}"
                           class="open-logout">{{ _apiService.isDemoMode() ? 'Demo-Modus verlassen' : 'Logout' }}</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="block block-submenu">
            <div class="inner">
                <div *ngIf="providerclass?.length > 0" class="presentbar">
                    <small>Präsentiert von</small>
                    <div [class]="providerclass"></div>
                </div>
                <nav class="submenu">
                    <ul>
                        <li><a href="#/impressum" angulartics2On="click" angularticsCategory="Menü"
                               angularticsAction="view_imprint" title="Impressum">Impressum</a></li>
                        <li>
                            <a [href]="privacyLink" target="_blank"
                               angulartics2On="click" angularticsCategory="Menü" angularticsAction="view_privacy"
                               title="Datenschutz">Datenschutz</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </aside>
    <div id="inner">
        <main id="mainContent" class="clearfix">
            <div class="kontakt">
                <div class="head">
                    <h2>Impressum</h2>
                </div>
                <div class="block block-content" *ngIf="providername === 'innogy'">
                    <div class="inner">
                        <p>Für die Applikation ist die innogy SE verantwortlich.</p>

                        <h4>Adresse</h4>
                        <p>innogy SE<br/>
                            Opernplatz 1<br/>
                            45128 Essen</p>

                        <p><strong>Sitz der Gesellschaft</strong>: Essen<br/>
                            Eingetragen im Handelsregister des Amtsgerichts Essen<br/>
                            Handelsregister-Nr. HRB 27091<br/>
                            USt-IdNr. DE304171711</p>

                        <h4>Vorsitzender des Aufsichtsrats</h4>
                        <p>Dr. Johannes Teyssen</p>

                        <h4>Vorstand</h4>
                        <p>
                            Dr.-Ing. Leonhard Birnbaum (CEO) <br>
                            Dr. Bernhard Günther <br>
                            Dr. Christoph Radke <br>
                        </p>

                        <h3>Verbraucherschlichtungen</h3>

                        <h4>Verbraucherservice der Bundesnetzagentur</h4>
                        <p>Der Verbraucherservice der Bundesnetzagentur stellt Ihnen Informationen über das geltende
                            Recht, Ihre Rechte als Haushaltskunde und über Streitbeilegungsverfahren für die Bereiche
                            Elektrizität und Gas zur Verfügung und ist unter folgenden Kontaktdaten erreichbar:</p>
                        <p>Bundesnetzagentur für Elektrizität, Gas, Telekommunikation, Post, Eisenbahnen<br/>
                            <strong>Verbraucherservice:<br/></strong>
                            Postfach 8001, 53105 Bonn<br/>
                            Mo-Fr 9 bis 15 Uhr,<br/>
                            <strong>T</strong> 030-22480-500 (bundesweites Infotelefon),<br/>
                            <strong>F</strong> 030-22480-323,<br/>
                            <strong>E</strong> <a href="mailto:verbraucherservice-energie@bnetza.de">verbraucherservice-energie@bnetza.de</a>.
                        </p>

                        <h4>Schlichtungsstelle Energie e.V.</h4>
                        <p>Zur Beilegung von Streitigkeiten kann von Privatkunden ein Schlichtungsverfahren bei der
                            <strong>Schlichtungsstelle Energie e.V.</strong> beantragt werden. Voraussetzung dafür ist,
                            dass unser innogy-Kundenservice angerufen und keine beidseitig zufriedenstellende Lösung
                            gefunden wurde. innogy ist zur Teilnahme an dem Schlichtungsverfahren verpflichtet.</p>

                        <p>Schlichtungsstelle Energie e.V.<br/>
                            Friedrichstraße 133, 10117 Berlin<br/>
                            <strong>T</strong> 030-2757240-0,<br/>
                            <strong>F</strong> 030-2757240-69,<br/>
                            <strong>E</strong> <a href="mailto:info@schlichtungsstelle-energie.de">info@schlichtungsstelle-energie.de</a><br/>
                            <a href="https://www.schlichtungsstelle-energie.de/">www.schlichtungsstelle-energie.de</a>
                        </p>

                        <h4>Online-Beilegung von Streitigkeiten</h4>
                        <p>Die EU-Kommission stellt eine Plattform für außergerichtliche Streitschlichtung bereit.
                            Verbrauchern gibt dies die Möglichkeit, Streitigkeiten im Zusammenhang mit Ihrer
                            Online-Bestellung zunächst ohne die Einschaltung eines Gerichts zu klären. Die
                            Streitbeilegungs-Plattform ist unter dem externen Link <a
                                href="https://ec.europa.eu/consumers/odr">https://ec.europa.eu/consumers/odr</a>
                            erreichbar.</p>

                        <h4>Zur Information</h4>
                        <p>innogy nimmt derzeit an keinem weiteren Verbraucherschlichtungsverfahren teil.</p>
                    </div>
                </div>
                <div class="block block-content" *ngIf="providername == 'enviam'">
                    <div class="inner">
                        <h4>Anschrift</h4>
                        <p>envia Mitteldeutsche Energie AG
                            Chemnitztalstraße 13<br/>
                            09114 Chemnitz<br/>
                            <strong>T</strong> +49 (0)371 482-0<br/>
                            <strong>F</strong> +49 (0)371 482-2999<br/>
                            <strong>E</strong> <a href="mailto:info@enviam.de">info@enviam.de</a></p>

                        <h4>Vorsitzender des Aufsichtsrats</h4>
                        <p>Bernd Böddeling</p>

                        <h4>Vorstand</h4>
                        <p>Dr. Stephan Lowis (Vorsitzender),<br/>
                            Dr. Andreas Auerbach,<br/>
                            Ralf Hiltenkamp</p>

                        <h4>Handelsregistereintragung</h4>
                        <p>Handelsregister des Amtsgerichts Chemnitz<br/>
                            Handelsregisternummer: HR B 19 751<br/>
                            Umsatzsteueridentifikationsnummer: DE 8134 27 980</p>

                        <h4>Bildnachweis</h4>
                        <p>enviaM-Gruppe<br/>
                            fotolia<br/>
                            iStock<br/>
                            RWE Imagebank<br/>
                            Michael Setzpfandt<br/>
                            shutterstock</p>

                        <h4>Aufsichtsbehörde</h4>
                        <p>Bundesnetzagentur für Elektrizität, Gas, Telekommunikation, Post und Eisenbahnen<br/>
                            Tulpenfeld 4<br/>
                            53113 Bonn</p>
                    </div>
                </div>

                <div class="block block-content" *ngIf="providername==='eprimo'">
                    <h2>Anschrift</h2>
                    <p>eprimo GmbH<br/>
                        Flughafenstraße 20<br/>
                        63263 Neu-Isenburg<br/>
                        T +49 (0)69 69 76 70-0<br/>
                        F +49 (0)69 69 76 70-111<br/>
                        M <a href="mailto:kundenservice@eprimo.de">kundenservice@eprimo.de</a></p>

                    <p>Amtsgericht Offenbach am Main, Abteilung B
                        Handelsregister-Nr.: HRB 43027<br/>
                        Ust.-IdNr.: DE 813091089<br/>
                        DUNS-Nr: 32-737-2181</p>

                    <h2>Geschäftsführer</h2>
                    <p>Jens Michael Peters (Vorsitzender der Geschäftsführung)<br/>
                        Hans-Martin Hellebrand</p>

                    <p>Verantwortlicher i.S.d. § 55 Abs. 2 RStV: Stefan Semmelroth<br/>
                        c/o eprimo GmbH<br/>
                        Flughafenstraße 20<br/>
                        63263 Neu-Isenburg<br/>

                    <h2>Informationen zum Verbraucherschutz</h2>
                    <p>Die Europäische Kommission stellt eine Online-Plattform für die Online-Streitbeilegung in
                        Verbraucherangelegenheiten zur Verfügung. Sie finden diese hier: <a
                            href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a>.
                        Die Plattform kann in Streitfällen mit eprimo zu online geschlossenen Verträgen mit
                        Verbrauchern zur
                        alternativen Streitbeilegung genutzt werden.</p>

                    <p>Sollten Beanstandungen von eprimo nicht binnen vier Wochen abgeholfen werden, können sich
                        Verbraucher unter den
                        Voraussetzungen des § 111b Abs. 1 Satz 2 EnWG an die folgende Schlichtungsstelle wenden.</p>

                    <p>Schlichtungsstelle Energie e.V.<br/>
                        Friedrichstraße 133<br/>
                        10117 Berlin<br/>
                        <a href="http://www.schlichtungsstelle-energie.de">www.schlichtungsstelle-energie.de</a><br/>
                        <a href="mailto:info@schlichtungsstelle-energie.de">info@schlichtungsstelle-energie.de</a><br/>
                        Tel.: 030 / 27 57 240-0<br/>
                        Fax: 030 / 27 57 240–69</p>

                    <p>eprimo ist zur Teilnahme an dem Streitbeilegungsverfahren gesetzlich verpflichtet.</p>
                </div>

                <div class="block block-content" *ngIf="providername === 'energis' ">
                    <div class="inner">
                        <h3>Seitenbetreiber i.S.d. § 5 TMG</h3>

                        <p>
                            energis GmbH<br>
                            Heinrich-Böcking-Str. 10-14<br>
                            66121 Saarbrücken
                        </p>

                        <p>Tel.: +49 (0) 681 / 9069 - 0</p>

                        <h4>Handelsregister</h4>

                        <p>
                            Registergericht: Amtsgericht Saarbrücken<br>
                            Registernummer: HRB 11004
                        </p>

                        <h4>Geschäftsführer:</h4>

                        <p>Dipl.-Ing. Michael Dewald, Dipl.-Wirtsch.-Ing. Dieter Schäfer</p>

                        <h4>Zuständige Aufsichtsbehörde:</h4>

                        <p>Ministerium für Wirtschaft, Arbeit, Energie und Verkehr<br>
                            Abt. Energie- und Energiekartellaufsicht<br>
                            Franz-Josef-Röder-Straße 17<br>
                            66119 Saarbrücken<br>
                            <span>Telefon: (0681) 501-00 </span><br>
                            <span>E-Mail: <a
                                href="mailto:presse%40wirtschaft.saarland.de">presse@wirtschaft.saarland.de</a></span>
                        </p>

                        <h4>
                            Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:</h4>

                        <p>DE 1869 69 908</p>

                        <h4>Inhaltlich Verantwortlicher gemäß § 55 Abs.
                            2 RStV:</h4>

                        <p>Jochen Strobel<br>
                            (Anschrift wie oben)</p>

                        <h4>Informationen zur
                            Online-Streitbeilegung:</h4>

                        <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS)
                            bereit.<br>
                            Diese Plattform finden Sie unter folgendem Link: <a
                                href="https://ec.europa.eu/consumers/odr/." target="_blank">https://ec.europa.eu/consumers/odr/.</a><br>
                            Verbraucher können diese Plattform nutzen, um ihre Streitigkeiten aus Online-Verträgen
                            beizulegen.</p>

                        <h4>Hinweis gemäß § 36 VSBG:</h4>

                        <p>Wir sind zu einer alternativen Streitbeilegung im Sinne des § 36 VSBG bereit, und bitten
                            Sie zur Streitbeilegung die folgende Stelle anzurufen:</p>

                        <p>Schlichtungsstelle Energie<br>
                            Friedrichstraße 133<br>
                            10117 Berlin<br>
                            Email: <a
                                href="mailto:info@schlichtungsstelle-energie.de">info@schlichtungsstelle-energie.de</a><br>
                            Tel.: +49 (0) 30 / 27 57 240 – 0<br>
                            Fax: +49 (0) 30 / 27 57 240 – 69<br>
                            Webseite: <a href="http://www.schlichtungsstelle-energie.de" target="_blank">www.schlichtungsstelle-energie.de</a><br>
                            Die Nutzung einer alternativen Schlichtungsstelle stellt keine zwingende Voraussetzung
                            für das Anrufen zuständiger ordentlicher Gerichte dar.</p>

                        <h4>Urheberrecht und Bildnachweise:</h4>

                        <p>Die Inhalte von <a href="https://www.energis.de" target="_blank">www.energis.de</a> sind
                            - soweit nicht abweichend angegeben - urheberrechtlich geschützt.<br>
                            Verwendete Fotografien sind ggf. mit Bildnachweisen gekennzeichnet oder unten
                            aufgeführt, soweit sie nicht selbst angefertigt wurden.<br>
                            Die Verwendung von Fotografien auf Drittseiten ist nur im Rahmen der jeweiligen Lizenz
                            der Urheber möglich.</p>

                        <p>Erstellt durch:<br>
                            © IT-Recht-Kanzlei DURY – <a href="http://www.dury.de"
                                                         target="_blank">www.dury.de</a><br>
                            © Website-Check GmbH – <a href="http://www.website-check.de" target="_blank">www.website-check.de</a>
                        </p>
                    </div>
                </div>

                <div class="block block-content" *ngIf="providername === 'lew' ">
                    <div class="inner">
                        <p>
                            Lechwerke AG <br>
                            Schaezlerstraße 3 <br>
                            86150 Augsburg <br><br>
                            T <a href="tel:08213280" title="Telefon">0821
                            328-0</a><br>F 0821 328-1170 <br>E <a
                            href="mailto:kontakt@lew.de">kontakt@lew.de&nbsp;</a><br><br>Postanschrift:
                            86136 Augsburg</p>
                        <p><strong><br>Vorsitzender des Aufsichtsrats:</strong><br>Bernd Böddeling</p>
                        <p><strong><br>Vorstand:<br></strong>Dr. Markus Litpher<br>Norbert Schürmann</p>
                        <p>Sitz der Gesellschaft: Augsburg<br>Handelsregister: HRB 6164, Amtsgericht Augsburg<br>Umsatzsteueridentifikations-Nr.:
                            DE 1274 70 129</p>
                        <p><strong><br>Rechte von Haushaltskunden - Information gemäß § 40 Abs. 2 Ziffer 8 aus dem
                            Energiewirtschaftsgesetz
                            vom 04.08.2011 - <span>&nbsp;</span></strong><strong>Fragen oder Beschwerden im
                            Zusammenhang mit ihrer
                            Energielieferung </strong>können von Verbrauchern an unseren Kundenservice per
                            Post&nbsp;<span>(Lechwerke AG, 86136 Augsburg), telefonisch (0800 539 539 1 kostenfrei) oder per E-Mail (service@lew.de) gerichtet werden.<br><br></span><strong>Der
                                Verbraucherservice der Bundesnetzagentur für den Bereich Elektrizität und
                                Gas</strong><span>&nbsp;</span> stellt
                            Ihnen Informationen über das geltende Recht, Ihre Rechte als Haushaltskunde und über
                            Streitbeilegungsverfahren
                            für die Bereiche Elektrizität und Gas zur Verfügung.</p>
                        <p>Bundesnetzagentur für Elektrizität, Gas, Telekommunikation, Post, Eisenbahnen
                            Verbraucherservice, Postfach 8001,
                            53105 Bonn, Mo. - Do .: 9.00 Uhr - 15.00 Uhr, Fr. 9.00 - 12.00 Uhr,&nbsp;
                            <strong>T</strong><span>&nbsp;</span>
                            +49 (0) 30-2 24 80-500 Bundesweites Infotelefon, <strong>F</strong> +49 (0) 30-2 24
                            80-323,
                            <strong>E </strong><a rel="noopener noreferrer"
                                                  href="mailto:verbraucherservice-energie@bnetza.de"
                                                  target="_blank"
                                                  class="internalLink">verbraucherservice-energie@bnetza.de</a><span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;<br><br></span><a
                                rel="noopener noreferrer" href="mailto:verbraucherservice-energie@bnetza.de"
                                target="_blank"
                                class="internalLink"></a><strong>Zur Beilegung von Streitigkeiten </strong><span>&nbsp;kann von Privatkunden ein Schlichtungsverfahren bei der Schlichtungsstelle Energie eV beantragt werden. Voraussetzung dafür ist, dass unser LEW-Kundenservice angerufen und keine beidseitig zufriedenstellende Lösung gefunden wurde. Unser Unternehmen ist zur Teilnahme am Schlichtungsverfahren der Schlichtungsstelle Energie verpflichtet.&nbsp;</span><span>Schlichtungsstelle Energie eV, Friedrichstraße 133, 10117 Berlin,&nbsp;</span><strong>T&nbsp;</strong><span>+49 (0) 30-2 75 72 40-0,&nbsp;</span><strong>F&nbsp;</strong><span>+49 (0) 30-2 75 72 40-69,&nbsp;</span><strong>I </strong><a
                                rel="noopener noreferrer" class="link link--external"
                                href="https://www.schlichtungsstelle-energie.de/"
                                target="_blank">https://www.schlichtungsstelle-energie.de</a><span>,&nbsp;</span><strong>E </strong><a
                                rel="noopener noreferrer" href="mailto:verbraucherservice-energie@bnetza.de"
                                target="_blank"
                                class="internalLink">info@schlichtungsstelle-energie.de</a><br><br><br><strong>Informationen
                                zur
                                Online-Streitbeilegung für Verbraucher <br></strong><span>Die EU-Kommission hat eine Internetplattform zur Online-Beilegung von Streitigkeiten (sog. „OS-Plattform“) bereitgestellt. Die OS-Plattform dient als Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten für die vertraglichen Verpflichtungen, die aus Online-Kaufverträgen erwachsen. Die OS-Plattform ist unter dem folgenden Link zu erreichen:&nbsp;</span><a
                                rel="noopener noreferrer" class="link link--external"
                                href="http://ec.europa.eu/consumers/odr"
                                target="_blank">https://ec.europa.eu/consumers/odr</a></p>
                    </div>
                </div>

                <div class="block block-content" *ngIf="providername === 'e.on' ">
                    <div class="inner">
                        <p>
                            E.ON Energie Deutschland GmbH <br>
                            Arnulfstraße 203 <br>
                            80634 München <br>
                            Deutschland
                        </p>
                        <p>
                            Tel.: 08 71-95 38 62 00 <br>
                            Fax: 08 71-95 38 62 20
                        </p>

                        <p>
                            <a href="mailto:kundenservice@eon.de">E-Mail</a>
                        </p>

                        <h4>Geschäftsführung</h4>
                        <p>
                            Dr. Victoria Ossadnik (Vorsitzende der Geschäftsführung), Dr. Philip Beckmann, Torsten
                            Flosbach, Dr. Uwe Kolks, Dr. Wolfgang Noetel, Otmar Zisler
                        </p>
                        <p>
                            Sitz: Arnulfstraße 203, 80634 München, Amtsgericht München, HRB 209327 <br>
                            Steuernummer: 9143/304/60211, Umsatzsteuer-ID: DE259922663, Gläubiger-ID: DE41EON00000129793
                        </p>

                        <h4>Verantwortlicher gemäß § 55 Abs. 2 RStV</h4>
                        <p>
                            Mirko Ravens <br>
                            Arnulfstraße 203 <br>
                            80634 München
                        </p>
                    </div>
                </div>

            </div>
        </main>
    </div>
</div>
<div *ngIf="logs" class="overlay-infos">
    <div class="overlay-box">
        <button type="button" (click)="hideChangelog()" class="icon-close fltrt">
            <span>Schließen</span>
        </button>
        <div *ngFor="let logs of changelog">
            <h4>{{ logs.version }}</h4>
            <ul *ngIf="logs.features.length > 0">
                <li class="head">Neue Funktionen:</li>
                <li *ngFor="let feature of logs.features">{{ feature.log }}</li>
            </ul>
            <ul *ngIf="logs.adjustments.length > 0">
                <li class="head">Anpassungen:</li>
                <li *ngFor="let adjustment of logs.adjustments">{{ adjustment.log }}</li>
            </ul>
            <ul *ngIf="logs.bugfixes.length > 0">
                <li class="head">Bugfixes:</li>
                <li *ngFor="let bugfix of logs.bugfixes">{{ bugfix.log }}</li>
            </ul>
        </div>
    </div>
</div>
<div id="overlay-logout" class="overlay-infos" style="display: none;">
    <div class="overlay-box">
        <button type="button" class="close-logout icon-close fltrt">
            <span>Schließen</span>
        </button>
        <h4>Abmelden</h4>
        <p>Wollen Sie sich wirklich abmelden?</p>
        <button type="button" (click)="_apiService.logoutUser()" class="btn orange">Ja</button>
        <button type="button" class="close-logout btn outline">Nein</button>
    </div>
</div>
