<div id="login">
    <fieldset>
        <form (submit)="login(username.value, password.value)">
            <div class="login-logo"></div>
            <div class="app-name">iONA</div>
            <div class="formItem">
                <label for="username">E-Mail-Adresse*</label>
                <input type="text" #username id="username" placeholder="E-Mail-Adresse" class="formText">
            </div>

            <div class="formItem">
                <label for="password">Passwort*</label>
                <input type="password" #password id="password" placeholder="Passwort" class="formText">
                <div class="forget-pw"><a (click)="setPwForgotMail(username.value)" href="#/passwort-vergessen"
                                          title="Passwort vergessen?">Passwort vergessen?</a></div>
            </div>
            <button type="submit" class="formSubmit">Anmelden</button>

            <div class="register">
                <p>Sie haben noch keinen Account?</p>
                <a href="#/registrieren" title="Jetzt registrieren">Jetzt registrieren</a>
            </div>

            <div class="demo" (click)="continueWithDemoMode()" angulartics2On="click" angularticsCategory="Login"
                 angularticsAction="login_demo_mode_start">Demo-Modus
            </div>
        </form>
    </fieldset>
</div>
<div class="bg">
    <video #videoElement width="100%" height="100%" autoplay muted="muted" loop (loadeddata)="onBgVideoLoad($event)">
        <source src="assets/media/background.mp4" type="video/mp4">
    </video>
</div>
