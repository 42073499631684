<div class="sidebar-head counter-wrap">

    <div class="btn-wrap fltrt">
        <button type="button" angulartics2On="click" angularticsCategory="Detailansichten"
                angularticsAction="detail_info_text" angularticsLabel="PowerChecker" class="icon-info">
            <span>Hilfe</span>
        </button>
        <button type="button" class="icon-close">
            <span>Schließen</span>
        </button>
    </div>

    <!-- title -->
    <h2>PowerChecker</h2>

    <!-- Powerchecker stats -->
    <div class="m-t-x" fxLayout="row">
        <div fxFlex="grow" fxLayout="column" class="m-r-x powerchecker-attributes">
            <input #titleInput
                   [disabled]="!editModeEnabled"
                   [ngClass]="{'edit': editModeEnabled, 'text': !editModeEnabled, 'selectable' : editModeEnabled}"
                   (focus)="onInputFocus($event)"
                   type="text" class="title" value="{{plugName}}"
                   placeholder="{{plugName === 'Mein Gerät' || plugName === '' || titleInput.value === '' ? 'Name' : plugName}}">
            <input #locationInput
                   [disabled]="!editModeEnabled"
                   [ngClass]="{'edit': editModeEnabled, 'text': !editModeEnabled, 'selectable' : editModeEnabled}"
                   (focus)="onInputFocus($event)"
                   type="text" class="location" value="{{plugRoom}}"
                   placeholder="{{plugRoom === 'Mein Zimmer' || plugRoom === '' || locationInput.value === ''? 'Raum' : plugRoom}}">
        </div>
        <div fxFlex="" fxLayout="column" fxLayoutAlign="center end">
            <button class="edit-button" (click)="onSaveChanges(titleInput.value, locationInput.value)">
                <ng-container *ngIf="editModeEnabled; else save">
                    <img class="btn-icon" src="assets/images/ui-icons/confirm/cornfirmTransparent@3x.png" alt="">
                </ng-container>
                <ng-template #save>
                    <img class="btn-icon" src="assets/images/ui-icons/edit/editTransparent@3x.png" alt="">
                </ng-template>
            </button>
        </div>
    </div>

    <div class="" fxLayout="row" fxLayoutAlign="center center">
        <app-power-switch [style]="{containerWidth: 200, elementSize: 75, darkTheme: true}"
                          [state]="state"
                          (stateChange)="onPlugStateChange($event)">
        </app-power-switch>
    </div>

</div>

<div class="myDevices-content">
    <div class="msg-wrap hidden">
        <div class="msg info icon-info">
            <button class="icon-close" type="button">
                <span>Schließen</span>
            </button>
            <h3>PowerChecker: Was ist das?</h3>
            <p> Schalten Sie einen Verbraucher einfach und unkompliziert von Unterwegs Ein und Aus. Der iONA
                PowerChecker ermöglicht es Ihnen, Ihren smarten Energiehaushalt nicht nur im Blick zu haben, sondern
                auch aktiv zu steuern. Er lässt sich außerdem vollständig in innogy SmartHome integrieren, in Szenarien
                einbinden und mit anderen Geräten verknüpfen. Einfach mal ausprobieren! </p>
        </div>
    </div>

    <div class="content-container">
        <h3>Anbindung an innogy SmartHome</h3>
        <p> Entfalten Sie zusammen mit innogy SmartHome das ganze Potenzial Ihres PowerCheckers! </p>

        <div class="m-b-m">
            <ngu-carousel #carousel [inputs]="carouselTileConfig" [dataSource]="slideshowAnimations">
                <ngu-tile *nguCarouselDef="let item; let j = index" class="image-tile">
                    <lottie-animation-view
                        [options]="item">
                    </lottie-animation-view>
                </ngu-tile>


                <div NguCarouselPoint
                     class="image-tile-point-list m-a-s"
                     fxLayout="row" fxLayoutAlign="center center">
                    <div *ngFor="let image of slideshowAnimations; let i = index"
                         [class.active]="i===carousel.activePoint"
                         (click)="carousel.moveTo(i)"
                         class="image-tile-point m-a-s">
                    </div>
                </div>

                <button NguCarouselPrev class="carousel-control carousel-prev"
                        [style.opacity]="carousel.isFirst ? 0.3: 1">
                    <i class="icon-arrow-rt"></i>
                </button>
                <button NguCarouselNext class="carousel-control carousel-next"
                        [style.opacity]="carousel.isLast ? 0.3: 1">
                    <i class="icon-arrow-rt"></i>
                </button>

            </ngu-carousel>
        </div>

        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="24px">
            <div>
                <a target="_blank" href="https://iam.innogy.com/fuer-zuhause/smarthome" class="btn button">Mehr
                    Informationen</a>
            </div>
            <h3>innogy SmartHome App</h3>
            <div fxLayout="row" fxLayoutAlign="center center" class="store-links">
                <a target="_blank" class="center-contents"
                   href='https://play.google.com/store/apps/details?id=com.innogy.smarthome&hl=de&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                    <img class="p-r-l" alt='Jetzt bei Google Play' src='assets/images/badges/googleplay.png'/>
                </a>
                <a target="_blank" class="center-contents"
                   href="https://apps.apple.com/de/app/innogy-smarthome/id1133163302?mt=8">
                    <img class="p-l-l" src="assets/images/badges/appstore.png" alt="Laden im AppStore">
                </a>
            </div>
        </div>

    </div>
</div>

