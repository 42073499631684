<div class="container" fxLayout="row" fxLayoutAlign="center center" (click)="outterClickHandler($event)">
    <div #body *ngIf="config !== null" class="overlay-body" fxLayout="column">

        <div class="overlay-header m-b-l" fxLayout="row" fxLayoutAlign="end center">
            <h1 class="m-a-n" fxFlex="grow">
                {{config.title}}
            </h1>
            <div>
                <button class="icon-close round-icon" (click)="hide()"></button>
            </div>
        </div>

        <h2> {{config.info.replace("#", config.appliance)}}</h2>

        <div fxFlex="grow" fxLayout="column" fxLayoutAlign="center center" class="p-v-l">
            <div fxFlex="40" class="appliance-selector-container m-b-m" fxLayout="row" fxLayoutAlign="center center">
                <div class="white-svg" [inlineSVG]="'icons/'+config.icon+'.svg'"></div>
            </div>

            <div fxLayout="row" fxLayoutAlign="center center" class="fw interaction-space">
                <div fxFlex="60px" fxLayout="row" fxLayoutAlign="center center">
                    <button class="round-icon icon-c-minus" (click)="lowerAmount()"></button>
                </div>

                <div fxFlex="60px" fxLayout="row" fxLayoutAlign="center center" class="yellow">
                    <h2 class="m-a-n value m-b-s"> {{config.amount}} </h2>
                </div>

                <div fxFlex="60px" fxLayout="row" fxLayoutAlign="center center">
                    <button class="round-icon icon-c-plus" (click)="raiseAmount()"></button>
                </div>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center">
            <button class="iona-button" (click)="confirm()">Übernehmen</button>
        </div>

    </div>
</div>
