<div class="block block-tile large">
    <div class="inner">

        <div class="fill-h m-b-x" fxLayout="column" fxLayoutAlign="start">
            <h3>PowerChecker</h3>

            <h4 class="indigo">{{plugName }}</h4>
            <h4 class="indigo-dark">{{plugRoom }}</h4>

            <div fxFlex="grow" fxLayout="row" fxLayoutAlign="center center">
                <app-power-switch [style]="{containerWidth: 200, elementSize: 75, darkTheme: false}"
                                  [state]="state"
                                  (stateChange)="onStateChange($event)">
                </app-power-switch>
            </div>

            <h4 fxFlex="40%">
                … <b>AN</b> und <b>AUS</b> schalten ist noch längst nicht alles! <br>
                Erlebe was dein PowerChecker zusammen mit <b>innogy SmartHome </b> kann!
            </h4>
        </div>

        <a href="javascript:;" angulartics2On="click" angularticsCategory="Screens" angularticsAction="screen_view"
           angularticsLabel="screen: PowerChecker - Details; previous_screen: Übersicht"
           title="Details anzeigen" class="powerchecker-details icon-arrow-rt"
           (click)="firstDetailViewAnalytics()">
            <!--            <span>Details anzeigen</span>-->
        </a>

    </div>
</div>
