import {Component} from '@angular/core';

import {Angulartics2GoogleAnalytics} from 'angulartics2/ga';

@Component({
    selector: 'iona-app',
    templateUrl: './app.component.html',
})

export class AppComponent {

	constructor(angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics) {
	}

}
