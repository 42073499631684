import {Injectable, OnDestroy, ɵNOT_FOUND_CHECK_ONLY_ELEMENT_INJECTOR} from '@angular/core';

import {throwError as observableThrowError, Observable, Subject} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';

import {ActiveToast, ToastrService} from 'ngx-toastr';
import {Angulartics2} from 'angulartics2';
import {Md5} from 'ts-md5/dist/md5';
import {environment} from '../../environments/environment';
import {constants} from '../constants';
import {User} from '../user';
import {UserService} from '../user.service';
import {OverlayService} from '../overlay/overlay.service';
import {Url} from '../Url';

import * as moment from 'moment';

@Injectable()
export class ApiService implements OnDestroy {

    /**
     * API-Base URL's. Sollte sich die Schnittstellen URL ändern, ist hier die zentrale Stelle dafür
     */
    private AUTH_BASE_URL = environment.urls.auth;
    private API_BASE_URL = environment.urls.api;

    public DAYS = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
    public MONTHS = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
    public meterStatus: number = 0;

    private refreshtoken = false;
    private checkMeterStatusInterval: any = [];
    private checkMeterNotificationId: number = 0;

    private countLoginError = 0;

    public loggedOut: Subject<boolean> = new Subject();

    public onLoggedIn: Subject<boolean> = new Subject();

    /**
     * MINE
     */
    // private app_state = constants.application.states.none;

    constructor(private _httpClient: HttpClient,
                private _router: Router,
                private _notification: ToastrService,
                public _analytics: Angulartics2,
                private _userService: UserService,
                private _overlayService: OverlayService) {
        // Prüfen ob Tracking deaktiviert
        if (localStorage.getItem('disable_tracking') !== null) {
            this._analytics.developerMode(true);
        }

    }

    ngOnDestroy(): void {
        clearInterval(this.checkMeterStatusInterval);
    }

    /**
     * Get actual Consumption
     */
    public getCurrentConsumption(): any {
        const headers = this.getDefaultHeaders(this.getToken());
        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.instantaneous, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error: any) => {
                if (error.status === 401) {
                    this.logoutUser();
                }

                return observableThrowError(error);
            }),);
    }

    /**
     * Get actual Status
     */
    public getHomeStateStatus(): any {
        let headers = this.getDefaultHeaders(this.getToken());

        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.homeState.current, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error: any) => {
                if (error.status === 401) {
                    this.logoutUser();
                }

                return observableThrowError(error);
            }),);
    }

    /**
     * Get Status-Configuration
     */
    public getHomeStateConfig(): any {
        let headers = this.getDefaultHeaders(this.getToken());

        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.homeState.config, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error: any) => {
                if (error.status === 401) {
                    this.logoutUser();
                }

                return observableThrowError(error);
            }),);
    }

    /**
     * Get live Consumption
     *
     * @param offset Now - X Minutes
     * @param limit Now - X Minutes
     * @param interval Seconds (1), Minutes (60)
     */
    public getLive(offset: number, limit: number, interval: number): any {
        let headers = this.getDefaultHeaders(this.getToken());

        let time_from: any = new Date();
        time_from.setMinutes(time_from.getMinutes() - offset);

        let time_to: any = new Date();
        time_to.setMinutes(time_to.getMinutes() - limit);

        let method: string = time_from.toISOString() + '/' + time_to.toISOString() + '/' + interval;

        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.power + '/' + method, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error: any) => {
                if (error.status === 401) {
                    this.logoutUser();
                }

                return observableThrowError(error);
            }),);
    }

    /**
     * Get Consumer
     *
     * @param offset Now - X Months
     */
    public getElectricalAppliances(offset: number): any {
        let headers = this.getDefaultHeaders(this.getToken());

        let date: any = new Date();
        date.setMonth(date.getMonth() - offset, 1);

        let method: string = date.getFullYear() + '/' + (date.getMonth() + 1);

        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.disaggregation.history + '/' + method, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error: any) => {
                if (error.status === 401) {
                    this.logoutUser();
                }

                return observableThrowError(error);
            }),);
    }

    /**
     * Get Consumer for specific Month
     *
     * @param month Month
     * @param offset Now - X Years
     */
    public getMonthConsumer(month: number, offset: number): any {
        let headers = this.getDefaultHeaders(this.getToken());

        let date: any = new Date();
        date.setFullYear(date.getFullYear() - offset);

        let method: string = date.getFullYear() + '/' + month;

        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.disaggregation.history + '/' + method, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error: any) => {
                if (error.status === 401) {
                    this.logoutUser();
                }

                return observableThrowError(error);
            }),);
    }

    /**
     * Get Consumption
     *
     * @param offset Now - X Days
     */
    public getConsumption(offset: number): any {
        let headers = this.getDefaultHeaders(this.getToken());

        let date: any = new Date();
        date.setDate(date.getDate() - offset);

        let method: string = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + '/' + date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();

        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.electricity.consumption.this + '/days/' + method, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error: any) => {
                if (error.status === 401) {
                    this.logoutUser();
                }

                return observableThrowError(error);
            }),);
    }

    /**
     * Get Consumption for specific Day
     *
     * @param offset Now - X Days
     */
    public getDayConsumption(offset: number): any {
        let headers = this.getDefaultHeaders(this.getToken());

        let date: any = new Date();
        date.setDate(date.getDate() - offset);

        let method: string = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + '/' + date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();

        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.electricity.consumption.this + '/hours/' + method, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error: any) => {
                if (error.status === 401) {
                    this.logoutUser();
                }

                return observableThrowError(error);
            }),);
    }

    /**
     * Get Custom Consumption
     *
     * @param type month/day/....
     * @param date1 From
     * @param date2 To
     */
    public getConsumptionCustom(type: string, date1, date2): any {
        // console.log(`type: ${type}, date1: ${date1}, date2: ${date2}`);
        let headers = this.getDefaultHeaders(this.getToken());

        let method: string = date1.getFullYear() + '-' + (date1.getMonth() + 1) + '-' + date1.getDate() + '/' + date2.getFullYear() + '-' + (date2.getMonth() + 1) + '-' + date2.getDate();

        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.electricity.consumption.this + '/' + type + '/' + method, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error: any) => {
                if (error.status === 401) {
                    this.logoutUser();
                }

                return observableThrowError(error);
            }),);
    }

    /**
     * Compare actual Month with previous Month
     */
    public getCompareMonth(): any {
        let headers = this.getDefaultHeaders(this.getToken());

        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.electricity.consumption.compare + '/month', {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error: any) => {
                if (error.status === 401) {
                    this.logoutUser();
                }

                return observableThrowError(error);
            }),);
    }

    /**
     * Get Compare-Data for specific Area
     *
     * @param from DateTime
     * @param to DateTime
     * @param area Months or Days
     */
    public getCompare(from, to, area): any {
        let headers = this.getDefaultHeaders(this.getToken());

        let date_from: any = new Date(from);
        let date_to: any = new Date(to);

        let method: string = null;

        switch (area) {
            case 'months': {
                method = date_from.getFullYear() + '-' + (date_from.getMonth() + 1) + '/' + date_to.getFullYear() + '-' + (date_to.getMonth() + 1);
                break;
            }
            default: {
                method = date_from.getFullYear() + '-' + (date_from.getMonth() + 1) + '-' + date_from.getDate() + '/' + date_to.getFullYear() + '-' + (date_to.getMonth() + 1) + '-' + date_to.getDate();
                break;
            }
        }

        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.electricity.consumption.this + '/' + area + '/' + method, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error: any) => {
                if (error.status === 401) {
                    this.logoutUser();
                }

                return observableThrowError(error);
            }),);
    }

    /**
     * Get Meter for specific Date
     *
     * @param date Date in Format YYYY-MM-DD
     */
    public getConsumptionForDate(date: any): any {
        let headers = this.getDefaultHeaders(this.getToken());

        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.electricity.consumption.this + '/' + date, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error: any) => {
                if (error.status === 401) {
                    this.logoutUser();
                }

                return observableThrowError(error);
            }),);
    }

    /**
     * Get financial Budget
     */
    public getFinance(): any {
        let headers = this.getDefaultHeaders(this.getToken());

        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.electricity.bill.prediction, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error: any) => {
                if (error.status === 401) {
                    this.logoutUser();
                }

                return observableThrowError(error);
            }),);
    }

    /**
     * Get Meter-Informations
     */
    public getInitialization(): any {
        let headers = this.getDefaultHeaders(this.getToken());
        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.initialization, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error_response: any) => {
                if ('error' in error_response) {
                    if ('error' in error_response.error) {
                        if ('code' in error_response.error.error) {
                            if (error_response.error.error.code === 125) {
                                this._router.navigate(['/registrieren'], {queryParams: {jumpToOnboarding: true}});
                            }
                        }

                    }
                }

                if ('_body' in error_response) {
                    let jsonError;

                    try {
                        jsonError = JSON.parse(error_response._body);
                    } catch (e) {
                        return observableThrowError(error_response);
                    }

                    if ('error' in jsonError) {
                        if ('code' in jsonError.error && jsonError.error.code === 125) {
                            // user not yet onboarded -> Zum Onboarding springen
                            console.log('not yet onboarded rerouting');
                            this._router.navigate(['/registrieren'], {queryParams: {jumpToOnboarding: true}});
                        }
                    }
                }

                return observableThrowError(error_response);
            }));
    }

    /**
     * Get Contract-Informations
     */
    public getContract(): any {
        let headers = this.getDefaultHeaders(this.getToken());

        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.profile.this, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error: any) => {
                if (error.status === 401) {
                    this.logoutUser();
                }

                return observableThrowError(error);
            }),);
    }

    /**
     * Get Profile
     */
    public getProfile(): any {
        let headers = this.getDefaultHeaders(this.getToken());

        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.profile.attributes, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error: any) => {
                if (error.status === 401) {
                    this.logoutUser();
                }

                return observableThrowError(error);
            }),);
    }

    /**
     * Set Profile
     *
     * @param config Profile-Configuration
     */
    public setProfile(config: any): any {
        let headers = this.getDefaultHeaders(this.getToken());

        return this._httpClient.post(this.API_BASE_URL + constants.api.routes.profile.attributes, config, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error: any) => {
                if (error.status === 401) {
                    this.logoutUser();
                }

                return observableThrowError(error);
            }),);
    }

    /**
     * Set E-Mail
     *
     * @param email Email-Address
     */
    public setEmail(email: string): any {
        let headers = this.getDefaultHeaders(this.getToken());

        return this._httpClient.put(this.API_BASE_URL + constants.api.routes.registration.email, {email: email}, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error: any) => {
                if (error.status === 401) {
                    this.logoutUser();
                }

                return observableThrowError(error);
            }),);
    }

    /**
     * Set E-Mail
     *
     * @param oldPass Altes Passwort
     * @param newPass Neues Passwort
     * @param newPassRepeat Wiederholung neues Passwort
     */
    public updatePassword(oldPass: string, newPass: string, newPassRepeat: string): any {
        let headers = this.getDefaultHeaders(this.getToken());

        return this._httpClient.put(this.API_BASE_URL + constants.api.routes.registration.setPassword, {
            oldpass: oldPass,
            newpass1: newPass,
            newpass2: newPassRepeat
        }, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error: any) => {
                const msg = error.error.error.message;
                if ('error' in error) {
                    if ('error' in error.error) {
                        if (error.error.error.code === 264) {
                            const msg = error.error.error.message;
                            if (msg.includes('MAX length')) {
                                this._notification.error('Das gewählte Passwort ist zu lang. Bitte verwenden Sie maximal 16 Zeichen.', 'Passwort ungültig', {timeOut: 6000});
                            } else if (msg.includes('Special characters')) {
                                this._notification.error('Das gewählte Passwort enthält unzulässige Sonderzeichen. Bitte verwenden Sie nur Buchstaben und Zahlen, sowie die folgenden Sonderzeichen: ! \" ( ) = [ ] { } ? \\ + * ~ # , ; . - _ Umlaute und ß sind nicht möglich.', 'Passwort ungültig', {timeOut: 6000});
                            }
                        }
                    }
                }
                console.log(error);
                if (error.status === 401) {
                    this.logoutUser();
                }

                return observableThrowError(error);
            }));
    }

    /**
     * Get Information on whether the users device is online or not.
     */
    public getRegistrationOnline(): Observable<any> {
        let headers = this.getDefaultHeaders(this.getToken());

        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.registration.online, {headers})
            .pipe(
                map(res => res),
                catchError(
                    (error) => {
                        if (error.status === 401) {
                            this.logoutUser();
                        }
                        return observableThrowError(error);
                    }
                )
            );
    }


    /**
     * Get Information on what hardware device is connected to the users account.
     */
    public getRegistrationModel(): Observable<any> {
        let headers = this.getDefaultHeaders(this.getToken());

        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.registration.model, {headers})
            .pipe(
                map(res => res),
                catchError(
                    (error) => {
                        if (error.status === 401) {
                            this.logoutUser();
                        }
                        return observableThrowError(error);
                    }
                )
            );
    }


    public getPlugStatus(): Observable<any> {
        let headers = this.getDefaultHeaders(this.getToken());

        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.plug.relay, {headers})
            .pipe(
                map(res => res),
                catchError(
                    (error) => {
                        if (error.status === 401) {
                            this.logoutUser();
                        }
                        return observableThrowError(error);
                    }
                )
            );

    }

    public setPlugStatus(value): Observable<any> {
        let headers = this.getDefaultHeaders(this.getToken());

        const payload = {
            on_off: value
        };

        return this._httpClient.put(this.API_BASE_URL + constants.api.routes.plug.relay, payload, {headers})
            .pipe(
                map(res => res),
                catchError(
                    (error) => {
                        if (error.status === 401) {
                            this.logoutUser();
                        }
                        return observableThrowError(error);
                    }
                )
            );

    }

    public getStoredUserSettings(): Observable<any> {
        let headers = this.getDefaultHeaders(this.getToken());
        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.configuration.dashboard, {headers})
            .pipe(
                map(res => res),
                catchError(
                    (error) => {
                        if (error.status === 401) {
                            this.logoutUser();
                        }
                        return observableThrowError(error);
                    }
                )
            );

    }

    public setPlugAttributes(): Observable<any> {
        let headers = this.getDefaultHeaders(this.getToken());
        const payload = {
            config: this._userService.getLocalKeyValueStore()
        };
        console.log('sending payload to server: ', payload);
        return this._httpClient.post(this.API_BASE_URL + constants.api.routes.configuration.dashboard, payload, {headers})
            .pipe(
                map(res => res),
                catchError(
                    (error) => {
                        if (error.status === 401) {
                            this.logoutUser();
                        }
                        return observableThrowError(error);
                    }
                )
            );

    }


    /**
     * Reset Password
     *
     * @param email E-Mail
     */
    public resetPassword(email: string): any {
        return this._httpClient.post(this.API_BASE_URL + constants.api.routes.registration.resetPassword + '/' + email, {}).pipe(
            map((res: any) => res),
            catchError((error: any) => {
                return observableThrowError(error);
            }),);
    }


    /**
     * Validate Voucher-Code
     *
     * @param voucher Voucher-Code
     * @param email Email-Address
     */
    public validateVoucher(voucher: string, email: string): any {
        let headers = this.getDefaultHeaders();

        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.registration.voucher + '/' + voucher + '/' + email, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error: any) => {
                return observableThrowError(error);
            }),);
    }

    /**
     * Register new User
     *
     * @param user User-ID
     */
    public registerUser(user: any): any {
        let headers = this.getDefaultHeaders();

        return this._httpClient.post(this.API_BASE_URL + constants.api.routes.registration.noGateway, user, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error: any) => {
                return observableThrowError(error);
            }),);
    }

    /**
     * Register new Device
     *
     * @param mac MAC-Address
     */
    public registerDevice(mac: string): any {
        let headers = this.getDefaultHeaders(this.getToken());

        return this._httpClient.put(this.API_BASE_URL + constants.api.routes.registration.onboard + '/' + mac, {}, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error: any) => {
                return observableThrowError(error);
            }),);
    }

    /**
     * Gateway Registration
     */
    public optInDevice(): any {
        let headers = this.getDefaultHeaders(this.getToken());

        const body = {opt_in: 1};
        return this._httpClient.put(this.API_BASE_URL + constants.api.routes.registration.optIn, body, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error: any) => {
                return observableThrowError(error);
            }),);
    }

    /**
     * Gateway Registration
     */
    public getDeviceStatus(): any {
        let headers = this.getDefaultHeaders(this.getToken());

        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.meter.status, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error: any) => {
                return observableThrowError(error);
            }));
    }

    /**
     * Login User
     *
     * @param username Username
     * @param password Password
     */
    public loginUser(username: string, password: string, first_call = false) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Content-Security-Policy': 'connect-src \'self\' \'unsafe-inline\' https://api.n2g-iona.net'
        });

        let params = new HttpParams();
        params = params.append('method', 'login');
        params = params.append('username', username);
        params = params.append('password', password);

        const body = {method: 'login', username, password};

        this._httpClient.post(this.AUTH_BASE_URL, JSON.stringify(body), {headers: headers}).pipe(
            // this._httpClient.post(this.AUTH_BASE_URL, JSON.stringify(body), {headers: headers}).pipe(
            // this._httpClient.post(this.AUTH_BASE_URL, body, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error) => {
                return observableThrowError(error);
            }))
            .subscribe(
                (response: any) => {
                    if (response === null || response === undefined) {
                        console.log('response null || undefined');
                    }
                    if ('access_token' in response) {
                        let expires: any = new Date();

                        expires.setSeconds(response.expires_in);
                        const u: User = {
                            email: username,
                            provider: null,
                            access_token: response.access_token,
                            refresh_token: response.refresh_token,
                            token_expires: expires,
                            charts: null,
                            nilm_status: null,
                            storage: null,
                            device: null
                        };

                        // console.log('token expires: ', response.expires_in, expires);

                        this._userService.setCurrentUser(u);

                        if (!first_call) {
                            this.getInitialization().subscribe(
                                (data: any) => {
                                    console.log('init data', data);
                                    if ('data' in data) {
                                        if ('profile' in data.data) {
                                            const providername = data.data.profile.labelpartner;
                                            this._userService.setActiveUserProvider(providername);
                                            if (this._router.routerState.snapshot.url === '/login') {
                                                this.trackUser(this._userService.getActiveUserName(), data.data.profile);
                                                this._router.navigate(['/']);
                                            }
                                        }
                                    }
                                },
                                (error) => {
                                    console.log('Error:', error);
                                }
                            );
                        } else {
                            this.onLoggedIn.next(true);
                        }

                    } else {
                        this._notification.error('Es ist ein Problem aufgetreten!');
                    }
                },
                (response: HttpErrorResponse) => {
                    console.log(response);
                    const error: any = response.error;
                    this.countLoginError++;

                    if (this.countLoginError >= 3) {
                        this._notification.error('Möchten Sie Ihr Passwort zurücksetzen? Sie erhalten zunächst eine Email mit einer Anleitung zum weiteren Vorgehen. <a class="btn" href="#/passwort-vergessen" title="Zurücksetzen">Zurücksetzen</a>', '', {
                            disableTimeOut: true,
                            enableHtml: true
                        });
                    }

                    if (response.status === 125) {
                        console.log('User appears to not be onboarded yet');
                    }

                    switch (error.error) {
                        case 'invalid_request': {
                            this._notification.error('E-Mail-Adresse oder Passwort fehlt!');
                            break;
                        }
                        case 'invalid_grant': {
                            this._notification.error('E-Mail-Adresse oder Passwort fehlerhaft!');
                            break;
                        }
                        case 'Maximum number of failed attempts reached. The account is now blocked.': {
                            this._notification.error('Ihr Account wurde nach der 10-maligen Eingabe eines falschen Passworts gesperrt. Möchten Sie Ihr Passwort zurücksetzen? <a class="btn" href="#/passwort-vergessen" title="Zurücksetzen">Zurücksetzen</a>', '', {
                                disableTimeOut: true,
                                enableHtml: true
                            });
                            break;
                        }
                        default: {
                            this._notification.error('Es ist ein Problem aufgetreten!');
                        }
                    }
                }
            );
    }

    /**
     * Logout current User
     */
    public logoutUser() {
        this._userService.logoutActiveUser();

        if (this.isDemoMode()) {
            this._analytics.eventTrack.next({
                action: 'login_demo_mode_end',
                properties: {
                    category: 'Login'
                }
            });
        }

        // notify login component to remove the notification interval
        if (this.isDemoMode()) {
            this.loggedOut.next(true);
        }

        // remove set local storage items
        localStorage.removeItem(constants.application.storage.keys.app_expire);
        localStorage.removeItem(constants.application.storage.keys.app_mode);
        this._userService.deleteAccessToken();

        this._overlayService.closeOverlay();

        console.log('logout at: ', new Date());
        console.log('logged out');

        this._router.navigate(['/login']);
        this.setApplicationState(constants.application.states.none);

        // clear all notifications
        this._notification.clear();
    }

    /**
     * Check and refresh current Token
     */
    public checkToken() {
        const token_expires = this._userService.getActiveUserTokenExpire();
        // console.log('token expires', token_expires);
        if (token_expires !== null) {
            const expires: any = new Date(token_expires);
            const time: any = new Date();

            // console.log(expires);
            // console.log(time);

            if (expires > time) {
                let diff: any = new Date(expires.getTime() - time.getTime());
                if (!this.isDemoMode()) {
                    if ((diff.getMinutes() <= 1) && (this.refreshtoken === false)) {
                        this.refreshToken();
                    }
                }

            } else {
                this.logoutUser();
            }
        } else {
            this.logoutUser();
        }
    }

    /**
     * Starten der regelmäßigen Abfrage des aktuellen Meter-Status -> um zu heraus zu finden, ob evtl. die Notification angezeigt werden muss, dass Meter noch nicht connected ist
     */
    public checkMeterStatus() {
        if (!this.isDemoMode()) {
            this._notification.clear();

            this.meterStatusCall();

            this.checkMeterStatusInterval = setInterval(() => {
                this.meterStatusCall();
            }, 5000);
        }
    }

    /**
     * Call an API, ob Meter connected ist, wenn nicht, Anzeige der Notification "Zähler wird verbunden", wenn doch, Interval clearen
     */
    private meterStatusCall() {
        let notification;
        const that = this;
        this.getDeviceStatus().subscribe(
            (response: any) => {
                if ('data' in response) {
                    if ('current_status' in response.data) {
                        switch (response.data.current_status.toUpperCase()) {
                            case 'READY_FOR_METER_INCLUSION':
                                this.meterStatus = 1;
                                localStorage.removeItem('isMeterConnected');

                                if (this.checkMeterNotificationId == 0) {
                                    notification = this._notification.info('Zähler wird verbunden <br /><a class="go-to-meterstate">Status ansehen</a>', '', {
                                        timeOut: 0,
                                        enableHtml: true,
                                        toastClass: 'toast-meterstatus toast'
                                    });
                                    setTimeout(function() {
                                        $('.go-to-meterstate').click(function() {
                                            that._router.navigate(['/registrieren'], {queryParams: {jumpToMeterstate: true}});
                                        });
                                    }, 500);

                                    this.checkMeterNotificationId = notification.toastId;
                                    notification.onHidden.subscribe(
                                        (value) => {
                                            this._notification.clear(this.checkMeterNotificationId);
                                            this.checkMeterNotificationId = 0;
                                        }
                                    );
                                }
                                break;
                            case 'CONTACT_WITH_METER':
                                this.meterStatus = 2;
                                localStorage.removeItem('isMeterConnected');

                                if (this.checkMeterNotificationId == 0) {
                                    notification = this._notification.info('Zähler wird verbunden <br /><a class="go-to-meterstate">Status ansehen</a>', '', {
                                        timeOut: 0,
                                        enableHtml: true,
                                        toastClass: 'toast-meterstatus toast'
                                    });
                                    setTimeout(function() {
                                        $('.go-to-meterstate').click(function() {
                                            that._router.navigate(['/registrieren'], {queryParams: {jumpToMeterstate: true}});
                                        });
                                    }, 500);

                                    this.checkMeterNotificationId = notification.toastId;
                                    notification.onHidden.subscribe(
                                        (value) => {
                                            this._notification.clear(this.checkMeterNotificationId);
                                            this.checkMeterNotificationId = 0;
                                        }
                                    );
                                }

                                break;
                            case 'CONNECTED_WITH_METER':
                                this.meterStatus = 3;
                                localStorage.setItem('isMeterConnected', '1');

                                if (this.checkMeterNotificationId > 0) {
                                    this._notification.clear(this.checkMeterNotificationId);
                                }

                                clearInterval(this.checkMeterStatusInterval);
                                break;
                        }
                    }
                }
            },
            (e) => {
                console.log(e);
                // Error!
            });
    }

    /**
     * Get Changelog
     */
    public getChangelog(): any {
        return this._httpClient.get('assets/changelog.json').pipe(
            map((res: any) => res));
    }

    /**
     * Get Difference of Date
     *
     * @param date DateTime
     */
    public getDifference(date: any): string {
        let diff: any = new Date().getTime() - date.getTime();

        let days: any = Math.floor(diff / (1000 * 60 * 60 * 24));
        diff = diff % (1000 * 60 * 60 * 24);

        let hours: any = Math.floor(diff / (1000 * 60 * 60));
        diff = diff % (1000 * 60 * 60);

        let minutes: any = Math.floor(diff / (1000 * 60));
        diff = diff % (1000 * 60);

        let seconds: any = Math.floor(diff / 1000);

        if (days > 0) {
            if (days === 1) {
                return days + ' Tag';
            } else {
                return days + ' Tagen';
            }
        } else if (hours > 0) {
            if (hours === 1) {
                return hours + ' Stunde';
            } else {
                return hours + ' Stunden';
            }
        } else if (minutes > 0) {
            if (minutes === 1) {
                return minutes + ' Minute';
            } else {
                return minutes + ' Minuten';
            }
        } else if (seconds > 0) {
            if (seconds === 1) {
                return seconds + ' Sekunde';
            } else {
                return seconds + ' Sekunden';
            }
        } else {
            return 'jetzt';
        }
    }

    /**
     * Get Weeknumber of Date
     *
     * @param date DateTime
     */
    public getWeek(date: any): number {
        let now: any = new Date(date);
        now.setHours(0, 0, 0);
        now.setDate(now.getDate() + 4 - (now.getDay() || 7));

        let start: any = new Date(now.getFullYear(), 0, 1);

        return Math.ceil((((now.valueOf() - start.valueOf()) / 86400000) + 1) / 7);
    }

    /**
     * Get Date
     *
     * @param offset Now - X Days
     */
    public getDate(offset: number): string {
        let date: any = new Date();
        date.setDate(date.getDate() - offset);

        return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear();
    }

    /**
     * Get Dayname
     *
     * @param offset Now - X Days
     */
    public getDayname(offset: number): string {
        let date: any = new Date();
        date.setDate(date.getDate() - offset);

        return this.DAYS[date.getDay()];
    }

    /**
     * Get Monthname
     *
     * @param offset Now - X Days
     */
    public getMonthname(offset: number): string {
        let date: any = new Date();
        date.setMonth(date.getMonth() - offset);

        return this.MONTHS[date.getMonth()];
    }


    /**
     * Refresh current Token
     */
    private refreshToken() {
        // console.log('refresh token called');

        let headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Content-Security-Policy': 'connect-src \'self\' \'unsafe-inline\' https://api.n2g-iona.net'
        });

        let params = new HttpParams();
        params = params.append('method', 'refresh');
        params = params.append('refresh_token', this._userService.getActiveUserRefreshToken());
        this.refreshtoken = true;

        const body = {method: 'refresh', refresh_token: this._userService.getActiveUserRefreshToken()};


        this._httpClient.post(this.AUTH_BASE_URL, JSON.stringify(body), {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error) => {
                return observableThrowError(error);
            }))
            .subscribe(
                (response: any) => {
                    if ('access_token' in response) {
                        let expires: any = new Date();
                        expires.setSeconds(response.expires_in);
                        this._userService.updateActiveUserAccessToken(response.access_token);
                        this._userService.updateActiveUserRefreshToken(response.refresh_token);
                        this._userService.updateActiveUserTokenExpire(expires);

                        this.getInitialization().subscribe(
                            (data: any) => {
                                if ('data' in data) {
                                    if ('profile' in data.data) {
                                        const providername = data.data.profile.labelpartner;
                                        this._userService.setActiveUserProvider(providername);
                                        if (this._router.routerState.snapshot.url === '/login') {
                                            this.trackUser(this._userService.getActiveUserName(), data.data.profile);
                                            this._router.navigate(['/']);
                                        }
                                    }
                                }
                            },
                            (error) => {
                                console.log('Error:', error);
                            }
                        );
                    }
                },
                () => {
                    this.logoutUser();
                },
                () => {
                    this.refreshtoken = false;
                }
            );
    }

    /**
     * Standard-Header für API-Calls
     *
     * @param accessToken
     */
    private getDefaultHeaders(accessToken: string = '') {
        const headers = new HttpHeaders({
            'Content-Security-Policy': 'connect-src \'self\' \'unsafe-inline\' https://api.n2g-iona.net'
        });

        if (typeof accessToken != 'undefined' && accessToken !== null && accessToken.length > 0) {
            const header_token = new HttpHeaders({'Authorization': 'Bearer ' + accessToken});
            return header_token;
        }

        return headers;
    }

    /**
     * Get current Token
     */
    private getToken(): string {
        // console.log('getToken() - token from service', this._userService.getActiveUserAccessToken());
        return this._userService.getActiveUserAccessToken();
    }


    /**
     * Request the current NILM status
     */
    public getNilmStatus(): Observable<any> {
        const headers = this.getDefaultHeaders(this.getToken());
        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.nilm.status, {headers: headers}).pipe(
            map((res: any) => res),
            catchError((error: any) => {
                if (error.status === 401) {
                    this.logoutUser();
                }
                return observableThrowError(error);
            }));
    }

    /**
     * Send User-Data to Google Analytics
     *
     * @param user Email-Address
     * @param profile Profile-Informations
     */
    private trackUser(user: string, profile: any) {
        this._analytics.eventTrack.next({
            action: 'user_id',
            properties: {
                category: 'User Properties',
                label: new Md5().appendStr(user).end()
            }
        });

        this._analytics.eventTrack.next({
            action: 'device_id',
            properties: {
                category: 'User Properties',
                label: profile.labelpartner_id
            }
        });

        this._analytics.eventTrack.next({
            action: 'labelpartner',
            properties: {
                category: 'User Properties',
                label: profile.labelpartner
            }
        });

        this._analytics.eventTrack.next({
            action: 'gateway_id',
            properties: {
                category: 'User Properties',
                label: profile.smartbridge_mac
            }
        });
    }


    /**
     * HAPPY HOURS
     */
    userParticipatesInHappyHour(): Observable<any> {
        const headers = this.getDefaultHeaders(this.getToken());

        const url = new Url(null);
        url.push(this.API_BASE_URL);
        url.push(constants.api.routes.iona.happyHour.participation);

        return this._httpClient.get(url.toString(), {headers})
            .map((d) => d)
            .catch((e) => {
                console.log(e);
                return observableThrowError(e);
            });
    }

    getHappyHourSchedule(year: number, month: number): Observable<any> {
        const headers = this.getDefaultHeaders(this.getToken());

        const url = new Url(null);
        url.push(this.API_BASE_URL);
        url.push(constants.api.routes.iona.happyHour.schedule);
        url.push(year.toString());
        url.push(month.toString());

        return this._httpClient.get(url.toString(), {headers})
            .map((d) => d)
            .catch((e) => {
                console.log(e);
                return observableThrowError(e);
            });
    }


    /**
     * Get the consumption-alert during happy hour for a specific timeframe
     * @param fct
     * @param first
     * @param second
     */
    getHappyHourConsumptionFor(fct: 'day' | 'week' | 'month' | 'year', first: string, second: string): Observable<any> {
        const headers = this.getDefaultHeaders(this.getToken());

        const url = new Url(null);
        url.push(this.API_BASE_URL);

        switch (fct) {
            case 'day':
                url.push(constants.api.routes.iona.happyHour.consumption.electricity.days);
                break;
            case 'month':
                url.push(constants.api.routes.iona.happyHour.consumption.electricity.months);
                break;
            case 'year':
                url.push(constants.api.routes.iona.happyHour.consumption.electricity.years);
                break;
            case 'week':
                url.push(constants.api.routes.iona.happyHour.consumption.electricity.days);
                break;

        }

        url.push(first.toString());
        url.push(second.toString());

        return this._httpClient.get(url.toString(), {headers})
            .map((res: any) => {
                    if (fct === 'week') {
                        if ('data' in res) {
                            const extracted_data = {};
                            const data = res['data'];
                            for (const element of data) {
                                const week = moment(element.timestamp).week();
                                if (extracted_data[week] === null || extracted_data[week] === undefined) {
                                    extracted_data[week] = {value: 0, timestamp: element.timestamp};
                                }
                                extracted_data[week].value += element.measured;
                            }
                            const final_data = [];
                            for (const key in extracted_data) {
                                const obj = extracted_data[key];
                                final_data.push({
                                    timestamp: obj.timestamp,
                                    measured: obj.value
                                });
                            }
                            return {status: 'ok', data: final_data};
                        }
                        return res;
                    } else {
                        return res;
                    }
                }
            )
            .catch((e) => {
                console.log(e);
                return observableThrowError(e);
            });
    }


    /**
     * Change some tracking state
     * @param state
     */
    public changeTrackingState(state: boolean): void {
        if (state) {
            this._analytics.developerMode(false);
        } else {
            this._analytics.developerMode(true);
        }
    }

    setApplicationState(state: string): void {
        localStorage.setItem(constants.application.storage.keys.app_mode, state);
    }

    enableDemoMode(current_time: Date): void {
        localStorage.setItem(constants.application.storage.keys.app_mode, constants.application.states.demo);
        localStorage.setItem(constants.application.storage.keys.app_expire, current_time.toString());
    }

    getAppMode(): string {
        return localStorage.getItem(constants.application.storage.keys.app_mode);
    }

    isLiveMode(): boolean {
        return localStorage.getItem(constants.application.storage.keys.app_mode) === constants.application.states.live;
    }

    isDemoMode(): boolean {
        return localStorage.getItem(constants.application.storage.keys.app_mode) === constants.application.states.demo;
    }

    isNoneMode(): boolean {
        return localStorage.getItem(constants.application.storage.keys.app_mode) === constants.application.states.none;
    }

}
