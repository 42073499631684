import {Component, OnInit, OnDestroy} from '@angular/core';

import {Chart} from 'angular-highcharts';
import {ToastrService} from 'ngx-toastr';
import {Angulartics2} from 'angulartics2';

import {ApiService} from '../../services/api.service';
import {MockDataService} from '../../mock-data.service';

@Component({
    selector: 'consumption-details',
    templateUrl: './details.consumption.html',
    viewProviders: [ApiService]
})

export class ConsumptionDetails implements OnInit, OnDestroy {

    refresh: any = [];

    chart: any = {};

    alarm: any = {
        limit: 0,
        trend: 0,
        since: null
    };

    config: any = {
        notify: 0
    };

    constructor(private _notification: ToastrService,
                private _analytics: Angulartics2,
                private _apiService: ApiService,
                private mockData: MockDataService) {
    }

    /**
     * Widget Verbrauchsalarm ist aktuell deaktiviert und ohne Funktion
     */
    ngOnInit() {
        this.initChart();

        if (this._apiService.isDemoMode()) {
            this.getConsumptionMock();
            this.getHomeStateStatusMock();

            this.refresh = setInterval(() => {
                this.getConsumptionMock();
                this.getHomeStateStatusMock();
            }, 10000);
            return;
        }

        this.getConsumption();
        this.getStatus();

        this.refresh = setInterval(() => {
            this.getConsumption();
            this.getStatus();
        }, 10000);
    }

    ngOnDestroy() {
        clearInterval(this.refresh);
    }

    getConsumption() {
        this._apiService.getLive(60 * 12, 0, 60 * 30).subscribe(
            (data: any) => {
                if ('data' in data) {
                    this.handleConsumption(data.data.results);
                }
            },
            () => {
                let chart: any = $('#chart-consumption-details').highcharts();
                chart.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');
            }
        );
    }

    private getConsumptionMock(): void {
        this.mockData.getLiveData(60 * 12, 4, 0, 60 * 30).subscribe(
            (data: any) => {
                    this.handleConsumption(data);
            },
            () => {
                let chart: any = $('#chart-consumption-details').highcharts();
                chart.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');
            }
        );
    }


    private handleConsumption(data): void {
        this.chart.removeSerie(0);
        this.chart.addSerie(
            {
                name: null,
                data:
                    data.map((con: any) => {
                        return [
                            new Date(con.timestamp).getTime(),
                            con.power
                        ];
                    }),
                color: '#EB4B0A'
            }
        );

        if (this.alarm.limit === 0) {
            this.colorChart();
        }

        let chart: any = $('#chart-consumption-details').highcharts();
        chart.hideLoading();
    }


    colorChart() {
        let from = new Date();
        from.setHours(0);
        from.setMinutes(0);
        from.setSeconds(0);

        let to = new Date();
        to.setHours(23);
        from.setMinutes(59);
        from.setSeconds(59);

        let chart: any = $('#chart-consumption-details').highcharts();
        chart.update({
            xAxis: {
                plotBands: [
                    {
                        color: '#f2f2f2',
                        from: from,
                        to: to
                    }
                ]
            },
        });

        if (this._apiService.isDemoMode()) {
            this.mockData.getHomeStateConfig().subscribe(
                (data: any) => {
                    if ('data' in data) {
                        if ('thresholds' in data.data) {
                            if (1 in data.data.thresholds) {
                                this.handleHomeStateConfig(data.data);
                            }
                        }
                    }
                }
            );
            return;
        }

        this._apiService.getHomeStateConfig().subscribe(
            (data: any) => {
                if ('data' in data) {
                    if ('thresholds' in data.data) {
                        if (1 in data.data.thresholds) {
                            this.handleHomeStateConfig(data.data);
                        }
                    }
                }
            }
        );
    }

    saveNotification() {
        if (this.config.notify > 0) {
            this.config.notify = 0;

            this._analytics.eventTrack.next({
                action: 'push_disable',
                properties: {
                    category: 'Push',
                    label: 'type: threshold; screen: Verbrauchs-Alarm'
                }
            });

            this._notification.warning('Benachrichtigung deaktiviert!');
        } else {
            this.config.notify = 1;

            this._analytics.eventTrack.next({
                action: 'push_enable',
                properties: {
                    category: 'Push',
                    label: 'type: threshold; screen: Verbrauchs-Alarm'
                }
            });

            this._notification.success('Benachrichtigung aktiviert!');
        }

        /*
        TODO: Einstellungen speichern
         */
        this._notification.info('Ihre Einstellung kann noch nicht übernommen werden!');
    }


    private getHomeStateStatusMock(): void {
        this.mockData.getHomeStateStatus().subscribe(
            (res) => {
                if ('data' in res) {
                    this.handleHomeStateStatus(res.data);
                }
            }
        );
    }

    private getStatus() {
        this._apiService.getHomeStateStatus().subscribe(
            (data: any) => {
                if ('data' in data) {
                    this.handleHomeStateStatus(data.data);
                }
            }
        );
    }

    private handleHomeStateStatus(data: any): void {
        this.alarm.trend = data.current_zone;
        this.alarm.since = this._apiService.getDifference(new Date(data.since));
    }

    private handleHomeStateConfig(data: any): void {
        let chart: any = $('#chart-consumption-details').highcharts();
        this.alarm.limit = data.thresholds[1];
        chart.update({
            yAxis: {
                plotLines: [
                    {
                        value: this.alarm.limit,
                        color: '#B9280A',
                        width: 2,
                        zIndex: 3,
                        label: {
                            text: this.alarm.limit.toLocaleString('de') + ' Watt',
                            style: {
                                color: '#B9280A',
                                fontFamily: 'Innogy medium, sans-serif',
                                fontSize: '20px'
                            },
                            align: 'right',
                            y: 20,
                            x: -20
                        }
                    }
                ],
                max: this.alarm.limit
            }
        });
    }


    private initChart(): void {

        this.chart = new Chart({
            chart: {
                type: 'column',
                backgroundColor: 'rgba(255, 255, 255, 0)',
                margin: [0, 0, 40, 0]
            },
            title: {
                text: null
            },
            xAxis: {
                type: 'datetime',
                tickInterval: 60 * 60 * 1000,
                dateTimeLabelFormats: {
                    millisecond: '%H:%M',
                    second: '%H:%M',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '%H:%M<br/>%d.%m',
                    week: '%d.%m',
                    month: '%m.%Y',
                    year: '%Y'
                },
                labels: {
                    style: {
                        fontSize: '13px'
                    }
                },
                minPadding: 0,
                maxPadding: 0
            },
            yAxis: {
                title: {
                    text: null
                },
                labels: {
                    align: 'right',
                    x: 60,
                    y: 15,
                    step: 2,
                    formatter: function() {
                        if (this.value >= 1) {
                            return this.value.toLocaleString('de-DE') + ' W';
                        } else {
                            return null;
                        }
                    }
                }
            },
            tooltip: {
                enabled: false
            },
            plotOptions: {
                column: {
                    pointWidth: 30,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                series: {
                    showInLegend: false,
                    animation: false
                }
            },
            series: [],
            credits: {
                enabled: false
            }
        });
    }

}
