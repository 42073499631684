/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./connection-indicator.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/flex-layout/extended";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "../height-control.directive";
import * as i6 from "./connection-indicator.component";
var styles_ConnectionIndicatorComponent = [i0.styles];
var RenderType_ConnectionIndicatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConnectionIndicatorComponent, data: {} });
export { RenderType_ConnectionIndicatorComponent as RenderType_ConnectionIndicatorComponent };
function View_ConnectionIndicatorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "indicator inverted"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "active-inv": 0 }), i1.ɵdid(4, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(5, { "active-inv": 0 }), i1.ɵdid(6, 606208, null, 0, i5.HeightControlDirective, [i1.ElementRef, i1.Renderer2], { heightControl: [0, "heightControl"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "indicator inverted"; var currVal_1 = _ck(_v, 3, 0, _co.determineBarActive(_v.context.$implicit)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 5, 0, _co.determineBarActive(_v.context.$implicit)); var currVal_3 = "indicator inverted"; _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_4 = i1.ɵinlineInterpolate(1, "", ((_v.context.index + 1) * (100 / _co.config.ranges.length)), ""); _ck(_v, 6, 0, currVal_4); }, null); }
function View_ConnectionIndicatorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConnectionIndicatorComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config.ranges; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ConnectionIndicatorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "indicator"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "active": 0 }), i1.ɵdid(4, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(5, { "active": 0 }), i1.ɵdid(6, 606208, null, 0, i5.HeightControlDirective, [i1.ElementRef, i1.Renderer2], { heightControl: [0, "heightControl"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "indicator"; var currVal_1 = _ck(_v, 3, 0, _co.determineBarActive(_v.context.$implicit)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 5, 0, _co.determineBarActive(_v.context.$implicit)); var currVal_3 = "indicator"; _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_4 = i1.ɵinlineInterpolate(1, "", ((_v.context.index + 1) * (100 / _co.config.ranges.length)), ""); _ck(_v, 6, 0, currVal_4); }, null); }
function View_ConnectionIndicatorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConnectionIndicatorComponent_4)), i1.ɵdid(1, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config.ranges; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ConnectionIndicatorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { root_container: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["container", 1]], null, 3, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConnectionIndicatorComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["inverted", 2]], null, 0, null, View_ConnectionIndicatorComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.invert_colors; var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_ConnectionIndicatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-connection-indicator", [], null, null, null, View_ConnectionIndicatorComponent_0, RenderType_ConnectionIndicatorComponent)), i1.ɵdid(1, 114688, null, 0, i6.ConnectionIndicatorComponent, [i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConnectionIndicatorComponentNgFactory = i1.ɵccf("app-connection-indicator", i6.ConnectionIndicatorComponent, View_ConnectionIndicatorComponent_Host_0, { value: "value", container_height: "container_height", config: "config", invert_colors: "invert_colors", disabled: "disabled", ignoreValue: "ignoreValue" }, {}, []);
export { ConnectionIndicatorComponentNgFactory as ConnectionIndicatorComponentNgFactory };
