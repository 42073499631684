import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class UserService {
    constructor() {
        this.storage_keys = {
            users: 'users',
            user_active: 'user-active',
        };
        this.plugAttributesChanged = new Subject();
    }
    setCurrentUser(user) {
        const users_str = this.getElementForKey(this.storage_keys.users);
        let users_obj;
        if (users_str === null || users_str === undefined) {
            users_obj = [];
            // set initial value of consumption alert to false
            user.initial_consumption_alert = true;
            user.charts = null;
            users_obj.push(user);
            localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
        }
        else {
            users_obj = JSON.parse(users_str);
            let user_exists = false;
            for (const u of users_obj) {
                if (u.email === user.email) {
                    u.access_token = user.access_token;
                    u.refresh_token = user.refresh_token;
                    u.token_expires = user.token_expires;
                    user_exists = true;
                }
            }
            if (!user_exists) {
                users_obj.push(user);
            }
            localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
        }
        this.setActiveUser(user.email);
    }
    getUser(email) {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        return users_obj.find((user) => user.email === email);
    }
    getActiveUserName() {
        return this.getElementForKey(this.storage_keys.user_active);
    }
    setActiveUserProvider(provider) {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.provider = provider;
            }
        }
        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    }
    getActiveUserProvider() {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.provider.toLowerCase();
            }
        }
        return null;
    }
    setActiveUserCharts(charts) {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.charts = charts;
            }
        }
        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    }
    getActiveUserCharts() {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.charts;
            }
        }
        return null;
    }
    getActiveUserAccessToken() {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                const token = user.access_token;
                return token;
            }
        }
        return null;
    }
    updateActiveUserAccessToken(access_token) {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.access_token = access_token;
            }
        }
        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    }
    getActiveUserRefreshToken() {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.refresh_token;
            }
        }
        return null;
    }
    updateActiveUserRefreshToken(refresh_token) {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.refresh_token = refresh_token;
            }
        }
        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    }
    getActiveUserTokenExpire() {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.token_expires;
            }
        }
        return null;
    }
    updateActiveUserTokenExpire(new_expire) {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        let expires;
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.token_expires = new_expire;
            }
        }
        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    }
    logoutActiveUser() {
        this.removeActiveUser();
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return;
        }
        const users = JSON.parse(users_str);
        for (const user of users) {
            if (user.email === this.getActiveUserName()) {
                user.refresh_token = '';
                user.access_token = '';
            }
        }
        localStorage.setItem(this.storage_keys.users, JSON.stringify(users));
    }
    getActiveUserNilmStatus() {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.nilm_status;
            }
        }
        return null;
    }
    updateActiveUserNilmStatus(nilm_status) {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.nilm_status = nilm_status;
            }
        }
        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    }
    updateActiveUserNilmStatusForAppliance(appliance, model_count) {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        let nilm_values;
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                nilm_values = user.nilm_status;
            }
        }
        nilm_values['timeBasedAppliances'][appliance].models = model_count;
        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    }
    getLocalKeyValueStore() {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.storage;
            }
        }
        return null;
    }
    getPlugAttributes() {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                if (user.storage === null) {
                    return null;
                }
                if (user.storage.power_checker === null) {
                    return null;
                }
                return user.storage.power_checker;
            }
        }
        return null;
    }
    updatePlugAttributes(attributes) {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                if (user.storage === null) {
                    user.storage = {};
                }
                user.storage.power_checker = attributes;
            }
        }
        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    }
    deleteAccessToken() {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.access_token = null;
            }
        }
        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    }
    getUserDevice() {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                if (user.device === null || user.device === undefined) {
                    return null;
                }
                return user.device;
            }
        }
        return null;
    }
    updateUserDevice(device) {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.device = device;
            }
        }
        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    }
    getConsumptionAlertAttribute() {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                if (user.initial_consumption_alert === null || user.initial_consumption_alert === undefined) {
                    return null;
                }
                return user.initial_consumption_alert;
            }
        }
        return null;
    }
    setConsumptionAlertAttribute(val) {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.initial_consumption_alert = val;
            }
        }
        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    }
    setActiveUser(email) {
        localStorage.setItem(this.storage_keys.user_active, email);
    }
    removeActiveUser() {
        localStorage.removeItem(this.storage_keys.user_active);
    }
    getElementForKey(key) {
        return localStorage.getItem(key);
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(); }, token: UserService, providedIn: "root" });
