<div id="container">
    <aside class="sidebar lft">
        <a href="#" id="hamburger" title="Menü" class="mobi">
            <span class="burger">
                <i class="top"></i>
                <i class="middle"></i>
                <i class="bottom"></i>
            </span>
            <span class="text"> Menü</span>
        </a>
        <div class="blackbox">
            <div class="version">
                <a href="javascript:;" (click)="showChangelog()">{{ version }}</a>
            </div>
            <div [class.demo]="username === 'Demo'" class="logo">
                <a href="#/" title="iONA">
                    <span>iONA</span>
                </a>
            </div>
        </div>
        <div class="block block-menu">
            <nav class="inner">
                <ul class="clearfix">
                    <li><a href="#/" title="Übersicht">Übersicht</a></li>
                    <li><a href="#/meine-daten" title="Meine Daten">Meine Daten</a></li>
                    <li><a href="#/mein-haushalt" title="Mein Haushalt" class="active">Mein Haushalt</a></li>
                    <li><a href="#/mein-kundenkonto" angulartics2On="click" angularticsCategory="Meine Daten"
                           angularticsAction="my_account_enter"
                           title="{{ providerMenuName }}">{{ providerMenuName }}</a></li>
                    <li><a href="#/einstellungen" title="Einstellungen">Einstellungen</a></li>
                    <li><a href="#/kontakt" title="Kontakt">Kontakt</a></li>
                    <li><a href="javascript:;"
                           title="{{ _apiService.isDemoMode() ? 'Demo-Modus verlassen' : 'Logout' }}"
                           class="open-logout">{{ _apiService.isDemoMode() ? 'Demo-Modus verlassen' : 'Logout' }}</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="block block-submenu">
            <div class="inner">
                <div *ngIf="providerclass?.length > 0" class="presentbar">
                    <small>Präsentiert von</small>
                    <div [class]="providerclass"></div>
                </div>
                <nav class="submenu">
                    <ul>
                        <li><a href="#/impressum" angulartics2On="click" angularticsCategory="Menü"
                               angularticsAction="view_imprint" title="Impressum">Impressum</a></li>
                        <li>
                            <a [href]="privacyLink" target="_blank"
                               angulartics2On="click" angularticsCategory="Menü" angularticsAction="view_privacy"
                               title="Datenschutz">Datenschutz</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </aside>
    <div id="inner">
        <main id="mainContent" class="clearfix">
            <div class="mein-haushalt-wrap">
                <div class="head">
                    <h2>Mein Haushalt</h2>
                    <h3 *ngIf="fullname?.length > 0">Hallo {{fullname}},</h3>
                    <p>hier haben Sie die Möglichkeit, Angaben zu Ihrem Haushalt zu machen.</p>
                </div>
                <div class="mein-haushalt">
                    <div [class.icon-checked]="household.Attributes.PropertyType"
                         [class.icon-empty]="!household.Attributes.PropertyType" class="eigenschaft">
                        <h3>In was für einem Objekt wohnen Sie?</h3>
                        <div class="grid six clearfix">
                            <div class="box">
                                <input type="radio" name="PropertyType" id="PropertyType-PT.01" [value]="'PT.01'"
                                       [checked]="household.Attributes.PropertyType === 'PT.01'"
                                       (change)="setSetting('PropertyType', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/PT.01.svg'"></span>
                                    <label for="PropertyType-PT.01">freistehendes Haus</label>
                                </div>
                            </div>
                            <div class="box">
                                <input type="radio" name="PropertyType" id="PropertyType-PT.02" [value]="'PT.02'"
                                       [checked]="household.Attributes.PropertyType === 'PT.02'"
                                       (change)="setSetting('PropertyType', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/PT.02.svg'"></span>
                                    <label for="PropertyType-PT.02">Doppelhaushälfte</label>
                                </div>
                            </div>
                            <div class="box">
                                <input type="radio" name="PropertyType" id="PropertyType-PT.03" [value]="'PT.03'"
                                       [checked]="household.Attributes.PropertyType === 'PT.03'"
                                       (change)="setSetting('PropertyType', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/PT.03.svg'"></span>
                                    <label for="PropertyType-PT.03">Bungalow</label>
                                </div>
                            </div>
                            <div class="box">
                                <input type="radio" name="PropertyType" id="PropertyType-PT.04" [value]="'PT.04'"
                                       [checked]="household.Attributes.PropertyType === 'PT.04'"
                                       (change)="setSetting('PropertyType', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/PT.04.svg'"></span>
                                    <label for="PropertyType-PT.04">Reihenhaus</label>
                                </div>
                            </div>
                            <div class="box">
                                <input type="radio" name="PropertyType" id="PropertyType-PT.05" [value]="'PT.05'"
                                       [checked]="household.Attributes.PropertyType === 'PT.05'"
                                       (change)="setSetting('PropertyType', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/PT.05.svg'"></span>
                                    <label for="PropertyType-PT.05">Reiheneckhaus</label>
                                </div>
                            </div>
                            <div class="box">
                                <input type="radio" name="PropertyType" id="PropertyType-PT.06" [value]="'PT.06'"
                                       [checked]="household.Attributes.PropertyType === 'PT.06'"
                                       (change)="setSetting('PropertyType', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/PT.06.svg'"></span>
                                    <label for="PropertyType-PT.06">Wohnung</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [class.icon-checked]="household.Attributes.PropertySize"
                         [class.icon-empty]="!household.Attributes.PropertySize" class="eigenschaft">
                        <h3>Wie groß ist Ihre Wohnfläche?</h3>
                        <div class="grid four clearfix">
                            <div class="box">
                                <input type="radio" name="PropertySize" id="PropertySize-PS.01" [value]="'PS.01'"
                                       [checked]="household.Attributes.PropertySize === 'PS.01'"
                                       (change)="setSetting('PropertySize', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/PS.01.svg'"></span>
                                    <label for="PropertySize-PS.01">1 Zimmer</label>
                                </div>
                            </div>
                            <div class="box">
                                <input type="radio" name="PropertySize" id="PropertySize-PS.02" [value]="'PS.02'"
                                       [checked]="household.Attributes.PropertySize === 'PS.02'"
                                       (change)="setSetting('PropertySize', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/PS.02.svg'"></span>
                                    <label for="PropertySize-PS.02">2 Zimmer</label>
                                </div>
                            </div>
                            <div class="box">
                                <input type="radio" name="PropertySize" id="PropertySize-PS.03" [value]="'PS.03'"
                                       [checked]="household.Attributes.PropertySize === 'PS.03'"
                                       (change)="setSetting('PropertySize', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/PS.03.svg'"></span>
                                    <label for="PropertySize-PS.03">3 Zimmer</label>
                                </div>
                            </div>
                            <div class="box">
                                <input type="radio" name="PropertySize" id="PropertySize-PS.04" [value]="'PS.04'"
                                       [checked]="household.Attributes.PropertySize === 'PS.04'"
                                       (change)="setSetting('PropertySize', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/PS.04.svg'"></span>
                                    <label for="PropertySize-PS.04">4+ Zimmer</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [class.icon-checked]="household.Attributes.PropertyAge"
                         [class.icon-empty]="!household.Attributes.PropertyAge" class="eigenschaft">
                        <h3>Wann wurde Ihr Wohnobjekt erbaut?</h3>
                        <div class="grid four clearfix">
                            <div class="box">
                                <input type="radio" name="PropertyAge" id="PropertyAge-PA.01" [value]="'PA.01'"
                                       [checked]="household.Attributes.PropertyAge === 'PA.01'"
                                       (change)="setSetting('PropertyAge', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/PA.01.svg'"></span>
                                    <label for="PropertyAge-PA.01">Vor 1919</label>
                                </div>
                            </div>
                            <div class="box">
                                <input type="radio" name="PropertyAge" id="PropertyAge-PA.02" [value]="'PA.02'"
                                       [checked]="household.Attributes.PropertyAge === 'PA.02'"
                                       (change)="setSetting('PropertyAge', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/PA.02.svg'"></span>
                                    <label for="PropertyAge-PA.02">1920-1975</label>
                                </div>
                            </div>
                            <div class="box">
                                <input type="radio" name="PropertyAge" id="PropertyAge-PA.03" [value]="'PA.03'"
                                       [checked]="household.Attributes.PropertyAge === 'PA.03'"
                                       (change)="setSetting('PropertyAge', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/PA.03.svg'"></span>
                                    <label for="PropertyAge-PA.03">1976-1999</label>
                                </div>
                            </div>
                            <div class="box">
                                <input type="radio" name="PropertyAge" id="PropertyAge-PA.04" [value]="'PA.04'"
                                       [checked]="household.Attributes.PropertyAge === 'PA.04'"
                                       (change)="setSetting('PropertyAge', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/PA.04.svg'"></span>
                                    <label for="PropertyAge-PA.04">Nach 2000</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [class.icon-checked]="household.Attributes.Occupants"
                         [class.icon-empty]="!household.Attributes.Occupants" class="eigenschaft">
                        <h3>Wie viele Personen leben in Ihrem Haushalt?</h3>
                        <div class="grid five clearfix">
                            <div class="box">
                                <input type="radio" name="Occupants" id="Occupants-OCC.01" [value]="'OCC.01'"
                                       [checked]="household.Attributes.Occupants === 'OCC.01'"
                                       (change)="setSetting('Occupants', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/OCC.01.svg'"></span>
                                    <label for="Occupants-OCC.01">1</label>
                                </div>
                            </div>
                            <div class="box">
                                <input type="radio" name="Occupants" id="Occupants-OCC.02" [value]="'OCC.02'"
                                       [checked]="household.Attributes.Occupants === 'OCC.02'"
                                       (change)="setSetting('Occupants', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/OCC.02.svg'"></span>
                                    <label for="Occupants-OCC.02">2</label>
                                </div>
                            </div>
                            <div class="box">
                                <input type="radio" name="Occupants" id="Occupants-OCC.03" [value]="'OCC.03'"
                                       [checked]="household.Attributes.Occupants === 'OCC.03'"
                                       (change)="setSetting('Occupants', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/OCC.03.svg'"></span>
                                    <label for="Occupants-OCC.03">3</label>
                                </div>
                            </div>
                            <div class="box">
                                <input type="radio" name="Occupants" id="Occupants-OCC.04" [value]="'OCC.04'"
                                       [checked]="household.Attributes.Occupants === 'OCC.04'"
                                       (change)="setSetting('Occupants', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/OCC.04.svg'"></span>
                                    <label for="Occupants-OCC.04">4</label>
                                </div>
                            </div>
                            <div class="box">
                                <input type="radio" name="Occupants" id="Occupants-OCC.05" [value]="'OCC.05'"
                                       [checked]="household.Attributes.Occupants === 'OCC.05'"
                                       (change)="setSetting('Occupants', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/OCC.05.svg'"></span>
                                    <label for="Occupants-OCC.05">5+</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [class.icon-checked]="household.Attributes.SpaceHeating"
                         [class.icon-empty]="!household.Attributes.SpaceHeating" class="eigenschaft">
                        <h3>Wie wird ihr Objekt beheizt?</h3>
                        <div class="grid four clearfix">
                            <div class="box">
                                <input type="radio" name="SpaceHeating" id="SpaceHeating-SH.01" [value]="'SH.01'"
                                       [checked]="household.Attributes.SpaceHeating === 'SH.01'"
                                       (change)="setSetting('SpaceHeating', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/SH.01.svg'"></span>
                                    <label for="SpaceHeating-SH.01">Gasheizung</label>
                                </div>
                            </div>
                            <div class="box">
                                <input type="radio" name="SpaceHeating" id="SpaceHeating-SH.02" [value]="'SH.02'"
                                       [checked]="household.Attributes.SpaceHeating === 'SH.02'"
                                       (change)="setSetting('SpaceHeating', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/SH.02.svg'"></span>
                                    <label for="SpaceHeating-SH.02">Wärmespeicher</label>
                                </div>
                            </div>
                            <div class="box">
                                <input type="radio" name="SpaceHeating" id="SpaceHeating-SH.03" [value]="'SH.03'"
                                       [checked]="household.Attributes.SpaceHeating === 'SH.03'"
                                       (change)="setSetting('SpaceHeating', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/SH.03.svg'"></span>
                                    <label for="SpaceHeating-SH.03">Elektroheizung</label>
                                </div>
                            </div>
                            <div class="box">
                                <input type="radio" name="SpaceHeating" id="SpaceHeating-SH.04" [value]="'SH.04'"
                                       [checked]="household.Attributes.SpaceHeating === 'SH.04'"
                                       (change)="setSetting('SpaceHeating', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/SH.04.svg'"></span>
                                    <label for="SpaceHeating-SH.04">Wärmepumpe</label>
                                </div>
                            </div>
                            <div class="box">
                                <input type="radio" name="SpaceHeating" id="SpaceHeating-SH.05" [value]="'SH.05'"
                                       [checked]="household.Attributes.SpaceHeating === 'SH.05'"
                                       (change)="setSetting('SpaceHeating', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/SH.05.svg'"></span>
                                    <label for="SpaceHeating-SH.05">Ölheizung</label>
                                </div>
                            </div>
                            <div class="box">
                                <input type="radio" name="SpaceHeating" id="SpaceHeating-SH.06" [value]="'SH.06'"
                                       [checked]="household.Attributes.SpaceHeating === 'SH.06'"
                                       (change)="setSetting('SpaceHeating', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/SH.06.svg'"></span>
                                    <label for="SpaceHeating-SH.06">Solarzelle</label>
                                </div>
                            </div>
                            <div class="box">
                                <input type="radio" name="SpaceHeating" id="SpaceHeating-SH.07" [value]="'SH.07'"
                                       [checked]="household.Attributes.SpaceHeating === 'SH.07'"
                                       (change)="setSetting('SpaceHeating', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/SH.07.svg'"></span>
                                    <label for="SpaceHeating-SH.07">Andere</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [class.icon-checked]="household.Attributes.WaterHeating"
                         [class.icon-empty]="!household.Attributes.WaterHeating" class="eigenschaft">
                        <h3>Wie wird Warmwasser hergestellt?</h3>
                        <div class="grid five clearfix">
                            <div class="box">
                                <input type="radio" name="WaterHeating" id="WaterHeating-WH.01" [value]="'WH.01'"
                                       [checked]="household.Attributes.WaterHeating === 'WH.01'"
                                       (change)="setSetting('WaterHeating', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/WH.01.svg'"></span>
                                    <label for="WaterHeating-WH.01">Gasheizkessel</label>
                                </div>
                            </div>
                            <div class="box">
                                <input type="radio" name="WaterHeating" id="WaterHeating-WH.02" [value]="'WH.02'"
                                       [checked]="household.Attributes.WaterHeating === 'WH.02'"
                                       (change)="setSetting('WaterHeating', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/WH.02.svg'"></span>
                                    <label for="WaterHeating-WH.02">Elektrischer Boiler</label>
                                </div>
                            </div>
                            <div class="box">
                                <input type="radio" name="WaterHeating" id="WaterHeating-WH.03" [value]="'WH.05'"
                                       [checked]="household.Attributes.WaterHeating === 'WH.03'"
                                       (change)="setSetting('WaterHeating', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/WH.03.svg'"></span>
                                    <label for="WaterHeating-WH.03">Ölheizkessel</label>
                                </div>
                            </div>
                            <div class="box">
                                <input type="radio" name="WaterHeating" id="WaterHeating-WH.04" [value]="'WH.04'"
                                       [checked]="household.Attributes.WaterHeating === 'WH.04'"
                                       (change)="setSetting('WaterHeating', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/WH.04.svg'"></span>
                                    <label for="WaterHeating-WH.04">Solarthermie</label>
                                </div>
                            </div>
                            <div class="box">
                                <input type="radio" name="WaterHeating" id="WaterHeating-WH.05" [value]="'WH.05'"
                                       [checked]="household.Attributes.WaterHeating === 'WH.05'"
                                       (change)="setSetting('WaterHeating', $event.target.value, 'Attributes')">
                                <div class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/WH.05.svg'"></span>
                                    <label for="WaterHeating-WH.05">Andere</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="eigenschaft" [class.icon-checked]="isApplianceSelected()"
                         [class.icon-empty]="!isApplianceSelected()">
                        <h3>Welche Geräte sind in Ihrem Haushalt vorhanden?</h3>
                        <div class="grid five clearfix">
                            <!-- Kühlschrank -->
                            <div class="box">
                                <button type="button" [class.active]="household.Appliances['A.01'] > 0"
                                        class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/A.01.svg'"></span>
                                    <span class="label">Kühlschrank</span>
                                </button>
                                <div class="counter-wrap">
                                    <button type="button" (click)="subAmount('A.01')" class="btn-counter icon-c-minus">
                                        <span>Weniger</span>
                                    </button>
                                    <button type="button" (click)="addAmount('A.01')" class="btn-counter icon-c-plus">
                                        <span>Mehr</span>
                                    </button>
                                    <input type="number" id="A.01" min="0" max="99"
                                           [value]="household.Appliances['A.01'] ? household.Appliances['A.01'] : 0"
                                           (change)="setSetting('A.01', $event.target.value, 'Appliances')"
                                           class="formNumber">
                                </div>
                            </div>

                            <!-- Gefrierschrank -->
                            <div class="box">
                                <button type="button" [class.active]="household.Appliances['A.02'] > 0"
                                        class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/A.02.svg'"></span>
                                    <span class="label">Gefrierschrank</span>
                                </button>
                                <div class="counter-wrap">
                                    <button type="button" (click)="subAmount('A.02')" class="btn-counter icon-c-minus">
                                        <span>Weniger</span>
                                    </button>
                                    <button type="button" (click)="addAmount('A.02')" class="btn-counter icon-c-plus">
                                        <span>Mehr</span>
                                    </button>
                                    <input type="number" id="A.02" min="0" max="99"
                                           [value]="household.Appliances['A.02'] ? household.Appliances['A.02'] : 0"
                                           (change)="setSetting('A.02', $event.target.value, 'Appliances')"
                                           class="formNumber">
                                </div>
                            </div>

                            <!-- Kühl-Gefrierkombination -->
                            <div class="box">
                                <button type="button" [class.active]="household.Appliances['A.03'] > 0"
                                        class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/A.03.svg'"></span>
                                    <span class="label">Kühlschrank mit Gefrierfach</span>
                                </button>
                                <div class="counter-wrap">
                                    <button type="button" (click)="subAmount('A.03')" class="btn-counter icon-c-minus">
                                        <span>Weniger</span>
                                    </button>
                                    <button type="button" (click)="addAmount('A.03')" class="btn-counter icon-c-plus">
                                        <span>Mehr</span>
                                    </button>
                                    <input type="number" id="A.03" min="0" max="99"
                                           [value]="household.Appliances['A.03'] ? household.Appliances['A.03'] : 0"
                                           (change)="setSetting('A.03', $event.target.value, 'Appliances')"
                                           class="formNumber">
                                </div>
                            </div>

                            <!-- Backofen -->
                            <div class="box">
                                <button type="button" [class.active]="household.Appliances['A.04'] > 0"
                                        class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/A.04.svg'"></span>
                                    <span class="label">Backofen</span>
                                </button>
                                <div class="counter-wrap">
                                    <button type="button" (click)="subAmount('A.04')" class="btn-counter icon-c-minus">
                                        <span>Weniger</span>
                                    </button>
                                    <button type="button" (click)="addAmount('A.04')" class="btn-counter icon-c-plus">
                                        <span>Mehr</span>
                                    </button>
                                    <input type="number" id="A.04" min="0" max="99"
                                           [value]="household.Appliances['A.04'] ? household.Appliances['A.04'] : 0"
                                           (change)="setSetting('A.04', $event.target.value, 'Appliances')"
                                           class="formNumber">
                                </div>
                            </div>

                            <!-- Grill -->
                            <div class="box">
                                <button type="button" [class.active]="household.Appliances['A.05'] > 0"
                                        class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/A.05.svg'"></span>
                                    <span class="label">Grill</span>
                                </button>
                                <div class="counter-wrap">
                                    <button type="button" (click)="subAmount('A.05')" class="btn-counter icon-c-minus">
                                        <span>Weniger</span>
                                    </button>
                                    <button type="button" (click)="addAmount('A.05')" class="btn-counter icon-c-plus">
                                        <span>Mehr</span>
                                    </button>
                                    <input type="number" id="A.05" min="0" max="99"
                                           [value]="household.Appliances['A.05'] ? household.Appliances['A.05'] : 0"
                                           (change)="setSetting('A.05', $event.target.value, 'Appliances')"
                                           class="formNumber">
                                </div>
                            </div>

                            <!-- Herd -->
                            <div class="box">
                                <button type="button" [class.active]="household.Appliances['A.06'] > 0"
                                        class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/A.06.svg'"></span>
                                    <span class="label">Herdplatten</span>
                                </button>
                                <div class="counter-wrap">
                                    <button type="button" (click)="subAmount('A.06')" class="btn-counter icon-c-minus">
                                        <span>Weniger</span>
                                    </button>
                                    <button type="button" (click)="addAmount('A.06')" class="btn-counter icon-c-plus">
                                        <span>Mehr</span>
                                    </button>
                                    <input type="number" id="A.06" min="0" max="99"
                                           [value]="household.Appliances['A.06'] ? household.Appliances['A.06'] : 0"
                                           (change)="setSetting('A.06', $event.target.value, 'Appliances')"
                                           class="formNumber">
                                </div>
                            </div>

                            <!-- Mikrowelle -->
                            <div class="box">
                                <button type="button" [class.active]="household.Appliances['A.07'] > 0"
                                        class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/A.07.svg'"></span>
                                    <span class="label">Mikrowelle</span>
                                </button>
                                <div class="counter-wrap">
                                    <button type="button" (click)="subAmount('A.07')" class="btn-counter icon-c-minus">
                                        <span>Weniger</span>
                                    </button>
                                    <button type="button" (click)="addAmount('A.07')" class="btn-counter icon-c-plus">
                                        <span>Mehr</span>
                                    </button>
                                    <input type="number" id="A.07" min="0" max="99"
                                           [value]="household.Appliances['A.07'] ? household.Appliances['A.07'] : 0"
                                           (change)="setSetting('A.07', $event.target.value, 'Appliances')"
                                           class="formNumber">
                                </div>
                            </div>

                            <!-- Wasserkocher -->
                            <div class="box">
                                <button type="button" [class.active]="household.Appliances['A.08'] > 0"
                                        class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/A.08.svg'"></span>
                                    <span class="label">Wasserkocher</span>
                                </button>
                                <div class="counter-wrap">
                                    <button type="button" (click)="subAmount('A.08')" class="btn-counter icon-c-minus">
                                        <span>Weniger</span>
                                    </button>
                                    <button type="button" (click)="addAmount('A.08')" class="btn-counter icon-c-plus">
                                        <span>Mehr</span>
                                    </button>
                                    <input type="number" id="A.08" min="0" max="99"
                                           [value]="household.Appliances['A.08'] ? household.Appliances['A.08'] : 0"
                                           (change)="setSetting('A.08', $event.target.value, 'Appliances')"
                                           class="formNumber">
                                </div>
                            </div>

                            <!-- Toaster -->
                            <div class="box">
                                <button type="button" [class.active]="household.Appliances['A.09'] > 0"
                                        class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/A.09.svg'"></span>
                                    <span class="label">Toaster</span>
                                </button>
                                <div class="counter-wrap">
                                    <button type="button" (click)="subAmount('A.09')" class="btn-counter icon-c-minus">
                                        <span>Weniger</span>
                                    </button>
                                    <button type="button" (click)="addAmount('A.09')" class="btn-counter icon-c-plus">
                                        <span>Mehr</span>
                                    </button>
                                    <input type="number" id="A.09" min="0" max="99"
                                           [value]="household.Appliances['A.09'] ? household.Appliances['A.09'] : 0"
                                           (change)="setSetting('A.09', $event.target.value, 'Appliances')"
                                           class="formNumber">
                                </div>
                            </div>

                            <!-- Geschirrspüler -->
                            <div class="box">
                                <button type="button" [class.active]="household.Appliances['A.10'] > 0"
                                        class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/A.10.svg'"></span>
                                    <span class="label">Spülmaschine</span>
                                </button>
                                <div class="counter-wrap">
                                    <button type="button" (click)="subAmount('A.10')" class="btn-counter icon-c-minus">
                                        <span>Weniger</span>
                                    </button>
                                    <button type="button" (click)="addAmount('A.10')" class="btn-counter icon-c-plus">
                                        <span>Mehr</span>
                                    </button>
                                    <input type="number" id="A.10" min="0" max="99"
                                           [value]="household.Appliances['A.10'] ? household.Appliances['A.10'] : 0"
                                           (change)="setSetting('A.10', $event.target.value, 'Appliances')"
                                           class="formNumber">
                                </div>
                            </div>

                            <!-- Waschmaschine -->
                            <div class="box">
                                <button type="button" [class.active]="household.Appliances['A.11'] > 0"
                                        class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/A.11.svg'"></span>
                                    <span class="label">Waschmaschine</span>
                                </button>
                                <div class="counter-wrap">
                                    <button type="button" (click)="subAmount('A.11')" class="btn-counter icon-c-minus">
                                        <span>Weniger</span>
                                    </button>
                                    <button type="button" (click)="addAmount('A.11')" class="btn-counter icon-c-plus">
                                        <span>Mehr</span>
                                    </button>
                                    <input type="number" id="A.11" min="0" max="99"
                                           [value]="household.Appliances['A.11'] ? household.Appliances['A.11'] : 0"
                                           (change)="setSetting('A.11', $event.target.value, 'Appliances')"
                                           class="formNumber">
                                </div>
                            </div>

                            <!-- Wäschetrockner -->
                            <div class="box">
                                <button type="button" [class.active]="household.Appliances['A.12'] > 0"
                                        class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/A.12.svg'"></span>
                                    <span class="label">Trockner</span>
                                </button>
                                <div class="counter-wrap">
                                    <button type="button" (click)="subAmount('A.12')" class="btn-counter icon-c-minus">
                                        <span>Weniger</span>
                                    </button>
                                    <button type="button" (click)="addAmount('A.12')" class="btn-counter icon-c-plus">
                                        <span>Mehr</span>
                                    </button>
                                    <input type="number" id="A.12" min="0" max="99"
                                           [value]="household.Appliances['A.12'] ? household.Appliances['A.12'] : 0"
                                           (change)="setSetting('A.12', $event.target.value, 'Appliances')"
                                           class="formNumber">
                                </div>
                            </div>

                            <!-- Bügeleisen -->
                            <div class="box">
                                <button type="button" [class.active]="household.Appliances['A.13'] > 0"
                                        class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/A.13.svg'"></span>
                                    <span class="label">Bügeleisen</span>
                                </button>
                                <div class="counter-wrap">
                                    <button type="button" (click)="subAmount('A.13')" class="btn-counter icon-c-minus">
                                        <span>Weniger</span>
                                    </button>
                                    <button type="button" (click)="addAmount('A.13')" class="btn-counter icon-c-plus">
                                        <span>Mehr</span>
                                    </button>
                                    <input type="number" id="A.13" min="0" max="99"
                                           [value]="household.Appliances['A.13'] ? household.Appliances['A.13'] : 0"
                                           (change)="setSetting('A.13', $event.target.value, 'Appliances')"
                                           class="formNumber">
                                </div>
                            </div>

                            <!-- Fernseher -->
                            <div class="box">
                                <button type="button" [class.active]="household.Appliances['A.14'] > 0"
                                        class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/A.14.svg'"></span>
                                    <span class="label">TV</span>
                                </button>
                                <div class="counter-wrap">
                                    <button type="button" (click)="subAmount('A.14')" class="btn-counter icon-c-minus">
                                        <span>Weniger</span>
                                    </button>
                                    <button type="button" (click)="addAmount('A.14')" class="btn-counter icon-c-plus">
                                        <span>Mehr</span>
                                    </button>
                                    <input type="number" id="A.14" min="0" max="99"
                                           [value]="household.Appliances['A.14'] ? household.Appliances['A.14'] : 0"
                                           (change)="setSetting('A.14', $event.target.value, 'Appliances')"
                                           class="formNumber">
                                </div>
                            </div>

                            <!-- Blu-ray-Player -->
                            <div class="box">
                                <button type="button" [class.active]="household.Appliances['A.15'] > 0"
                                        class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/A.15.svg'"></span>
                                    <span class="label">DVD oder Blu-ray Player</span>
                                </button>
                                <div class="counter-wrap">
                                    <button type="button" (click)="subAmount('A.15')" class="btn-counter icon-c-minus">
                                        <span>Weniger</span>
                                    </button>
                                    <button type="button" (click)="addAmount('A.15')" class="btn-counter icon-c-plus">
                                        <span>Mehr</span>
                                    </button>
                                    <input type="number" id="A.15" min="0" max="99"
                                           [value]="household.Appliances['A.15'] ? household.Appliances['A.15'] : 0"
                                           (change)="setSetting('A.15', $event.target.value, 'Appliances')"
                                           class="formNumber">
                                </div>
                            </div>

                            <!-- TV-Receiver -->
                            <div class="box">
                                <button type="button" [class.active]="household.Appliances['A.16'] > 0"
                                        class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/A.16.svg'"></span>
                                    <span class="label">Digitale TV Box</span>
                                </button>
                                <div class="counter-wrap">
                                    <button type="button" (click)="subAmount('A.16')" class="btn-counter icon-c-minus">
                                        <span>Weniger</span>
                                    </button>
                                    <button type="button" (click)="addAmount('A.16')" class="btn-counter icon-c-plus">
                                        <span>Mehr</span>
                                    </button>
                                    <input type="number" id="A.16" min="0" max="99"
                                           [value]="household.Appliances['A.16'] ? household.Appliances['A.16'] : 0"
                                           (change)="setSetting('A.16', $event.target.value, 'Appliances')"
                                           class="formNumber">
                                </div>
                            </div>

                            <!-- Spielekonsole -->
                            <div class="box">
                                <button type="button" [class.active]="household.Appliances['A.17'] > 0"
                                        class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/A.17.svg'"></span>
                                    <span class="label">Spielkonsole</span>
                                </button>
                                <div class="counter-wrap">
                                    <button type="button" (click)="subAmount('A.17')" class="btn-counter icon-c-minus">
                                        <span>Weniger</span>
                                    </button>
                                    <button type="button" (click)="addAmount('A.17')" class="btn-counter icon-c-plus">
                                        <span>Mehr</span>
                                    </button>
                                    <input type="number" id="A.17" min="0" max="99"
                                           [value]="household.Appliances['A.17'] ? household.Appliances['A.17'] : 0"
                                           (change)="setSetting('A.17', $event.target.value, 'Appliances')"
                                           class="formNumber">
                                </div>
                            </div>

                            <!-- Computer -->
                            <div class="box">
                                <button type="button" [class.active]="household.Appliances['A.18'] > 0"
                                        class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/A.18.svg'"></span>
                                    <span class="label">Computer</span>
                                </button>
                                <div class="counter-wrap">
                                    <button type="button" (click)="subAmount('A.18')" class="btn-counter icon-c-minus">
                                        <span>Weniger</span>
                                    </button>
                                    <button type="button" (click)="addAmount('A.18')" class="btn-counter icon-c-plus">
                                        <span>Mehr</span>
                                    </button>
                                    <input type="number" id="A.18" min="0" max="99"
                                           [value]="household.Appliances['A.18'] ? household.Appliances['A.18'] : 0"
                                           (change)="setSetting('A.18', $event.target.value, 'Appliances')"
                                           class="formNumber">
                                </div>
                            </div>

                            <!-- Tablet -->
                            <div class="box">
                                <button type="button" [class.active]="household.Appliances['A.19'] > 0"
                                        class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/A.19.svg'"></span>
                                    <span class="label">Tablet</span>
                                </button>
                                <div class="counter-wrap">
                                    <button type="button" (click)="subAmount('A.19')" class="btn-counter icon-c-minus">
                                        <span>Weniger</span>
                                    </button>
                                    <button type="button" (click)="addAmount('A.19')" class="btn-counter icon-c-plus">
                                        <span>Mehr</span>
                                    </button>
                                    <input type="number" id="A.19" min="0" max="99"
                                           [value]="household.Appliances['A.19'] ? household.Appliances['A.19'] : 0"
                                           (change)="setSetting('A.19', $event.target.value, 'Appliances')"
                                           class="formNumber">
                                </div>
                            </div>

                            <!-- Elektrische Dusche -->
                            <div class="box">
                                <button type="button" [class.active]="household.Appliances['A.20'] > 0"
                                        class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/A.20.svg'"></span>
                                    <span class="label">Durchlauf-erhitzer (Dusche)</span>
                                </button>
                                <div class="counter-wrap">
                                    <button type="button" (click)="subAmount('A.20')" class="btn-counter icon-c-minus">
                                        <span>Weniger</span>
                                    </button>
                                    <button type="button" (click)="addAmount('A.20')" class="btn-counter icon-c-plus">
                                        <span>Mehr</span>
                                    </button>
                                    <input type="number" id="A.20" min="0" max="99"
                                           [value]="household.Appliances['A.20'] ? household.Appliances['A.20'] : 0"
                                           (change)="setSetting('A.20', $event.target.value, 'Appliances')"
                                           class="formNumber">
                                </div>
                            </div>

                            <!-- Ladestation -->
                            <div class="box">
                                <button type="button" [class.active]="household.Appliances['A.21'] > 0"
                                        class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/A.21.svg'"></span>
                                    <span class="label">Elektroauto Ladesäule</span>
                                </button>
                                <div class="counter-wrap">
                                    <button type="button" (click)="subAmount('A.21')" class="btn-counter icon-c-minus">
                                        <span>Weniger</span>
                                    </button>
                                    <button type="button" (click)="addAmount('A.21')" class="btn-counter icon-c-plus">
                                        <span>Mehr</span>
                                    </button>
                                    <input type="number" id="A.21" min="0" max="99"
                                           [value]="household.Appliances['A.21'] ? household.Appliances['A.21'] : 0"
                                           (change)="setSetting('A.21', $event.target.value, 'Appliances')"
                                           class="formNumber">
                                </div>
                            </div>

                            <!-- Pool -->
                            <div class="box">
                                <button type="button" [class.active]="household.Appliances['A.22'] > 0"
                                        class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/A.22.svg'"></span>
                                    <span class="label">Pool</span>
                                </button>
                                <div class="counter-wrap">
                                    <button type="button" (click)="subAmount('A.22')" class="btn-counter icon-c-minus">
                                        <span>Weniger</span>
                                    </button>
                                    <button type="button" (click)="addAmount('A.22')" class="btn-counter icon-c-plus">
                                        <span>Mehr</span>
                                    </button>
                                    <input type="number" id="A.22" min="0" max="99"
                                           [value]="household.Appliances['A.22'] ? household.Appliances['A.22'] : 0"
                                           (change)="setSetting('A.22', $event.target.value, 'Appliances')"
                                           class="formNumber">
                                </div>
                            </div>

                            <!-- Sauna -->
                            <div class="box">
                                <button type="button" [class.active]="household.Appliances['A.23'] > 0"
                                        class="btn-option">
                                    <span class="icon" [inlineSVG]="'icons/A.23.svg'"></span>
                                    <span class="label">Sauna</span>
                                </button>
                                <div class="counter-wrap">
                                    <button type="button" (click)="subAmount('A.23')" class="btn-counter icon-c-minus">
                                        <span>Weniger</span>
                                    </button>
                                    <button type="button" (click)="addAmount('A.23')" class="btn-counter icon-c-plus">
                                        <span>Mehr</span>
                                    </button>
                                    <input type="number" id="A.23" min="0" max="99"
                                           [value]="household.Appliances['A.23'] ? household.Appliances['A.23'] : 0"
                                           (change)="setSetting('A.23', $event.target.value, 'Appliances')"
                                           class="formNumber">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="btn-wrap">
                        <button type="submit" (click)="saveSettings()" class="formSubmit">Angaben speichern</button>
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>
<div *ngIf="changelog_visible" class="overlay-infos">
    <div class="overlay-box">
        <button type="button" (click)="hideChangelog()" class="icon-close fltrt">
            <span>Schließen</span>
        </button>
        <div *ngFor="let logs of changelog">
            <h4>{{ logs.version }}</h4>
            <ul *ngIf="logs.features.length > 0">
                <li class="head">Neue Funktionen:</li>
                <li *ngFor="let feature of logs.features">{{ feature.log }}</li>
            </ul>
            <ul *ngIf="logs.adjustments.length > 0">
                <li class="head">Anpassungen:</li>
                <li *ngFor="let adjustment of logs.adjustments">{{ adjustment.log }}</li>
            </ul>
            <ul *ngIf="logs.bugfixes.length > 0">
                <li class="head">Bugfixes:</li>
                <li *ngFor="let bugfix of logs.bugfixes">{{ bugfix.log }}</li>
            </ul>
        </div>
    </div>
</div>
<div id="overlay-logout" class="overlay-infos" style="display: none;">
    <div class="overlay-box">
        <button type="button" class="close-logout icon-close fltrt">
            <span>Schließen</span>
        </button>
        <h4>Abmelden</h4>
        <p>Wollen Sie sich wirklich abmelden?</p>
        <button type="button" (click)="_apiService.logoutUser()" class="btn orange">Ja</button>
        <button type="button" class="close-logout btn outline">Nein</button>
    </div>
</div>
