import {Component, OnInit, OnDestroy} from '@angular/core';
import {Title} from '@angular/platform-browser';

import {ToastrService} from 'ngx-toastr';

import {ApiService} from '../services/api.service';
import {MockDataService} from '../mock-data.service';
import {UserService} from '../user.service';
import {getProviderIcon, getProviderIconClass, getProviderMenuName} from '../ProivderUtil';
import {static_links} from '../constants';

@Component({
    selector: 'iona-app',
    templateUrl: './profile.component.html',
    viewProviders: []
})

export class ProfileComponent implements OnInit, OnDestroy {

    refresh: any = [];

    /**
     * Aktueller Anbieter (innogy/enviaM/...)
     */
    provider: number = 0;
    providericon: string = '';
    providerclass: string = '';
    providername: string = null;
    privacyLink = '';
    providerMenuName: string = null;
    username: string = null;

    /**
     * Vertragsdaten
     */
    contract: any = {
        name: '',
        tarif: null,
        basicprice: null,
        workingprice: null
    };

    /**
     * Konfiguration, ob Overlay angezeigt wird
     */
    view: any = {
        changeEmail: false,
        changePassword: false
    };

    version: string = '0.0.0';
    changelog: any = [];
    changelog_visible: boolean = false;

    constructor(private _titleService: Title,
                private _notification: ToastrService,
                public _apiService: ApiService,
                private _mockData: MockDataService,
                private _userService: UserService) {
    }

    ngOnInit() {
        this._titleService.setTitle('Meine Daten | iONA');

        this.username = this._userService.getActiveUserName();
        this.providername = this._userService.getActiveUserProvider();
        this.privacyLink = static_links.privacy[this.providername];
        this.providerclass = getProviderIconClass(this.providername);
        this.providericon = getProviderIcon(this.providername);
        this.providerMenuName = getProviderMenuName(this.providername);

        this.getVersion();
        this.getChangelog();

        if (this._apiService.isDemoMode()) {
            this.getMockInitialize();
            this.getMockProfile();
            return;
        }

        // check if user logged in
        this._apiService.checkToken();
        this._apiService.checkMeterStatus();

        // git gud
        this.refresh = setInterval(() => {
            this._apiService.checkToken();
        }, 10000);

        this.getInitialize();
        this.getProfile();

        setTimeout(function() {
            let elements: any = $('.grid .box');
            elements.matchHeight();
        }, 100);

    }

    ngOnDestroy() {
        clearInterval(this.refresh);
    }


    /**
     * Send initialize call to the API
     */
    private getInitialize(): void {
        this._apiService.getInitialization().subscribe(
            (data: any) => {
                if ('data' in data) {
                    if ('profile' in data.data) {
                        this.processContractData(data);
                    }
                }
            }
        );
    }

    /**
     * Send mock initialize call to the API
     */
    private getMockInitialize(): void {
        this._mockData.getInitialize().subscribe(
            (data) => {
                if ('data' in data) {
                    if ('profile' in data.data) {
                        this.processContractData(data);
                    }
                }
            }
        );
    }


    /**
     * Get user profile from API
     */
    private getProfile(): void {
        this._apiService.getContract().subscribe(
            (data: any) => {
                if ('data' in data) {
                    if ('profile' in data.data) {
                        this.processProfileData(data);
                    }
                }
            }
        );
    }


    /**
     * Get Mock user profile
     */
    private getMockProfile() {
        this._mockData.getProfile().subscribe(
            (data) => {
                if ('data' in data) {
                    if ('profile' in data.data) {
                        this.processProfileData(data);
                    }
                }
            }
        );
    }


    /**
     * Change mail adress
     * @param email
     */
    changeEmail(email: string) {
        if (this.username !== 'Demo') {
            this._apiService.setEmail(email).subscribe(
                () => {
                    this._notification.success('Ihre E-Mail-Adresse wurde geändert!');
                    this.username = email;
                },
                (response: any) => {
                    if (response._body) {
                        let error: any = JSON.parse(response._body);

                        switch (error.error.code) {
                            case 104: {
                                this._notification.error('Ihre E-Mail-Adresse ist fehlerhaft!');

                                break;
                            }
                            default: {
                                this._notification.error('Ihre E-Mail-Adresse konnte nicht geändert werden!');
                            }
                        }
                    } else {
                        this._notification.error('Ihre E-Mail-Adresse konnte nicht geändert werden!');
                    }
                }
            );
        } else {
            this._notification.info('Die E-Mail-Adresse kann im Demo-Modus nicht geändert werden!');
        }

        this.toggleEmailView();
    }

    /**
     * Change user password
     * @param oldPass
     * @param newPass
     * @param newPassRepeat
     */
    changePassword(oldPass: string, newPass: string, newPassRepeat: string) {
        if (this.username !== 'Demo') {
            if (oldPass.length == 0 || newPassRepeat.length == 0 || newPassRepeat.length == 0) {
                this._notification.error('Bitte alle Felder ausfüllen!');
            }
            this._apiService.updatePassword(oldPass, newPass, newPassRepeat).subscribe(
                () => {
                    this._notification.success('Ihr Passwort wurde geändert!');
                },
                (response: any) => {
                    if (response._body) {
                        this._notification.error('Ihr Passwort konnte nicht geändert werden!');
                    } else {
                        this._notification.error('Ihr Passwort konnte nicht geändert werden!');
                    }
                }
            );
        } else {
            this._notification.info('Das Passwort kann im Demo-Modus nicht geändert werden!');
        }

        this.togglePasswordView();
    }

    /**
     * Change-Password-Overlay anzeigen/ausblenden
     */
    togglePasswordView() {
        this.view.changePassword = !this.view.changePassword;
    }

    /**
     * Change-Mail-Overlay anzeigen/ausblenden
     */
    toggleEmailView() {
        this.view.changeEmail = !this.view.changeEmail;
    }

    /**
     * Get app version
     */
    getVersion() {
        this._apiService.getChangelog().subscribe(
            (data: any) => {
                if (0 in data) {
                    this.version = data[0].version;
                }
            }
        );
    }

    /**
     * Get Changelog
     */
    getChangelog() {
        this._apiService.getChangelog().subscribe(
            (data: any) => {
                this.changelog = data;
            }
        );
    }

    /**
     * Show changelog
     */
    showChangelog() {
        this.changelog_visible = true;
    }

    /**
     * Hide changelog
     */
    hideChangelog() {
        this.changelog_visible = false;
    }

    /**
     * Process contract data
     * @param data
     */
    private processContractData(data): void {
        if (this.username == 'Demo') {
            this.contract.name = 'Maxi Mustermann';
        } else {
            this.contract.name = data.data.profile.customer_name;
        }
        this.contract.tarif = data.data.profile.product_name;
    }

    /**
     * Process Profile information
     * @param data
     */
    private processProfileData(data): void {
        let basicprice = 0;
        if (typeof data.data.profile.e_fixed_tariff != 'undefined') {
            basicprice = parseFloat(data.data.profile.e_fixed_tariff);
        } else {
            basicprice = parseFloat(data.data.profile.budget_bill);
        }

        // Strompreise auslesen
        const workingprice = parseFloat(data.data.profile.e_tariff);
        this.contract.basicprice = basicprice.toLocaleString('de-DE', {style: 'currency', currency: 'EUR'});
        this.contract.workingprice = `${workingprice} €`;
        // this.contract.workingprice = workingprice.toLocaleString('de-DE', {style: 'currency', currency: 'EUR'});

    }

}
