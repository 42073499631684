import {Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef} from '@angular/core';

import {ApiService} from '../../services/api.service';
import {MockDataService} from '../../mock-data.service';
import {NguCarousel, NguCarouselConfig} from '@ngu/carousel';
import {UserService} from '../../user.service';
import {PowercheckerService} from '../../powerchecker.service';

@Component({
    selector: 'powerchecker-details',
    templateUrl: './details.powerchecker.html',
    styleUrls: ['./details.powerchecker.component.scss'],
})

export class PowerCheckerDetails implements OnInit, OnDestroy {

    title: string = '';
    location: string = '';
    editModeEnabled: boolean = false;
    links = ['https://play.google.com/store/apps/details?id=com.innogy.smarthome&hl=de',
        'https://apps.apple.com/de/app/innogy-smarthome/id1133163302'];

    plugAttributes = {
        name: '',
        room: ''
    };
    plugName = 'Mein Gerät';
    plugRoom = 'Mein Zimmer';

    state = false;

    slideshowAnimations = [
        {
            path: 'assets/anim/powerchecker/powerchecker_1.json',
            renderer: 'svg',
            autoplay: true,
            autoplaySegments: false,
            loop: true,
            name: 'Smart Meter'
        },
        {
            path: 'assets/anim/powerchecker/powerchecker_2.json',
            renderer: 'svg',
            autoplay: true,
            autoplaySegments: false,
            loop: true,
            name: 'Smart Meter'
        },
        {
            path: 'assets/anim/powerchecker/powerchecker_3.json',
            renderer: 'svg',
            autoplay: true,
            autoplaySegments: false,
            loop: true,
            name: 'Smart Meter'
        },
        {
            path: 'assets/anim/powerchecker/powerchecker_4.json',
            renderer: 'svg',
            autoplay: true,
            autoplaySegments: false,
            loop: true,
            name: 'Smart Meter'
        },
    ];

    carouselTileConfig: NguCarouselConfig = {
        grid: {xs: 1, sm: 1, md: 1, lg: 1, all: 0},
        speed: 300,
        point: {visible: true},
        touch: true,
        loop: true,
        animation: 'lazy',
        interval: {
            timing: 10000, initialDelay: 10000
        },
        load: 4
    };

    subscriptions = [];

    @ViewChild('carousel', {static: true}) carousel: NguCarousel<any>;

    constructor(public _apiService: ApiService,
                private _mockData: MockDataService,
                private _userService: UserService,
                private _pcService: PowercheckerService) {
    }

    ngOnInit() {
        this.getPlugAttributes();
        // this.initPlugAttributes();

        this._pcService.stateChange.subscribe(
            (value) => {
                this.state = value;
            }
        );

        if (!this._apiService.isDemoMode()) {
            this.initializePlugStatus();
            return;
        }

        this._mockData.getPlugStatus().subscribe(
            (response: any) => {
                this.state = response.data.on_off === 1;
            }
        );
    }

    ngOnDestroy() {
        for (const sub of this.subscriptions) {
            if (sub) {
                sub.unsubscribe();
            }
        }
    }

    /**
     * Initialize the plugs attributes
     */
    private initPlugAttributes(): void {
        this.plugAttributes = this._userService.getPlugAttributes();
        if (this.plugAttributes === null || this.plugAttributes === undefined) {
            this.getPlugAttributes();
            return;
        }

        this.plugName = this.plugAttributes.name;
        this.plugRoom = this.plugAttributes.room;
    }

    /**
     * Get the plugs current set status
     */
    private initializePlugStatus(): void {
        const sub = this._apiService.getPlugStatus().subscribe(
            (response) => {
                if ('data' in response) {
                    if ('relay' in response.data) {
                        if ('on_off' in response.data.relay) {
                            const value = response.data.relay.on_off;
                            this.state = value === 1;
                        }
                    }
                }
            }
        );
        this.subscriptions.push(sub);
    }

    /**
     * Get plug attributes from user storage
     */
    private getPlugAttributes(): void {
        if (!this._apiService.isDemoMode()) {
            const sub = this._apiService.getStoredUserSettings().subscribe(
                (response) => {
                    if ('data' in response) {

                        let obj;
                        if (typeof response.data === 'string') {
                            obj = JSON.parse(response.data);
                        } else {
                            obj = response.data;
                        }

                        if ('power_checker' in response.data) {
                            this._userService.updatePlugAttributes(obj);
                            this.plugAttributes = obj.power_checker;
                            if (this.plugAttributes.name !== '') {
                                this.plugName = this.plugAttributes.name;
                            }
                            if (this.plugAttributes.room !== '') {
                                this.plugRoom = this.plugAttributes.room;
                            }
                        }

                    }
                }
            );
            this.subscriptions.push(sub);
        }
    }

    /**
     * On plug stat changed
     * @param state
     */
    onPlugStateChange(state: boolean): void {
        this.state = state;
        this._pcService.stateChange.next(state);
        if (!this._apiService.isDemoMode()) {
            this._apiService.setPlugStatus(state ? 1 : 0).subscribe(
                (response) => {
                    if ('data' in response) {
                        if ('relay' in response.data) {
                            if ('on_off' in response.data) {
                                const value = response.data.relay.on_off;
                                this.state = value === 1;
                            }
                        }
                    }
                },
                (error) => {
                    console.log(this.constructor.name, error);
                }
            );
        }
    }

    /**
     * On saved change
     * @param new_name
     * @param new_room
     */
    onSaveChanges(new_name: string, new_room: string): void {
        if (new_name === '' || new_room === '') {
            this.editModeEnabled = !this.editModeEnabled;
            return;
        }
        if (this.editModeEnabled) {
            this._userService.plugAttributesChanged.next({name: new_name, room: new_room});
            if (this._apiService.isDemoMode()) {
                if (new_name !== this.plugAttributes.name || new_room !== this.plugAttributes.room) {
                    this.plugName = new_name;
                    this.plugRoom = new_room;
                }
                this.editModeEnabled = !this.editModeEnabled;
                return;
            }
            if (new_name !== this.plugAttributes.name || new_room !== this.plugAttributes.room) {
                this._userService.updatePlugAttributes({name: new_name, room: new_room});
                this._apiService.setPlugAttributes().subscribe(
                    (response) => {
                    },
                    (error) => {
                        console.log('Error:', error);
                    }
                );
            }
        }
        this.editModeEnabled = !this.editModeEnabled;
    }

    onInputFocus(evt: FocusEvent): void {
        const target = evt.target as HTMLInputElement;
        target.select();
    }
}
