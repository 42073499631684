import {Injectable} from '@angular/core';
import {User} from './user';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    public storage_keys = {
        users: 'users',
        user_active: 'user-active',
    };

    public plugAttributesChanged = new Subject();

    constructor() {
    }

    setCurrentUser(user: User): void {
        const users_str = this.getElementForKey(this.storage_keys.users);
        let users_obj;
        if (users_str === null || users_str === undefined) {
            users_obj = [];
            // set initial value of consumption alert to false
            user.initial_consumption_alert = true;
            user.charts = null;
            users_obj.push(user);
            localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
        } else {
            users_obj = JSON.parse(users_str);
            let user_exists = false;
            for (const u of users_obj) {
                if (u.email === user.email) {
                    u.access_token = user.access_token;
                    u.refresh_token = user.refresh_token;
                    u.token_expires = user.token_expires;
                    user_exists = true;
                }
            }

            if (!user_exists) {
                users_obj.push(user);
            }

            localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
        }

        this.setActiveUser(user.email);
    }

    getUser(email: string): User {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        return users_obj.find((user: User) => user.email === email);
    }

    getActiveUserName(): string {
        return this.getElementForKey(this.storage_keys.user_active);
    }

    setActiveUserProvider(provider: string): void {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }

        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.provider = provider;
            }
        }
        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    }

    getActiveUserProvider(): string {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.provider.toLowerCase();
            }
        }
        return null;
    }

    setActiveUserCharts(charts: any[]): void {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.charts = charts;
            }
        }

        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    }

    getActiveUserCharts(): any[] {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.charts;
            }
        }
        return null;
    }

    getActiveUserAccessToken(): string {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                const token = user.access_token;
                return token;
            }
        }
        return null;
    }

    updateActiveUserAccessToken(access_token: string): string {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.access_token = access_token;
            }
        }
        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    }

    getActiveUserRefreshToken(): string {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.refresh_token;
            }
        }
        return null;
    }

    updateActiveUserRefreshToken(refresh_token: string): string {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.refresh_token = refresh_token;
            }
        }
        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    }

    getActiveUserTokenExpire(): Date {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.token_expires;
            }
        }
        return null;
    }

    updateActiveUserTokenExpire(new_expire: Date): void {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        let expires;
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.token_expires = new_expire;
            }
        }

        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    }

    logoutActiveUser(): void {
        this.removeActiveUser();
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return;
        }
        const users = JSON.parse(users_str);
        for (const user of users) {
            if (user.email === this.getActiveUserName()) {
                user.refresh_token = '';
                user.access_token = '';
            }
        }

        localStorage.setItem(this.storage_keys.users, JSON.stringify(users));
    }

    getActiveUserNilmStatus(): any {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.nilm_status;
            }
        }
        return null;
    }

    updateActiveUserNilmStatus(nilm_status): void {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.nilm_status = nilm_status;
            }
        }
        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    }

    updateActiveUserNilmStatusForAppliance(appliance, model_count): void {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        let nilm_values;
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                nilm_values = user.nilm_status;
            }
        }

        nilm_values['timeBasedAppliances'][appliance].models = model_count;

        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    }

    getLocalKeyValueStore(): any {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                return user.storage;
            }
        }
        return null;
    }

    getPlugAttributes(): any {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                if (user.storage === null) {
                    return null;
                }
                if (user.storage.power_checker === null) {
                    return null;
                }
                return user.storage.power_checker;
            }
        }
        return null;
    }

    updatePlugAttributes(attributes: any): void {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                if (user.storage === null) {
                    user.storage = {};
                }
                user.storage.power_checker = attributes;
            }
        }

        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    }

    deleteAccessToken(): void {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.access_token = null;
            }
        }

        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    }


    getUserDevice(): string | 'box' | 'plug' {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                if (user.device === null || user.device === undefined) {
                    return null;
                }
                return user.device;
            }
        }
        return null;
    }

    updateUserDevice(device: string): void {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.device = device;
            }
        }

        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    }

    getConsumptionAlertAttribute(): any {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                if (user.initial_consumption_alert === null || user.initial_consumption_alert === undefined) {
                    return null;
                }
                return user.initial_consumption_alert;
            }
        }
        return null;
    }

    setConsumptionAlertAttribute(val: boolean): any {
        const users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        const users_obj = JSON.parse(users_str);
        for (const user of users_obj) {
            if (user.email === this.getActiveUserName()) {
                user.initial_consumption_alert = val;
            }
        }

        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    }


    private setActiveUser(email: string): void {
        localStorage.setItem(this.storage_keys.user_active, email);
    }

    private removeActiveUser(): void {
        localStorage.removeItem(this.storage_keys.user_active);
    }

    private getElementForKey(key: string): any {
        return localStorage.getItem(key);
    }

}
