<div id="container">
    <!-- Auswählbare Charts -->
    <div class="sb-overlay">
        <aside class="sidebar addItem">
            <div class="sidebar-head">
                <button type="button" class="icon-close fltrt">
                    <span>Schließen</span>
                </button>
                <h2>Bereich hinzufügen</h2>
            </div>
            <div class="tab-wrap">
                <div class="grid widget-grid three clearfix">
                    <ng-container *ngFor="let chart of charts">
                        <div (click)="addChart(chart.id)"
                             [class.selected]="isSelectedChart(chart.id)" class="box">
                            <img src="assets/images/widget-{{ chart.id }}.jpg" alt="{{ chart.name }}"
                                 title="{{ chart.name }}"/>
                            <h3>{{ chart.name }}</h3>
                            <p>{{ chart.info }}</p>
                        </div>
                    </ng-container>
                </div>
            </div>
        </aside>

        <aside class="sidebar myDevices blue live-details">
            <live-details></live-details>
        </aside>
        <aside class="sidebar today myDevices green today-details">
            <today-details></today-details>
        </aside>
        <aside class="sidebar myDevices magenta consumer-details">
            <consumer-details></consumer-details>
        </aside>
        <aside class="sidebar today myDevices orange compare-details">
            <compare-details></compare-details>
        </aside>
        <aside class="sidebar myDevices meter-stat red meter-details">
            <meter-details></meter-details>
        </aside>
        <aside class="sidebar myDevices invoice-stat mint finance-details">
            <finance-details></finance-details>
        </aside>
        <ng-container *ngIf="userHasPlug">
            <aside class="sidebar myDevices indigo powerchecker-details">
                <powerchecker-details></powerchecker-details>
            </aside>
        </ng-container>
        <aside class="sidebar myDevices wastage-alert orangeish consumption-details">
            <consumption-details></consumption-details>
        </aside>

        <!-- 15.10.18 "Abschlags-Check" und "Verbrauchsalarm" temporär deaktivieren-->
    </div>

    <!-- Sortierung der Charts -->
    <aside class="sidebar sort">
        <div class="sidebar-head">
            <button type="button" angulartics2On="click" angularticsCategory="Dashboard"
                    angularticsAction="edit_mode_end" class="icon-close fltrt">
                <span>Schließen</span>
            </button>
            <h2>Umsortieren</h2>
        </div>
        <div class="sort-list" [sortablejs]="charts_selected" [sortablejsOptions]="sort_options">
            <div *ngFor="let chart of charts_selected; let pos = index" class="sort-list-item clearfix">
                <div class="points icon-dots"></div>
                <div class="pos">{{ pos + 1 }}</div>
                <div class="name">{{ getChartName(chart.id) }}</div>
                <div class="btn">
                    <button class="icon-trash" (click)="removeChart(chart.id)"></button>
                </div>
            </div>
        </div>
    </aside>

    <!-- Menü -->
    <aside class="sidebar lft">
        <a href="#" id="hamburger" title="Menü" class="mobi">
            <span class="burger">
                <i class="top"></i>
                <i class="middle"></i>
                <i class="bottom"></i>
            </span>
            <span class="text"> Menü</span>
        </a>
        <div class="blackbox">
            <div class="version">
                <a href="javascript:;" (click)="showChangelog()">{{ version }}</a>
            </div>
            <div [class.demo]="username === 'demo'" class="logo">
                <a href="#/" title="iONA">
                    <span>iONA</span>
                </a>
            </div>
        </div>
        <div class="block block-menu">
            <nav class="inner">
                <ul class="clearfix">
                    <li><a href="#/" title="Übersicht" class="active">Übersicht</a></li>
                    <li><a href="#/meine-daten" title="Meine Daten">Meine Daten</a></li>
                    <li><a href="#/mein-haushalt" title="Mein Haushalt">Mein Haushalt</a></li>
                    <li><a href="#/mein-kundenkonto" angulartics2On="click" angularticsCategory="Meine Daten"
                           angularticsAction="my_account_enter"
                           title="{{ providerMenuName }}">{{ providerMenuName }}</a></li>
                    <li><a href="#/einstellungen" title="Einstellungen">Einstellungen</a></li>
                    <li><a href="#/kontakt" title="Kontakt">Kontakt</a></li>
                    <li><a href="javascript:;"
                           title="{{ _apiService.isDemoMode() ? 'Demo-Modus verlassen' : 'Logout' }}"
                           class="open-logout">{{ _apiService.isDemoMode() ? 'Demo-Modus verlassen' : 'Logout' }}</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="block block-submenu">
            <div class="inner">
                <div *ngIf="providerclass?.length > 0" class="presentbar">
                    <small>Präsentiert von</small>
                    <div [class]="providerclass"></div>
                </div>
                <nav class="submenu">
                    <ul>
                        <li><a href="#/impressum" angulartics2On="click" angularticsCategory="Menü"
                               angularticsAction="view_imprint" title="Impressum">Impressum</a></li>
                        <li>
                            <a [href]="privacyLink" target="_blank"
                               angulartics2On="click" angularticsCategory="Menü" angularticsAction="view_privacy"
                               title="Datenschutz">Datenschutz</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </aside>

    <!-- Dashboard Content -->
    <div id="inner">

        <header id="header" class="clearfix">
            <nav id="topmenu" class="inline">
                <ul class="clearfix">
                    <li class="sort">
                        <a href="javascript:;" angulartics2On="click" angularticsCategory="Dashboard"
                           angularticsAction="edit_mode_start" title="Umsortieren" class="icon-sort">Umsortieren</a>
                    </li>
                    <li [class.add]="charts_available.length > 0">
                        <a href="javascript:;" title="Bereich hinzufügen"
                           [class.disabled]="charts_available.length === 0" class="icon-add">Bereich hinzufügen</a>
                    </li>
                </ul>
            </nav>
        </header>

        <main id="mainContent" class="clearfix">
            <div class="dashboard grid two clearfix">
                <div class="grid-sizer"></div>
                <div *ngFor="let chart of charts_selected" class="cardwrap large">
                    <button type="button" [attr.data-chart]="chart.id" title="Bereich entfernen"
                            class="open-remove icon-close">
                        <span>Bereich entfernen</span>
                    </button>
                    <live-widget *ngIf="chart.id === 'live'"></live-widget>
                    <!--                    // todo enable again-->
                    <today-widget *ngIf="chart.id === 'today'"></today-widget>
                    <consumer-widget *ngIf="chart.id === 'consumer'"></consumer-widget>
                    <compare-widget *ngIf="chart.id === 'compare'"></compare-widget>
                    <meter-widget *ngIf="chart.id === 'meter'"></meter-widget>
                    <finance-widget *ngIf="chart.id === 'finance'"></finance-widget>

                    <ng-container *ngIf="userHasPlug">
                        <powerchecker-widget *ngIf="chart.id === 'powerchecker'"></powerchecker-widget>
                    </ng-container>

                    <!-- 15.10.18 "Abschlags-Check" und "Verbrauchsalarm" temporär deaktivieren -->
                    <consumption-alert-widget *ngIf="chart.id === 'consumption-alert'"></consumption-alert-widget>

                </div>
                <div *ngIf="charts_available.length > 0" class="cardwrap large add">
                    <div class="block block-tile add large grey">
                        <div class="inner">
                            <a href="javascript:;" class="icon-plus">
                                <span>Bereich hinzufügen</span>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </main>

    </div>

</div>

<!-- Changlog Overlay -->
<div *ngIf="logs" class="overlay-infos">
    <div class="overlay-box">
        <button type="button" (click)="hideChangelog()" class="icon-close fltrt">
            <span>Schließen</span>
        </button>
        <div *ngFor="let logs of changelog">
            <h4>{{ logs.version }}</h4>
            <ul *ngIf="logs.features.length > 0">
                <li class="head">Neue Funktionen:</li>
                <li *ngFor="let feature of logs.features">{{ feature.log }}</li>
            </ul>
            <ul *ngIf="logs.adjustments.length > 0">
                <li class="head">Anpassungen:</li>
                <li *ngFor="let adjustment of logs.adjustments">{{ adjustment.log }}</li>
            </ul>
            <ul *ngIf="logs.bugfixes.length > 0">
                <li class="head">Bugfixes:</li>
                <li *ngFor="let bugfix of logs.bugfixes">{{ bugfix.log }}</li>
            </ul>
        </div>
    </div>
</div>
<div id="overlay-remove" class="overlay-infos" style="display: none;">
    <div class="overlay-box">
        <button type="button" class="close-remove icon-close fltrt">
            <span>Schließen</span>
        </button>
        <h4>Bereich entfernen</h4>
        <p>Wollen Sie diesen Bereich wirklich entfernen?</p>
        <input type="hidden" #chart name="chart" value="">
        <button type="button" (click)="removeChart(chart.value)" class="close-remove btn orange">Ja</button>
        <button type="button" class="close-remove btn outline">Nein</button>
    </div>
</div>
<div id="overlay-logout" class="overlay-infos" style="display: none;">
    <div class="overlay-box">
        <button type="button" class="close-logout icon-close fltrt">
            <span>Schließen</span>
        </button>
        <h4>Abmelden</h4>
        <p>Wollen Sie sich wirklich abmelden?</p>
        <button type="button" (click)="_apiService.logoutUser()" class="btn orange">Ja</button>
        <button type="button" class="close-logout btn outline">Nein</button>
    </div>
</div>
