<div id="register-wrap">
    <fieldset class="register forgot">
        <div class="form-header clearfix">
            <div class="logo"></div>
            <div class="app-name">iONA</div>
        </div>

        <div *ngIf="!resetSuccess" class="form-content">
            <h2>Möchten Sie Ihr Passwort zurücksetzen?</h2>
            <p>Bitte geben Sie zum Zurücksetzen Ihres Passworts einfach Ihre E-Mail-Adresse ein.</p>
            <form (submit)="resetPassword(email.value); false">
                <div class="formItem formCenter">
                    <label for="email">E-Mail-Adresse</label>
                    <input type="text" #email [value]="emailVal" id="email" placeholder="E-Mail-Adresse" class="formText">
                </div>

                <div class="btn-wrap">
                    <button type="submit" class="formSubmit">Passwort zurücksetzen</button>
                </div>

                <div class="helpbtn-wrap">
                    <a href="#/" title="Zurück zum Login" class="skip">Zurück zum Login</a>
                </div>
            </form>
        </div>

        <div *ngIf="resetSuccess" class="form-content">
            <h2>Passwort erfolgreich zurück gesetzt.</h2>
            <p>Bitte überprüfen Sie Ihr Email-Postfach und folgen Sie der Anleitung zum Zurücksetzen Ihres Passworts.</p>

            <div class="btn-wrap">
                <a href="#/" title="Ok" class="btn">Ok</a>
            </div>
        </div>
    </fieldset>
    <div class="bg">
        <video width="100%" height="100%" autoplay muted loop>
            <source src="assets/media/background.mp4" type="video/mp4">
        </video>
    </div>
</div>
