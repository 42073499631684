<div id="container">
    <aside class="sidebar lft">
        <a href="#" id="hamburger" title="Menü" class="mobi">
            <span class="burger">
                <i class="top"></i>
                <i class="middle"></i>
                <i class="bottom"></i>
            </span>
            <span class="text"> Menü</span>
        </a>
        <div class="blackbox">
            <div class="version">
                <a href="javascript:;" (click)="showChangelog()">{{ version }}</a>
            </div>
            <div [class.demo]="username === 'Demo'" class="logo">
                <a href="#/" title="iONA">
                    <span>iONA</span>
                </a>
            </div>
        </div>
        <div class="block block-menu">
            <nav class="inner">
                <ul class="clearfix">
                    <li><a href="#/" title="Übersicht">Übersicht</a></li>
                    <li><a href="#/meine-daten" title="Meine Daten">Meine Daten</a></li>
                    <li><a href="#/mein-haushalt" title="Mein Haushalt">Mein Haushalt</a></li>
                    <li><a href="#/mein-kundenkonto" angulartics2On="click" angularticsCategory="Meine Daten"
                           angularticsAction="my_account_enter"
                           title="{{ providerMenuName }}">{{ providerMenuName }}</a></li>
                    <li><a href="#/einstellungen" title="Einstellungen" class="active">Einstellungen</a></li>
                    <li><a href="#/kontakt" title="Kontakt">Kontakt</a></li>
                    <li><a href="javascript:;"
                           title="{{ _apiService.isDemoMode() ? 'Demo-Modus verlassen' : 'Logout' }}"
                           class="open-logout">{{ _apiService.isDemoMode() ? 'Demo-Modus verlassen' : 'Logout' }}</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="block block-submenu">
            <div class="inner">
                <div *ngIf="providerclass?.length > 0" class="presentbar">
                    <small>Präsentiert von</small>
                    <div [class]="providerclass"></div>
                </div>
                <nav class="submenu">
                    <ul>
                        <li><a href="#/impressum" angulartics2On="click" angularticsCategory="Menü"
                               angularticsAction="view_imprint" title="Impressum">Impressum</a></li>
                        <li>
                            <a [href]="privacyLink" target="_blank"
                               angulartics2On="click" angularticsCategory="Menü" angularticsAction="view_privacy"
                               title="Datenschutz">Datenschutz</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </aside>

    <div id="inner">
        <main id="mainContent" class="clearfix">
            <div class="einstellungen">
                <div class="head">
                    <h2>Einstellungen</h2>
                </div>
                <div class="grid two clearfix">
                    <div class="box">
                        <div class="block block-content">
                            <div class="inner">
                                <h3>Tracking</h3>
                                <fieldset>
                                    <div class="formItem checkbox-wrap">
                                        <input type="checkbox" id="config-tracking" [checked]="config.tracking === 1"
                                               (change)="setSetting('tracking')">
                                        <label for="config-tracking">
                                            <i></i>
                                            <span>
                                                <strong>Tracking erlauben</strong>
											    <small>Wir möchten uns gerne weiterentwickeln und verbessern. Aus diesem Grund analysieren wir anonymisiert die Nutzung der Webseite.</small>
                                            </span>
                                        </label>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div class="box">
                        <div class="block block-content">
                            <div class="inner">
                                <h3>Verbindungen</h3>
                                <div>
                                    <h4>{{deviceIsPlug ? 'Ihr PowerChecker' : 'Ihre iONA-Box'}}</h4>
                                    <div class="connection-container p-b-l p-t-s">
                                        <div class="p-r-s">
                                            <app-connection-indicator [value]="wifi_connection_quality"
                                                                      container_height="24px"
                                                                      [disabled]="wifi_connected >= 0"
                                                                      [config]="wifi_connection_config"
                                                                      [ignoreValue]="lanConnected">
                                            </app-connection-indicator>
                                        </div>
                                        <ng-container *ngIf="lanConnected else wifiConnection">
                                            <div> Verbunden</div>
                                        </ng-container>
                                        <ng-template #wifiConnection>
                                            <div> {{ wifi_connected < 0 ? 'Verbunden' : 'Getrennt' }} </div>
                                        </ng-template>
                                    </div>

                                    <h4>Ihr Stromzähler</h4>
                                    <div class="connection-container p-t-s p-r-s">
                                        <div class="p-r-s">
                                            <app-connection-indicator [value]="meter_connection_quality"
                                                                      container_height="24px"
                                                                      [disabled]="meter_status!== 'connected'"
                                                                      [config]="meter_connection_config">
                                            </app-connection-indicator>
                                        </div>
                                        <div> {{determineMeterStatusMessage()}} </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 22.10.18 - Push Regler sollen vorrübergehend ausgeblendet werden -->
                    <!--<div class="box">
                        <div class="block block-content">
                            <div class="inner">
                                <h3>Verbrauchs-Alarm</h3>
                                <fieldset>
                                    <div class="formItem checkbox-wrap">
                                        <input type="checkbox" id="config-notify-consumption-alert" [checked]="config.notify_consumption === 1" (change)="setSetting('notify_consumption')">
                                        <label for="config-notify-consumption-alert">
                                            <i></i>
                                            <span>
                                                <strong>Benachrichtigung bei erhöhter Aktivität</strong>
											    <small>Sie erhalten eine Benachrichtigung, wenn Ihr Verbrauch auffällig hoch ist.</small>
                                            </span>
                                        </label>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>-->
                </div>
                <!--<div class="grid two clearfix">
                    <div class="box">
                        <div class="block block-content">
                            <div class="inner">
                                <h3>Abschlagskontrolle</h3>
                                <fieldset>
                                    <div class="formItem checkbox-wrap">
                                        <input type="checkbox" id="config-notify-finance" [checked]="config.notify_finance === 1" (change)="setSetting('notify_finance')">
                                        <label for="config-notify-finance">
                                            <i></i>
                                            <span>
                                                <strong>Benachrichtigung zur Abschlagskontrolle erhalten</strong>
											    <small>Sie erhalten eine Benachrichtigung, wenn Ihr Stromverbrauch und die entstandenen Kosten deutlich von Ihrem derzeitigen Abschlag abweichen. Damit haben Sie immer im Blick, ob Sie eine Gutschrift oder eine Nachzahlung zu erwarten haben.</small>
                                            </span>
                                        </label>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>-->
                <div class="btn-wrap">
                    <a href="javascript:;" (click)="saveSettings()" class="btn orange">Einstellungen speichern</a>
                </div>
            </div>
        </main>
    </div>
</div>
<div *ngIf="logs" class="overlay-infos">
    <div class="overlay-box">
        <button type="button" (click)="hideChangelog()" class="icon-close fltrt">
            <span>Schließen</span>
        </button>
        <div *ngFor="let logs of changelog">
            <h4>{{ logs.version }}</h4>
            <ul *ngIf="logs.features.length > 0">
                <li class="head">Neue Funktionen:</li>
                <li *ngFor="let feature of logs.features">{{ feature.log }}</li>
            </ul>
            <ul *ngIf="logs.adjustments.length > 0">
                <li class="head">Anpassungen:</li>
                <li *ngFor="let adjustment of logs.adjustments">{{ adjustment.log }}</li>
            </ul>
            <ul *ngIf="logs.bugfixes.length > 0">
                <li class="head">Bugfixes:</li>
                <li *ngFor="let bugfix of logs.bugfixes">{{ bugfix.log }}</li>
            </ul>
        </div>
    </div>
</div>
<div id="overlay-logout" class="overlay-infos" style="display: none;">
    <div class="overlay-box">
        <button type="button" class="close-logout icon-close fltrt">
            <span>Schließen</span>
        </button>
        <h4>Abmelden</h4>
        <p>Wollen Sie sich wirklich abmelden?</p>
        <button type="button" (click)="_apiService.logoutUser()" class="btn orange">Ja</button>
        <button type="button" class="close-logout btn outline">Nein</button>
    </div>
</div>
