import {Component, OnInit} from '@angular/core';

import {Chart} from 'angular-highcharts';

import {ApiService} from '../../services/api.service';
import {Globals} from '../../services/globals.service';
import {MockDataService} from '../../mock-data.service';

@Component({
    selector: 'consumer-widget',
    templateUrl: './widget.consumer.html',
    viewProviders: [],
    providers: [Globals]
})

export class ConsumerWidget implements OnInit {

    monthname: string = null;

    colors: any = ['#C81E82', '#780A5F', '#808080', '#CCCCCC'];
    color: number = 0;

    tempdata: any = [];

    chart: any = {};

    consumer: any = [];

    showConsumers: boolean = true;
    showDiagrams: boolean = true;
    nilmdata: any = null;

    constructor(private _apiService: ApiService,
                private _globals: Globals,
                private _mockData: MockDataService) {
    }

    ngOnInit() {
        this.monthname = this._apiService.getMonthname(0);

        this.initializeChart();

        if (this._apiService.isDemoMode()) {
            this.getMockElectricalAppliances();
            this.getMockNilmStatus();
            return;
        }

        this.getElectricalApplicances();
        this.getNilmStatus();
    }

    /**
     * Get Demo Mock data
     */
    private getMockElectricalAppliances(): void {
        this._mockData.getElectricalAppliances(0).subscribe(
            (data) => {
                if ('data' in data) {
                    this.drawDiagram(data);
                } else {
                    this.showDiagrams = false;
                }
            }
        );
    }

    private getElectricalApplicances(): void {
        this._apiService.getElectricalAppliances(0).subscribe(
            (data: any) => {
                if ('data' in data) {
                    this.drawDiagram(data);
                } else {
                    this.showDiagrams = false;
                }
            },
            () => {
                this.showDiagrams = false;
            }
        );
    }

    private getMockNilmStatus(): void {
        this._mockData.getNILMStatus().subscribe(
            (data) => {
                if ('data' in data) {
                    this.nilmdata = data.data;
                }
            }
        );

    }

    private getNilmStatus(): void {
        this._apiService.getNilmStatus().subscribe(
            (data: any) => {
                if ('data' in data) {
                    this.nilmdata = data.data;
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }


    /**
     * Sort & align data --> draw the diagram
     * @param data
     */
    private drawDiagram(data: any): void {
        for (let appliance of Object.keys(data.data.electricity.used_budget.categories)) {
            if (data.data.electricity.used_budget.categories[appliance].usage > 0) {
                this.tempdata.push({name: appliance, usage: data.data.electricity.used_budget.categories[appliance].usage});
            }
        }

        this.tempdata.sort((a, b) => a.usage < b.usage);

        for (let appliance of this.tempdata.slice(0, 3)) {

            let color: number = this.getColor();

            this.consumer.push({
                name: appliance.name,
                color: color
            });

            this.chart.addPoint({
                name: appliance.name,
                y: appliance.usage,
                color: color
            });

        }

        let other: number = 0;
        for (const appliance of this.tempdata.slice(3)) {
            other += appliance.usage;
        }

        if (other > 0) {
            let color: number = this.getColor();

            this.consumer.push({
                name: 'Other',
                color: color
            });

            this.chart.addPoint({
                name: 'Other',
                y: other,
                color: color
            });
        }

        this.showConsumers = this.consumer.length != 0;
    }

    /**
     * Farbe für Verbraucher
     */
    getColor() {
        if (this.color < this.colors.length) {
            this.color++;
            return this.colors[this.color - 1];
        } else {
            return this.colors[this.colors.length - 1];
        }
    }

    /**
     * Verbraucher von API Übersetzen
     *
     * @param text
     */
    translate(text: string): string {
        switch (text) {
            case 'AlwaysOn': {
                return 'Standby';
            }
            case 'Refrigeration': {
                return 'Kühlung';
            }
            case 'SpaceHeating': {
                return 'Nachtspeicherofen';
            }
            case 'WaterHeating': {
                return 'Durchlauferhitzer';
            }
            case 'Cooking': {
                return 'Kochen';
            }
            case 'Laundry': {
                return 'Waschen & Trocknen';
            }
            case 'Lighting': {
                return 'Beleuchtung';
            }
            case 'Entertainment': {
                return 'Unterhaltung';
            }
            case 'ElectricVehicle': {
                return 'E-Auto';
            }
            case 'PoolOrSauna': {
                return 'Pool & Sauna';
            }
            case 'Other': {
                return 'Sonstige';
            }
            default: {
                return text;
            }
        }
    }

    firstDetailViewAnalytics() {
        if (!(this._globals.getFirstDetailsViewed())) {
            // Erstes aufrufen eines Detail Screens
            this._apiService._analytics.eventTrack.next({
                action: 'first_detail_view',
                properties: {
                    category: 'Screens',
                    label: 'Screen: Consumer-Details'
                }
            });
        }

        this._globals.setFirstDetailsViews();
    }

    /**
     * Initialize Chart
     */
    private initializeChart(): void {
        const self = this;
        this.chart = new Chart({
            chart: {
                type: 'pie',
                backgroundColor: 'rgba(255, 255, 255, 0)',
                margin: [0, 0, 25, 0],
                events: {
                    redraw: function() {
                        this.reflow();
                    },
                    click: function() {
                        $('.sidebar').each(function() {
                            $(this).removeClass('visible');
                        });

                        $('html').addClass('sidebar-open');
                        $('.sb-overlay').addClass('visible');
                        $('.sidebar.myDevices.consumer-details').toggleClass('visible');
                    }
                }
            },
            title: {
                text: null
            },
            tooltip: {
                hideDelay: 0,
                shadow: false,
                positioner: function(boxWidth: number, boxHeight: number) {
                    return {
                        x: (this.chart.plotWidth / 2) - (boxWidth / 2),
                        y: (this.chart.plotHeight / 2) - (boxHeight / 2)
                    };
                },
                useHTML: true,
                formatter: function() {
                    let html = '<div style="text-align: center;">';

                    const point_name = this.point.name.toLowerCase();
                    if (point_name !== 'other') {
                        if (point_name === 'laundry' || point_name === 'refrigeration' || point_name === 'entertainment' || point_name === 'cooking') {
                            const profile_complete = self.determineProfileCompleteness(point_name);
                            // nilm condition
                            if (profile_complete) {
                                html += `<i class="ico chart-icon icon-${point_name}" style="color: ${this.point.color}"></i>`;
                            } else {
                                const inner = `<i class="boeppel"></i>`;
                                html += `<i class="ico iic chart-icon icon-${point_name}" style="color: ${this.point.color}">${inner}</i>`;
                            }
                        } else {
                            html += `<i class="ico chart-icon icon-${point_name}" style="color: ${this.point.color}"></i>`;
                        }
                    }

                    html += '<h3>' + Math.round(this.point.y).toString().replace(/\B(?=(\d{3})+(?!\d))/, '.') + ' kWh</h3>';

                    const name: string = self.translate(this.point.name);
                    html += '<h4 style="color: ' + this.point.color + ';">' + name + '</h4>';

                    html += '</div>';

                    return html;
                },
                backgroundColor: 'rgba(255, 255, 255, 0)',
                borderWidth: 0
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        useHTML: true,
                        formatter: function() {
                            return '<div class="label" style="color: ' + this.point.color + ';">' + Math.round(this.percentage) + ' %</div>';
                        },
                        distance: 20,
                        padding: 0,
                        connectorWidth: 1,
                        softConnector: false
                    },
                    startAngle: -180,
                    states: {
                        hover: {
                            brightness: 0,
                            halo: false
                        }
                    },
                    point: {
                        events: {
                            mouseOver: function() {
                                this.graphic.attr({
                                    r: this.shapeArgs.r + 5
                                });
                            },
                            mouseOut: function() {
                                this.graphic.attr({
                                    r: this.shapeArgs.r
                                });
                            }
                        }
                    }
                }
            },
            series: [
                {
                    name: null,
                    innerSize: '65%',
                    data: []
                }
            ],
            credits: {
                enabled: false
            }
        });

    }

    /**
     * Determines the profile completenes for a certain category.
     * @param point_name
     */
    public determineProfileCompleteness(point_name: string): boolean {
        if (point_name === 'refrigeration') {
            if (this.nilmdata.nonTimeBasedAppliances.refrigeration !== null || this.nilmdata.nonTimeBasedAppliances.refrigeration !== undefined) {
                const el = this.nilmdata.nonTimeBasedAppliances.refrigeration;
                return this.determineCompletenesOfAppliance(el);
            }
        } else if (point_name === 'cooking') {
            const oven = this.nilmdata.timeBasedAppliances.oven;
            // const hob = this.nilmdata.timeBasedAppliances.hob;
            const profiles = [oven];
            if (profiles.every(element => element !== false && element !== undefined && element !== null)) {
                const results = [];
                for (const element of profiles) {
                    results.push(this.determineCompletenesOfAppliance(element));
                }
                return results.every(el => el !== false && el !== undefined && el !== null);
            }
        } else if (point_name === 'laundry') {
            const washingMachine = this.nilmdata.timeBasedAppliances.washingMachine;
            const dryer = this.nilmdata.timeBasedAppliances.dryer;
            const dishWasher = this.nilmdata.timeBasedAppliances.dishWasher;
            const profiles = [washingMachine, dryer, dishWasher];
            if (profiles.every(element => element !== false && element !== undefined && element !== null)) {
                const results = [];
                for (const element of profiles) {
                    results.push(this.determineCompletenesOfAppliance(element));
                }
                return results.every(el => el !== false && el !== undefined && el !== null);
            }
        } else if (point_name === 'entertainment') {
            let el;
            if (this.nilmdata.nonTimeBasedAppliances.entertainment !== null ||
                this.nilmdata.nonTimeBasedAppliances.entertainment !== undefined) {
                el = this.nilmdata.nonTimeBasedAppliances.entertainment;
            }
            if (el.profileComplete || el.profileComplete === false && el.profileAdded === true) {
                return true;
            }
        } else {
            return true;
        }

        return false;
    }

    public determineCompletenesOfAppliance(el: any): boolean {
        return el.profileComplete || el.profileComplete === false && el.profileAdded === true;
    }

}
