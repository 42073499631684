/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./widget.live.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../remaining-time-indicator/remaining-time-indicator.component.ngfactory";
import * as i3 from "../../remaining-time-indicator/remaining-time-indicator.component";
import * as i4 from "angular-highcharts";
import * as i5 from "angulartics2";
import * as i6 from "@angular/common";
import * as i7 from "../../services/globals.service";
import * as i8 from "./widget.live";
import * as i9 from "../../services/api.service";
import * as i10 from "../../mock-data.service";
import * as i11 from "../../user.service";
var styles_LiveWidget = [i0.styles];
var RenderType_LiveWidget = i1.ɵcrt({ encapsulation: 0, styles: styles_LiveWidget, data: {} });
export { RenderType_LiveWidget as RenderType_LiveWidget };
function View_LiveWidget_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "happy-hour-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-remaining-time-indicator", [], null, null, null, i2.View_RemainingTimeIndicatorComponent_0, i2.RenderType_RemainingTimeIndicatorComponent)), i1.ɵdid(2, 114688, [[1, 4], ["happyHourIndicator", 4]], 0, i3.RemainingTimeIndicatorComponent, [i1.Renderer2], { size: [0, "size"], refreshRate: [1, "refreshRate"], overallTime: [2, "overallTime"], indicatorWidth: [3, "indicatorWidth"] }, null)], function (_ck, _v) { var currVal_0 = 36; var currVal_1 = 10; var currVal_2 = (60 * 60); var currVal_3 = 6; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_LiveWidget_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { hhIndicator: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 21, "div", [["class", "block block-tile large"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 20, "div", [["class", "inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Live"])), (_l()(), i1.ɵeld(5, 0, null, null, 10, "div", [["class", "live-chartwrap"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["id", "chart-live-large"]], null, null, null, null, null)), i1.ɵdid(7, 737280, null, 0, i4.ɵb, [i1.ElementRef], { chart: [0, "chart"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "watt"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", " "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Watt"])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "a", [["angulartics2On", "click"], ["angularticsAction", "screen_view"], ["angularticsCategory", "Screens"], ["angularticsLabel", "screen: Live; previous_screen: \u00DCbersicht"], ["class", "live-details icon-arrow-rt"], ["href", "javascript:;"], ["title", "Details anzeigen"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.firstDetailViewAnalytics() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 1064960, null, 0, i5.Angulartics2On, [i1.ElementRef, i5.Angulartics2, i1.Renderer2], { angulartics2On: [0, "angulartics2On"], angularticsAction: [1, "angularticsAction"], angularticsCategory: [2, "angularticsCategory"], angularticsLabel: [3, "angularticsLabel"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Details anzeigen"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LiveWidget_1)), i1.ɵdid(17, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 0, "div", [["class", "error blue"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 3, "div", [["class", "error-text"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Daten sind noch "])), (_l()(), i1.ɵeld(21, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["nicht verf\u00FCgbar"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.chart; _ck(_v, 7, 0, currVal_1); var currVal_3 = "click"; var currVal_4 = "screen_view"; var currVal_5 = "Screens"; var currVal_6 = "screen: Live; previous_screen: \u00DCbersicht"; _ck(_v, 13, 0, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.userHasHappyHour; _ck(_v, 17, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.showDiagrams; _ck(_v, 5, 0, currVal_0); var currVal_2 = _co.current_consumption; _ck(_v, 9, 0, currVal_2); var currVal_8 = _co.showDiagrams; _ck(_v, 18, 0, currVal_8); var currVal_9 = _co.showDiagrams; _ck(_v, 19, 0, currVal_9); }); }
export function View_LiveWidget_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "live-widget", [], null, null, null, View_LiveWidget_0, RenderType_LiveWidget)), i1.ɵprd(512, null, i7.Globals, i7.Globals, []), i1.ɵdid(2, 245760, null, 0, i8.LiveWidget, [i9.ApiService, i7.Globals, i10.MockDataService, i11.UserService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var LiveWidgetNgFactory = i1.ɵccf("live-widget", i8.LiveWidget, View_LiveWidget_Host_0, {}, {}, []);
export { LiveWidgetNgFactory as LiveWidgetNgFactory };
