<div class="sidebar-head">
    <div class="btn-wrao fltrt">
        <button type="button" angulartics2On="click" angularticsCategory="Detailansichten"
                angularticsAction="detail_info_text" angularticsLabel="Vergleich" class="icon-info">
            <span>Hilfe</span>
        </button>
        <button type="button" class="icon-close">
            <span>Schließen</span>
        </button>
    </div>
    <h2>Vergleich</h2>
</div>
<div class="myDevices-content">
    <div class="msg-wrap hidden">
        <div class="msg info icon-info">
            <button type="button" class="icon-close">
                <span>Schließen</span>
            </button>
            <h3>Vergleich: Was ist das?</h3>
            <p>iONA liebt Statistiken. Vergleichen Sie doch einfach mal Ihren Energieverbrauch mit dem Vortag, der
                Vorwoche, dem Vormonat oder dem Vorjahr. iONA vergleicht Ihnen alles - nur nicht Äpfel mit Birnen.</p>
        </div>
    </div>
    <div class="myDevices-options">
        <div fxLayout="row" fxLayoutAlign="end center">
            <div [ngClass]="{'p-r-m': userHasHappyHour}">
                <div>
                    <!--                    <label for="area">Bereich wählen</label>-->
                    <select id="area" class="dropdown"
                            (change)="setTimeframe($event.target.value)" angulartics2On="change"
                            angularticsCategory="Detailansichten" angularticsAction="detail_time_interval_change"
                            angularticsLabel="Vergleich - {{ mode === 1 ? 'Statisch' : 'Anpassbar' }}">
                        <option value="1" [selected]="timeframe === 1">Tag</option>
                        <option value="2" [selected]="timeframe === 2">Woche</option>
                        <option value="3" [selected]="timeframe === 3">Monat</option>
                        <option value="4" [selected]="timeframe === 4">Jahr</option>
                    </select>
                </div>
            </div>

            <div class="switch" fxLayout="row" fxLayoutAlign=" center" *ngIf="userHasHappyHour">
                <div class="center-contents"
                     [ngClass]="{'active' : showConsumptionFor === 'all'}"
                     (click)="setDataMode('all')">
                    Gesamt
                </div>
                <div class="center-contents"
                     [ngClass]="{'active' : showConsumptionFor !== 'all'}"
                     (click)="setDataMode('happyhour')">
                    Happy Hour
                </div>
            </div>
        </div>

        <div class="view-options clearfix">
            <a href="javascript:;" (click)="setMode(1)" title="Statisch" [class.active]="mode === 1">Statisch</a>
            <a href="javascript:;" (click)="setMode(2)" title="Anpassbar" [class.active]="mode === 2">Anpassbar</a>
        </div>
    </div>
    <div class="compare-chart-wrap">
        <div class="compare-chart">
            <div [chart]="chart" id="chart-compare-details"></div>
        </div>
    </div>
    <fieldset *ngIf="mode === 2" [class.day]="timeframe === 1" [class.week]="timeframe === 2"
              [class.month]="timeframe === 3" [class.year]="timeframe === 4" class="filterbar">
        <div class="formItem" *ngIf="timeframe === 1">
            <label for="left_day">Tag</label>
            <select id="left_day" (change)="setCompare('left_day', $event.target.value)">
                <option *ngFor="let day of loop(1, 31)" value="{{ day }}"
                        [selected]="compare.left_day === day">{{ day }}</option>
            </select>
        </div>
        <div class="formItem" *ngIf="timeframe === 2">
            <label for="left_kw">Kalenderwoche</label>
            <select id="left_kw" (change)="setCompare('left_kw', $event.target.value)">
                <option *ngFor="let kw of loop(1, 52)" value="{{ kw }}" [selected]="compare.left_kw === kw">
                    KW {{ kw }}</option>
            </select>
        </div>
        <div class="formItem" *ngIf="(timeframe === 1) || (timeframe === 3)">
            <label for="left_month">Monat</label>
            <select id="left_month" (change)="setCompare('left_month', $event.target.value)">
                <option *ngFor="let month of _apiService.MONTHS; let i = index" value="{{ i + 1 }}"
                        [selected]="compare.left_month === (i + 1)">{{ month }}</option>
            </select>
        </div>
        <div class="formItem">
            <label for="left_year">Jahr</label>
            <select id="left_year" (change)="setCompare('left_year', $event.target.value)">
                <option *ngFor="let year of loop(year - 4, 5)" value="{{ year }}"
                        [selected]="compare.left_year === year">{{ year }}</option>
            </select>
        </div>
        <div class="formItem splitter"></div>
        <div class="formItem" *ngIf="timeframe === 1">
            <label for="right_day">Tag</label>
            <select id="right_day" (change)="setCompare('right_day', $event.target.value)">
                <option *ngFor="let day of loop(1, 31)" value="{{ day }}"
                        [selected]="compare.right_day === day">{{ day }}</option>
            </select>
        </div>
        <div class="formItem" *ngIf="timeframe === 2">
            <label for="right_kw">Kalenderwoche</label>
            <select id="right_kw" (change)="setCompare('right_kw', $event.target.value)">
                <option *ngFor="let kw of loop(1, 52)" value="{{ kw }}" [selected]="compare.right_kw === kw">
                    KW {{ kw }}</option>
            </select>
        </div>
        <div class="formItem" *ngIf="(timeframe === 1) || (timeframe === 3)">
            <label for="right_month">Monat</label>
            <select id="right_month" (change)="setCompare('right_month', $event.target.value)">
                <option *ngFor="let month of _apiService.MONTHS; let i = index" value="{{ i + 1 }}"
                        [selected]="compare.right_month === (i + 1)">{{ month }}</option>
            </select>
        </div>
        <div class="formItem">
            <label for="right_year">Jahr</label>
            <select id="right_year" (change)="setCompare('right_year', $event.target.value)">
                <option *ngFor="let year of loop(year - 4, 5)" value="{{ year }}"
                        [selected]="compare.right_year === year">{{ year }}</option>
            </select>
        </div>
    </fieldset>
</div>
<div *ngIf="(mode === 1) && (timeframe !== 4)" class="myDevices-pager clearfix">
    <button type="button" (click)="positionBack()" [disabled]="disabled || positionFinal" title="Zurück" class="icon-back fltlft">
        <span>Zurück</span>
    </button>
    <button type="button" (click)="positionNow()" [disabled]="disabled || position === 1" title="Jetzt"
            class="current fltrt">
        <span>Jetzt</span>
    </button>
    <button type="button" (click)="positionForward()" [disabled]="disabled || position === 1" title="Weiter"
            class="icon-forward fltrt">
        <span>Weiter</span>
    </button>
</div>
