import {Component, OnInit} from '@angular/core';

import {Chart} from 'angular-highcharts';
import {Angulartics2} from 'angulartics2';

import {ApiService} from '../../services/api.service';
import {MockDataService} from '../../mock-data.service';

@Component({
    selector: 'consumer-details',
    templateUrl: './details.consumer.html',
    styleUrls: ['details.consumer.scss'],
    viewProviders: []
})

export class ConsumerDetails implements OnInit {

    months: any = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
    date: any = null;

    colors: any = ['#C81E82', '#780A5F', '#808080', '#CCCCCC'];
    color: number = 0;

    tempdata: any = [];

    mode: number = 1;
    offset: number = 0;
    month: number = 0;

    disabled: boolean = true;

    chart: any = {};

    consumer: any = [];

    showConsumers: boolean = true;
    nilmdata: any = null;

    constructor(private _analytics: Angulartics2,
                private _apiService: ApiService,
                private _mockData: MockDataService) {
    }

    ngOnInit() {
        this.date = this.months[new Date().getMonth()] + ' ' + new Date().getFullYear();
        this.initializeChart();

        if (this._apiService.isDemoMode()) {
            this.getMockElectricalAppliances();
            this.getMockNilmStatus();
            return;
        }

        this.getElectricalAppliances();
        this.getNilmStatus();
    }

    /**
     * Alle Verbraucher-Daten holen (Monatsübersicht)
     */
    getElectricalAppliances() {
        let mode: number = this.mode;
        let offset: number = this.offset;
        this.showConsumers = true;

        this._apiService.getElectricalAppliances(this.offset).subscribe(
            (data: any) => {
                if ((mode === this.mode) && (offset === this.offset) && ('data' in data)) {
                    this.drawChart(data);
                }
            },
            () => {
                let chart: any = $('#chart-consumer-details').highcharts();
                chart.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');
                this.showConsumers = false;
                this.disabled = false;
            }
        );
    }

    private getMockElectricalAppliances(): void {
        let mode: number = this.mode;
        let offset: number = this.offset;
        this.showConsumers = true;

        this._mockData.getElectricalAppliances(offset).subscribe(
            (data) => {
                if (data.data !== null && data.data !== undefined) {
                    if ('data' in data) {
                        this.drawChart(data);
                    }
                } else {
                    let chart: any = $('#chart-consumer-details').highcharts();
                    chart.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');
                    this.showConsumers = false;
                    this.disabled = false;
                }

            }
        );
    }

    private getMockNilmStatus(): void {
        this._mockData.getNILMStatus().subscribe(
            (data) => {
                if ('data' in data) {
                    this.nilmdata = data.data;
                }
            }
        );

    }

    private getNilmStatus(): void {
        this._apiService.getNilmStatus().subscribe(
            (data: any) => {
                if ('data' in data) {
                    this.nilmdata = data.data;
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }


    /**
     * Alle Verbraucher-Daten holen (Jahresübersicht)
     */
    getElectricalAppliancesYear() {
        let mode: number = this.mode;
        let offset: number = this.offset;
        this.showConsumers = true;

        for (let month of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]) {

            this._apiService.getMonthConsumer(month, this.offset).subscribe(
                (data: any) => {
                    this.month++;

                    if ((mode === this.mode) && (offset === this.offset) && ('data' in data)) {
                        for (let consumer of Object.keys(data.data.electricity.used_budget.categories)) {

                            if (data.data.electricity.used_budget.categories[consumer].usage > 0) {
                                if (consumer in this.tempdata) {
                                    this.tempdata[consumer].push({
                                        usage: data.data.electricity.used_budget.categories[consumer].usage,
                                        cost: data.data.electricity.used_budget.categories[consumer].cost
                                    });
                                } else {
                                    this.tempdata[consumer] = [{
                                        usage: data.data.electricity.used_budget.categories[consumer].usage,
                                        cost: data.data.electricity.used_budget.categories[consumer].cost
                                    }];
                                }
                            }

                        }
                    }

                    this.updateChart();
                },
                () => {
                    this.month++;
                    this.updateChart();
                }
            );

        }
    }


    /**
     *
     */
    private getMockElectricalAppliancesYear(): void {

        for (let month of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]) {
            this._mockData.getElectricalAppliancesForMonth(month, this.offset).subscribe(
                (data) => {

                    if (data.data !== null && data.data !== undefined) {

                        ++this.month;
                        if ('data' in data) {
                            for (let appliance of Object.keys(data.data.electricity.used_budget.categories)) {

                                if (data.data.electricity.used_budget.categories[appliance].usage > 0) {
                                    if (appliance in this.tempdata) {
                                        this.tempdata[appliance].push({
                                            usage: data.data.electricity.used_budget.categories[appliance].usage,
                                            cost: data.data.electricity.used_budget.categories[appliance].cost
                                        });
                                    } else {
                                        this.tempdata[appliance] = [{
                                            usage: data.data.electricity.used_budget.categories[appliance].usage,
                                            cost: data.data.electricity.used_budget.categories[appliance].cost
                                        }];
                                    }
                                }

                            }
                        }
                    } else {
                        this.month++;
                        this.updateChart();
                    }

                    this.updateChart();
                }
            );
        }
    }


    /**
     * Modus wechseln (Monat/Jahr)
     *
     * @param mode
     */
    setMode(mode: number) {
        if (mode !== this.mode) {
            this.mode = mode;
            this.offset = 0;

            this.resetChart();

            this._analytics.eventTrack.next({
                action: 'screen_view',
                properties: {
                    category: 'Screens',
                    label: 'screen: Meine Geräte - ' + (this.mode === 2 ? 'Jahresübersicht' : 'Monatsübersicht') + '; previous_screen: Meine Geräte - ' + (this.mode === 2 ? 'Monatsübersicht' : 'Jahresübersicht')
                }
            });
        }
    }

    /**
     * Eine Einheit vor
     */
    positionForward() {
        if ((this.offset > 0) && (!this.disabled)) {
            this.offset--;

            this.resetChart();
        }
    }

    /**
     * Eine Einheit zurück
     */
    positionBack() {
        if (!this.disabled) {
            this.offset++;

            this.resetChart();
        }
    }

    /**
     * Diagramm zurücksetzen und Daten neu holen
     */
    resetChart() {
        this.updateDate();

        let chart: any = $('#chart-consumer-details').highcharts();
        chart.showLoading();

        this.chart.removeSerie(0);
        this.chart.addSerie(
            {
                name: null,
                innerSize: '65%',
                data: []
            }
        );

        this.disabled = true;
        this.consumer = [];

        if (this.mode === 2) {
            if (this._apiService.isDemoMode()) {
                this.getMockElectricalAppliancesYear();
                return;
            }

            this.getElectricalAppliancesYear();
        } else {
            if (this._apiService.isDemoMode()) {
                this.getMockElectricalAppliances();
                return;
            }

            this.getElectricalAppliances();
        }
    }

    /**
     * Diagramm aktualisieren
     */
    updateChart() {
        this.showConsumers = true;

        if (this.month === 12) {
            let tempdata: any = [];

            for (const appliance of Object.keys(this.tempdata)) {
                let usage: number = 0;
                let cost: number = 0;

                for (const month of this.tempdata[appliance]) {
                    usage += month.usage;
                    cost += month.cost;
                }

                tempdata.push({
                    name: appliance,
                    usage: usage,
                    cost: cost
                });
            }

            tempdata.sort((a: any, b: any) => {
                if (a.usage < b.usage) {
                    return 1;
                }
                if (b.usage < a.usage) {
                    return -1;
                }
                return 0;
            });

            for (const appliance of tempdata) {

                let color: number = this.getColor();
                this.chart.addPoint({
                    name: appliance.name,
                    y: Math.round(appliance.usage),
                    x: appliance.cost,
                    color: color
                });

                this.consumer.push({
                    name: appliance.name,
                    kwh: Math.round(appliance.usage).toLocaleString('de-DE'),
                    cost: appliance.cost.toLocaleString('de-DE', {style: 'currency', currency: 'EUR'}),
                    color: color
                });

            }

            this.showConsumers = this.consumer.length != 0;

            let chart: any = $('#chart-consumer-details').highcharts();

            if (this.consumer.length > 0) {
                chart.hideLoading();
            } else {
                chart.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');
            }

            this.month = 0;
            this.color = 0;
            this.tempdata = [];
            this.disabled = false;
        }
    }

    /**
     * Datum zurücksetzen
     */
    updateDate() {
        switch (this.mode) {
            case 1: {
                let date: any = new Date();
                date.setMonth(date.getMonth() - this.offset, 1);
                this.date = this.months[date.getMonth()] + ' ' + date.getFullYear();
                break;
            }
            case 2: {
                let date: any = new Date();
                date.setFullYear(date.getFullYear() - this.offset);
                this.date = date.getFullYear();
                break;
            }
        }
    }

    /**
     * Farbe für Verbraucher
     */
    getColor() {
        if (this.color < this.colors.length) {
            this.color++;
            return this.colors[this.color - 1];
        } else {
            return this.colors[this.colors.length - 1];
        }
    }

    /**
     * Übersetzung der Verbraucher von API
     *
     * @param text
     */
    translate(text: string): string {
        switch (text) {
            case 'AlwaysOn': {
                return 'Standby';
            }
            case 'Refrigeration': {
                return 'Kühlung';
            }
            case 'SpaceHeating': {
                return 'Nachtspeicherofen';
            }
            case 'WaterHeating': {
                return 'Durchlauferhitzer';
            }
            case 'Cooking': {
                return 'Kochen';
            }
            case 'Laundry': {
                return 'Waschen & Trocknen';
            }
            case 'Lighting': {
                return 'Beleuchtung';
            }
            case 'Entertainment': {
                return 'Unterhaltung';
            }
            case 'ElectricVehicle': {
                return 'E-Auto';
            }
            case 'PoolOrSauna': {
                return 'Pool & Sauna';
            }
            case 'Other': {
                return 'Sonstige';
            }
            default: {
                return text;
            }
        }
    }

    /**
     * Draw
     * @param data
     */
    private drawChart(data): void {
        for (let consumer of Object.keys(data.data.electricity.used_budget.categories)) {

            if (data.data.electricity.used_budget.categories[consumer].usage > 0) {
                this.tempdata.push({
                    name: consumer,
                    usage: data.data.electricity.used_budget.categories[consumer].usage,
                    cost: data.data.electricity.used_budget.categories[consumer].cost
                });
            }

        }

        this.tempdata.sort((a: any, b: any) => {
            if (a.usage < b.usage) {
                return 1;
            }
            if (b.usage < a.usage) {
                return -1;
            }
            return 0;
        });

        for (let consumer of this.tempdata) {

            let color: number = this.getColor();

            this.consumer.push({
                name: consumer.name,
                kwh: Math.round(consumer.usage).toLocaleString('de-DE'),
                cost: consumer.cost.toLocaleString('de-DE', {style: 'currency', currency: 'EUR'}),
                color: color
            });

            this.chart.addPoint({
                name: consumer.name,
                y: Math.round(consumer.usage),
                x: consumer.cost,
                color: color
            });
        }

        let chart: any = $('#chart-consumer-details').highcharts();
        if (chart !== null && chart !== undefined) {
            chart.hideLoading();
        }

        this.color = 0;
        this.tempdata = [];
        this.disabled = false;

        this.showConsumers = this.consumer.length != 0;
    }


    /**
     * Initialize the chart
     */
    private initializeChart(): void {
        const self = this;
        this.chart = new Chart({
            chart: {
                type: 'pie',
                backgroundColor: 'rgba(255, 255, 255, 0)',
                margin: [0, 0, 25, 0]
            },
            title: {
                text: null
            },
            tooltip: {
                hideDelay: 0,
                shadow: false,
                positioner: function(boxWidth: number, boxHeight: number) {
                    return {
                        x: (this.chart.plotWidth / 2) - (boxWidth / 2),
                        y: (this.chart.plotHeight / 2) - (boxHeight / 2)
                    };
                },
                useHTML: true,
                formatter: function() {
                    let html = '<div style="text-align: center;">';

                    const point_name = this.point.name.toLowerCase();
                    if (point_name !== 'other') {
                        if (point_name === 'laundry' || point_name === 'refrigeration' || point_name === 'entertainment' || point_name === 'cooking') {
                            const profile_complete = self.determineProfileCompleteness(point_name);
                            // nilm condition
                            if (profile_complete) {
                                html += `<i class="ico chart-icon icon-${point_name}" style="color: ${this.point.color}"></i>`;
                            } else {
                                const inner = `<i class="boeppel"></i>`;
                                html += `<i class="ico iic chart-icon icon-${point_name}" style="color: ${this.point.color}">${inner}</i>`;
                            }
                        } else {
                            html += `<i class="ico chart-icon icon-${point_name}" style="color: ${this.point.color}"></i>`;
                        }
                    }


                    html += '<h3>' + this.point.y.toLocaleString('de-DE') + ' kWh</h3>';
                    html += '<hr>';
                    html += '<h3>' + this.point.x.toLocaleString('de-DE', {style: 'currency', currency: 'EUR'}) + '</h3>';
                    html += '</div>';

                    return html;
                },
                backgroundColor: 'rgba(255, 255, 255, 0)',
                borderWidth: 0
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        useHTML: true,
                        formatter: function() {
                            return '<div class="label" style="color: ' + this.point.color + ';">' + Math.round(this.percentage) + ' %</div>';
                        },
                        distance: 20,
                        padding: 0,
                        connectorWidth: 1,
                        softConnector: false
                    },
                    startAngle: -180,
                    states: {
                        hover: {
                            brightness: 0,
                            halo: false
                        }
                    },
                    point: {
                        events: {
                            mouseOver: function() {
                                this.graphic.attr({
                                    r: this.shapeArgs.r + 5
                                });
                            },
                            mouseOut: function() {
                                this.graphic.attr({
                                    r: this.shapeArgs.r
                                });
                            }
                        }
                    }
                }
            },
            series: [
                {
                    name: null,
                    innerSize: '65%',
                    data: []
                }
            ],
            credits: {
                enabled: false
            }
        });

    }

    /**
     * Determines the profile completenes for a certain category.
     * @param point_name
     */
    public determineProfileCompleteness(point_name: string): boolean {
        if (point_name === 'refrigeration') {
            if (this.nilmdata.nonTimeBasedAppliances.refrigeration !== null || this.nilmdata.nonTimeBasedAppliances.refrigeration !== undefined) {
                const el = this.nilmdata.nonTimeBasedAppliances.refrigeration;
                return this.determineCompletenesOfAppliance(el);
            }
        } else if (point_name === 'cooking') {
            const oven = this.nilmdata.timeBasedAppliances.oven;
            // const hob = this.nilmdata.timeBasedAppliances.hob;
            const profiles = [oven];
            if (profiles.every(element => element !== false && element !== undefined && element !== null)) {
                const results = [];
                for (const element of profiles) {
                    results.push(this.determineCompletenesOfAppliance(element));
                }
                return results.every(el => el !== false && el !== undefined && el !== null);
            }
        } else if (point_name === 'laundry') {
            const washingMachine = this.nilmdata.timeBasedAppliances.washingMachine;
            const dryer = this.nilmdata.timeBasedAppliances.dryer;
            const dishWasher = this.nilmdata.timeBasedAppliances.dishWasher;
            const profiles = [washingMachine, dryer, dishWasher];
            if (profiles.every(element => element !== false && element !== undefined && element !== null)) {
                const results = [];
                for (const element of profiles) {
                    results.push(this.determineCompletenesOfAppliance(element));
                }
                return results.every(el => el !== false && el !== undefined && el !== null);
            }
        } else if (point_name === 'entertainment') {
            let el;
            if (this.nilmdata.nonTimeBasedAppliances.entertainment !== null ||
                this.nilmdata.nonTimeBasedAppliances.entertainment !== undefined) {
                el = this.nilmdata.nonTimeBasedAppliances.entertainment;
            }
            if (el.profileComplete || el.profileComplete === false && el.profileAdded === true) {
                return true;
            }
        } else {
            return true;
        }

        return false;
    }

    public determineCompletenesOfAppliance(el: any): boolean {
        return el.profileComplete || el.profileComplete === false && el.profileAdded === true;
    }


}
