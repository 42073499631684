<div class="block block-tile large">
    <div class="inner">

        <h3>Meine Geräte</h3>

        <h4 [hidden]="!showDiagrams">{{ monthname }}</h4>
        <div [chart]="chart" [hidden]="!showDiagrams" id="chart-consumer-large"></div>

        <a href="javascript:;" angulartics2On="click" angularticsCategory="Screens" angularticsAction="screen_view"
           angularticsLabel="screen: Meine Geräte - Monatsübersicht; previous_screen: Übersicht"
           (click)="firstDetailViewAnalytics()" title="Details anzeigen" class="consumer-details icon-arrow-rt">
            <span>Details anzeigen</span>
        </a>

        <div class="chart-stat-wrap" [hidden]="!showConsumers || !showDiagrams">
            <div class="chart-stat">
                <div *ngFor="let con of consumer" class="stat-item" [style.color]="con.color">
                    <span class="stat">{{ translate(con.name) }}</span>
                    <span class="bar" [style.backgroundColor]="con.color"></span>
                </div>
            </div>
        </div>

        <div class="error magenta" [hidden]="showDiagrams"></div>
        <div class="error-text" [hidden]="showDiagrams">Daten sind noch <br/>nicht verfügbar</div>
    </div>
</div>
