/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "angular-highcharts";
import * as i2 from "angulartics2";
import * as i3 from "@angular/common";
import * as i4 from "../../services/globals.service";
import * as i5 from "./widget.compare";
import * as i6 from "../../services/api.service";
import * as i7 from "../../mock-data.service";
import * as i8 from "../../user.service";
var styles_CompareWidget = [];
var RenderType_CompareWidget = i0.ɵcrt({ encapsulation: 2, styles: styles_CompareWidget, data: {} });
export { RenderType_CompareWidget as RenderType_CompareWidget };
function View_CompareWidget_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", " % ", " "])), (_l()(), i0.ɵted(3, null, [" als im ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.trend.percent; var currVal_1 = ((_co.trend.direction > 0) ? "mehr" : "weniger"); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.trend.monthname; _ck(_v, 3, 0, currVal_2); }); }
export function View_CompareWidget_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 17, "div", [["class", "block block-tile large"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 16, "div", [["class", "inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Vergleich"])), (_l()(), i0.ɵeld(4, 0, null, null, 6, "div", [["class", "orange-chart-wrap"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["id", "chart-compare-large"]], null, null, null, null, null)), i0.ɵdid(6, 737280, null, 0, i1.ɵb, [i0.ElementRef], { chart: [0, "chart"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 3, "a", [["angulartics2On", "click"], ["angularticsAction", "screen_view"], ["angularticsCategory", "Screens"], ["angularticsLabel", "screen: Vergleich - Statisch; previous_screen: \u00DCbersicht"], ["class", "compare-details icon-arrow-rt"], ["href", "javascript:;"], ["title", "Details anzeigen"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.firstDetailViewAnalytics() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 1064960, null, 0, i2.Angulartics2On, [i0.ElementRef, i2.Angulartics2, i0.Renderer2], { angulartics2On: [0, "angulartics2On"], angularticsAction: [1, "angularticsAction"], angularticsCategory: [2, "angularticsCategory"], angularticsLabel: [3, "angularticsLabel"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Details anzeigen"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CompareWidget_1)), i0.ɵdid(12, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(13, 0, null, null, 0, "div", [["class", "error orange"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 3, "div", [["class", "error-text"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Daten sind noch "])), (_l()(), i0.ɵeld(16, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["nicht verf\u00FCgbar"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.chart; _ck(_v, 6, 0, currVal_1); var currVal_2 = "click"; var currVal_3 = "screen_view"; var currVal_4 = "Screens"; var currVal_5 = "screen: Vergleich - Statisch; previous_screen: \u00DCbersicht"; _ck(_v, 8, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = ((_co.trend.percent > 0) && (_co.trend.percent < 9999)); _ck(_v, 12, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.showDiagrams; _ck(_v, 4, 0, currVal_0); var currVal_7 = _co.showDiagrams; _ck(_v, 13, 0, currVal_7); var currVal_8 = _co.showDiagrams; _ck(_v, 14, 0, currVal_8); }); }
export function View_CompareWidget_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "compare-widget", [], null, null, null, View_CompareWidget_0, RenderType_CompareWidget)), i0.ɵprd(512, null, i4.Globals, i4.Globals, []), i0.ɵdid(2, 114688, null, 0, i5.CompareWidget, [i6.ApiService, i4.Globals, i7.MockDataService, i8.UserService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var CompareWidgetNgFactory = i0.ɵccf("compare-widget", i5.CompareWidget, View_CompareWidget_Host_0, {}, {}, []);
export { CompareWidgetNgFactory as CompareWidgetNgFactory };
