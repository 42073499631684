import {Component, OnDestroy, OnInit} from '@angular/core';

import {ApiService} from '../../services/api.service';
import {Globals} from '../../services/globals.service';
import {MockDataService} from '../../mock-data.service';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {flatMap, map} from 'rxjs/operators';
import {constants} from '../../constants';
import {applySourceSpanToExpressionIfNeeded} from '@angular/compiler/src/output/output_ast';

@Component({
    selector: 'consumption-alert-widget',
    templateUrl: './widget.consumption-alert.html',
    styleUrls: ['./widget.consumption-alert.scss'],
    viewProviders: [ApiService],
    providers: [Globals]
})

export class ConsumptionAlertWidget implements OnInit, OnDestroy {

    currentZone: number = 0;
    since = '';
    power = '';
    showSinceInfo = false;
    showPowerInfo = false;

    ZONE_HYPERACTIVE = 2;
    private ZONE_IDLE = 0;
    private ZONE_UNKNOWN = 4;

    private dateFormat = 'DD.MM.YYYY';

    private mockSubscriptions: Subscription[] = [];

    constructor(private _apiService: ApiService,
                private _globals: Globals,
                private mockData: MockDataService) {
    }

    /**
     * Widget Verbrauchsalarm ist aktuell deaktiviert und ohne Funktion
     */
    ngOnInit() {
        if (this._apiService.isDemoMode()) {
            this.getHomeStateMock();
            return;
        }
        this.getHomeState();
    }

    ngOnDestroy(): void {
        for (const element of this.mockSubscriptions) {
            if (element) {
                element.unsubscribe();
            }
        }

    }

    private getHomeState(): void {
        const s = this._apiService.getHomeStateStatus().pipe(
            flatMap((status) => {
                return this._apiService.getHomeStateConfig().pipe(
                    map((config) => {
                        return {status, config};
                    })
                );
            })
        ).subscribe((res) => {
            const status = res.status;
            this.handleHomeStateStatus(status.data);
            const config = res.config;
            this.handleHomeStateConfig(config.data);
        });

        this.mockSubscriptions.push(s);

    }

    private getHomeStateMock(): void {
        const s = this.mockData.getHomeStateStatus().pipe(
            flatMap((status) => {
                return this.mockData.getHomeStateConfig().pipe(
                    map((config) => {
                        return {status, config};
                    })
                );
            })
        ).subscribe((res) => {
            const status = res.status;
            if (status.status === 'ok') {
                this.handleHomeStateStatus(status.data);
            }
            const config = res.config;
            if (config.status === 'ok') {
                this.handleHomeStateConfig(config.data);
            }
        });

        this.mockSubscriptions.push(s);
    }

    private handleHomeStateStatus(status: any): void {
        try {

            // this.currentZone = Math.max(this.ZONE_IDLE, Math.min(this.ZONE_UNKNOWN, status.current_zone));
            //
            // let since = null;
            // if (this._apiService.isDemoMode()) {
            //     const app_expire_date = localStorage.getItem(constants.application.storage.keys.app_expire);
            //     const d = new Date(app_expire_date);
            //     since = moment(d).subtract(5, 'minutes');
            // } else {
            //     since = moment(new Date(status.since));
            // }
            //
            //
            //
            //
            //
            // const diff_from_now = moment().diff(since) / 1000;
            // if (diff_from_now <= 60) {
            //     this.since = 'seit gerade eben';
            // }
            // if (diff_from_now > 60 && diff_from_now <= 3600) {
            //     const mins = diff_from_now / 60;
            //     this.since = `seit ${Math.round(mins)} Minute${mins > 1 ? 'n' : ''}`;
            // }
            // if (diff_from_now > 3600 && diff_from_now <= 86400) {
            //     this.since = `seit ${Math.round(diff_from_now / 360)} Stunden`;
            // }
            // if (diff_from_now > 86400) {
            //     this.since = `seit ${since.format(this.dateFormat)}`;
            // }

            this.since = 'Maximum der letzten 7 Tage:';
            this.showSinceInfo = true;

        } catch (e) {
            console.log('Error', e);
        }
    }

    private handleHomeStateConfig(config: any): void {
        console.log('config', config);
        try {
            if (config.thresholds.length < 1) {
                this.power = `${0} Watt`;
            } else {
                this.power = `${Math.round(config.thresholds.reduce((a, b) => Math.max(a, b))).toLocaleString('de')} Watt`;
            }
            this.showPowerInfo = true;
        } catch (e) {
            console.log('Error', e);
        }
    }


    firstDetailViewAnalytics() {
        if (!(this._globals.getFirstDetailsViewed())) {
            // Erstes aufrufen eines Detail Screens
            this._apiService._analytics.eventTrack.next({
                action: 'first_detail_view',
                properties: {
                    category: 'Screens',
                    label: 'Screen: Consumption-Details'
                }
            });
        }
        this._globals.setFirstDetailsViews();
    }
}
