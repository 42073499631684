import {Component, OnInit, OnDestroy} from '@angular/core';
import {Title} from '@angular/platform-browser';

import {ToastrService} from 'ngx-toastr';

import {ApiService} from '../services/api.service';
import {constants, meter_connection_config, static_links, wifi_connection_config} from '../constants';
import {MockDataService} from '../mock-data.service';
import {UserService} from '../user.service';
import {getProviderIconClass, getProviderMenuName} from '../ProivderUtil';
import {forkJoin} from 'rxjs';

@Component({
    selector: 'iona-app',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})

export class SettingsComponent implements OnInit, OnDestroy {

    refresh: any = [];

    /**
     * Provider Daten
     */
    providerclass: string = '';
    providername: string = '';
    privacyLink = '';
    providerMenuName: string = null;
    username: string = null;

    /**
     * Aktuelle Settings
     */
    config: any = {
        tracking: 1,
        notify_consumption: 0,
        notify_finance: 0
    };

    version: string = '0.0.0';
    changelog: any = [];
    logs: boolean = false;

    // connection qualities
    wifi_connection_config = wifi_connection_config;
    wifi_connection_quality = 0;
    wifi_connected = 0;
    lanConnected = false;

    meter_connection_config = meter_connection_config;
    meter_connection_quality = 0;
    meter_status: string = 'disconnected';

    private device: string = constants.application.devices.box;
    deviceIsPlug: boolean = false;

    constructor(private _titleService: Title,
                private _notification: ToastrService,
                public _apiService: ApiService,
                private _mockService: MockDataService,
                private _userService: UserService) {
    }

    ngOnInit() {
        this._titleService.setTitle('Einstellungen | iONA');

        this.username = this._userService.getActiveUserName();
        this.providername = this._userService.getActiveUserProvider();
        this.privacyLink = static_links.privacy[this.providername];
        this.providerclass = getProviderIconClass(this.providername);
        this.providerMenuName = getProviderMenuName(this.providername);

        // determine whether the user has a powerchecker
        const dev = this._userService.getUserDevice();
        if (dev === constants.application.devices.plug) {
            this.deviceIsPlug = true;
        }

        /**
         * Tracking Settings
         */
        if (localStorage.getItem('disable_tracking') !== null) {
            this.config.tracking = false;
        }

        this.getVersion();
        this.getChangelog();

        if (this._apiService.isDemoMode()) {
            this.getMockConnectionData();
            return;
        }

        this._apiService.checkToken();
        this._apiService.checkMeterStatus();
        this.getMeterinfo();
        this.getMeterStatus();

        /**
         * Wiederkehrende Prüfung, ob der Nutzer eingeloggt ist
         */
        this.refresh = setInterval(() => {
            this._apiService.checkToken();
        }, 10000);

        setTimeout(function() {
            let elements: any = $('.grid .box');
            elements.matchHeight();
        }, 100);

    }

    ngOnDestroy() {
        clearInterval(this.refresh);
    }


    /**
     * Meter-Nummer holen
     */
    getMeterinfo() {
        this._apiService.getInitialization().subscribe(
            (data: any) => {
                // console.log(data);
                if ('data' in data) {
                    if ('profile' in data.data) {
                        if ('meter_status_electricity' in data.data.profile) {
                            // this.meter_connected = data.data.profile.meter_status_electricity === 0;
                            const status = data.data.profile.meter_status_electricity;
                            if (status === 0) {
                                this.meter_status = 'connected';
                            } else if (status === 1) {
                                this.meter_status = 'disconnected';
                            } else {
                                this.meter_status = 'pending';
                            }

                        }
                    }
                }
            }
        );
    }

    getMeterStatus(): void {
        // const status_sub = this._apiService.getDeviceStatus();
        // const online_sub = this._apiService.getRegistrationOnline();
        this._apiService.getRegistrationOnline().subscribe(
            (response) => {
                this._apiService.getDeviceStatus().subscribe(
                    (response2) => {
                        if ('data' in response2) {
                            if ('meter_txrssi' in response2.data) {
                                this.meter_connection_quality = response2.data.meter_txrssi;
                            } else {
                                this.meter_connection_quality = 0;
                            }

                            if ('wlan_rssi' in response2.data) {
                                this.wifi_connection_quality = response2.data.wlan_rssi;
                                this.wifi_connected = this.wifi_connection_quality;

                                if (this.wifi_connection_quality === 0) {
                                    this.lanConnected = true;
                                    return;
                                }

                                return;
                            }
                        }

                        this.meter_connection_quality = 0;
                        this.wifi_connected = 0;
                    },
                    (error) => {
                        console.log(error);
                    }
                );

            },
            (error) => {
                this._apiService.getDeviceStatus().subscribe(
                    (response) => {
                        if ('data' in response) {
                            if ('meter_txrssi' in response.data) {
                                this.meter_connection_quality = response.data.meter_txrssi;
                            } else {
                                this.meter_connection_quality = 0;
                            }
                            return;
                        }

                        this.meter_connection_quality = 0;
                        this.wifi_connected = 0;
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            }
        );
    }

    getMockConnectionData(): void {
        this._mockService.getRegistrationOnline().subscribe(
            (response) => {

                this._mockService.getMeterStatus().subscribe(
                    (response2) => {
                        if ('meter_txrssi' in response2.data) {
                            this.meter_status = 'connected';
                            this.meter_connection_quality = response2.data.meter_txrssi;
                        } else {
                            this.meter_connection_quality = 0;
                        }

                        if ('wlan_rssi' in response2.data) {
                            this.wifi_connection_quality = response2.data.wlan_rssi;
                            this.wifi_connected = this.wifi_connection_quality;

                            if (this.wifi_connection_quality === 0) {
                                this.lanConnected = true;
                                return;
                            }

                            return;
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );

            },
            (error) => {
                console.log('requestion stats');
                this._mockService.getMeterStatus().subscribe(
                    (response2) => {
                        if ('data' in response2) {
                            if ('meter_txrssi' in response2.data) {
                                this.meter_status = 'connected';
                            } else {
                                this.meter_connection_quality = 0;
                            }
                        }
                    },
                    (error) => {
                        console.log(error);

                    }
                );
            }
        );
    }



    setSetting(setting: string) {
        if (this.config[setting] > 0) {
            this.config[setting] = 0;
        } else {
            this.config[setting] = 1;
        }
    }

    saveSettings() {
        // Aktuell nur Tracking Settings -> werden im LocalStorage gespeichert
        if (this.config.tracking) {
            localStorage.removeItem('disable_tracking');
            this._apiService.changeTrackingState(true);
        } else {
            localStorage.setItem('disable_tracking', '1');
            this._apiService.changeTrackingState(false);
        }

        this._notification.success('Ihre Einstellungen wurden erfolgreich gespeichert!');
    }


    /**
     * get current Software version
     */
    getVersion() {
        this._apiService.getChangelog().subscribe(
            (data: any) => {
                if (0 in data) {
                    this.version = data[0].version;
                }
            }
        );
    }

    /**
     * get current changelog
     */
    getChangelog() {
        this._apiService.getChangelog().subscribe(
            (data: any) => {
                this.changelog = data;
            }
        );
    }

    /**
     * show Changelog
     */
    showChangelog() {
        this.logs = true;
    }

    /**
     * hide Changelog
     */
    hideChangelog() {
        this.logs = false;
    }

    determineMeterStatusMessage(): string {
        switch (this.meter_status) {
            case 'connected':
                return 'Verbunden';
            case 'disconnected':
                return 'Getrennt';
            case 'pending':
                return 'Noch nicht verbunden';
            default:
                return 'Noch nicht verbunden';
        }
    }

}
