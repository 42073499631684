<div class="sidebar-head counter-wrap">
    <div class="btn-wrap fltrt">
        <button type="button" angulartics2On="click" angularticsCategory="Detailansichten"
                angularticsAction="detail_info_text" angularticsLabel="Abschlags-Check" class="icon-info">
            <span>Hilfe</span>
        </button>
        <button type="button" class="icon-close">
            <span>Schließen</span>
        </button>
    </div>

    <h4>Abschlags-Check</h4>

    <ng-container *ngIf="data_available; else empty_state">
        <div>
            <div class="incident-wrap circle">
                <i [class.icon-piggybank]="finance.trend < 0"
                   [class.icon-thumbs-up]="finance.trend === 0"
                   [class.icon-alert]="finance.trend > 0">
                </i>
                <div class="incident-stat"
                     [style.width.%]="finance.trend < 0 ? finance.percent : 100"
                     [style.height.%]="finance.trend < 0 ? finance.percent : 100">
                </div>
                <div class="incident-outline"
                     [style.width.%]="finance.trend > 0 ? finance.percent : 100"
                     [style.height.%]="finance.trend > 0 ? finance.percent : 100">
                </div>
            </div>
        </div>
    </ng-container>

    <ng-template #empty_state>
        <lottie-animation-view [height]="238"
                               [options]="lotti_config"
                               (animCreated)="animationCreated">
        </lottie-animation-view>
    </ng-template>

    <ng-container *ngIf="data_available; else subtitle_empty">
        <div *ngIf="finance.trend < 0" class="price">Etwa <strong>{{ finance.amount }} &euro;</strong> Gutschrift</div>
        <div *ngIf="finance.trend === 0" class="price"><strong>Verbrauch passt genau</strong></div>
        <div *ngIf="finance.trend > 0" class="price">Etwa <strong>{{ finance.amount }} &euro;</strong> Nachzahlung</div>
    </ng-container>

    <ng-template #subtitle_empty>
        <div class="price">Daten werden noch gesammelt</div>
    </ng-template>

</div>
<div class="myDevices-content">

    <ng-container *ngIf="data_available">

        <div class="msg-wrap hidden">
            <div class="msg info icon-info">
                <button class="icon-close" type="button">
                    <span>Schließen</span>
                </button>
                <h3>Abschlags-Check: Was ist das?</h3>
                <p>Nie mehr nachzahlen. iONA wirft für Sie einen Blick in die Zukunft und berechnet, ob Ihr monatlicher
                    Abschlag ausreicht. Falls nicht, können Sie ihn online anpassen. Die Vorhersage von iONA wird jeden
                    Monat besser. Für eine genaue Berechnung werden ca. sechs Monatsverbräuche benötigt.</p>
            </div>
        </div>
        <div class="invoice-content-wrap">
            <!--        <h4>Einstellungen</h4>-->
            <!--        <fieldset class="invoice-alert">-->
            <!--            <div class="formItem">-->
            <!--                <div class="checkbox-wrap">-->
            <!--                    <input type="checkbox" id="notify-finance" [checked]="config.notify === 1"-->
            <!--                           (change)="saveNotification()" class="formRadio">-->
            <!--                    <label for="notify-finance">-->
            <!--                        <i></i>-->
            <!--                        <span>Benachrichtigung zum Abschlags-Check erhalten</span>-->
            <!--                        <small>iONA berechnet, ob Ihr monatlicher Abschlag ausreicht. Falls nicht, erhalten Sie direkt-->
            <!--                            einen Hinweis.-->
            <!--                        </small>-->
            <!--                    </label>-->
            <!--                </div>-->
            <!--            </div>-->
            <!--        </fieldset>-->
            <!--        <hr>-->

            <h4>Abrechnungszeitraum</h4>
            <p class="time-range">{{ finance.from }} - {{ finance.to }}</p>
            <ng-container [ngSwitch]="finance.trend">
                <p *ngSwitchCase="-1">Auf Basis der ermittelten Daten ist eine zu Gutschrift zu
                    erwarten.</p>
                <p *ngSwitchCase="0"> Auf Basis der ermittelten Daten passt der Abschlag. </p>
                <p *ngSwitchCase="1">Auf Basis der ermittelten Daten ist eine zu Nachzahlung zu
                    erwarten.</p>
            </ng-container>
            <p></p>

            <ng-container *ngIf="finance.amount >= 50 || finance.amount <= -50">
                <div class="hotline-msg">
                    <div class="p-b-m">
                        Sie können ihren Abschlag jederzeit online anpassen.
                    </div>
                    <a class="btn button" target="_blank" href="{{provider_link}}"> Abschlag ändern</a>
                    <p class="p-t-m">
                        Die Vorhersage von iONA wird jeden Monat besser. Für eine genaue Berechnung werden ca. sechs
                        Monatsverbräuche benötigt.
                    </p>
                </div>
            </ng-container>

            <!--        <div class="hotline-msg">-->
            <!--            <div class="number">-->
            <!--                <i class="icon-hotline"></i>-->
            <!--                <div class="call">-->
            <!--                    Kostenlose Hotline<br>-->
            <!--                    0800 - 1234060-->
            <!--                </div>-->
            <!--            </div>-->
            <!--            <p>Unser Support-Team beantwortet alle Fragen zu Ihrer Abrechnung.</p>-->
            <!--        </div>-->

        </div>

    </ng-container>
</div>
