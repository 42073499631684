<div id="container">
    <aside class="sidebar lft">
        <a href="#" id="hamburger" title="Menü" class="mobi">
            <span class="burger">
                <i class="top"></i>
                <i class="middle"></i>
                <i class="bottom"></i>
            </span>
            <span class="text"> Menü</span>
        </a>
        <div class="blackbox">
            <div class="version">
                <a href="javascript:;" (click)="showChangelog()">{{ version }}</a>
            </div>
            <div [class.demo]="username === 'Demo'" class="logo">
                <a href="#/" title="iONA">
                    <span>iONA</span>
                </a>
            </div>
        </div>
        <div class="block block-menu">
            <nav class="inner">
                <ul class="clearfix">
                    <li><a href="#/" title="Übersicht">Übersicht</a></li>
                    <li><a href="#/meine-daten" title="Meine Daten" class="active">Meine Daten</a></li>
                    <li><a href="#/mein-haushalt" title="Mein Haushalt">Mein Haushalt</a></li>
                    <li><a href="#/mein-kundenkonto" angulartics2On="click" angularticsCategory="Meine Daten"
                           angularticsAction="my_account_enter"
                           title="{{ providerMenuName }}">{{ providerMenuName }}</a></li>
                    <li><a href="#/einstellungen" title="Einstellungen">Einstellungen</a></li>
                    <li><a href="#/kontakt" title="Kontakt">Kontakt</a></li>
                    <li><a href="javascript:;"
                           title="{{ _apiService.isDemoMode() ? 'Demo-Modus verlassen' : 'Logout' }}"
                           class="open-logout">{{ _apiService.isDemoMode() ? 'Demo-Modus verlassen' : 'Logout' }}</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="block block-submenu">
            <div class="inner">
                <div *ngIf="providerclass?.length > 0" class="presentbar">
                    <small>Präsentiert von</small>
                    <div [class]="providerclass"></div>
                </div>
                <nav class="submenu">
                    <ul>
                        <li><a href="#/impressum" angulartics2On="click" angularticsCategory="Menü"
                               angularticsAction="view_imprint" title="Impressum">Impressum</a></li>
                        <li>
                            <a [href]="privacyLink" target="_blank"
                               angulartics2On="click" angularticsCategory="Menü" angularticsAction="view_privacy"
                               title="Datenschutz">Datenschutz</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </aside>
    <div id="inner">
        <main id="mainContent" class="clearfix">
            <div class="meine-daten">
                <div class="head">
                    <h2>Meine Daten</h2>
                </div>
                <div class="grid two clearfix">
                    <div class="box">
                        <div class="block block-content">
                            <div class="inner">
                                <h3>Persönliche Daten</h3>
                                <p>
                                    <strong>Name:</strong> {{ contract.name }}<br>
                                    <strong>Benutzername:</strong> {{ username === 'Demo' ? 'info@innogy.com' : username }}
                                </p>
                                <button type="button" class="orange edit-email" [hidden]="view.changeEmail"
                                        (click)="toggleEmailView()">E-Mail-Adresse ändern
                                </button>
                                <fieldset class="change-email" [hidden]="!view.changeEmail">
                                    <div class="formItem">
                                        <label for="email">E-Mail Adresse ändern</label>
                                        <input type="text" #email id="email" placeholder="Ihre neue E-Mail-Adresse"
                                               class="formText">
                                    </div>
                                    <button type="button" (click)="changeEmail(email.value)" class="orange save-email">
                                        Speichern
                                    </button>
                                </fieldset>
                                <br/><br/>
                                <button type="button" class="orange edit-password" [hidden]="view.changePassword"
                                        (click)="togglePasswordView()">Passwort ändern
                                </button>
                                <fieldset class="change-password" [hidden]="!view.changePassword">
                                    <div class="grid two clearfix">
                                        <div class="box">
                                            <div class="formItem">
                                                <label>Aktuelles Passwort</label>
                                                <input type="password" #oldPass id="oldPass"
                                                       placeholder="Aktuelles Passwort" class="formText">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="grid two clearfix">
                                        <div class="box">
                                            <div class="formItem">
                                                <label>Neues Passwort</label>
                                                <input type="password" #newPass id="newPass"
                                                       placeholder="Neues Passwort" class="formText">
                                            </div>
                                        </div>
                                        <div class="box">
                                            <div class="formItem">
                                                <label>Passwort wiederholen</label>
                                                <input type="password" #newPassRepeat id="newPassRepeat"
                                                       placeholder="Passwort wiederholen" class="formText">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="box">
                                        <div class="formItem">
                                            <button type="button"
                                                    (click)="changePassword(oldPass.value, newPass.value, newPassRepeat.value)"
                                                    class="orange">Passwort speichern
                                            </button>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div class="box">
                        <div class="block block-content">
                            <div class="inner">
                                <h3>Lieferant</h3>
                                <div class="logo fltrt">
                                    <img *ngIf="providericon?.length > 0" src="assets/images/{{providericon}}.png"
                                         height="78" alt="{{ providername }}" title="{{ providername }}"/>
                                </div>
                                <p>
                                    <strong>{{ providername }}</strong><br>
                                    <strong>Tarif:</strong> {{ contract.tarif }}<br>
                                    <strong>Grundpreis:</strong> {{ contract.basicprice}} pro Jahr<br>
                                    <strong>Arbeitspreis:</strong> {{ contract.workingprice }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>
<div *ngIf="changelog_visible" class="overlay-infos">
    <div class="overlay-box">
        <button type="button" (click)="hideChangelog()" class="icon-close fltrt">
            <span>Schließen</span>
        </button>
        <div *ngFor="let logs of changelog">
            <h4>{{ logs.version }}</h4>
            <ul *ngIf="logs.features.length > 0">
                <li class="head">Neue Funktionen:</li>
                <li *ngFor="let feature of logs.features">{{ feature.log }}</li>
            </ul>
            <ul *ngIf="logs.adjustments.length > 0">
                <li class="head">Anpassungen:</li>
                <li *ngFor="let adjustment of logs.adjustments">{{ adjustment.log }}</li>
            </ul>
            <ul *ngIf="logs.bugfixes.length > 0">
                <li class="head">Bugfixes:</li>
                <li *ngFor="let bugfix of logs.bugfixes">{{ bugfix.log }}</li>
            </ul>
        </div>
    </div>
</div>
<div id="overlay-logout" class="overlay-infos" style="display: none;">
    <div class="overlay-box">
        <button type="button" class="close-logout icon-close fltrt">
            <span>Schließen</span>
        </button>
        <h4>Abmelden</h4>
        <p>Wollen Sie sich wirklich abmelden?</p>
        <button type="button" (click)="_apiService.logoutUser()" class="btn orange">Ja</button>
        <button type="button" class="close-logout btn outline">Nein</button>
    </div>
</div>
