<div class="sidebar-head counter-wrap">
    <div class="btn-wrap fltrt">
        <button type="button" angulartics2On="click" angularticsCategory="Detailansichten"
                angularticsAction="detail_info_text" angularticsLabel="Verbrauchs-Alarm" class="icon-info">
            <span>Hilfe</span>
        </button>
        <button type="button" class="icon-close">
            <span>Schließen</span>
        </button>
    </div>
    <h4>Verbrauchsalarm</h4>
</div>
<div class="myDevices-content">
    <div class="msg-wrap hidden">
        <div class="msg info icon-info">
            <button type="button" class="icon-close">
                <span>Schließen</span>
            </button>
            <h3>Verbrauchsalarm: Was ist das?</h3>
            <p>Ihr Verbrauch ist auffällig hoch? Keine Angst, iONA benachrichtigt Sie sofort. Dafür vergleicht iONA den
                aktuellen Stromverbrauch mit Ihrem üblichen Verbrauch. Bei großen Abweichungen bekommen Sie direkt einen
                Hinweis.</p>
        </div>
    </div>
<!--    <div class="myDevices-options">-->
<!--        <h5 *ngIf="alarm.trend > 1" class="icon-arrow-ne"><strong>Erhöhte Aktivität</strong> seit {{ alarm.since }}</h5>-->
<!--    </div>-->
    <div class="chart-wrap">
        <div [chart]="chart" id="chart-consumption-details"></div>
    </div>
<!--    <div class="content-wrap">-->
<!--        <h4>Einstellungen</h4>-->
<!--        <fieldset class="invoice-alert">-->
<!--            <div class="formItem">-->
<!--                <div class="checkbox-wrap">-->
<!--                    <input type="checkbox" id="notify-consumption" [checked]="config.notify === 1"-->
<!--                           (change)="saveNotification()" class="formRadio">-->
<!--                    <label for="notify-consumption">-->
<!--                        <i></i>-->
<!--                        <span>Benachrichtigung bei erhöhter Aktivität</span>-->
<!--                        <small>iONA benachrichtigt Sie, wenn Ihr Verbrauch ungewöhnlich hoch ist.</small>-->
<!--                    </label>-->
<!--                </div>-->
<!--            </div>-->
<!--        </fieldset>-->
<!--    </div>-->
</div>
