import {Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';

@Component({
    selector: 'app-connection-indicator',
    templateUrl: './connection-indicator.component.html',
    styleUrls: ['./connection-indicator.component.scss']
})
export class ConnectionIndicatorComponent implements OnInit {

    @Input() value: number;
    @Input() container_height: string;
    @Input() config: ConnectionIndicatorConfig;
    @Input() invert_colors: boolean = false;
    @Input() disabled: boolean = false;
    @Input() ignoreValue: boolean = false;

    @ViewChild('container', {static: true}) root_container: ElementRef;

    constructor(private renderer: Renderer2) {

    }

    ngOnInit() {
        this.renderer.setStyle(this.root_container.nativeElement, 'height', this.container_height);
    }

    determineBarActive(item: ConnectionRange): boolean {
        if (this.ignoreValue) {
            return true;
        }

        if (this.disabled) {
            return false;
        }
        if (this.config.fill) {
            return this.value >= item.max;
        }
        return this.value >= item.max && this.value <= item.min;
    }
}

export interface ConnectionIndicatorConfig {
    ranges: ConnectionRange[];
    fill: boolean;
}

export interface ConnectionRange {
    min: number;
    max: number;
}

