<div class="block block-tile large">
    <div class="inner" [hidden]="!showDiagrams">

        <h3>Zählerstand</h3>

        <div class="meter-wrap">
            <div class="kwh">{{ meter.kwh }}
                <small>kWh</small>
            </div>
            <div *ngIf="meter.id && meter.id.length > 0" class="meter-id">
                <div class="label">Zählernummer</div>
                <strong>{{ meter.id }}</strong>
            </div>
        </div>

        <div class="connected">
            <div class="connection-container">
                <div class="p-r-s">
                    <app-connection-indicator [value]="connection_quality"
                                              container_height="24px"
                                              [disabled]="meter_status!== 'connected'"
                                              [invert_colors]="false"
                                              [config]="connection_config">
                    </app-connection-indicator>
                </div>
                <h4 class="connection-text">
                    {{determineMeterStatusMessage()}}
                </h4>
            </div>
        </div>

        <a href="javascript:;" angulartics2On="click" angularticsCategory="Screens" angularticsAction="screen_view"
           angularticsLabel="screen: Zählerstand; previous_screen: Übersicht" (click)="firstDetailViewAnalytics()"
           title="Details anzeigen" class="meter-details icon-arrow-rt">
            <span>Details anzeigen</span>
        </a>

    </div>
    <div class="inner" [hidden]="showDiagrams">
        <h3>Zählerstand</h3>
        <div class="error red"></div>
        <div class="error-text">Daten sind noch <br/>nicht verfügbar</div>
    </div>
</div>
