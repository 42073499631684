import {Component, OnInit} from '@angular/core';

import {ToastrService} from 'ngx-toastr';
import {Angulartics2} from 'angulartics2';

import {ApiService} from '../../services/api.service';
import {MockDataService} from '../../mock-data.service';
import {UserService} from '../../user.service';
import {static_links} from '../../constants';

@Component({
    selector: 'finance-details',
    templateUrl: './details.finance.html',
    styleUrls: ['./details.finance.scss'],
    viewProviders: [ApiService]
})

export class FinanceDetails implements OnInit {

    refresh: any = [];

    finance: any = {
        trend: 0,
        amount: 0,
        percent: 0,
        from: null,
        to: null
    };

    config: any = {
        notify: 0
    };

    data_available = false;

    lotti_config = {
        path: 'assets/anim/finance-detail-2.json',
        renderer: 'svg',
        autoplay: true,
        autoplaySegments: false,
        loop: true,
        name: 'Smart Meter'
    };
    private animation = null;
    private providername: string = null;
    provider_link: string = '';

    constructor(private _notification: ToastrService,
                private _analytics: Angulartics2,
                private _apiService: ApiService,
                private _mockData: MockDataService,
                private _userService: UserService) {
    }

    /**
     * Widget "Abschlags-Check" ist aktuell deaktiviert
     */
    ngOnInit() {
        this.providername = this._userService.getActiveUserProvider();
        this.provider_link = static_links.finance[this.providername];
        if (this._apiService.isDemoMode()) {
            this.getMockFinanceData();
            return;
        }

        this.getFinanceDate();
    }

    animationCreated(anim: any) {
        this.animation = anim;
    }

    getFinanceDate(): void {
        this._apiService.getFinance().subscribe(
            (response: any) => {
                if ('data' in response) {
                    if (response.data.sum_of_prepayments > 12) {
                        this.data_available = true;
                        this.extractFinanceData(response);
                    }
                }
            }
        );

    }

    getMockFinanceData(): void {
        this._mockData.getBillPrediction().subscribe(
            (response: any) => {
                if (response.data.sum_of_prepayments > 12) {
                    this.data_available = true;
                    this.extractFinanceData(response);
                }
            }
        );
    }

    extractFinanceData(data: any): void {
        let percent = ((data.data.estimated_cost_billing_period - data.data.sum_of_prepayments) / data.data.sum_of_prepayments) * 100;

        if (percent <= -10) {
            let percent = Math.round(((data.data.sum_of_prepayments - data.data.estimated_cost_billing_period) / data.data.estimated_cost_billing_period) * 100);

            this.finance = {
                trend: -1,
                amount: Math.ceil((data.data.sum_of_prepayments - data.data.estimated_cost_billing_period) / 10) * 10,
                percent: percent < 50 ? (100 - percent) : 50
            };
            if (this.finance.amount > (data.data.estimated_cost_billing_period / 2)) {
                this.data_available = false;
            }
        } else if (percent >= 10) {
            let percent = Math.round(((data.data.estimated_cost_billing_period - data.data.sum_of_prepayments) / data.data.sum_of_prepayments) * 100);

            this.finance = {
                trend: 1,
                amount: Math.ceil((data.data.estimated_cost_billing_period - data.data.sum_of_prepayments) / 10) * 10,
                percent: percent < 50 ? (100 - percent) : 50
            };
            if (this.finance.amount > (data.data.estimated_cost_billing_period / 2)) {
                this.data_available = false;
            }
        } else {
            this.finance = {
                trend: 0,
                amount: 0,
                percent: 0
            };
        }

        let from: any = new Date(data.data.billing_period_from);
        let to: any = new Date(data.data.billing_period_until);

        this.finance.from = from.getDate() + '. ' + this._apiService.MONTHS[from.getMonth()] + ' ' + from.getFullYear();
        this.finance.to = to.getDate() + '. ' + this._apiService.MONTHS[to.getMonth()] + ' ' + to.getFullYear();
    }

    saveNotification() {
        if (this.config.notify > 0) {
            this.config.notify = 0;

            this._analytics.eventTrack.next({
                action: 'push_disable',
                properties: {
                    category: 'Push',
                    label: 'type: prediction; screen: Abschlags-Check'
                }
            });

            this._notification.warning('Benachrichtigung deaktiviert!');
        } else {
            this.config.notify = 1;

            this._analytics.eventTrack.next({
                action: 'push_enable',
                properties: {
                    category: 'Push',
                    label: 'type: prediction; screen: Abschlags-Check'
                }
            });

            this._notification.success('Benachrichtigung aktiviert!');
        }

        /*
        TODO: Einstellungen speichern
         */
        this._notification.info('Ihre Einstellung kann noch nicht übernommen werden!');
    }

}
