import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { constants } from '../constants';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "@angular/router";
export class AuthGuardService {
    constructor(api, router) {
        this.api = api;
        this.router = router;
    }
    canActivate() {
        const state = this.api.getAppMode();
        // console.log(state);
        if (state === 'demo') {
            if (!this.checkDemoModeValid()) {
                this.router.navigate(['/login']);
                return false;
            }
        }
        else if (state === 'none') {
            this.router.navigate(['/login']);
            return false;
        }
        else if (state === null || state === 'undefined') {
            this.router.navigate(['/login']);
            return false;
        }
        return true;
    }
    checkDemoModeValid() {
        const now = new Date();
        const expire = new Date(localStorage.getItem(constants.application.storage.keys.app_expire));
        return expire > now;
    }
}
AuthGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.Router)); }, token: AuthGuardService, providedIn: "root" });
