/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@angular/common";
import * as i3 from "../services/api.service";
import * as i4 from "@angular/common/http";
import * as i5 from "@angular/router";
import * as i6 from "ngx-toastr";
import * as i7 from "angulartics2";
import * as i8 from "../user.service";
import * as i9 from "../overlay/overlay.service";
import * as i10 from "../services/globals.service";
import * as i11 from "./forgot.component";
import * as i12 from "@angular/platform-browser";
var styles_ForgotComponent = [];
var RenderType_ForgotComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ForgotComponent, data: {} });
export { RenderType_ForgotComponent as RenderType_ForgotComponent };
function View_ForgotComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 19, "div", [["class", "form-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["M\u00F6chten Sie Ihr Passwort zur\u00FCcksetzen?"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Bitte geben Sie zum Zur\u00FCcksetzen Ihres Passworts einfach Ihre E-Mail-Adresse ein."])), (_l()(), i0.ɵeld(5, 0, null, null, 14, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 7).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("submit" === en)) {
        _co.resetPassword(i0.ɵnov(_v, 13).value);
        var pd_2 = (false !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 16384, null, 0, i1.ɵangular_packages_forms_forms_z, [], null, null), i0.ɵdid(7, 4210688, null, 0, i1.NgForm, [[8, null], [8, null]], null, null), i0.ɵprd(2048, null, i1.ControlContainer, null, [i1.NgForm]), i0.ɵdid(9, 16384, null, 0, i1.NgControlStatusGroup, [[4, i1.ControlContainer]], null, null), (_l()(), i0.ɵeld(10, 0, null, null, 3, "div", [["class", "formItem formCenter"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "label", [["for", "email"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["E-Mail-Adresse"])), (_l()(), i0.ɵeld(13, 0, [["email", 1]], null, 0, "input", [["class", "formText"], ["id", "email"], ["placeholder", "E-Mail-Adresse"], ["type", "text"]], [[8, "value", 0]], null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 2, "div", [["class", "btn-wrap"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 1, "button", [["class", "formSubmit"], ["type", "submit"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Passwort zur\u00FCcksetzen"])), (_l()(), i0.ɵeld(17, 0, null, null, 2, "div", [["class", "helpbtn-wrap"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 1, "a", [["class", "skip"], ["href", "#/"], ["title", "Zur\u00FCck zum Login"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Zur\u00FCck zum Login"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 9).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 9).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 9).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 9).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 9).ngClassValid; var currVal_5 = i0.ɵnov(_v, 9).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 9).ngClassPending; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.emailVal; _ck(_v, 13, 0, currVal_7); }); }
function View_ForgotComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "form-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Passwort erfolgreich zur\u00FCck gesetzt."])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Bitte \u00FCberpr\u00FCfen Sie Ihr Email-Postfach und folgen Sie der Anleitung zum Zur\u00FCcksetzen Ihres Passworts."])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "btn-wrap"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "a", [["class", "btn"], ["href", "#/"], ["title", "Ok"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Ok"]))], null, null); }
export function View_ForgotComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["id", "register-wrap"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "fieldset", [["class", "register forgot"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "form-header clearfix"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "div", [["class", "logo"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "app-name"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["iONA"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ForgotComponent_1)), i0.ɵdid(7, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ForgotComponent_2)), i0.ɵdid(9, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 2, "div", [["class", "bg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "video", [["autoplay", ""], ["height", "100%"], ["loop", ""], ["muted", ""], ["width", "100%"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 0, "source", [["src", "assets/media/background.mp4"], ["type", "video/mp4"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.resetSuccess; _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.resetSuccess; _ck(_v, 9, 0, currVal_1); }, null); }
export function View_ForgotComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "iona-app", [], null, null, null, View_ForgotComponent_0, RenderType_ForgotComponent)), i0.ɵprd(139776, null, i3.ApiService, i3.ApiService, [i4.HttpClient, i5.Router, i6.ToastrService, i7.Angulartics2, i8.UserService, i9.OverlayService]), i0.ɵprd(512, null, i10.Globals, i10.Globals, []), i0.ɵdid(3, 114688, null, 0, i11.ForgotComponent, [i12.Title, i5.Router, i6.ToastrService, i7.Angulartics2, i3.ApiService, i10.Globals], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var ForgotComponentNgFactory = i0.ɵccf("iona-app", i11.ForgotComponent, View_ForgotComponent_Host_0, {}, {}, []);
export { ForgotComponentNgFactory as ForgotComponentNgFactory };
