import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';

import {ApiService} from '../services/api.service';
import {Globals} from '../services/globals.service';
import {Router} from '@angular/router';
import {MockDataService} from '../mock-data.service';

import {forkJoin} from 'rxjs/observable/forkJoin';
import {ToastrService} from 'ngx-toastr';
import {constants} from '../constants';
import {UserService} from '../user.service';
import {User} from '../user';

@Component({
    selector: 'iona-app',
    templateUrl: './login.component.html',
    viewProviders: [],
    providers: [Globals]
})

export class LoginComponent implements OnInit {

    private start_time: Date;
    private demo_interval;

    username: string = '';
    password: string = '';

    constructor(private _titleService: Title,
                public _apiService: ApiService,
                private _globals: Globals,
                private _router: Router,
                private _mockData: MockDataService,
                private _notification: ToastrService,
                private _userService: UserService) {
    }

    ngOnInit() {
        this._titleService.setTitle('Login | iONA');
        this._apiService.loggedOut.subscribe(
            (next: boolean) => {
                if (next) {
                    clearInterval(this.demo_interval);
                }
            }
        );

    }

    setPwForgotMail(email) {
        if (email !== null && email.length > 0) {
            this._globals.setForgotMail(email);
        }
    }

    login(username: string, password: string): void {
        this._apiService.setApplicationState(constants.application.states.live);
        this._apiService.loginUser(username, password);
    }

    continueWithDemoMode() {
        const init_sub = this._mockData.getInitialize();
        const profile_sub = this._mockData.getProfile();

        // set globally needed variables
        forkJoin([init_sub, profile_sub]).subscribe(
            (values: any) => {
                this._router.navigate(['']);

                const u: User = {
                    email: 'demo',
                    access_token: '',
                    refresh_token: '',
                    provider: values[0].data.profile.labelpartner,
                    nilm_status: null,
                    token_expires: null,
                    charts: null,
                    storage: null,
                    device: constants.application.devices.plug
                };
                this._userService.setCurrentUser(u);
                this._userService.setActiveUserProvider(values[0].data.profile.labelpartner);

                // calculate expire date
                const demo_mode_min = 5;
                const demo_mode_time = demo_mode_min * 60 * 1000;
                const current_time = new Date();
                const expire_date = new Date();
                expire_date.setMinutes(current_time.getMinutes() + 5);
                this._apiService.enableDemoMode(expire_date);

                // set demo mode timeout
                setTimeout(() => {
                    this._apiService.logoutUser();
                }, demo_mode_time);

                this._notification.info('Sie werden in ' + `${5} Minuten` + ' automatisch ausgeloggt.');

                this.start_time = new Date();
                this.demo_interval = setInterval(() => {
                    const now = new Date();

                    const diff = now.getTime() - this.start_time.getTime();
                    const diff_s = diff / 1000;
                    const diff_i = parseInt(diff_s.toString(), 10);
                    const diff_min = parseInt((diff_i / 60).toFixed(0), 10);

                    if (diff_min) {
                        if (diff_i % 60 == 0) {
                            if (diff_i <= 239) {
                                this._notification.info('Sie werden in ' + `${demo_mode_min - diff_min} Minuten` + ' automatisch ausgeloggt.');
                            } else if (diff_i >= 240 && diff_i <= 299) {
                                this._notification.info('Sie werden in ' + `${demo_mode_min - diff_min} Minute` + ' automatisch ausgeloggt.');
                            } else if (diff_i >= 300) {
                                clearInterval(this.demo_interval);
                            }
                        }
                    }
                }, 10000);
            },
            (errors) => {
                console.log(errors);
            }
        );
    }

    onBgVideoLoad(event): void {
        const video = event.target as HTMLVideoElement;
        video.play();
    }
}
