<div class="sidebar-head">
    <div class="btn-wrao fltrt">
        <button type="button" angulartics2On="click" angularticsCategory="Detailansichten"
                angularticsAction="detail_info_text" angularticsLabel="Live" class="icon-info">
            <span>Hilfe</span>
        </button>
        <button type="button" class="icon-close">
            <span>Schließen</span>
        </button>
    </div>
    <h2>Live</h2>
</div>

<div class="sidebar-subhead">
    <div class="current-watt">
        {{ current_consumption }}
        <small>Watt</small>
    </div>
    <div class="active-time">
        <ng-container *ngIf="!userHasHappyHour; else happyHourTemplate">
            <div class="status-wrap">
                <div *ngIf="!status.noZone" [ngClass]="{'active' : status.trend === 0}" class="status">niedrig</div>
                <div *ngIf="!status.noZone" [ngClass]="{'active' : status.trend === 1}" class="status">mittel</div>
                <div *ngIf="!status.noZone" [ngClass]="{'active' : status.trend === 2}" class="status">hoch</div>
                <div *ngIf="status.noZone" class="nodevices">Verbrauchszone kann nicht ermittelt werden</div>
            </div>
            <div class="splitter">
                <i class="bar" [class.middle]="status.trend === 0 && !status.noZone"></i>
                <i class="bar" [class.middle]="status.trend === 1"></i>
                <i class="bar" [class.middle]="status.trend === 2"></i>
            </div>
            <div *ngIf="status.since && status.trend === 0" class="time zone1">seit {{ status.since }}</div>
            <div *ngIf="status.since && status.trend === 1" class="time zone2">seit {{ status.since }}</div>
            <div *ngIf="status.since && status.trend === 2" class="time zone3">seit {{ status.since }}</div>
        </ng-container>
        <ng-template #happyHourTemplate>
            <div fxLayout="row" fxLayoutAlign="center">
                <app-remaining-time-indicator
                    #happyHourIndicator
                    [size]="36"
                    [overallTime]="60*60"
                    [refreshrate]="10"
                    [indicatorWidth]="6"
                    [inverted]="true">
                </app-remaining-time-indicator>
            </div>
        </ng-template>
    </div>
</div>

<div class="myDevices-content">
    <div class="msg-wrap hidden">
        <div class="msg info icon-info">
            <button type="button" class="icon-close">
                <span>Schließen</span>
            </button>
            <h3>Live: Was ist das?</h3>
            <p>iONA stellt Ihren Stromverbrauch in Echtzeit dar. So sehen Sie jederzeit, wie viel Strom aktuell
                verbraucht wird. Ob sich dieser Wert im ruhigen, normalen oder aktiven Bereich befindet, erkennen Sie an
                den oberen drei Balken.</p>
        </div>
    </div>
    <div class="myDevices-options">
        <ng-container *ngFor="let zoom of zoomlevels">
            <div *ngIf="zoom.level === zoomlevel" class="zoom-label">{{ zoom.name }}
                <small *ngIf="zoom.hint"> ({{ zoom.hint }})</small>
            </div>
        </ng-container>
        <div class="zoom fltrt">
            <button type="button" (click)="zoomOut()" [disabled]="disabled || zoomlevel === zoomlevels.length"
                    angulartics2On="click" angularticsCategory="Detailansichten"
                    angularticsAction="detail_time_interval_change" angularticsLabel="Live"
                    class="icon-zoomout"></button>
            <button type="button" (click)="zoomIn()" [disabled]="disabled || zoomlevel === 1" angulartics2On="click"
                    angularticsCategory="Detailansichten" angularticsAction="detail_time_interval_change"
                    angularticsLabel="Live" class="icon-zoomin"></button>
            <div class="zoom-level">
                <div *ngFor="let zoom of zoomlevels" (click)="setZoom(zoom.level)"
                     [class.active]="zoom.level === zoomlevel" title="{{ zoom.name }}" class="circle dot"></div>
            </div>
        </div>
    </div>
    <div class="chart-wrap">
        <div [chart]="chart" id="chart-live-details"></div>
    </div>
</div>

<div class="myDevices-pager clearfix">
    <button type="button" (click)="positionBack()" [disabled]="disabled" title="Zurück" class="icon-back fltlft">
        <span>Zurück</span>
    </button>
    <button type="button" (click)="positionNow()" [disabled]="disabled || position === 1" title="Jetzt"
            class="current fltrt">
        <span>Jetzt</span>
    </button>
    <button type="button" (click)="positionForward()" [disabled]="disabled || position === 1" title="Weiter"
            class="icon-forward fltrt">
        <span>Weiter</span>
    </button>
</div>
