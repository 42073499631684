<div class="block block-tile large">
    <div class="inner">
        <h3>Live</h3>
        <div class="live-chartwrap" [hidden]="!showDiagrams">
            <div [chart]="chart" id="chart-live-large"></div>
            <div class="watt">{{ current_consumption }}
                <small>Watt</small>
            </div>
            <a href="javascript:;" angulartics2On="click" angularticsCategory="Screens" angularticsAction="screen_view"
               angularticsLabel="screen: Live; previous_screen: Übersicht" title="Details anzeigen"
               (click)="firstDetailViewAnalytics()" class="live-details icon-arrow-rt">
                <span>Details anzeigen</span>
            </a>
        </div>

        <div *ngIf="userHasHappyHour" class="happy-hour-info">
            <app-remaining-time-indicator
                #happyHourIndicator
                [size]="36"
                [overallTime]="60*60"
                [refreshrate]="10"
                [indicatorWidth]="6">
            </app-remaining-time-indicator>
        </div>

        <div class="error blue" [hidden]="showDiagrams"></div>
        <div class="error-text" [hidden]="showDiagrams">Daten sind noch <br/>nicht verfügbar</div>
    </div>
</div>
