import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {ApiService} from './api.service';
import {constants} from '../constants';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

    constructor(private api: ApiService,
                private router: Router) {
    }

    canActivate(): boolean {
        const state = this.api.getAppMode();
        // console.log(state);
        if (state === 'demo') {
            if (!this.checkDemoModeValid()) {
                this.router.navigate(['/login']);
                return false;
            }
        } else if (state === 'none') {
            this.router.navigate(['/login']);
            return false;
        } else if (state === null || state === 'undefined') {
            this.router.navigate(['/login']);
            return false;
        }
        return true;
    }

    private checkDemoModeValid(): boolean {
        const now = new Date();
        const expire = new Date(localStorage.getItem(constants.application.storage.keys.app_expire));
        return expire > now;
    }

}
