import {Component, OnInit, OnDestroy} from '@angular/core';

import {ApiService} from '../../services/api.service';
import {MockDataService} from '../../mock-data.service';
import {constants, meter_connection_config} from '../../constants';

@Component({
    selector: 'meter-details',
    templateUrl: './details.meter.html',
    styleUrls: ['./details.meter.component.scss'],
})

export class MeterDetails implements OnInit, OnDestroy {

    refresh: any = [];

    year: number = 0;

    meter: any = {
        kwh: 0,
        id: null,
    };

    search: any = {
        date: {
            day: 0,
            month: 0,
            year: 0
        },
        kwh: 0
    };

    connection_quality = 0;
    connection_config = meter_connection_config;
    meter_connected: boolean = false;
    meter_status: string = 'disconnected';

    constructor(public _apiService: ApiService, private _mockData: MockDataService) {
    }

    ngOnInit() {
        let date: any = new Date();
        this.year = date.getFullYear();

        this.search.date = {
            day: date.getDate(),
            month: date.getMonth() + 1,
            year: date.getFullYear()
        };

        if (this._apiService.isDemoMode()) {
            this.getMockCurrentConsumption();
            this.getMockMeterInfo();
            this.searchMockMeter();
            this.getMockConnectionData();
            this.refresh = setInterval(() => {
                this.getMockCurrentConsumption();
            }, 10000);

            return;
        }

        this.getCurrentConsumption();
        this.getMeterinfo();
        this.searchMeter();
        this.getMeterStatus();

        this.refresh = setInterval(() => {
            this.getCurrentConsumption();
            this.getMeterStatus();
        }, 10000);
    }

    ngOnDestroy() {
        clearInterval(this.refresh);
    }

    /**
     * Meter-Nummer holen
     */
    getMeterinfo() {
        this._apiService.getInitialization().subscribe(
            (data: any) => {
                if ('data' in data) {
                    if ('profile' in data.data) {
                        if ('meter_status_electricity' in data.data.profile) {
                            const status = data.data.profile.meter_status_electricity;
                            // console.log('status from response: ', status);
                            if (status === 0) {
                                this.meter_status = 'connected';
                            } else if (status === 1) {
                                this.meter_status = 'disconnected';
                            } else {
                                this.meter_status = 'pending';
                            }
                            this.meter_connected = data.data.profile.meter_status_electricity === 0;
                        }

                        let meterId = data.data.profile.meter_serial_number;
                        this.meter.id = this.formatSerialNumber(meterId);
                    }
                }
            }
        );
    }

    getMockMeterInfo(): void {
        this._mockData.getInitialize().subscribe(
            (data) => {
                if ('data' in data) {
                    if ('profile' in data.data) {
                        let meterId = data.data.profile.meter_serial_number;
                        if ('meter_status_electricity' in data.data.profile) {
                            const status = data.data.profile.meter_status_electricity;
                            // console.log('status from response: ', status);
                            if (status === 0) {
                                this.meter_status = 'connected';
                            } else if (status === 1) {
                                this.meter_status = 'disconnected';
                            } else {
                                this.meter_status = 'pending';
                            }
                            this.meter_connected = data.data.profile.meter_status_electricity === 0;
                        }
                        this.meter.id = this.formatSerialNumber(meterId);
                    }
                }
            }
        );

    }

    /**
     * Get Conneciton mock data
     */
    getMockConnectionData(): void {
        this._mockData.getMeterStatus().subscribe(
            (values) => {
                if ('data' in values) {
                    this.meter_connected = values.data.connected_with_meter.status === 0;
                    this.connection_quality = values.data.meter_txrssi;
                }
            }
        );
    }

    /**
     * Aktuellen Zählerstand golen
     */
    getCurrentConsumption() {
        this._apiService.getCurrentConsumption().subscribe(
            (data: any) => {
                if ('data' in data) {
                    if ('electricity' in data.data) {
                        this.meter.kwh = Math.round(data.data.electricity.current_summation / 1000);
                    }
                }
            }
        );
    }

    /**
     *
     */
    private getMockCurrentConsumption(): void {
        this._mockData.getCurrentConsumption().subscribe(
            (data) => {
                if ('data' in data) {
                    if ('electricity' in data.data) {
                        this.meter.kwh = Math.round(data.data.electricity.current_summation / 1000);
                    }
                }
            }
        );
    }


    /**
     *
     */
    getMeterStatus(): void {
        this._apiService.getDeviceStatus().subscribe(
            (response: any) => {
                if ('data' in response) {
                    if ('meter_txrssi' in response.data) {
                        this.connection_quality = response.data.meter_txrssi;
                        return;
                    }
                }
                this.connection_quality = 0;
            }
        );
    }


    /**
     * Neues Datum setzen
     *
     * @param date
     * @param type
     */
    setDate(date: string, type: string) {
        this.search.date[type] = parseInt(date);

        if (this._apiService.isDemoMode()) {
            this.searchMockMeter();
            return;
        }
        this.searchMeter();
    }

    /**
     *
     */
    searchMeter() {
        this.search.kwh = 0;

        this._apiService.getConsumptionForDate(this.search.date.year + '-' + this.search.date.month + '-' + this.search.date.day + 'T23:59:59').subscribe(
            (data: any) => {
                if ('data' in data) {
                    this.search.kwh = Math.round(data.data.current_summation / 1000);
                }
            }
        );
    }

    /**
     *
     */
    private searchMockMeter(): void {
        this.search.kwh = 0;

        const date = new Date();
        date.setMonth(this.search.date.month - 1);
        date.setFullYear(this.search.date.year);
        date.setDate(this.search.date.day);

        this._mockData.getConsumptionForDate(date).subscribe(
            (data: any) => {
                if ('data' in data) {
                    this.search.kwh = Math.round(data.data[0].current_summation / 1000);
                }
            }
        );
    }

    /**
     *
     * @param start
     * @param times
     */
    loop(start: number, times: number) {
        let loop: any = [];

        for (let i = start; i < (start + times); i++) {
            loop.push(i);
        }

        return loop;
    }


    /**
     * Meter-Nummer formatieren
     *
     * @param serialNumber
     */
    formatSerialNumber(serialNumber: string) {
        let returnVal: string = '';

        if ((serialNumber === null || serialNumber === undefined) || serialNumber.length <= 1) {
            return serialNumber;
        }

        for (let i = 0; i < serialNumber.length; i++) {
            if (i == 1 || i == 4 || i == 6 || i == 10) {
                returnVal += ' ';
            }

            returnVal += serialNumber.charAt(i);
        }

        return returnVal;
    }


    determineMeterStatusMessage(): string {
        switch (this.meter_status) {
            case 'connected':
                return 'Verbunden';
            case 'disconnected':
                return 'Getrennt';
            case 'pending':
                return 'Noch nicht verbunden';
            default:
                return 'Noch nicht verbunden';
        }
    }

}
