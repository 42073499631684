import {AfterViewInit, Component, OnInit, ɵdevModeEqual} from '@angular/core';

import {Chart} from 'angular-highcharts';
import {Angulartics2} from 'angulartics2';

import {ApiService} from '../../services/api.service';
import {MockDataService} from '../../mock-data.service';
import {forkJoin, Subject} from 'rxjs';

import {UserService} from '../../user.service';
import * as moment from 'moment';
import {months} from 'moment';

@Component({
    selector: 'compare-details',
    templateUrl: './details.compare.html',
    styleUrls: ['details.compare.scss'],
    viewProviders: []
})

export class CompareDetails implements OnInit, AfterViewInit {

    year: number = 0;

    mode: number = 1;
    timeframe: number = 3;
    position: number = 1;

    compare: any = {
        from_day: 0,
        from_kw: 0,
        from_month: 0,
        from_year: 0,
        to_day: 0,
        to_kw: 0,
        to_month: 0,
        to_year: 0
    };

    limit: number = 6;

    disabled: boolean = true;

    chart: any = {};

    lastYearColor = '#D27300';
    thisYearColor = '#F59B00';
    positionFinal = false;

    public MONTHS = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];

    // happyhour
    userHasHappyHour = false;
    private onUserHasHappyHour = new Subject<boolean>();
    private onDataModeChange = new Subject<string>();
    showConsumptionFor: 'happyhour' | 'all' = 'all';

    constructor(private _analytics: Angulartics2,
                public _apiService: ApiService,
                public _mockData: MockDataService,
                private _userService: UserService) {
    }

    ngOnInit() {
        this.onUserHasHappyHour.subscribe(
            (value) => {
                this.userHasHappyHour = value;
            }
        );

        this.onDataModeChange.subscribe(
            (value: 'all' | 'happyhour') => {
                this.showConsumptionFor = value;
                this.buildCompareDates();
                this.resetChart();
            }
        );

        this.initializeStaticChart();
    }

    ngAfterViewInit(): void {
        if (this._apiService.isDemoMode()) {
            this.buildCompareDates();
            this.getMockStatic();
            return;
        }

        if (this._userService.getActiveUserProvider().toLowerCase() === 'enviam') {
            this.determineUserHasHappyHour();
        }

        this.buildCompareDates();
        this.getStatic();
    }

    /**
     * Vergleichs-Daten zusammen bauen
     */
    buildCompareDates() {
        let dateRight: any = new Date(),
            dateLeft: any = new Date();

        this.year = dateRight.getFullYear();

        switch (this.timeframe) {
            case 2: { // Woche
                dateLeft.setDate(dateRight.getDate() - 7);
                break;
            }
            case 3: { // Monat
                dateLeft.setMonth(dateRight.getMonth() - 1);
                break;
            }
            case 4: { // Jahr
                dateLeft.setFullYear(dateRight.getFullYear() - 1);
                break;
            }
            default: { // Tag
                dateLeft.setDate(dateRight.getDate() - 1);
            }
        }

        this.compare = {
            left_day: dateLeft.getDate(),
            left_kw: this._apiService.getWeek(dateLeft),
            left_month: dateLeft.getMonth() + 1,
            left_year: dateLeft.getFullYear(),
            right_day: dateRight.getDate(),
            right_kw: this._apiService.getWeek(dateRight),
            right_month: dateRight.getMonth() + 1,
            right_year: dateRight.getFullYear()
        };
    }

    /**
     * Daten für Chart "Statisch"
     */
    getStatic() {
        let mode: number = this.mode;
        let area: number = this.timeframe;
        let position: number = this.position;

        for (let year of [{year: this.year - 1, color: '#D27300'}, {year: this.year, color: '#F59B00'}]) {

            let from: any = {};
            let to: any = {};
            let api: string = null;


            switch (this.timeframe) {
                case 2: {
                    from = new Date();
                    from.setFullYear(year.year);
                    from.setDate(from.getDate() - (this.position * (this.limit * 7)) + 7);
                    from.setDate(from.getDate() - (from.getDay() > 0 ? (from.getDay() - 1) : 6));

                    to = new Date();
                    to.setFullYear(year.year);
                    to.setDate(to.getDate() - (this.position * (this.limit * 7)) + (this.limit * 7));
                    to.setDate(to.getDate() + (to.getDay() > 0 ? (7 - to.getDay()) : 0));

                    api = 'days';

                    break;
                }
                case 3: {
                    from = new Date();
                    from.setFullYear(year.year);
                    from.setMonth(from.getMonth() - (this.position * this.limit) + 1);

                    to = new Date();
                    to.setFullYear(year.year);
                    to.setMonth(to.getMonth() - (this.position * this.limit) + this.limit);

                    api = 'months';

                    break;
                }
                case 4: {
                    from = new Date(0);
                    from.setFullYear(year.year);
                    from.setMonth(0);

                    to = new Date(0);
                    to.setFullYear(year.year);
                    to.setMonth(11);
                    to.setDate(31);

                    api = 'months';

                    break;
                }
                default: {
                    from = new Date();
                    from.setFullYear(year.year);
                    from.setDate(from.getDate() - (this.position * this.limit) + 1);

                    to = new Date();
                    to.setFullYear(year.year);
                    to.setDate(to.getDate() - (this.position * this.limit) + this.limit);

                    api = 'days';
                }
            }

            this._apiService.getCompare(from, to, api).subscribe(
                (data: any) => {
                    if ((mode === this.mode) && (area === this.timeframe) && (position === this.position) && ('data' in data)) {
                        let chart: any = $('#chart-compare-details').highcharts();

                        if (data.data.length > 0) {
                            let categories: any = [];

                            switch (this.timeframe) {
                                case 2: {
                                    let consumption: any = [];

                                    for (let con of data.data) {

                                        let kw: number = this._apiService.getWeek(new Date(con.timestamp));
                                        let index: number = consumption.map((con: any) => con.kw).indexOf(kw);

                                        if (index > -1) {
                                            if ('measured' in con) {
                                                consumption[index].wh += con.measured;
                                            }
                                        } else {
                                            if ('measured' in con) {
                                                consumption.push({
                                                    kw: kw,
                                                    wh: con.measured
                                                });
                                            } else {
                                                consumption.push({
                                                    kw: kw,
                                                    wh: 0
                                                });
                                            }
                                        }

                                    }

                                    for (let con of consumption) {
                                        categories.push('KW ' + con.kw);
                                    }

                                    this.chart.addSerie(
                                        {
                                            name: new Date(data.data[data.data.length - 1].timestamp).getFullYear().toString(),
                                            data:
                                                consumption.map((con: any) => {
                                                    return Number((con.wh / 1000).toFixed(2));
                                                }),
                                            color: year.color
                                        }
                                    );

                                    break;
                                }
                                case 4: {
                                    let consumption: number = 0;
                                    for (let con of data.data) {
                                        if ('measured' in con) {
                                            consumption += con.measured;
                                        }
                                    }

                                    categories = ['', ''];
                                    this.chart.addSerie(
                                        {
                                            name: year.year,
                                            data: [Number((consumption / 1000).toFixed(2))],
                                            color: year.color
                                        }
                                    );

                                    break;
                                }
                                default: {
                                    for (let con of data.data) {

                                        let date: any = new Date(con.timestamp);
                                        switch (this.timeframe) {
                                            case 3: {
                                                categories.push(this._apiService.MONTHS[date.getMonth()]);
                                                break;
                                            }
                                            default: {
                                                categories.push(date.getDate() + '. ' + this._apiService.MONTHS[date.getMonth()]);
                                            }
                                        }

                                    }

                                    this.chart.addSerie(
                                        {
                                            name: new Date(data.data[data.data.length - 1].timestamp).getFullYear().toString(),
                                            data:
                                                data.data.map((con: any) => {
                                                    return Number((con.measured / 1000).toFixed(2));
                                                }),
                                            color: year.color
                                        }
                                    );
                                }
                            }

                            chart.update({
                                xAxis: {
                                    categories: categories
                                }
                            });
                        }

                        chart.hideLoading();

                        this.disabled = false;
                    }
                },
                () => {
                    let chart: any = $('#chart-compare-details').highcharts();
                    chart.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');

                    this.disabled = false;
                }
            );

        }

    }

    getMockStatic(): void {
        for (let year of [{year: this.year - 1, color: '#D27300'}, {year: this.year, color: '#F59B00'}]) {

            const req_params = this.determineStaticComparisonApiParams(year);
            const from = req_params.from;
            const to = req_params.to;
            const api = req_params.api;

            this._mockData.getConsumptionByDate(api, from, to).subscribe(
                (data: any) => {
                    if ('data' in data) {
                        let chart: any = $('#chart-compare-details').highcharts();

                        if (data.data.length > 0) {
                            let categories: any = [];

                            switch (this.timeframe) {
                                case 2: {
                                    let consumption: any = [];

                                    for (let con of data.data) {

                                        let kw: number = this._apiService.getWeek(new Date(con.timestamp));
                                        let index: number = consumption.map((con: any) => con.kw).indexOf(kw);

                                        if (index > -1) {
                                            if ('measured' in con) {
                                                consumption[index].wh += con.measured;
                                            }
                                        } else {
                                            if ('measured' in con) {
                                                consumption.push({
                                                    kw: kw,
                                                    wh: con.measured
                                                });
                                            } else {
                                                consumption.push({
                                                    kw: kw,
                                                    wh: 0
                                                });
                                            }
                                        }

                                    }

                                    for (let con of consumption) {
                                        categories.push('KW ' + con.kw);
                                    }

                                    this.chart.addSerie(
                                        {
                                            name: new Date(data.data[data.data.length - 1].timestamp).getFullYear().toString(),
                                            data:
                                                consumption.map((con: any) => {
                                                    return Number((con.wh / 1000).toFixed(2));
                                                }),
                                            color: year.color
                                        }
                                    );

                                    break;
                                }
                                case 4: {
                                    let consumption: number = 0;
                                    for (let con of data.data) {
                                        if ('measured' in con) {
                                            consumption += con.measured;

                                        }
                                    }

                                    categories = ['', ''];

                                    this.chart.addSerie(
                                        {
                                            name: year.year,
                                            data: [Number((consumption / 1000).toFixed(2))],
                                            color: year.color
                                        }
                                    );

                                    break;
                                }
                                default: {
                                    for (let con of data.data) {

                                        let date: any = new Date(con.timestamp);
                                        switch (this.timeframe) {
                                            case 3: {
                                                categories.push(this._apiService.MONTHS[date.getMonth()]);
                                                break;
                                            }
                                            default: {
                                                categories.push(date.getDate() + '. ' +
                                                    this._apiService.MONTHS[date.getMonth()]);
                                            }
                                        }

                                    }

                                    this.chart.addSerie(
                                        {
                                            name: new Date(data.data[data.data.length - 1].timestamp).getFullYear().toString(),
                                            data:
                                                data.data.map((con: any) => {
                                                    return Number((con.measured / 1000).toFixed(2));
                                                }),
                                            color: year.color
                                        }
                                    );
                                }
                            }

                            this.chart.options.xAxis.categories = categories;
                            chart.update({xAxis: {categories: categories}});
                        }

                        chart.hideLoading();

                        this.disabled = false;
                    }
                },
                () => {
                    let chart: any = $('#chart-compare-details').highcharts();
                    chart.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');

                    this.disabled = false;
                }
            );

        }

    }

    /**
     * Daten für Chart "Anpassbar"
     */
    getDynamic() {

        let mode: number = this.mode;
        let area: number = this.timeframe;
        let position: number = this.position;

        let left_from: any = {};
        let left_to: any = {};

        let right_from: any = {};
        let right_to: any = {};

        let api: string = null;

        switch (this.timeframe) {
            case 2: { // Woche
                left_from = new Date(0);
                left_from.setFullYear(this.compare.left_year);
                left_from.setDate(left_from.getDate() + (this.compare.left_kw * 7) - 7);

                left_to = new Date(0);
                left_to.setFullYear(this.compare.left_year);
                left_to.setDate(left_to.getDate() + (this.compare.left_kw * 7) - 1);

                right_from = new Date(0);
                right_from.setFullYear(this.compare.right_year);
                right_from.setDate(right_from.getDate() + (this.compare.right_kw * 7) - 7);

                right_to = new Date(0);
                right_to.setFullYear(this.compare.right_year);
                right_to.setDate(right_to.getDate() + (this.compare.right_kw * 7) - 1);

                api = 'days';

                break;
            }
            case 3: { // Monat
                left_from = new Date(0);
                left_from.setDate(1);
                left_from.setMonth(this.compare.left_month - 1);
                left_from.setFullYear(this.compare.left_year);

                left_to = left_from;

                right_from = new Date(0);
                right_from.setDate(1);
                right_from.setMonth(this.compare.right_month - 1);
                right_from.setFullYear(this.compare.right_year);

                right_to = right_from;

                api = 'months';

                break;
            }
            case 4: { // Jahr
                left_from = new Date(0);
                left_from.setFullYear(this.compare.left_year);

                left_to = new Date(0);
                left_to.setFullYear(this.compare.left_year);
                left_to.setMonth(11);
                left_to.setDate(31);

                right_from = new Date(0);
                right_from.setFullYear(this.compare.right_year);

                right_to = new Date(0);
                right_to.setFullYear(this.compare.right_year);
                right_to.setMonth(11);
                right_to.setDate(31);

                api = 'months';

                break;
            }
            default: { // Tag
                left_from = new Date(0);
                left_from.setFullYear(this.compare.left_year);
                left_from.setMonth(this.compare.left_month - 1);
                left_from.setDate(this.compare.left_day);

                left_to = left_from;

                right_from = new Date(0);
                right_from.setFullYear(this.compare.right_year);
                right_from.setMonth(this.compare.right_month - 1);
                right_from.setDate(this.compare.right_day);

                right_to = right_from;

                api = 'days';
            }
        }

        let left_wh: number = 0;
        let right_wh: number = 0;

        this._apiService.getCompare(left_from, left_to, api).subscribe(
            (data: any) => {
                if ((mode === this.mode) && (area === this.timeframe) && (position === this.position) && ('data' in data)) {
                    for (let con of data.data) {

                        if ('measured' in con) {
                            left_wh += con.measured;
                        }

                    }

                    this._apiService.getCompare(right_from, right_to, api).subscribe(
                        (data: any) => {
                            if ((area === this.timeframe) && (position === this.position) && ('data' in data)) {
                                for (let con of data.data) {
                                    if ('measured' in con) {
                                        right_wh += con.measured;
                                    }
                                }

                                this.chart.addSerie(
                                    {
                                        name: null,
                                        data: [
                                            {
                                                name: null,
                                                y: Number((left_wh / 1000).toFixed(2)),
                                                color: '#D27300'
                                            },
                                            {
                                                name: null,
                                                y: Number((right_wh / 1000).toFixed(2)),
                                                color: '#F59B00'
                                            }
                                        ]
                                    }
                                );

                                let chart: any = $('#chart-compare-details').highcharts();
                                chart.hideLoading();

                                this.disabled = false;
                            }
                        },
                        () => {
                            let chart: any = $('#chart-compare-details').highcharts();
                            chart.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');

                            this.disabled = false;
                        }
                    );
                }
            },
            () => {
                let chart: any = $('#chart-compare-details').highcharts();
                chart.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');

                this.disabled = false;
            }
        );
    }


    /**
     *
     */
    getMockDynamic(): void {
        const times = this.determineDynamicComparisonApiParams();
        const left_from: any = times.left_from;
        const left_to: any = times.left_to;
        const right_from: any = times.right_from;
        const right_to: any = times.right_to;
        const api: string = times.api;

        let left_wh: number = 0;
        let right_wh: number = 0;

        this._mockData.getConsumptionByDate(api, left_from, left_to).subscribe(
            (data: any) => {
                if ('data' in data) {
                    for (let con of data.data) {
                        if ('measured' in con) {
                            left_wh += con.measured;
                        }
                    }

                    this._mockData.getConsumptionByDate(api, right_from, right_to).subscribe(
                        (data: any) => {
                            if ('data' in data) {
                                for (let con of data.data) {
                                    if ('measured' in con) {
                                        right_wh += con.measured;
                                    }
                                }

                                this.chart.addSerie(
                                    {
                                        name: null,
                                        data: [
                                            {
                                                name: null,
                                                y: Number((left_wh / 1000).toFixed(2)),
                                                color: '#D27300'
                                            },
                                            {
                                                name: null,
                                                y: Number((right_wh / 1000).toFixed(2)),
                                                color: '#F59B00'
                                            }
                                        ]
                                    }
                                );

                                let chart: any = $('#chart-compare-details').highcharts();
                                chart.hideLoading();

                                this.disabled = false;
                            }
                        },
                        () => {
                            let chart: any = $('#chart-compare-details').highcharts();
                            chart.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');

                            this.disabled = false;
                        }
                    );
                }
            },
            () => {
                let chart: any = $('#chart-compare-details').highcharts();
                chart.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');

                this.disabled = false;
            }
        );
    }

    getHappyHourStatic(): void {
        let api_mode: 'day' | 'week' | 'month' | 'year';
        let left_start;
        let left_end;
        let right_start;
        let right_end;

        const day_format = 'YYYY-MM-DD';
        const month_format = 'YYYY-MM';

        this.positionFinal = false;
        // determine start and end days
        switch (this.timeframe) {
            case 1:
                api_mode = 'day';
                left_start = moment().subtract((this.position * this.limit) - 1, 'days').format(day_format);
                left_end = moment().subtract((this.position - 1) * this.limit, 'days').format(day_format);
                right_start = moment(left_start, day_format).subtract(1, 'year').format(day_format);
                if (moment(right_start, day_format).year() < 2017) {
                    right_start = moment('01-01-2017', 'DD-MM-YYYY').format(day_format);
                    this.positionFinal = true;
                }
                right_end = moment(left_end, day_format).subtract(1, 'year').format(day_format);
                break;
            case 2:
                // using day api for weeks
                api_mode = 'week';
                left_start = moment().subtract((7 * this.position * this.limit) - 7, 'days').format(day_format);
                left_end = moment().subtract(7 * (this.position - 1) * this.limit, 'days').format(day_format);
                right_start = moment(left_start, day_format).subtract(1, 'year').format(day_format);
                if (moment(right_start, day_format).year() < 2017) {
                    right_start = moment('01-01-2017', 'DD-MM-YYYY').format(day_format);
                    this.positionFinal = true;
                }
                right_end = moment(left_end, day_format).subtract(1, 'year').format(day_format);
                break;
            case 3:
                api_mode = 'month';
                left_start = moment().subtract((this.position * this.limit) - 1, 'months').format(month_format);
                left_end = moment().subtract((this.position - 1) * this.limit, 'months').format(month_format);
                right_start = moment(left_start, month_format).subtract(1, 'year').format(month_format);
                if (moment(right_start, day_format).year() < 2017) {
                    right_start = moment('01-01-2017', 'DD-MM-YYYY').format(month_format);
                    this.positionFinal = true;
                }
                right_end = moment(left_end, month_format).subtract(1, 'year').format(month_format);

                break;
            case 4:
                api_mode = 'year';
                left_start = moment().year();
                left_end = moment().year();
                right_start = moment().subtract(this.position, 'year').year();
                right_end = right_start;
                break;
            default:
                console.log('Unknown case');
                break;
        }

        // console.log(`left from ${left_start} to ${left_end}`);
        // console.log(`right from ${right_start} to ${right_end}`);

        const chart: any = $('#chart-compare-details').highcharts();

        const this_year_request = this._apiService.getHappyHourConsumptionFor(api_mode, left_start, left_end);
        const last_year_request = this._apiService.getHappyHourConsumptionFor(api_mode, right_start, right_end);

        forkJoin([this_year_request, last_year_request]).subscribe(
            (responses) => {
                const this_year_data = responses[0];
                const last_year_data = responses[1];

                if ('data' in last_year_data) {
                    this.chart.addSerie(
                        {
                            name: new Date(last_year_data.data[last_year_data.data.length - 1].timestamp).getFullYear().toString(),
                            data:
                                last_year_data.data.map((con: any) => {
                                    return Number((con.measured / 1000).toFixed(2));
                                }),
                            color: this.lastYearColor
                        }
                    );
                }

                if ('data' in this_year_data) {
                    let categories = [];
                    if (api_mode === 'day') {
                        for (const consumption of this_year_data.data) {
                            const date = moment(consumption.timestamp).toDate();
                            const date_string = `${date.getDate()}. ${this.MONTHS[date.getMonth()]}`;
                            categories.push(date_string);
                        }
                    } else if (api_mode === 'week') {
                        for (const consumption of this_year_data.data) {
                            const date_string = `KW ${moment(consumption.month).week()}`;
                            categories.push(date_string);
                        }
                    } else if (api_mode === 'month') {
                        for (const consumption of this_year_data.data) {
                            const date = moment(consumption.timestamp).toDate();
                            const date_string = `${this.MONTHS[date.getMonth()]}`;
                            categories.push(date_string);
                        }
                    } else if (api_mode === 'year') {
                        categories = ['', ''];
                    }

                    this.chart.addSerie(
                        {
                            name: new Date(this_year_data.data[this_year_data.data.length - 1].timestamp).getFullYear().toString(),
                            data:
                                this_year_data.data.map((con: any) => {
                                    return Number((con.measured / 1000).toFixed(2));
                                }),
                            color: this.thisYearColor
                        }
                    );

                    if (api_mode !== 'year') {
                        this.chart.options.xAxis.categories = categories;
                        this.chart.ref.update({xAxis: {categories: categories}});
                    }

                }


                this.disabled = false;
                chart.hideLoading();
            }
        );
    }

    getHappyHourDynamic(): void {
        let api_mode: 'day' | 'week' | 'month' | 'year';
        let right_start;
        let right_end;
        let left_start;
        let left_end;

        const day_format = 'YYYY-MM-DD';
        const month_format = 'YYYY-MM';

        const date_str_right = `${this.compare.right_year}-${this.compare.right_month}-${this.compare.right_day}`;
        const date_str_left = `${this.compare.left_year}-${this.compare.left_month}-${this.compare.left_day}`;

        switch (this.timeframe) {
            case 1:
                api_mode = 'day';
                right_start = moment(date_str_right, day_format).format(day_format);
                left_start = moment(date_str_left, day_format).format(day_format);
                right_end = right_start;
                left_end = left_start;
                break;
            case 2:
                api_mode = 'week';
                moment.locale('de');
                right_start = moment().year(this.compare.right_year).week(this.compare.right_kw).startOf('week').format(day_format);
                right_end = moment(right_start, day_format).endOf('week').format(day_format);
                left_start = moment().year(this.compare.left_year).week(this.compare.left_kw).format(day_format);
                left_end = moment(left_start, day_format).endOf('week').format(day_format);

                break;
            case 3:
                api_mode = 'month';
                right_start = moment(date_str_right, day_format).format(month_format);
                right_end = right_start;

                left_start = moment(date_str_left, day_format).format(month_format);
                left_end = left_start;
                break;
            case 4:
                right_start = moment(date_str_right, day_format).year();
                right_end = right_start;
                left_start = moment(date_str_left, day_format).year();
                left_end = left_start;
                api_mode = 'year';
                break;
            default:
                console.log('Unknown case');
        }

        // console.log(`left from ${left_start} to ${left_end}`);
        // console.log(`right from ${right_start} to ${right_end}`);

        const chart: any = $('#chart-compare-details').highcharts();

        const right_request = this._apiService.getHappyHourConsumptionFor(api_mode, right_start, right_end);
        const left_request = this._apiService.getHappyHourConsumptionFor(api_mode, left_start, left_end);

        forkJoin([right_request, left_request]).subscribe(
            (responses) => {
                const right_data = responses[0];
                const left_data = responses[1];

                if ('data' in left_data && 'data' in right_data) {
                    this.chart.addSerie(
                        {
                            name: null,
                            data: [
                                {
                                    name: null,
                                    y: Number((left_data.data[0].measured / 1000).toFixed(2)),
                                    color: this.lastYearColor
                                },
                                {
                                    name: null,
                                    y: Number((right_data.data[0].measured / 1000).toFixed(2)),
                                    color: this.thisYearColor
                                }
                            ]
                        }
                    );
                }

                // if ('data' in last_year_data) {
                //     this.chart.addSerie(
                //         {
                //             name: new Date(last_year_data.data[last_year_data.data.length - 1].timestamp).getFullYear().toString(),
                //             data:
                //                 last_year_data.data.map((con: any) => {
                //                     return Number((con.measured / 1000).toFixed(2));
                //                 }),
                //             color: this.lastYearColor
                //         }
                //     );
                // }
                //
                // if ('data' in this_year_data) {
                //     const categories_this_year = [];
                //     if (api_mode === 'day') {
                //         for (const consumption-alert of this_year_data.data) {
                //             const date = moment(consumption-alert.timestamp).toDate();
                //             const date_string = `${date.getDate()}. ${this.MONTHS[date.getMonth()]}`;
                //             categories_this_year.push(date_string);
                //         }
                //     } else if (api_mode === 'week') {
                //         for (const consumption-alert of this_year_data.data) {
                //             const date_string = `KW ${moment(consumption-alert.month).week()}`;
                //             categories_this_year.push(date_string);
                //         }
                //     } else if (api_mode === 'month') {
                //         for (const consumption-alert of this_year_data.data) {
                //             const date = moment(consumption-alert.timestamp).toDate();
                //             const date_string = `${this.MONTHS[date.getMonth()]}`;
                //             categories_this_year.push(date_string);
                //         }
                //     }
                //
                //     this.chart.addSerie(
                //         {
                //             name: new Date(this_year_data.data[this_year_data.data.length - 1].timestamp).getFullYear().toString(),
                //             data:
                //                 this_year_data.data.map((con: any) => {
                //                     return Number((con.measured / 1000).toFixed(2));
                //                 }),
                //             color: this.thisYearColor
                //         }
                //     );
                //
                //     if (api_mode !== 'year') {
                //         this.chart.options.xAxis.categories = categories_this_year;
                //         this.chart.ref.update({xAxis: {categories: categories_this_year}});
                //     }
                //
                // }


                this.disabled = false;
                chart.hideLoading();
            }
        );
        this.disabled = false;
    }

    /**
     * Modus "Statisch" / "Anpassbar" wechseln
     *
     * @param mode
     */
    setMode(mode: number) {
        if (mode !== this.mode) {
            this.mode = mode;

            this.resetChart();

            this._analytics.eventTrack.next({
                action: 'screen_view',
                properties: {
                    category: 'Screens',
                    label: 'screen: Vergleich - ' + (this.mode === 2 ? 'Anpassbar' : 'Statisch') +
                        '; previous_screen: Vergleich - ' + (this.mode === 2 ? 'Statisch' : 'Anpassbar')
                }
            });
        }
    }

    /**
     * Skala wechseln (Tag/Woche/Monat/Jahr)
     *
     * @param timeframe
     */
    setTimeframe(timeframe: string) {
        if ((parseInt(timeframe, 10) !== this.timeframe) && (!this.disabled)) {
            this.timeframe = parseInt(timeframe, 10);
            this.position = 1;

            this.buildCompareDates();
            this.resetChart();
        }
    }

    /**
     * Anpassbares Datum setzen
     *
     * @param key
     * @param value
     */
    setCompare(key, value) {
        this.compare[key] = parseInt(value, 10);

        this.resetChart();
    }

    /**
     * Eine Einheit vor
     */
    positionForward() {
        if ((this.position > 1) && (!this.disabled)) {
            this.position--;

            this.resetChart();
        }
    }

    /**
     * Eine Einheit zurück
     */
    positionBack() {
        if (!this.disabled) {
            this.position++;

            this.resetChart();
        }
    }

    /**
     * zu "Jetzt" springen
     */
    positionNow() {
        if (!this.disabled) {
            this.position = 1;

            this.resetChart();
        }
    }

    /**
     * Zurücksetzen des Diagramms und Werte neu holen
     */
    resetChart() {
        let chart: any = $('#chart-compare-details').highcharts();
        chart.showLoading();

        this.disabled = true;
        if (this.mode === 2) {
            this.initializeDynamicChart();

            if (this._apiService.isDemoMode()) {
                this.getMockDynamic();
                return;
            }

            if (this.showConsumptionFor === 'all') {
                this.getDynamic();
            } else if (this.showConsumptionFor === 'happyhour') {
                this.getHappyHourDynamic();
            }

        } else {
            this.initializeStaticChart();

            if (this._apiService.isDemoMode()) {
                this.getMockStatic();
                return;
            }

            if (this.showConsumptionFor === 'all') {
                this.getStatic();
            } else if (this.showConsumptionFor === 'happyhour') {
                this.getHappyHourStatic();
            }
        }
    }

    loop(start: number, times: number) {
        let loop: any = [];

        for (let i = start; i < (start + times); i++) {
            loop.push(i);
        }

        return loop;
    }

    /**
     * Chart-Konfiguration "Statisch"
     */
    initializeStaticChart() {
        this.chart = new Chart({
            chart: {
                type: 'column',
                backgroundColor: 'rgba(255, 255, 255, 0)',
                margin: [0, 0, 25, 0]
            },
            title: {
                text: null
            },
            xAxis: {
                type: 'categories',
                categories: []
            },
            yAxis: {
                title: {
                    text: ''
                },
                labels: {
                    align: 'right',
                    x: 75,
                    y: 15,
                    step: 2,
                    formatter: function() {
                        if (this.value >= 1) {
                            return this.value.toLocaleString('de-DE') + ' kWh';
                        } else {
                            return null;
                        }
                    }
                }
            },
            tooltip: {
                formatter: function() {
                    return this.y.toLocaleString('de-DE') + ' kWh';
                },
                borderRadius: 10,
                shadow: false
            },
            plotOptions: {
                column: {
                    borderRadius: 10,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            },
            legend: {
                align: 'right',
                verticalAlign: 'top',
                padding: 0
            },
            series: [],
            credits: {
                enabled: false
            }
        });
    }

    /**
     * Chart-Konfiguration "Anpassbar"
     */
    initializeDynamicChart() {
        this.chart = new Chart({
            chart: {
                type: 'column',
                backgroundColor: 'rgba(255, 255, 255, 0)',
                margin: [0, 0, 0, 0]
            },
            title: {
                text: null
            },
            xAxis: {
                title: {
                    text: null
                },
                labels: {
                    enabled: false
                }
            },
            yAxis: {
                title: {
                    text: null
                },
                labels: {
                    align: 'right',
                    x: 75,
                    y: 15,
                    step: 2,
                    formatter: function() {
                        if (this.value >= 1) {
                            return this.value.toLocaleString('de-DE') + ' kWh';
                        } else {
                            return null;
                        }
                    }
                }
            },
            tooltip: {
                formatter: function() {
                    return this.y.toLocaleString('de-DE') + ' kWh';
                },
                borderRadius: 10,
                shadow: false
            },
            plotOptions: {
                column: {
                    borderRadius: 10,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                series: {
                    showInLegend: false
                }
            },
            series: [],
            credits: {
                enabled: false
            }
        });
    }

    /**
     * Determine API params for the static comparison
     * @param year
     */
    private determineStaticComparisonApiParams(year): any {
        let from: any = {};
        let to: any = {};
        let api: string = null;

        switch (this.timeframe) {
            case 2: {
                from = new Date();
                from.setFullYear(year.year);
                from.setDate(from.getDate() - (this.position * (this.limit * 7)) + 7);
                from.setDate(from.getDate() - (from.getDay() > 0 ? (from.getDay() - 1) : 6));

                to = new Date();
                to.setFullYear(year.year);
                to.setDate(to.getDate() - (this.position * (this.limit * 7)) + (this.limit * 7));
                to.setDate(to.getDate() + (to.getDay() > 0 ? (7 - to.getDay()) : 0));

                api = 'days';

                break;
            }
            case 3: {
                from = new Date();
                from.setFullYear(year.year);
                from.setMonth(from.getMonth() - (this.position * this.limit) + 1);

                to = new Date();
                to.setFullYear(year.year);
                to.setMonth(to.getMonth() - (this.position * this.limit) + this.limit);

                api = 'months';

                break;
            }
            case 4: {
                from = new Date(0);
                from.setFullYear(year.year);
                from.setMonth(0);

                to = new Date(0);
                to.setFullYear(year.year);
                to.setMonth(11);
                to.setDate(31);

                api = 'months';

                break;
            }
            default: {
                from = new Date();
                from.setFullYear(year.year);
                from.setDate(from.getDate() - (this.position * this.limit) + 1);

                to = new Date();
                to.setFullYear(year.year);
                to.setDate(to.getDate() - (this.position * this.limit) + this.limit);

                api = 'days';
            }
        }

        return {from, to, api};
    }


    /**
     * Determine API params for the dynamic comparison
     * @param year
     */
    private determineDynamicComparisonApiParams(): any {

        let left_from: any = {};
        let left_to: any = {};

        let right_from: any = {};
        let right_to: any = {};

        let api: string = null;

        switch (this.timeframe) {
            case 2: { // Woche
                left_from = new Date(0);
                left_from.setFullYear(this.compare.left_year);
                left_from.setDate(left_from.getDate() + (this.compare.left_kw * 7) - 7);

                left_to = new Date(0);
                left_to.setFullYear(this.compare.left_year);
                left_to.setDate(left_to.getDate() + (this.compare.left_kw * 7) - 1);

                right_from = new Date(0);
                right_from.setFullYear(this.compare.right_year);
                right_from.setDate(right_from.getDate() + (this.compare.right_kw * 7) - 7);

                right_to = new Date(0);
                right_to.setFullYear(this.compare.right_year);
                right_to.setDate(right_to.getDate() + (this.compare.right_kw * 7) - 1);

                api = 'days';

                break;
            }
            case 3: { // Monat
                left_from = new Date(0);
                left_from.setDate(1);
                left_from.setMonth(this.compare.left_month - 1);
                left_from.setFullYear(this.compare.left_year);

                left_to = left_from;

                right_from = new Date(0);
                right_from.setDate(1);
                right_from.setMonth(this.compare.right_month - 1);
                right_from.setFullYear(this.compare.right_year);

                right_to = right_from;

                api = 'months';

                break;
            }
            case 4: { // Jahr
                left_from = new Date(0);
                left_from.setFullYear(this.compare.left_year);

                left_to = new Date(0);
                left_to.setFullYear(this.compare.left_year);
                left_to.setMonth(11);
                left_to.setDate(31);

                right_from = new Date(0);
                right_from.setFullYear(this.compare.right_year);

                right_to = new Date(0);
                right_to.setFullYear(this.compare.right_year);
                right_to.setMonth(11);
                right_to.setDate(31);

                api = 'months';

                break;
            }
            default: { // Tag
                left_from = new Date(0);
                left_from.setFullYear(this.compare.left_year);
                left_from.setMonth(this.compare.left_month - 1);
                left_from.setDate(this.compare.left_day);

                left_to = left_from;

                right_from = new Date(0);
                right_from.setFullYear(this.compare.right_year);
                right_from.setMonth(this.compare.right_month - 1);
                right_from.setDate(this.compare.right_day);

                right_to = right_from;

                api = 'days';
            }
        }

        return {left_from, left_to, right_from, right_to, api};
    }


    /**
     * Request whether the user participates in the happy hour program
     */
    private determineUserHasHappyHour(): void {
        this._apiService.userParticipatesInHappyHour().subscribe(
            (response) => {

                if (response === null || response === undefined) {
                    console.log('undefined from response');
                    return;
                }

                if ('data' in response) {
                    const now = moment();

                    const parsed_response_data = response.data as Array<{ from_date: string, value: number }>;
                    // filter by elements with date earlier than today
                    const filtered = parsed_response_data.filter((element) => now >= moment(element.from_date, 'DD/MM/YYYY'));
                    const sorted = filtered.sort((a, b) => {
                        const a_from = moment(a.from_date, 'DD/MM/YYYY');
                        const b_from = moment(b.from_date, 'DD/MM/YYYY');
                        if (a_from.unix() > b_from.unix()) {
                            return -1;
                        } else if (a_from.unix() < b_from.unix()) {
                            return 1;
                        }
                        return 0;
                    });

                    if ('value' in sorted[0]) {
                        this.onUserHasHappyHour.next(sorted[0].value === 1);
                    }
                }

            },
            (error) => {
            }
        );
    }

    /**
     * On Mode change
     */
    public setDataMode(mode: string): void {
        this.onDataModeChange.next(mode);
    }
}
