import {Component, OnInit} from '@angular/core';

import {ApiService} from '../../services/api.service';
import {Chart} from 'angular-highcharts';
import {Globals} from '../../services/globals.service';
import {MockDataService} from '../../mock-data.service';
import {UserService} from '../../user.service';

@Component({
    selector: 'compare-widget',
    templateUrl: './widget.compare.html',
    viewProviders: [],
    providers: [Globals]
})

export class CompareWidget implements OnInit {

    chart: any = {};

    trend: any = {
        monthname: null,
        direction: 0,
        percent: 0
    };

    showDiagrams: boolean = true;

    consumption_current = 0;
    consumption_comparision = 0;


    constructor(private _apiService: ApiService,
                private _globals: Globals,
                private _mockData: MockDataService,
                private _userService: UserService) {
    }

    ngOnInit() {
        this.initializeChart();

        if (this._apiService.isDemoMode()) {
            this.getMockComparison();
            return;
        }

        this.getCompare();
    }

    /**
     * Chart Daten von API holen
     */
    getCompare() {
        let currentDate = new Date();

        let lastMonth = currentDate.getMonth() == 0 ? 11 : currentDate.getMonth() - 1;
        let lastYear = currentDate.getMonth() == 0 ? (currentDate.getFullYear() - 1) : currentDate.getFullYear();

        let daysThisMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
        let daysLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate();

        let consumptionCurrent = 0;
        let consumptionCompare = 0;

        if (currentDate.getDate() == 1) { // Wenn heute der erste Tag im Monat ist
            // Anfrage für vergangenen Monat
            let date1 = new Date(lastYear, lastMonth, 1, 0, 0, 0);

            let hour = currentDate.getHours();
            if (currentDate.getMinutes() >= 30 && currentDate.getHours() < 23) {
                hour = hour + 1;
            }

            // Wir müssen hier nur 2 Anfragen machen jewails Stundenweise für den gleichen Tag im vergangenen Monat und heute
            this._apiService.getConsumptionCustom('hours', date1, date1).subscribe(
                (data: any) => {
                    if ('data' in data) {
                        for (let con of data.data) {
                            let date: any = new Date(con.timestamp);
                            if (date.getHours() <= hour) {  // Prüfen, ob Zeit der Verbrauchsangabe vor aktueller ist
                                if (('measured' in con)) {
                                    consumptionCompare += con.measured;
                                }
                            }

                        }
                    }

                    // Anfrage für jetzigen Monat
                    let date3 = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);

                    this._apiService.getConsumptionCustom('hours', date3, date3).subscribe(
                        (data: any) => {
                            if ('data' in data) {
                                for (let con of data.data) {
                                    let date: any = new Date(con.timestamp);
                                    if (date.getHours() <= currentDate.getHours()) {  // Prüfen, ob Zeit der Verbrauchsangabe vor aktueller ist
                                        if (('measured' in con)) {
                                            consumptionCurrent += con.measured;
                                        }
                                    }

                                }
                            }

                            this.calculateConsumption(consumptionCurrent, consumptionCompare);
                        }
                    );
                }
            );
        } else if (daysThisMonth > daysLastMonth && currentDate.getDate() > daysLastMonth) { //Aktueller Monat hat mehr Tage als vorheriger UND currentDate.day ist größer als Anzahl Tage im Vormonat
            // Anfrage für für vergangenen Monat
            let date1 = new Date(lastYear, lastMonth, 1, 0, 0, 0);
            let date2 = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0, 23, 59, 59);

            // Wir müssen hier einmal Tageweise den kompletten letzten Monat anfragen, dann Tageweise bis gestern, und vom heutigen Tag noch Stundenweise
            this._apiService.getConsumptionCustom('days', date1, date2).subscribe(
                (data: any) => {
                    if ('data' in data) {
                        for (let con of data.data) {
                            if (('measured' in con)) {
                                consumptionCompare += con.measured;
                            }
                        }
                    }

                    // Anfragen für heutigen Monat
                    let date3 = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0);
                    let date4 = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1, 23, 59, 59);

                    this._apiService.getConsumptionCustom('days', date3, date4).subscribe(
                        (data: any) => {
                            if ('data' in data) {
                                for (let con of data.data) {
                                    if (('measured' in con)) {
                                        consumptionCurrent += con.measured;
                                    }
                                }
                            }

                            // Anfragen für heutigen Tag stündlich
                            let date5 = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);

                            this._apiService.getConsumptionCustom('hours', date5, date5).subscribe(
                                (data: any) => {
                                    if ('data' in data) {
                                        for (let con of data.data) {
                                            let date: any = new Date(con.timestamp);
                                            if (date.getHours() <= currentDate.getHours()) {  // Prüfen, ob Zeit der Verbrauchsangabe vor aktueller ist
                                                if (('measured' in con)) {
                                                    consumptionCurrent += con.measured;
                                                }
                                            }

                                        }
                                    }

                                    this.calculateConsumption(consumptionCurrent, consumptionCompare);
                                }
                            );

                        }
                    );
                }
            );
        } else {
            // Anfragen für vergangen Monat
            let date1 = new Date(lastYear, lastMonth, 1, 0, 0, 0);
            let date2 = new Date(lastYear, lastMonth, currentDate.getDate() - 1, 23, 59, 59);

            // Hier müssen wir den letzten Monat Tageweise anfragen bis gestern vor einem Monat, dann heute vor einem Monat Stundenweise und das gleiche nochmal für diesen Monat
            this._apiService.getConsumptionCustom('days', date1, date2).subscribe( // Anfrage letzter Monat Tageweise
                (data: any) => {
                    if ('data' in data) {
                        for (let con of data.data) {
                            if (('measured' in con)) {
                                consumptionCompare += con.measured;
                            }
                        }
                    }

                    let date3 = new Date(lastYear, lastMonth, currentDate.getDate(), 0, 0, 0);

                    let hour = currentDate.getHours();
                    if (currentDate.getMinutes() >= 30 && currentDate.getHours() < 23) {
                        hour = hour + 1;
                    }

                    this._apiService.getConsumptionCustom('hours', date3, date3).subscribe( // Anfrage gleicher Tag, letzter Monat, stundenweise
                        (data: any) => {
                            if ('data' in data) {
                                for (let con of data.data) {
                                    let date: any = new Date(con.timestamp);
                                    if (date.getHours() <= hour) {  // Prüfen, ob Zeit der Verbrauchsangabe vor aktueller ist
                                        if (('measured' in con)) {
                                            consumptionCompare += con.measured;
                                        }
                                    }

                                }
                            }

                            // Anfragen für heutigen Monat
                            let date5 = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0);
                            let date6 = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1, 23, 59, 59);

                            this._apiService.getConsumptionCustom('days', date5, date6).subscribe(
                                (data: any) => {
                                    if ('data' in data) {
                                        for (let con of data.data) {
                                            if (('measured' in con)) {
                                                consumptionCurrent += con.measured;
                                            }
                                        }
                                    }

                                    // Anfragen für heutigen Tag stündlich
                                    let date7 = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);

                                    this._apiService.getConsumptionCustom('hours', date7, date7).subscribe(
                                        (data: any) => {
                                            if ('data' in data) {
                                                for (let con of data.data) {
                                                    let date: any = new Date(con.timestamp);
                                                    if (date.getHours() <= currentDate.getHours()) {  // Prüfen, ob Zeit der Verbrauchsangabe vor aktueller ist
                                                        if (('measured' in con)) {
                                                            consumptionCurrent += con.measured;
                                                        }
                                                    }

                                                }
                                            }

                                            this.calculateConsumption(consumptionCurrent, consumptionCompare);
                                        }
                                    );

                                }
                            );
                        }
                    );
                }
            );
        }
    }

    /**
     * Chart Daten von API holen
     */
    getMockCompare() {
        let currentDate = new Date();

        let lastMonth = currentDate.getMonth() == 0 ? 11 : currentDate.getMonth() - 1;
        let lastYear = currentDate.getMonth() == 0 ? (currentDate.getFullYear() - 1) : currentDate.getFullYear();

        let daysThisMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
        let daysLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate();

        this.consumption_current = 0;
        this.consumption_comparision = 0;

        if (currentDate.getDate() == 1) { // Wenn heute der erste Tag im Monat ist
            // Anfrage für vergangenen Monat
            let date1 = new Date(lastYear, lastMonth, 1, 0, 0, 0);

            let hour = currentDate.getHours();
            if (currentDate.getMinutes() >= 30 && currentDate.getHours() < 23) {
                hour = hour + 1;
            }

            // Wir müssen hier nur 2 Anfragen machen jewails Stundenweise für den gleichen Tag im vergangenen Monat und heute
            this._apiService.getConsumptionCustom('hours', date1, date1).subscribe(
                (data: any) => {
                    if ('data' in data) {
                        for (let con of data.data) {
                            let date: any = new Date(con.timestamp);
                            if (date.getHours() <= hour) {  // Prüfen, ob Zeit der Verbrauchsangabe vor aktueller ist
                                if (('measured' in con)) {
                                    this.consumption_comparision += con.measured;
                                }
                            }

                        }
                    }

                    // Anfrage für jetzigen Monat
                    let date3 = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);

                    this._apiService.getConsumptionCustom('hours', date3, date3).subscribe(
                        (data: any) => {
                            if ('data' in data) {
                                for (let con of data.data) {
                                    let date: any = new Date(con.timestamp);
                                    if (date.getHours() <= currentDate.getHours()) {  // Prüfen, ob Zeit der Verbrauchsangabe vor aktueller ist
                                        if (('measured' in con)) {
                                            this.consumption_current += con.measured;
                                        }
                                    }

                                }
                            }

                            this.calculateConsumption(this.consumption_current, this.consumption_comparision);
                        }
                    );
                }
            );
        } else if (daysThisMonth > daysLastMonth && currentDate.getDate() > daysLastMonth) { //Aktueller Monat hat mehr Tage als vorheriger UND currentDate.day ist größer als Anzahl Tage im Vormonat
            // Anfrage für für vergangenen Monat
            let date1 = new Date(lastYear, lastMonth, 1, 0, 0, 0);
            let date2 = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0, 23, 59, 59);

            // Wir müssen hier einmal Tageweise den kompletten letzten Monat anfragen, dann Tageweise bis gestern, und vom heutigen Tag noch Stundenweise
            this._apiService.getConsumptionCustom('days', date1, date2).subscribe(
                (data: any) => {
                    if ('data' in data) {
                        for (let con of data.data) {
                            if (('measured' in con)) {
                                this.consumption_comparision += con.measured;
                            }
                        }
                    }

                    // Anfragen für heutigen Monat
                    let date3 = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0);
                    let date4 = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1, 23, 59, 59);

                    this._apiService.getConsumptionCustom('days', date3, date4).subscribe(
                        (data: any) => {
                            if ('data' in data) {
                                for (let con of data.data) {
                                    if (('measured' in con)) {
                                        this.consumption_current += con.measured;
                                    }
                                }
                            }

                            // Anfragen für heutigen Tag stündlich
                            let date5 = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);

                            this._apiService.getConsumptionCustom('hours', date5, date5).subscribe(
                                (data: any) => {
                                    if ('data' in data) {
                                        for (let con of data.data) {
                                            let date: any = new Date(con.timestamp);
                                            if (date.getHours() <= currentDate.getHours()) {  // Prüfen, ob Zeit der Verbrauchsangabe vor aktueller ist
                                                if (('measured' in con)) {
                                                    this.consumption_current += con.measured;
                                                }
                                            }

                                        }
                                    }

                                    this.calculateConsumption(this.consumption_current, this.consumption_comparision);
                                }
                            );

                        }
                    );
                }
            );
        } else {
            // Anfragen für vergangen Monat
            let date1 = new Date(lastYear, lastMonth, 1, 0, 0, 0);
            let date2 = new Date(lastYear, lastMonth, currentDate.getDate() - 1, 23, 59, 59);

            // Hier müssen wir den letzten Monat Tageweise anfragen bis gestern vor einem Monat, dann heute vor einem Monat Stundenweise und das gleiche nochmal für diesen Monat
            this._mockData.getConsumptionByDate('days', date1, date2).subscribe( // Anfrage letzter Monat Tageweise
                (data: any) => {
                    if ('data' in data) {
                        for (let con of data.data) {
                            if (('measured' in con)) {
                                this.consumption_comparision += con.measured;
                            }
                        }
                    }

                    let date3 = new Date(lastYear, lastMonth, currentDate.getDate(), 0, 0, 0);

                    let hour = currentDate.getHours();
                    if (currentDate.getMinutes() >= 30 && currentDate.getHours() < 23) {
                        hour = hour + 1;
                    }

                    this._mockData.getConsumptionByDate('hours', date3, date3).subscribe( // Anfrage gleicher Tag, letzter Monat, stundenweise
                        (data: any) => {
                            if ('data' in data) {
                                for (let con of data.data) {
                                    let date: any = new Date(con.timestamp);
                                    if (date.getHours() <= hour) {  // Prüfen, ob Zeit der Verbrauchsangabe vor aktueller ist
                                        if (('measured' in con)) {
                                            this.consumption_comparision += con.measured;
                                        }
                                    }

                                }
                            }

                            // Anfragen für heutigen Monat
                            let date5 = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0);
                            let date6 = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1, 23, 59, 59);

                            this._mockData.getConsumptionByDate('days', date5, date6).subscribe(
                                (data: any) => {

                                    if ('data' in data) {
                                        for (let con of data.data) {
                                            if (('measured' in con)) {
                                                this.consumption_current += con.measured;
                                            }
                                        }
                                    }

                                    // Anfragen für heutigen Tag stündlich
                                    let date7 = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);

                                    this._mockData.getConsumptionByDate('hours', date7, date7).subscribe(
                                        (data: any) => {
                                            if ('data' in data) {
                                                for (let con of data.data) {
                                                    let date: any = new Date(con.timestamp);
                                                    if (date.getHours() <= currentDate.getHours()) {  // Prüfen, ob Zeit der Verbrauchsangabe vor aktueller ist
                                                        if (('measured' in con)) {
                                                            this.consumption_current += con.measured;
                                                        }
                                                    }

                                                }
                                            }

                                            this.calculateConsumption(this.consumption_current, this.consumption_comparision);
                                        }
                                    );

                                }
                            );
                        }
                    );
                }
            );
        }
    }

    getMockComparison(): void {
        this._mockData.getMonthlyComparison().subscribe(
            (data) => {
                this.calculateConsumption(data.data[0].measured, data.data[1].measured);
            }
        );
    }



    /**
     * Verbrauch berechnen
     *
     * @param consumptionCurrent
     * @param consumptionCompare
     */
    calculateConsumption(consumptionCurrent, consumptionCompare) {
        this.showDiagrams = consumptionCurrent > 0 || consumptionCompare > 0;

        let date = new Date();
        date.setMonth(date.getMonth() - 1);

        let day = ('0' + date.getDate()).slice(-2);
        let month = ('0' + (date.getMonth() + 1)).slice(-2);

        this.chart.addPoint({
            name: '01.' + month + '.-' + day + '.' + month + '.' + date.getFullYear(),
            y: Math.round(consumptionCompare / 1000),
            color: '#D27300'
        });

        date = new Date();

        day = ('0' + date.getDate()).slice(-2);
        month = ('0' + (date.getMonth() + 1)).slice(-2);

        this.chart.addPoint({
            name: '01.' + month + '.-' + day + '.' + month + '.' + date.getFullYear(),
            y: Math.round(consumptionCurrent / 1000),
            color: '#F59B00'
        });

        if (consumptionCurrent > consumptionCompare) {
            this.trend = {
                monthname: this._apiService.getMonthname(1),
                direction: 1,
                percent: Math.round(((consumptionCurrent - consumptionCompare) / consumptionCompare) * 100)
            };
        } else if (consumptionCurrent < consumptionCompare) {
            this.trend = {
                monthname: this._apiService.getMonthname(1),
                direction: -1,
                percent: Math.round((1 - (consumptionCurrent / consumptionCompare)) * 100)
            };
        }
    }

    firstDetailViewAnalytics() {
        if (!(this._globals.getFirstDetailsViewed())) {
            // Erstes aufrufen eines Detail Screens
            this._apiService._analytics.eventTrack.next({
                action: 'first_detail_view',
                properties: {
                    category: 'Screens',
                    label: 'Screen: Compare-Details'
                }
            });
        }

        this._globals.setFirstDetailsViews();
    }

    /**
     * Init chart
     */
    private initializeChart(): void {
        this.chart = new Chart({
            chart: {
                type: 'column',
                backgroundColor: 'rgba(255, 255, 255, 0)',
                margin: [0, 50, 150, 50],
                events: {
                    redraw: function() {
                        let bottom = this.plotHeight + 25;

                        $.each(this.series[0].data, function(i, data) {

                            data.dataLabel.attr({
                                y: bottom
                            });
                        });

                        this.reflow();
                    },
                    click: function() {
                        $('.sidebar').each(function() {
                            $(this).removeClass('visible');
                        });

                        $('html').addClass('sidebar-open');
                        $('.sb-overlay').addClass('visible');
                        $('.sidebar.myDevices.compare-details').toggleClass('visible');
                    }
                }
            },
            title: {
                text: null
            },
            xAxis: {
                title: {
                    text: null
                },
                labels: {
                    enabled: false
                },
                lineColor: 'transparent',
                tickColor: 'transparent'
            },
            yAxis: {
                title: {
                    text: null
                },
                labels: {
                    enabled: false
                },
                gridLineWidth: 0
            },
            tooltip: {
                enabled: false
            },
            plotOptions: {
                column: {
                    pointWidth: 175,
                    borderRadius: 10,
                    dataLabels: {
                        enabled: true,
                        useHTML: true,
                        formatter: function() {
                            return '<div class="label date" style="color: ' + this.point.color + ';">' + this.point.name + '</div>'
                                + '<h3 class="label" style="color: ' + this.point.color + ';">' + this.y.toLocaleString('de-DE') + ' kWh</h3>';
                        },
                        verticalAlign: 'bottom'
                    },
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                series: {
                    showInLegend: false
                }
            },
            series: [
                {
                    name: null,
                    data: []
                }
            ],
            credits: {
                enabled: false
            }
        });

    }
}
