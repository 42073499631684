<div class="block block-tile large">
    <div class="inner" [hidden]="!showDiagrams">
        <h3>Heute</h3>
        <h4 *ngIf="trend.percent > 0 && trend.percent < 9999"><strong>{{ trend.percent }} % {{ trend.direction > 0 ? 'mehr' : 'weniger' }} </strong> als letzten {{ week.dayname }}</h4>
        <h4 *ngIf="trend.percent === 0"><strong>identisch</strong> zum letzten {{ week.dayname }}</h4>
        <div class="chart-wrap">
            <div class="pie-chart-wrap">
                <div class="pie-chart-left" [style.transform]="'scale(' + trend.scale.left + ')'"></div>
                <div class="pie-chart-right" [style.transform]="'scale(' + trend.scale.right + ')'"></div>
            </div>
            <a href="javascript:;" angulartics2On="click" angularticsCategory="Screens" angularticsAction="screen_view" angularticsLabel="screen: Heute; previous_screen: Übersicht" (click)="firstDetailViewAnalytics()" title="Details anzeigen" class="today-details icon-arrow-rt">
                <span>Details anzeigen</span>
            </a>
        </div>
        <div class="todaystat-wrap">
            <div class="lft">
                <small>{{ week.date }}</small>
                <div class="price">{{ history.costs }} <small>&euro;</small>
                </div>
                <div class="khw">{{ history.kwh }} kWh</div>
            </div>
            <div class="mid"></div>
            <div class="rt">
                <small>Heute</small>
                <div class="price">{{ today.costs }} <small>&euro;</small></div>
                <div class="khw">{{ today.kwh }} kWh</div>
            </div>
        </div>
    </div>

    <div class="inner" [hidden]="showDiagrams">
        <h3>Heute</h3>
        <div class="error green"></div>
        <div class="error-text">Daten sind noch <br />nicht verfügbar</div>
    </div>
</div>