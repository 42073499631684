import {Component, OnInit} from '@angular/core';

import {ApiService} from '../../services/api.service';
import {Globals} from '../../services/globals.service';
import {MockDataService} from '../../mock-data.service';

@Component({
    selector: 'finance-widget',
    templateUrl: './widget.finance.html',
    styleUrls: ['./widget.finance.scss'],
    providers: [Globals]
})

export class FinanceWidget implements OnInit {

    finance: any = {
        trend: 0,
        amount: 0,
        percent: 0
    };

    data_available = false;

    lotti_config = {
        path: 'assets/anim/finance.json',
        renderer: 'svg',
        autoplay: true,
        autoplaySegments: false,
        loop: true,
        name: 'Smart Meter'
    };

    private animation = null;

    constructor(private _apiService: ApiService,
                private _globals: Globals,
                private _mockData: MockDataService) {
    }

    /**
     * Widget "Abschlags-Check" ist aktuell deaktiviert
     */
    ngOnInit() {

        if (this._apiService.isDemoMode()) {
            this.getMockFinanceData();
            return;
        }

        this.getFinanceData();
    }

    animationCreated(anim: any) {
        this.animation = anim;
    }

    private getFinanceData(): void {
        this._apiService.getFinance().subscribe(
            (response: any) => {
                if ('data' in response) {
                    if (response.data.sum_of_prepayments > 12) {
                        this.data_available = true;
                        this.extractFinanceData(response);
                    }
                }
            },
            (error) => {
                console.log('err');
            }
        );
    }

    private getMockFinanceData(): void {
        this._mockData.getBillPrediction().subscribe(
            (response) => {
                if ('data' in response) {
                    // if the sum of prepayments is greater than 12
                    const total_prepayments = response.data.sum_of_prepayments;
                    const total_estimated = response.data.estimated_cost_billing_period;

                    if (response.data.sum_of_prepayments > 12) {

                        this.data_available = true;
                        this.extractFinanceData(response);
                    }
                }
            }
        );
    }

    private extractFinanceData(data: any): void {
        let percent = ((data.data.estimated_cost_billing_period - data.data.sum_of_prepayments) / data.data.sum_of_prepayments) * 100;

        if (percent <= -10) {
            let percent = Math.round(((data.data.sum_of_prepayments - data.data.estimated_cost_billing_period) / data.data.estimated_cost_billing_period) * 100);

            this.finance = {
                trend: -1,
                amount: Math.ceil((data.data.sum_of_prepayments - data.data.estimated_cost_billing_period) / 10) * 10,
                percent: percent < 50 ? (100 - percent) : 50
            };

            if (this.finance.amount > (data.data.estimated_cost_billing_period / 2)) {
                this.data_available = false;
            }

        } else if (percent >= 10) {
            let percent = Math.round(((data.data.estimated_cost_billing_period - data.data.sum_of_prepayments) / data.data.sum_of_prepayments) * 100);

            this.finance = {
                trend: 1,
                amount: Math.ceil((data.data.estimated_cost_billing_period - data.data.sum_of_prepayments) / 10) * 10,
                percent: percent < 50 ? (100 - percent) : 50
            };


            if (this.finance.amount > (data.data.estimated_cost_billing_period / 2)) {
                this.data_available = false;
            }

        } else {
            this.finance = {
                trend: 0,
                amount: 0,
                percent: 0
            };
        }
    }

    firstDetailViewAnalytics() {
        if (!(this._globals.getFirstDetailsViewed())) {
            // Erstes aufrufen eines Detail Screens
            this._apiService._analytics.eventTrack.next({
                action: 'first_detail_view',
                properties: {
                    category: 'Screens',
                    label: 'Screen: Finance-Details'
                }
            });
        }

        this._globals.setFirstDetailsViews();
    }
}
