/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./widget.finance.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/ng-lottie/dist/esm/src/lottieAnimationView.component.ngfactory";
import * as i4 from "ng-lottie/dist/esm/src/lottieAnimationView.component";
import * as i5 from "angulartics2";
import * as i6 from "../../services/globals.service";
import * as i7 from "./widget.finance";
import * as i8 from "../../services/api.service";
import * as i9 from "../../mock-data.service";
var styles_FinanceWidget = [i0.styles];
var RenderType_FinanceWidget = i1.ɵcrt({ encapsulation: 0, styles: styles_FinanceWidget, data: {} });
export { RenderType_FinanceWidget as RenderType_FinanceWidget };
function View_FinanceWidget_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Etwa "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " \u20AC"])), (_l()(), i1.ɵted(-1, null, [" Gutschrift"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.finance.amount; _ck(_v, 3, 0, currVal_0); }); }
function View_FinanceWidget_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Verbrauch passt genau"]))], null, null); }
function View_FinanceWidget_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Etwa "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " \u20AC"])), (_l()(), i1.ɵted(-1, null, [" Nachzahlung"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.finance.amount; _ck(_v, 3, 0, currVal_0); }); }
function View_FinanceWidget_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FinanceWidget_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FinanceWidget_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FinanceWidget_4)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.finance.trend < 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.finance.trend === 0); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.finance.trend > 0); _ck(_v, 6, 0, currVal_2); }, null); }
function View_FinanceWidget_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Daten werden noch gesammelt"]))], null, null); }
function View_FinanceWidget_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [["container", 1]], null, 4, "div", [["class", "invoice-status-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "invoice-status circle"]], [[2, "credit", null], [2, "optimal", null], [2, "additional", null]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [], [[2, "icon-piggybank", null], [2, "icon-thumbs-up", null], [2, "icon-alert", null]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "status-circle circle"]], [[4, "width", "%"], [4, "height", "%"]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "status-circle-outline circle"]], [[4, "width", "%"], [4, "height", "%"]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.finance.trend < 0); var currVal_1 = (_co.finance.trend === 0); var currVal_2 = (_co.finance.trend > 0); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = (_co.finance.trend < 0); var currVal_4 = (_co.finance.trend === 0); var currVal_5 = (_co.finance.trend > 0); _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = ((_co.finance.trend < 0) ? _co.finance.percent : 100); var currVal_7 = ((_co.finance.trend < 0) ? _co.finance.percent : 100); _ck(_v, 4, 0, currVal_6, currVal_7); var currVal_8 = ((_co.finance.trend > 0) ? _co.finance.percent : 100); var currVal_9 = ((_co.finance.trend > 0) ? _co.finance.percent : 100); _ck(_v, 5, 0, currVal_8, currVal_9); }); }
function View_FinanceWidget_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "animation-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "lottie-animation-view", [], null, [[null, "animCreated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("animCreated" === en)) {
        var pd_0 = (_co.animationCreated !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_LottieAnimationViewComponent_0, i3.RenderType_LottieAnimationViewComponent)), i1.ɵdid(2, 114688, null, 0, i4.LottieAnimationViewComponent, [i1.PLATFORM_ID], { options: [0, "options"] }, { animCreated: "animCreated" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.lotti_config; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FinanceWidget_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "block block-tile large"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Abschlags-Check"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FinanceWidget_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["subtitle_empty", 2]], null, 0, null, View_FinanceWidget_5)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FinanceWidget_6)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["empty_state", 2]], null, 0, null, View_FinanceWidget_7)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "a", [["angulartics2On", "click"], ["angularticsAction", "screen_view"], ["angularticsCategory", "Screens"], ["angularticsLabel", "screen: Abschlags-Check; previous_screen: \u00DCbersicht"], ["class", "finance-details icon-arrow-rt"], ["href", "javascript:;"], ["title", "Details anzeigen"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.firstDetailViewAnalytics() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 1064960, null, 0, i5.Angulartics2On, [i1.ElementRef, i5.Angulartics2, i1.Renderer2], { angulartics2On: [0, "angulartics2On"], angularticsAction: [1, "angularticsAction"], angularticsCategory: [2, "angularticsCategory"], angularticsLabel: [3, "angularticsLabel"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Details anzeigen"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data_available; var currVal_1 = i1.ɵnov(_v, 6); _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_2 = _co.data_available; var currVal_3 = i1.ɵnov(_v, 9); _ck(_v, 8, 0, currVal_2, currVal_3); var currVal_4 = "click"; var currVal_5 = "screen_view"; var currVal_6 = "Screens"; var currVal_7 = "screen: Abschlags-Check; previous_screen: \u00DCbersicht"; _ck(_v, 11, 0, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_FinanceWidget_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "finance-widget", [], null, null, null, View_FinanceWidget_0, RenderType_FinanceWidget)), i1.ɵprd(512, null, i6.Globals, i6.Globals, []), i1.ɵdid(2, 114688, null, 0, i7.FinanceWidget, [i8.ApiService, i6.Globals, i9.MockDataService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var FinanceWidgetNgFactory = i1.ɵccf("finance-widget", i7.FinanceWidget, View_FinanceWidget_Host_0, {}, {}, []);
export { FinanceWidgetNgFactory as FinanceWidgetNgFactory };
