<div id="register-wrap">
    <fieldset class="register">
        <div class="form-header clearfix">
            <div class="logo"></div>
            <div class="app-name">iONA</div>
        </div>


        <!--
           * EMAIL & VOUCHER CODE
         -->
        <div *ngIf="currentStep === onboardingSteps.accountCreation" class="form-content">
            <h2>Registrierung</h2>
            <p></p>
            <form (submit)="validateVoucher(voucher.value, email.value); false">
                <div class="grid two clearfix">
                    <div class="box">
                        <div class="formItem">
                            <label for="email">E-Mail-Adresse</label>
                            <input type="text" #email id="email" placeholder="E-Mail-Adresse" class="formText">
                        </div>
                    </div>
                    <div class="box">
                        <div class="formItem">
                            <label for="voucher">iONA-Code</label>
                            <input type="text" #voucher id="voucher" placeholder="iONA-Code" class="formText">
                        </div>
                    </div>
                </div>
                <div class="grid two clearfix">
                    <div class="box">
                        <div class="formItem">
                            <a href="#/login" title="Abbrechen" class="btn outline">Abbrechen</a>
                        </div>
                    </div>
                    <div class="box">
                        <div class="formItem">
                            <button type="submit" class="formSubmit">Weiter</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>


        <!--
           * SET PASSWORD
         -->
        <div *ngIf="currentStep === onboardingSteps.passwordEntry" class="form-content">
            <h2>Wählen Sie ein Passwort und bestätigen Sie es.</h2>
            <p></p>
            <form (submit)="registerUser(password.value, confirm.value); false">
                <div class="grid two clearfix">
                    <div class="box">
                        <div class="formItem">
                            <label for="password">Passwort eingeben</label>
                            <input type="password" #password id="password" placeholder="Passwort eingeben"
                                   class="formText">
                        </div>
                    </div>
                    <div class="box">
                        <div class="formItem">
                            <label for="confirm">Passwort wiederholen</label>
                            <input type="password" #confirm id="confirm" placeholder="Passwort wiederholen"
                                   class="formText">
                        </div>
                    </div>
                </div>
                <button type="submit" class="formSubmit">Weiter</button>
            </form>
        </div>


        <!--
           * SELECT DEVICE
         -->
        <div *ngIf="currentStep === onboardingSteps.deviceSelection" class="form-content">
            <h2>Bitte wählen Sie ein Gerät</h2>
            <p></p>
            <form (submit)="false">
                <div fxLayout="row">
                    <div fxFlex="50" fxLayout="column">
                        <div class="center-contents">
                            <img src="assets/images/box.png" height="250px"
                                 (click)="selectDevice('box')">
                        </div>
                        <button title="iONA Box" class="formSubmit"
                                (click)="selectDevice('box')">
                            iONA Box
                        </button>
                    </div>

                    <div fxFlex="50" fxLayout="column">
                        <div class="center-contents">
                            <img src="assets/images/plug.png" height="250px"
                                 (click)="selectDevice('plug')">
                        </div>
                        <button title="PowerChecker" class="formSubmit"
                                (click)="selectDevice('plug')">
                            PowerChecker
                        </button>
                    </div>

                </div>
            </form>
        </div>


        <!--        &lt;!&ndash; Dieser Step wird aktuell nicht angezeigt/übersprungen &ndash;&gt;-->
        <!--        <div *ngIf="step === 'I'" class="form-content">-->
        <!--            <h2>Wie möchten Sie Ihre iONA-Box mit dem Internet verbinden?</h2>-->
        <!--            <div class="msg-wrap hidden">-->
        <!--                <div class="msg info icon-info">-->
        <!--                    <button type="button" class="icon-close">-->
        <!--                        <span>Schließen</span>-->
        <!--                    </button>-->
        <!--                    <h3>Informationen</h3>-->
        <!--                    <p>Informationen zum aktuellen Schritt</p>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--            <div class="grid two clearfix">-->
        <!--                <div class="box">-->
        <!--                    <div class="formItem">-->
        <!--                        <a href="javascript:;" (click)="setStep(onboardingSteps.devices.box.powerConnect)" title="Netzwerkkabel"-->
        <!--                           class="btn">Netzwerkkabel</a>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--                &lt;!&ndash; 23.10.18 Verbindung mit WPS temporär entfernen-->
        <!--                <div class="box">-->
        <!--                    <div class="formItem">-->
        <!--                        <a href="javascript:;" (click)="setStep('W')" title="WLAN" class="btn">WLAN</a>-->
        <!--                    </div>-->
        <!--                </div>&ndash;&gt;-->
        <!--            </div>-->
        <!--            <div class="helpbtn-wrap">-->
        <!--                <a href="javascript:;" angulartics2On="click" angularticsCategory="Onboarding"-->
        <!--                   angularticsAction="onboarding_register" title="Hilfe" class="helpme">Hilfe</a>-->
        <!--            </div>-->
        <!--        </div>-->

        <!--
           * CONNECT BOX POWER VIDEO
        -->
        <div *ngIf="currentStep === onboardingSteps.devices.box.powerConnect" class="form-content">
            <h2>Verbinden Sie die iONA-Box mit der Steckdose.</h2>
            <div class="msg-wrap hidden">
                <div class="msg info icon-info">
                    <button type="button" class="icon-close">
                        <span>Schließen</span>
                    </button>
                    <h3>Informationen</h3>
                    <p>Informationen zum aktuellen Schritt</p>
                </div>
            </div>
            <figure>
                <video width="100%" height="100%" autoplay muted loop>
                    <source src="assets/media/power.mp4" type="video/mp4">
                </video>
            </figure>
            <div class="btn-wrap">
                <a href="javascript:;" (click)="setStep(onboardingSteps.devices.box.lanConnect)" title="Weiter"
                   class="btn">Weiter</a>
            </div>
            <div class="helpbtn-wrap">
                <a href="javascript:;" angulartics2On="click" angularticsCategory="Onboarding"
                   angularticsAction="onboarding_register" title="Hilfe" class="helpme">Hilfe</a>
            </div>
        </div>

        <!--
           * CONNECT BOX LAN CABLE
        -->
        <div *ngIf="currentStep === onboardingSteps.devices.box.lanConnect" class="form-content">
            <h2>Verbinden Sie die iONA-Box über das mitgelieferte Netzwerkkabel mit Ihrem Router.</h2>
            <div class="msg-wrap hidden">
                <div class="msg info icon-info">
                    <button type="button" class="icon-close">
                        <span>Schließen</span>
                    </button>
                    <h3>Informationen</h3>
                    <p>Informationen zum aktuellen Schritt</p>
                </div>
            </div>
            <figure>
                <video width="100%" height="100%" autoplay muted loop>
                    <source src="assets/media/lan.mp4" type="video/mp4">
                </video>
            </figure>
            <div class="btn-wrap">
                <a href="javascript:;" (click)="setStep(onboardingSteps.devices.box.macEntry)" title="Weiter"
                   class="btn">Weiter</a>
            </div>
            <div class="helpbtn-wrap">
                <a href="javascript:;" angulartics2On="click" angularticsCategory="Onboarding"
                   angularticsAction="onboarding_register" title="Hilfe" class="helpme">Hilfe</a>
            </div>
        </div>

        <!--
           * Note Mac address
        -->
        <div *ngIf="currentStep === onboardingSteps.devices.plug.noteMac" class="form-content">
            <h2>Bitte notieren Sie sich die MAC-Adresse von der Rückseite Ihres PowerCheckers. </h2>
            <figure class="center-contents pc-mac-location-image">
                <img clas src="assets/images/powerchecker-mac-location.png" alt="PowerChecker Mac-Adresse">
            </figure>
            <div class="btn-wrap">
                <a href="javascript:;" (click)="setStep(onboardingSteps.devices.plug.powerConnect)" title="Weiter"
                   class="btn">Weiter</a>
            </div>
        </div>

        <!--
           * CONNECT POWERCHECKER TO WALL PLUG
        -->
        <div *ngIf="currentStep === onboardingSteps.devices.plug.powerConnect" class="form-content">
            <h2>Verbinden Sie den PowerChecker mit der Steckdose.</h2>
            <div class="msg-wrap hidden">
                <div class="msg info icon-info">
                    <button type="button" class="icon-close">
                        <span>Schließen</span>
                    </button>
                    <h3>Informationen</h3>
                    <p>Informationen zum aktuellen Schritt</p>
                </div>
            </div>
            <figure>
                <video width="100%" height="100%" autoplay muted loop>
                    <source src="assets/media/powerchecker-connect.mp4" type="video/mp4">
                </video>
            </figure>
            <div class="btn-wrap">
                <a href="javascript:;" (click)="setStep(onboardingSteps.devices.plug.appSelect)" title="Weiter"
                   class="btn">Weiter</a>
            </div>
            <div class="helpbtn-wrap">
                <a href="javascript:;" angulartics2On="click" angularticsCategory="Onboarding"
                   angularticsAction="onboarding_register" title="Hilfe" class="helpme">Hilfe</a>
            </div>
        </div>

        <!--
           * CONNECT POWERCHECKER TO WALL PLUG
        -->
        <div *ngIf="currentStep === onboardingSteps.devices.plug.appSelect" class="form-content">
            <h2>Der PowerChecker muss nun mit Ihrem WLAN verbunden werden. Einfach und unkompliziert funktioniert dies
                über WPS.</h2>

            <div class="button-container center-contents">
                <a href="javascript:;" (click)="setStep(onboardingSteps.devices.plug.wpsConnect)" title="Weiter"
                   class="btn">Weiter</a>
            </div>

            <p class="info-text">
                Sollte Ihr Router kein WPS unterstützen, so verwenden Sie bitte die iONA-App auf dem Smartphone/Tablet.
                Nähere Informationen zu WPS finden Sie in der Bedienungsanleitung Ihres Routers.
            </p>
            <div fxLayout="row" fxLayoutAlign="center center" class="store-links m-b-l">
                <a target="_blank" class="center-contents"
                   href='https://play.google.com/store/apps/details?id=com.iona_energy.android&hl=en'>
                    <img class="p-r-l" alt='Jetzt bei Google Play' src='assets/images/badges/googleplay.png'/>
                </a>
                <a target="_blank" class="center-contents"
                   href="https://apps.apple.com/de/app/iona/id1434046824">
                    <img class="p-l-l" src="assets/images/badges/appstore.png" alt="Laden im AppStore">
                </a>
            </div>

        </div>

        <!--
           * CONNECT POWERCHECKER TO WALL PLUG
         -->
        <div *ngIf="currentStep === onboardingSteps.devices.plug.wpsConnect" class="form-content">
            <h2>Drücken Sie den Ein-/Aus-Schalter des PowerCheckers ca. 10 Sekunden bis dieser schnell grün blinkt.</h2>
            <div class="msg-wrap hidden">
                <div class="msg info icon-info">
                    <button type="button" class="icon-close">
                        <span>Schließen</span>
                    </button>
                    <h3>Informationen</h3>
                    <p>Informationen zum aktuellen Schritt</p>
                </div>
            </div>
            <figure>
                <video width="100%" height="100%" autoplay muted loop>
                    <source src="assets/media/powerchecker-2.mp4" type="video/mp4">
                </video>
            </figure>
            <div class="btn-wrap">
                <a href="javascript:;" (click)="setStep(onboardingSteps.devices.plug.wpsRouter)" title="Weiter"
                   class="btn">Weiter</a>
            </div>
            <div class="helpbtn-wrap">
                <a href="javascript:;" angulartics2On="click" angularticsCategory="Onboarding"
                   angularticsAction="onboarding_register" title="Hilfe" class="helpme">Hilfe</a>
            </div>
        </div>

        <!--
           * PRESS WPS BUTTON ON ROUTER
         -->
        <div *ngIf="currentStep === onboardingSteps.devices.plug.wpsRouter" class="form-content">
            <h2>Aktivieren Sie jetzt innerhalb von 2 Minuten die WPS-Funktion an Ihrem Router.</h2>
            <div class="msg-wrap hidden">
                <div class="msg info icon-info">
                    <button type="button" class="icon-close">
                        <span>Schließen</span>
                    </button>
                    <h3>Informationen</h3>
                    <p>Informationen zum aktuellen Schritt</p>
                </div>
            </div>
            <p>
                In der Regel erfolgt dies durch längeres Drücken eines entsprechend bezeichneten Knopfes. Nähere
                Informationen zu WPS finden Sie in der Bedienungsanleitung Ihres Routers.
            </p>
            <figure>
                <video width="100%" height="100%" autoplay muted loop>
                    <source src="assets/media/wlan.mp4" type="video/mp4">
                </video>
            </figure>
            <div class="btn-wrap">
                <a href="javascript:;" (click)="setStep(onboardingSteps.devices.plug.wifiConnected)" title="Weiter"
                   class="btn">Weiter</a>
            </div>
            <div class="helpbtn-wrap">
                <a href="javascript:;" angulartics2On="click" angularticsCategory="Onboarding"
                   angularticsAction="onboarding_register" title="Hilfe" class="helpme">Hilfe</a>
            </div>
        </div>

        <!--
           * PRESS WPS BUTTON ON ROUTER
         -->
        <div *ngIf="currentStep === onboardingSteps.devices.plug.wifiConnected" class="form-content">
            <h2>Bitte warten Sie, bis der PowerChecker konstant blau leuchtet. Dies kann bis zu 3 Minuten dauern.</h2>
            <div class="msg-wrap hidden">
                <div class="msg info icon-info">
                    <button type="button" class="icon-close">
                        <span>Schließen</span>
                    </button>
                    <h3>Informationen</h3>
                    <p>Informationen zum aktuellen Schritt</p>
                </div>
            </div>
            <figure class="center-contents pc-final-step-image">
                <img src="assets/images/powerchecker-onboarding-final-state.png" alt="Blau leuchtender Powerchecker">
            </figure>
            <div class="btn-wrap">
                <a href="javascript:;" (click)="setStep(onboardingSteps.devices.plug.macEntry)" title="Weiter"
                   class="btn">Weiter</a>
            </div>
            <div class="helpbtn-wrap">
                <a href="javascript:;" angulartics2On="click" angularticsCategory="Onboarding"
                   angularticsAction="onboarding_register" title="Hilfe" class="helpme">Hilfe</a>
            </div>
        </div>

        <!--
           * BOX MAC ADRESS INPUT
          -->
        <div *ngIf="currentStep === onboardingSteps.devices.box.macEntry" class="form-content">
            <h2>Geben Sie die MAC-ID von der Rückseite Ihrer iONA-Box ein.</h2>
            <p>Damit verknüpfen wir Ihre iONA-Box mit ihrem Account.</p>
            <div class="msg-wrap hidden">
                <div class="msg info icon-info">
                    <button type="button" class="icon-close">
                        <span>Schließen</span>
                    </button>
                    <h3>Informationen</h3>
                    <p>Informationen zum aktuellen Schritt</p>
                </div>
            </div>
            <div class="formItem formCenter">
                <label for="mac">MAC-ID</label>
                <input #macInputField type="text" id="mac" class="formText"
                       [(ngModel)]="input.mac" [textMask]="{mask: format.mac}"
                       (focus)="onInputFocus($event)" (focusout)="onInputFocus($event)"
                       placeholder="{{macPlaceholder}}"
                       style="text-align: left;">
            </div>
            <div class="btn-wrap">
                <a href="javascript:;" (click)="setStep(onboardingSteps.devices.box.macEntry2)" title="Weiter"
                   class="btn">Weiter</a>
            </div>
            <div class="helpbtn-wrap">
                <a href="javascript:;" angulartics2On="click" angularticsCategory="Onboarding"
                   angularticsAction="onboarding_register" title="Hilfe" class="helpme">Hilfe</a>
            </div>
        </div>

        <div *ngIf="currentStep === onboardingSteps.devices.box.macEntry2" class="form-content">
            <h2> Bitte überprüfen Sie die eingegebene Adresse erneut. Stimmt Sie mit dem Aufkleber auf der iONA-Box
                überein? </h2>
            <div class="msg-wrap hidden">
                <div class="msg info icon-info">
                    <button type="button" class="icon-close">
                        <span>Schließen</span>
                    </button>
                    <h3>Informationen</h3>
                    <p>Informationen zum aktuellen Schritt</p>
                </div>
            </div>
            <div class="formItem formCenter">
                <label for="mac">MAC-ID</label>
                <input #macInputField type="text" id="mac" class="formText"
                       [(ngModel)]="input.mac" [textMask]="{mask: format.mac}"
                       (focus)="onInputFocus($event)" (focusout)="onInputFocus($event)"
                       placeholder="{{macPlaceholder}}"
                       style="text-align: left;">
            </div>
            <div class="btn-wrap">
                <a href="javascript:;" (click)="registerDevice(macInputField.value)" title="Weiter"
                   class="btn">Weiter</a>
            </div>
            <div class="helpbtn-wrap">
                <a href="javascript:;" angulartics2On="click" angularticsCategory="Onboarding"
                   angularticsAction="onboarding_register" title="Hilfe" class="helpme">Hilfe</a>
            </div>
        </div>

        <!--
           * POWERCHECKER MAC ADRESS INPUT
          -->
        <div *ngIf="currentStep === onboardingSteps.devices.plug.macEntry" class="form-content">
            <h2>Geben Sie die MAC-ID von der Rückseite Ihres PowerCheckers ein.</h2>
            <p>Damit verknüpfen wir Ihren PowerChecker mit ihrem Account.</p>
            <div class="msg-wrap hidden">
                <div class="msg info icon-info">
                    <button type="button" class="icon-close">
                        <span>Schließen</span>
                    </button>
                    <h3>Informationen</h3>
                    <p>Informationen zum aktuellen Schritt</p>
                </div>
            </div>
            <div class="formItem formCenter">
                <label for="mac">MAC-ID</label>
                <input type="text" id="mac" class="formText"
                       [(ngModel)]="input.mac" [textMask]="{mask: format.mac}"
                       (focus)="onInputFocus($event)" (focusout)="onInputFocus($event)"
                       placeholder="{{macPlaceholder}}"
                       style="text-align: left;">
            </div>
            <div class="btn-wrap">
                <a href="javascript:;" (click)="setStep(onboardingSteps.devices.plug.macEntry2)" title="Weiter"
                   class="btn">Weiter</a>
            </div>
            <div class="helpbtn-wrap">
                <a href="javascript:;" angulartics2On="click" angularticsCategory="Onboarding"
                   angularticsAction="onboarding_register" title="Hilfe" class="helpme">Hilfe</a>
            </div>
        </div>

        <div *ngIf="currentStep === onboardingSteps.devices.plug.macEntry2" class="form-content">
            <h2> Bitte überprüfen Sie die eingegebene Adresse erneut. Stimmt Sie mit dem Aufkleber auf dem PowerChecker
                überein? </h2>
            <p></p>
            <div class="msg-wrap hidden">
                <div class="msg info icon-info">
                    <button type="button" class="icon-close">
                        <span>Schließen</span>
                    </button>
                    <h3>Informationen</h3>
                    <p>Informationen zum aktuellen Schritt</p>
                </div>
            </div>
            <div class="formItem formCenter">
                <label for="mac">MAC-ID</label>
                <input #macInputField type="text" id="mac" class="formText"
                       [(ngModel)]="input.mac" [textMask]="{mask: format.mac}"
                       (focus)="onInputFocus($event)" (focusout)="onInputFocus($event)"
                       placeholder="{{macPlaceholder}}"
                       style="text-align: left;">
            </div>
            <div class="btn-wrap">
                <a href="javascript:;" (click)="registerDevice(macInputField.value)" title="Weiter"
                   class="btn">Weiter</a>
            </div>
            <div class="helpbtn-wrap">
                <a href="javascript:;" angulartics2On="click" angularticsCategory="Onboarding"
                   angularticsAction="onboarding_register" title="Hilfe" class="helpme">Hilfe</a>
            </div>
        </div>


        <!--
           * CONNECTION STATUS
         -->
        <div *ngIf="currentStep === onboardingSteps.devices.box.connecting" class="form-content meter-status-form">
            <h2> Wir verbinden jetzt Ihre iONA-Box mit Ihrem Smart Meter. </h2>
            <ng-lottie id="loading-smartmeter" height="108px" [options]="lottieConfig2"
                       (animationCreated)="handleAnimation($event)"></ng-lottie>
            <ul class="connect-progress">
                <li [ngClass]="{'finished': (meterStatus > 0), 'progress': (meterStatus <= 0)}">
                    <input type="checkbox" disabled="1" [checked]="meterStatus > 0" name="status-1" id="status-1"/>
                    <label for="status-1"><i></i> <span>iONA-Box vorbereiten</span></label>
                </li>
                <li [ngClass]="{'finished': (meterStatus > 1), 'progress': (meterStatus == 1)}">
                    <input type="checkbox" disabled="1" [checked]="meterStatus > 1" name="status-2" id="status-2"/>
                    <label for="status-2"><i></i> <span>Smart Meter suchen</span></label>
                </li>
                <li [ngClass]="{'finished': (meterStatus > 2), 'progress': (meterStatus == 2)}">
                    <input type="checkbox" disabled="1" [checked]="meterStatus > 2" name="status-3" id="status-3"/>
                    <label for="status-3"><i></i> <span>Mit Smart Meter verbinden</span></label>
                </li>
            </ul>
            <div class="msg-wrap hidden">
                <div class="msg info icon-info">
                    <button type="button" class="icon-close">
                        <span>Schließen</span>
                    </button>
                    <h3>Informationen</h3>
                    <p>Informationen zum aktuellen Schritt</p>
                </div>
            </div>
            <div class="btn-wrap">
                <a href="javascript:;" *ngIf="meterStatus > 0" (click)="setStep(onboardingSteps.accountSetup)"
                   title="Weiter"
                   class="btn">Weiter</a>
            </div>
            <div class="helpbtn-wrap">
                <a href="javascript:;"
                   title="Hilfe" class="helpme"
                   angulartics2On="click"
                   angularticsCategory="Onboarding"
                   angularticsAction="onboarding_register">Hilfe</a>
            </div>
        </div>

        <div *ngIf="currentStep === onboardingSteps.devices.plug.connecting" class="form-content meter-status-form">
            <h2> Wir verbinden jetzt Ihren PowerChecker mit Ihrem Smart Meter. </h2>
            <ng-lottie id="loading-smartmeter" height="108px" [options]="lottieConfig2"
                       (animationCreated)="handleAnimation($event)"></ng-lottie>
            <ul class="connect-progress">
                <li [ngClass]="{'finished': (meterStatus > 0), 'progress': (meterStatus <= 0)}">
                    <input type="checkbox" disabled="1" [checked]="meterStatus > 0" name="status-1" id="status-1"/>
                    <label for="status-1"><i></i> <span>PowerChecker vorbereiten</span></label>
                </li>
                <li [ngClass]="{'finished': (meterStatus > 1), 'progress': (meterStatus == 1)}">
                    <input type="checkbox" disabled="1" [checked]="meterStatus > 1" name="status-2" id="status-2"/>
                    <label for="status-2"><i></i> <span>Smart Meter suchen</span></label>
                </li>
                <li [ngClass]="{'finished': (meterStatus > 2), 'progress': (meterStatus == 2)}">
                    <input type="checkbox" disabled="1" [checked]="meterStatus > 2" name="status-3" id="status-3"/>
                    <label for="status-3"><i></i> <span>Mit Smart Meter verbinden</span></label>
                </li>
            </ul>
            <div class="msg-wrap hidden">
                <div class="msg info icon-info">
                    <button type="button" class="icon-close">
                        <span>Schließen</span>
                    </button>
                    <h3>Informationen</h3>
                    <p>Informationen zum aktuellen Schritt</p>
                </div>
            </div>
            <div class="btn-wrap">
                <a href="javascript:;" *ngIf="meterStatus > 0" (click)="setStep(onboardingSteps.accountSetup)"
                   title="Weiter"
                   class="btn">Weiter</a>
            </div>
            <div class="helpbtn-wrap">
                <a href="javascript:;" angulartics2On="click" angularticsCategory="Onboarding"
                   angularticsAction="onboarding_register" title="Hilfe" class="helpme">Hilfe</a>
            </div>
        </div>

        <!--
           * START USER PROFILE CREATION
         -->
        <div *ngIf="currentStep === onboardingSteps.accountSetup" class="form-content">
            <h2>Wir haben jetzt ein bisschen Zeit und können uns um Ihr Benutzerprofil kümmern.</h2>
            <div class="msg-wrap hidden">
                <div class="msg info icon-info">
                    <button type="button" class="icon-close">
                        <span>Schließen</span>
                    </button>
                    <h3>Informationen</h3>
                    <p>Informationen zum aktuellen Schritt</p>
                </div>
            </div>
            <div class="btn-wrap align-left">
                <a href="#/mein-haushalt" (click)="setIsOnboarding()" angulartics2On="click"
                   angularticsCategory="Onboarding" angularticsAction="onboarding_profile_start" title="Start"
                   class="btn">Start</a>

                <a href="#/" angulartics2On="click" angularticsCategory="Onboarding" (click)="setIsOnboarding()"
                   angularticsAction="onboarding_profile_cancel" title="Überspringen" class="skip">Überspringen</a>
            </div>
            <div class="helpbtn-wrap">
                <a href="javascript:;" angulartics2On="click" angularticsCategory="Onboarding"
                   angularticsAction="onboarding_register" title="Hilfe" class="helpme">Hilfe</a>
            </div>
        </div>

        <!-- Dieser Step wird aktuell nicht angezeigt/übersprungen -->
        <div *ngIf="currentStep === 'Z'" class="form-content">
            <h2>Verbindung zum Zähler wird hergestellt.</h2>
            <div class="msg-wrap hidden">
                <div class="msg info icon-info">
                    <button type="button" class="icon-close">
                        <span>Schließen</span>
                    </button>
                    <h3>Informationen</h3>
                    <p>Informationen zum aktuellen Schritt</p>
                </div>
            </div>
            <div class="connect-wrap">
                <img src="assets/images/connect.png" width="257" height="300" alt="Bitte warten ..."
                     title="Bitte warten ..."/>
                <p>Bitte warten ...</p>
            </div>
            <div class="helpbtn-wrap">
                <a href="javascript:;" angulartics2On="click" angularticsCategory="Onboarding"
                   angularticsAction="onboarding_register" title="Hilfe" class="helpme">Hilfe</a>
            </div>
        </div>

        <!-- Dieser Step wird aktuell nicht angezeigt/übersprungen -->
        <div *ngIf="currentStep === 'W'" class="form-content">
            <h2>Drücken Sie bitte die WPS-Taste an ihrem Router.</h2>
            <div class="msg-wrap hidden">
                <div class="msg info icon-info">
                    <button type="button" class="icon-close">
                        <span>Schließen</span>
                    </button>
                    <h3>Informationen</h3>
                    <p>Informationen zum aktuellen Schritt</p>
                </div>
            </div>
            <figure>
                <video width="100%" height="100%" autoplay muted loop>
                    <source src="assets/media/wlan.mp4" type="video/mp4">
                </video>
            </figure>
            <div class="btn-wrap">
                <a href="javascript:;" (click)="setStep('X')" title="Weiter" class="btn">Weiter</a>
            </div>
            <div class="helpbtn-wrap">
                <a href="javascript:;" angulartics2On="click" angularticsCategory="Onboarding"
                   angularticsAction="onboarding_register" title="Hilfe" class="helpme">Hilfe</a>
            </div>
        </div>

        <!-- Dieser Step wird aktuell nicht angezeigt/übersprungen -->
        <div *ngIf="currentStep === 'X'" class="form-content">
            <h2>Verbinden Sie danach die iONA-Box mit der Steckdose.</h2>
            <p>Ihre iONA-Box wird so automatisch mit Ihrem WLAN verbunden.</p>
            <div class="msg-wrap hidden">
                <div class="msg info icon-info">
                    <button type="button" class="icon-close">
                        <span>Schließen</span>
                    </button>
                    <h3>Informationen</h3>
                    <p>Informationen zum aktuellen Schritt</p>
                </div>
            </div>
            <figure>
                <video width="100%" height="100%" autoplay muted loop>
                    <source src="assets/media/power.mp4" type="video/mp4">
                </video>
            </figure>
            <div class="btn-wrap">
                <a href="javascript:;" (click)="setStep(onboardingSteps.devices.box.macEntry)" title="Weiter"
                   class="btn">Weiter</a>
            </div>
            <div class="helpbtn-wrap">
                <a href="javascript:;" angulartics2On="click" angularticsCategory="Onboarding"
                   angularticsAction="onboarding_register" title="Hilfe" class="helpme">Hilfe</a>
            </div>
        </div>

        <!--
           * COULD NOT CONNECT TO BOX
         -->
        <div *ngIf="currentStep === onboardingSteps.devices.box.connectionError" class="form-content">
            <h2>Leider konnte iONA die iONA-Box nicht finden.</h2>
            <p>Sie können die MAC-ID erneut eingeben oder erneut mit dem Router verbinden.</p>
            <div class="msg-wrap hidden">
                <div class="msg info icon-info">
                    <button type="button" class="icon-close">
                        <span>Schließen</span>
                    </button>
                    <h3>Informationen</h3>
                    <p>Informationen zum aktuellen Schritt</p>
                </div>
            </div>
            <div class="grid two clearfix">
                <div class="box">
                    <div class="formItem">
                        <a href="javascript:;" (click)="setStep(onboardingSteps.devices.box.macEntry)"
                           title="MAC-ID erneut eingeben" class="btn">MAC-ID
                            erneut eingeben</a>
                    </div>
                </div>
                <div class="box">
                    <div class="formItem">
                        <a href="javascript:;" (click)="setStep('W')" title="WPS erneut verbinden" class="btn">WPS
                            erneut verbinden</a>
                    </div>
                </div>
            </div>
            <div class="helpbtn-wrap">
                <a href="javascript:;" angulartics2On="click" angularticsCategory="Onboarding"
                   angularticsAction="onboarding_register" title="Hilfe" class="helpme">Hilfe</a>
            </div>
        </div>

        <div *ngIf="currentStep === onboardingSteps.devices.box.notFoundError" class="form-content">
            <h2>Leider konnte iONA die iONA-Box nicht finden.</h2>
            <p>Sie können die MAC-ID erneut eingeben. Andernfalls kontaktieren Sie bitte den Support.</p>
            <div class="msg-wrap hidden">
                <div class="msg info icon-info">
                    <button type="button" class="icon-close">
                        <span>Schließen</span>
                    </button>
                    <h3>Informationen</h3>
                    <p>Informationen zum aktuellen Schritt</p>
                </div>
            </div>
            <div class="grid two clearfix">
                <div class="box">
                    <div class="formItem">
                        <a href="javascript:;" (click)="setStep(onboardingSteps.devices.box.macEntry)"
                           title="MAC-ID erneut eingeben" class="btn">MAC-ID
                            erneut eingeben</a>
                    </div>
                </div>
                <div class="box">
                    <div class="formItem">
                        <a href="javascript:;" (click)="setStep('P')" title="Support anfordern" class="btn">Support
                            anfordern</a>
                    </div>
                </div>
            </div>
            <div class="helpbtn-wrap">
                <a href="javascript:;" angulartics2On="click" angularticsCategory="Onboarding"
                   angularticsAction="onboarding_register" title="Hilfe" class="helpme">Hilfe</a>
            </div>
        </div>

        <div *ngIf="currentStep === 'Ü'" class="form-content">
            <h2>Leider konnte iONA die iONA-Box nicht finden.</h2>
            <p>Versuchen Sie, die Internetverbindung mit dem Netzwerkkabel herzustellen.</p>
            <div class="msg-wrap hidden">
                <div class="msg info icon-info">
                    <button type="button" class="icon-close">
                        <span>Schließen</span>
                    </button>
                    <h3>Informationen</h3>
                    <p>Informationen zum aktuellen Schritt</p>
                </div>
            </div>
            <div class="btn-wrap">
                <a href="javascript:;" (click)="setStep(onboardingSteps.devices.box.powerConnect)" title="LAN starten"
                   class="btn">LAN starten</a>
            </div>
            <div class="helpbtn-wrap">
                <a href="javascript:;" angulartics2On="click" angularticsCategory="Onboarding"
                   angularticsAction="onboarding_register" title="Hilfe" class="helpme">Hilfe</a>
            </div>
        </div>

        <div *ngIf="currentStep === 'P'" class="form-content">
            <h2>Leider konnte iONA die iONA-Box nicht finden.</h2>
            <p>Bitte kontaktieren Sie den Support.</p>
            <div class="msg-wrap hidden">
                <div class="msg info icon-info">
                    <button type="button" class="icon-close">
                        <span>Schließen</span>
                    </button>
                    <h3>Informationen</h3>
                    <p>Informationen zum aktuellen Schritt</p>
                </div>
            </div>
            <div class="hotline-msg">
                <div class="number">
                    <i class="icon-hotline"></i>
                    <div class="call">
                        Kostenlose Hotline<br>
                        0800 - 1234060
                    </div>
                </div>
                <p>Unser Support-Team hilft Ihnen gern mit der Einrichtung Ihrer iONA-Box.</p>
            </div>
            <div class="helpbtn-wrap">
                <a href="javascript:;" angulartics2On="click" angularticsCategory="Onboarding"
                   angularticsAction="onboarding_register" title="Hilfe" class="helpme">Hilfe</a>
            </div>
        </div>

        <div *ngIf="currentStep === 'S'" class="form-content">
            <h2>{{statusError.title}}</h2>
            <p>{{statusError.text}}</p>
            <div class="hotline-msg">
                <div class="number">
                    <i class="icon-hotline"></i>
                    <div class="call">
                        Kostenlose Hotline<br>
                        0800 - 1234060
                    </div>
                </div>
                <p>Unser Support-Team hilft Ihnen gern mit der Einrichtung Ihrer iONA-Box.</p>
            </div>

            <div class="btn-wrap">
                <a href="javascript:;" (click)="retryMeterStatus()" title="Weiter Versuchen" class="btn">Weiter
                    Versuchen</a>
            </div>
        </div>

    </fieldset>
    <div class="bg">
        <video width="100%" height="100%" autoplay muted loop>
            <source src="assets/media/background.mp4" type="video/mp4">
        </video>
    </div>
</div>
