import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class OverlayService {
    constructor() {
        this.onShow = new Subject();
        this.onInit = new Subject();
        this.onConfirm = new Subject();
        this.closeOverlaySub = new Subject();
    }
    initialize(config) {
        this.onInit.next(config);
    }
    showOverlay(show) {
        this.onShow.next(show);
    }
    closeOverlay() {
        this.closeOverlaySub.next();
    }
}
OverlayService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OverlayService_Factory() { return new OverlayService(); }, token: OverlayService, providedIn: "root" });
