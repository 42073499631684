import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';

import {ToastrService} from 'ngx-toastr';
import {Angulartics2} from 'angulartics2';

import {ApiService} from '../services/api.service';
import {Globals} from '../services/globals.service';

@Component({
    selector: 'iona-app',
    templateUrl: './forgot.component.html',
    viewProviders: [ApiService],
    providers: [Globals]
})

export class ForgotComponent implements OnInit {
    resetSuccess = false;
    emailVal: string = '';

    constructor(private _titleService: Title,
                private _router: Router,
                private _notification: ToastrService,
                private _analytics: Angulartics2,
                public _apiService: ApiService,
                private _globals: Globals) {
    }

    ngOnInit() {
        this._titleService.setTitle('Passwort vergessen | iONA');

        // Wurde eine Mail vom Login gesetzt? --> Input vorausfüllen
        if(this._globals.getForgotMail() !== null && this._globals.getForgotMail().length > 0){
            this.emailVal = this._globals.getForgotMail();
            this._globals.resetForgotMail();
        }
    }

    resetPassword(email: string) {
        // Email validieren
        let emailPattern: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(email == null || !emailPattern.test(email)){
            this._notification.error('E-Mail-Adresse fehlerhaft.');
            return null;
        }

        this._apiService.resetPassword(email).subscribe(
            (response: any) => {
                this._notification.success('Passwort erfolgreich zurückgesetzt.');
                this.resetSuccess = true;
            },
            (response: any) => {
                this._notification.error('Passwort konnte nicht zurück gesetzt werden.');
            }
        );
    }
}
