import {Component, OnInit, OnDestroy} from '@angular/core';

import {ApiService} from '../../services/api.service';
import {Globals} from '../../services/globals.service';
import {MockDataService} from '../../mock-data.service';
import {UserService} from '../../user.service';
import {PowercheckerService} from '../../powerchecker.service';

@Component({
    selector: 'powerchecker-widget',
    templateUrl: './widget.powerchecker.html',
    styleUrls: ['./powerchecker.component.scss'],
    providers: [Globals]
})

export class PowerCheckerWidget implements OnInit, OnDestroy {

    plugAttributes = {
        name: '',
        room: ''
    };

    plugName = 'Mein Gerät';
    plugRoom = 'Mein Zimmer';

    state = false;

    subscriptions = [];

    constructor(private _apiService: ApiService,
                private _globals: Globals,
                private _mockData: MockDataService,
                private _userService: UserService,
                private _pcService: PowercheckerService) {
    }

    ngOnInit() {
        // this.initPlugAttributes();
        this.getPlugAttributes();

        // subscribe to external changes
        this._userService.plugAttributesChanged.subscribe(
            (values: any) => {
                this.plugAttributes = values;
                this.plugName = this.plugAttributes.name;
                this.plugRoom = this.plugAttributes.room;
            }
        );

        this._pcService.stateChange.subscribe(
            (value) => {
                this.state = value;
            }
        );

        if (this._apiService.isDemoMode()) {
            this._mockData.getPlugStatus().subscribe(
                (response: any) => {
                    this.state = response.data.on_off === 1;
                }
            );
            return;
        }

        console.log('get plug state');
        const sub = this._apiService.getPlugStatus().subscribe(
            (response) => {
                if ('data' in response) {
                    if ('relay' in response.data) {
                        if ('on_off' in response.data.relay) {
                            const value = response.data.relay.on_off;
                            this.state = value === 1;
                        }
                    }
                }
            }
        );
        this.subscriptions.push(sub);

    }

    ngOnDestroy() {
    }


    /**
     * Initialize the plugs attributes
     */
    private initPlugAttributes(): void {
        this.plugAttributes = this._userService.getPlugAttributes();
        if (this.plugAttributes === null || this.plugAttributes === undefined) {
            this.getPlugAttributes();
            return;
        }

        this.plugName = this.plugAttributes.name;
        this.plugRoom = this.plugAttributes.room;
    }

    private getPlugAttributes(): void {
        if (!this._apiService.isDemoMode()) {
            const sub = this._apiService.getStoredUserSettings().subscribe(
                (response) => {
                    if ('data' in response) {
                        const obj = JSON.parse(response.data);
                        this.plugAttributes = obj.power_checker;

                        if (this.plugAttributes.name !== '') {
                            this.plugName = this.plugAttributes.name;
                        }
                        if (this.plugAttributes.room !== '') {
                            this.plugRoom = this.plugAttributes.room;
                        }

                        this._userService.updatePlugAttributes(obj);
                    }
                }
            );
            this.subscriptions.push(sub);
        }
    }

    onStateChange(state: boolean): void {
        this.state = state;
        this._pcService.stateChange.next(state);
        if (!this._apiService.isDemoMode()) {
            this._apiService.setPlugStatus(state ? 1 : 0).subscribe(
                (response) => {
                    if ('data' in response) {
                        if ('relay' in response.data) {
                            if ('on_off' in response.data) {
                                const value = response.data.relay.on_off;
                                this.state = value === 1;
                            }
                        }
                    }
                },
                (error) => {
                    console.log('powerchecker widget', error);
                }
            );
        }
    }

    firstDetailViewAnalytics() {
        if (!(this._globals.getFirstDetailsViewed())) {
            // Erstes aufrufen eines Detail Screens
            this._apiService._analytics.eventTrack.next({
                action: 'first_detail_view',
                properties: {
                    category: 'Screens',
                    label: 'Screen: Powerchecker-Details'
                }
            });
        }

        this._globals.setFirstDetailsViews();
    }
}
