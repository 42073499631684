<div #container class="container">

    <ng-container *ngIf="invert_colors; else inverted">
        <div class="indicator inverted"
             *ngFor="let item of config.ranges; let i = index"
             [ngClass]="{'active-inv': determineBarActive(item)}"
             heightControl="{{(i + 1) * (100 / config.ranges.length)}}">
        </div>
    </ng-container>

    <ng-template #inverted>
        <div class="indicator"
             *ngFor="let item of config.ranges; let i = index"
             [ngClass]="{'active': determineBarActive(item)}"
             heightControl="{{(i + 1) * (100 / config.ranges.length)}}">
        </div>
    </ng-template>
</div>
