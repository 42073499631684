<div class="sidebar-head">
    <div class="btn-wrao fltrt">
        <button type="button" angulartics2On="click" angularticsCategory="Detailansichten"
                angularticsAction="detail_info_text" angularticsLabel="Heute" class="icon-info">
            <span>Hilfe</span>
        </button>
        <button type="button" class="icon-close">
            <span>Schließen</span>
        </button>
    </div>
    <h2>Heute</h2>
    <p *ngIf="trend.percent > 0 && trend.percent < 9999"><strong>{{ trend.percent }}
        % {{ trend.direction > 0 ? 'mehr' : 'weniger' }} </strong> als letzten {{ week.dayname }}</p>
    <p *ngIf="trend.percent === 0"><strong>identisch</strong> zum letzten {{ week.dayname }}</p>
    <div class="todaystat-wrap">
        <div class="lft">
            <small>{{ week.date }}</small>
            <div class="price">{{ history.costs }}
                <small>&euro;</small>
            </div>
            <div class="khw">{{ history.kwh }}  kWh</div>
        </div>
        <div class="mid">
            <div class="pie-chart-wrap">
                <div class="pie-chart-left" [style.transform]="'scale(' + trend.scale.left + ')'"></div>
                <div class="pie-chart-right" [style.transform]="'scale(' + trend.scale.right + ')'"></div>
            </div>
        </div>
        <div class="rt">
            <small>Heute</small>
            <div class="price">{{ today.costs }}
                <small>&euro;</small>
            </div>
            <div class="khw">{{ today.kwh }} kWh</div>
        </div>
    </div>
</div>

<div class="myDevices-content">
    <div class="msg-wrap hidden">
        <div class="msg info icon-info">
            <button type="button" class="icon-close">
                <span>Schließen</span>
            </button>
            <h3>Heute: Was ist das?</h3>
            <p>iONA ist nicht von gestern und kennt den Stromverbrauch und die Kosten von heute. Sie sehen auf einen
                Blick, wie sich der Verbrauch im Tagesverlauf entwickelt hat. Und wie hoch war der Tagesverbrauch
                eigentlich letzte Woche? Bei Bedarf kann iONA diese Frage mit einem Vergleich dieser Zeiträume
                beantworten.</p>
        </div>
    </div>
    <div class="timeline-wrap">
        <h3>Heute in Stunden</h3>
        <div *ngFor="let con of today.hours" class="time-item">
            <div class="time">{{ con.hour }}:00 - {{ con.hour }}:59 Uhr</div>
            <div class="kwh">{{ con.kwh }} kWh</div>
            <div class="price">{{ con.costs }} &euro;</div>
        </div>
    </div>
</div>
