import {Component, OnInit, OnDestroy} from '@angular/core';

import {ApiService} from '../../services/api.service';
import {Globals} from '../../services/globals.service';
import {MockDataService} from '../../mock-data.service';
import {ConnectionIndicatorConfig} from '../../connection-indicator/connection-indicator.component';
import {meter_connection_config} from '../../constants';

@Component({
    selector: 'meter-widget',
    templateUrl: './widget.meter.html',
    styleUrls: ['./meter.component.scss'],
    providers: [Globals]
})

export class MeterWidget implements OnInit, OnDestroy {

    refresh: any = [];
    meter: any = {
        kwh: 0,
        id: null,
    };

    showDiagrams: boolean = true;

    connection_quality: number = 0;
    connection_config: ConnectionIndicatorConfig = meter_connection_config;

    meter_status: string = 'disconnected';
    meter_connected: boolean = false;

    constructor(private _apiService: ApiService,
                private _globals: Globals,
                private _mockData: MockDataService) {
    }

    ngOnInit() {
        /**
         * Daten von API holen
         */

        if (this._apiService.isDemoMode()) {
            this.getMockMeter();
            this.getMockMeterInfo();
            this.getMockConnectionData();
            return;
        }

        this.getMeter();
        this.getMeterinfo();
        this.getMeterStatus();


        /**
         * Daten regelmäßig aktualisieren
         */
        this.refresh = setInterval(() => {
            this.getMeter();
            this.getMeterStatus();
        }, 10000);
    }

    ngOnDestroy() {
        clearInterval(this.refresh);
    }

    /**
     * Meter-Nummer holen
     */
    getMeterinfo() {
        this._apiService.getInitialization().subscribe(
            (data: any) => {
                // console.log(data);
                if ('data' in data) {
                    if ('profile' in data.data) {
                        if ('meter_status_electricity' in data.data.profile) {
                            const status = data.data.profile.meter_status_electricity;
                            if (status === 0) {
                                this.meter_status = 'connected';
                            } else if (status === 1) {
                                this.meter_status = 'disconnected';
                            } else {
                                this.meter_status = 'pending';
                            }

                            // console.log('meter status: ', this.meter_status);
                            this.meter_connected = data.data.profile.meter_status_electricity === 0;
                        }
                        this.meter.id = this.formatSerialNumber(data.data.profile.meter_serial_number);
                    }
                }
            }
        );
    }

    /**
     * Get mock meter data
     */
    private getMockMeterInfo(): void {
        this._mockData.getInitialize().subscribe(
            (data) => {
                if ('data' in data) {
                    if ('profile' in data.data) {
                        if ('meter_status_electricity' in data.data.profile) {

                            const status = data.data.profile.meter_status_electricity;
                            // console.log('status from response: ', status);
                            if (status === 0) {
                                this.meter_status = 'connected';
                            } else if (status === 1) {
                                this.meter_status = 'disconnected';
                            } else {
                                this.meter_status = 'pending';
                            }

                            // console.log('meter status: ', this.meter_status);

                            this.meter_connected = data.data.profile.meter_status_electricity === 0;
                        }

                        let meterId = data.data.profile.meter_serial_number;
                        this.meter.id = this.formatSerialNumber(meterId);
                    }
                }
            }
        );
    }


    /**
     * Aktuellen Zählerstand holen
     */
    getMeter() {
        this._apiService.getCurrentConsumption().subscribe(
            (data: any) => {
                if ('data' in data) {
                    if ('electricity' in data.data) {
                        this.showDiagrams = true;
                        this.meter.kwh = Math.round(data.data.electricity.current_summation / 1000);
                    } else {
                        this.showDiagrams = false;
                    }
                } else {
                    this.showDiagrams = false;
                }
            },
            () => {
                this.showDiagrams = false;
            }
        );
    }


    /**
     * Get Mock meter status
     */
    getMockMeter(): void {
        this._mockData.getCurrentConsumption().subscribe(
            (data) => {
                if ('data' in data) {
                    if ('electricity' in data.data) {
                        this.showDiagrams = true;
                        this.meter.kwh = Math.round(data.data.electricity.current_summation / 1000);
                    } else {
                        this.showDiagrams = false;
                    }
                } else {
                    this.showDiagrams = false;
                }
            }
        );
    }

    /**
     * Get Meter Status
     */
    getMeterStatus(): void {
        this._apiService.getDeviceStatus().subscribe(
            (response: any) => {
                if ('data' in response) {
                    if ('meter_txrssi' in response.data) {
                        this.connection_quality = response.data.meter_txrssi;
                        return;
                    }
                }
                this.connection_quality = 0;
            }
        );
    }

    /**
     * Get Conneciton mock data
     */
    getMockConnectionData(): void {
        this._mockData.getMeterStatus().subscribe(
            (values) => {
                if ('data' in values) {
                    this.meter.connect = values.data.connected_with_meter.status;
                    this.connection_quality = values.data.meter_txrssi;
                }
            }
        );
    }


    /**
     * Analytics stuff
     */
    firstDetailViewAnalytics() {
        if (!(this._globals.getFirstDetailsViewed())) {
            // Erstes aufrufen eines Detail Screens
            this._apiService._analytics.eventTrack.next({
                action: 'first_detail_view',
                properties: {
                    category: 'Screens',
                    label: 'Screen: Meter-Details'
                }
            });
        }

        this._globals.setFirstDetailsViews();
    }

    /**
     * Format Meter-serial number
     * @param serialNumber
     */
    private formatSerialNumber(serialNumber: string) {
        let returnVal: string = '';

        if ((serialNumber !== null || serialNumber !== undefined) || serialNumber.length <= 1) {
            return serialNumber;
        }

        for (let i = 0; i < serialNumber.length; i++) {
            if (i == 1 || i == 4 || i == 6 || i == 10) {
                returnVal += ' ';
            }

            returnVal += serialNumber.charAt(i);
        }

        return returnVal;
    }

    determineMeterStatusMessage(): string {
        switch (this.meter_status) {
            case 'connected':
                return 'Verbunden';
            case 'disconnected':
                return 'Getrennt';
            case 'pending':
                return 'Noch nicht verbunden';

        }
    }
}
