<div id="container">
    <aside class="sidebar lft">
        <a href="#" id="hamburger" title="Menü" class="mobi">
            <span class="burger">
                <i class="top"></i>
                <i class="middle"></i>
                <i class="bottom"></i>
            </span>
            <span class="text"> Menü</span>
        </a>
        <div class="blackbox">
            <div class="version">
                <a href="javascript:;" (click)="showChangelog()">{{ version }}</a>
            </div>
            <div [class.demo]="username === 'Demo'" class="logo">
                <a href="#/" title="iONA">
                    <span>iONA</span>
                </a>
            </div>
        </div>
        <div class="block block-menu">
            <nav class="inner">
                <ul class="clearfix">
                    <li><a href="#/" title="Übersicht">Übersicht</a></li>
                    <li><a href="#/meine-daten" title="Meine Daten">Meine Daten</a></li>
                    <li><a href="#/mein-haushalt" title="Mein Haushalt">Mein Haushalt</a></li>
                    <li><a href="#/mein-kundenkonto" angulartics2On="click" angularticsCategory="Meine Daten"
                           angularticsAction="my_account_enter"
                           title="{{ providerMenuName }}">{{ providerMenuName }}</a></li>
                    <li><a href="#/einstellungen" title="Einstellungen">Einstellungen</a></li>
                    <li><a href="#/kontakt" title="Kontakt" class="active">Kontakt</a></li>
                    <li><a href="javascript:;"
                           title="{{ _apiService.isDemoMode() ? 'Demo-Modus verlassen' : 'Logout' }}"
                           class="open-logout">{{ _apiService.isDemoMode() ? 'Demo-Modus verlassen' : 'Logout' }}</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="block block-submenu">
            <div class="inner">
                <div *ngIf="providerclass?.length > 0" class="presentbar">
                    <small>Präsentiert von</small>
                    <div [class]="providerclass"></div>
                </div>
                <nav class="submenu">
                    <ul>
                        <li><a href="#/impressum" angulartics2On="click" angularticsCategory="Menü"
                               angularticsAction="view_imprint" title="Impressum">Impressum</a></li>
                        <li>
                            <a [href]="privacyLink" target="_blank"
                               angulartics2On="click" angularticsCategory="Menü" angularticsAction="view_privacy"
                               title="Datenschutz">Datenschutz</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </aside>
    <div id="inner">
        <main id="mainContent" class="clearfix">
            <div class="kontakt">
                <div class="head">
                    <h2>Kontakt</h2>
                </div>
                <div class="grid two clearfix">
                    <div class="box">
                        <div class="block block-content">
                            <div class="inner">
                                <h3>Direktkontakt</h3>
                                <p>
                                    <strong>E-Mail </strong> kundenservice@iona-energy.com<br>
                                    <strong>Telefon </strong> 0800-8888863<br>
                                    <strong>Telefax </strong> +49 201 12-20000
                                </p>
                                <a href="mailto:kundenservice@iona-energy.com" title="E-Mail senden" class="btn orange">E-Mail
                                    senden</a>
                            </div>
                        </div>
                    </div>
                    <div class="box">
                        <div class="block block-content">
                            <div class="inner">
                                <h3>Adresse</h3>
                                <address>
                                    <strong>innogy SE</strong><br>
                                    Opernplatz 1<br>
                                    45128 Essen
                                </address>
                                <a href="http://www.innogy.com" title="www.innogy.com"
                                   target="_blank">www.innogy.com</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>
<div *ngIf="logs" class="overlay-infos">
    <div class="overlay-box">
        <button type="button" (click)="hideChangelog()" class="icon-close fltrt">
            <span>Schließen</span>
        </button>
        <div *ngFor="let logs of changelog">
            <h4>{{ logs.version }}</h4>
            <ul *ngIf="logs.features.length > 0">
                <li class="head">Neue Funktionen:</li>
                <li *ngFor="let feature of logs.features">{{ feature.log }}</li>
            </ul>
            <ul *ngIf="logs.adjustments.length > 0">
                <li class="head">Anpassungen:</li>
                <li *ngFor="let adjustment of logs.adjustments">{{ adjustment.log }}</li>
            </ul>
            <ul *ngIf="logs.bugfixes.length > 0">
                <li class="head">Bugfixes:</li>
                <li *ngFor="let bugfix of logs.bugfixes">{{ bugfix.log }}</li>
            </ul>
        </div>
    </div>
</div>
<div id="overlay-logout" class="overlay-infos" style="display: none;">
    <div class="overlay-box">
        <button type="button" class="close-logout icon-close fltrt">
            <span>Schließen</span>
        </button>
        <h4>Abmelden</h4>
        <p>Wollen Sie sich wirklich abmelden?</p>
        <button type="button" (click)="_apiService.logoutUser()" class="btn orange">Ja</button>
        <button type="button" class="close-logout btn outline">Nein</button>
    </div>
</div>
