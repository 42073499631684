import {static_links} from './constants';

export const getProviderIcon = (provider: string): string => {
    if (provider === null || provider === undefined) {
        return null;
    }
    switch (provider.toLowerCase()) {
        case 'innogy':
            return 'logo-innogy';
        case 'enviam':
            return 'logo-envia';
        case 'eprimo':
            return 'logo-eprimo';
        case 'energis':
            return 'logo-energis';
        case 'lew':
            return 'logo-lew';
        case 'e.on':
            return 'logo-eon';
        default:
            return 'logo-innogy';
    }
};

export const getProviderIconClass = (provider: string): string => {
    if (provider === null || provider === undefined) {
        return null;
    }
    switch (provider.toLowerCase()) {
        case 'innogy':
            return 'innogy-logo';
        case 'enviam':
            return 'envia-logo';
        case 'eprimo':
            return 'eprimo-logo';
        case 'energis':
            return 'energis-logo';
        case 'lew':
            return 'lew-logo';
        case 'e.on':
            return 'eon-logo';
        default:
            return 'innogy-logo';
    }
};

export const getProviderSettingsLink = (provider: string): string => {
    if (provider === null || provider === undefined) {
        return null;
    }

    switch (provider.toLowerCase()) {
        case 'innogy':
            return static_links.settings.innogy;
        case 'enviam':
            return static_links.settings.enviam;
        case 'eprimo':
            return static_links.settings.eprimo;
        case 'energis':
            return static_links.settings.energis;
        case 'lew':
            return static_links.settings.lew;
        case 'e.on':
            return static_links.settings['e.on'];
        default:
            return static_links.settings.innogy;
    }
};

export const getProviderMenuName = (provider: string): string => {
    if (provider === null || provider === undefined) {
        return null;
    }

    switch (provider.toLowerCase()) {
        case 'innogy':
            return 'Meine innogy';
        case 'enviam':
            return 'Meine enviaM';
        case 'eprimo':
            return 'Mein eprimo';
        case 'energis':
            return 'Meine energis';
        case 'lew':
            return 'Meine LEW';
        case 'e.on':
            return 'Mein E.ON';
        default:
            return 'Meine innogy';
    }
};




