import {Component, OnInit, OnDestroy} from '@angular/core';

import {ApiService} from '../../services/api.service';
import {MockDataService} from '../../mock-data.service';

@Component({
    selector: 'today-details',
    templateUrl: './details.today.html',
    viewProviders: []
})

export class TodayDetails implements OnInit, OnDestroy {

    refresh: any = [];

    week: any = {
        dayname: null,
        date: null
    };

    today: any = {
        kwh: 0,
        costs: '0,00',
        hours: []
    };

    history: any = {
        kwh: 0,
        costs: '0,00'
    };

    trend: any = {
        direction: 0,
        percent: 0,
        scale: {
            left: 1,
            right: 1
        }
    };

    constructor(private _apiService: ApiService,
                private _mockData: MockDataService) {
    }

    ngOnInit() {
        this.week = {
            dayname: this._apiService.getDayname(7),
            date: this._apiService.getDate(7)
        };

        if (this._apiService.isDemoMode()) {
            this.initializeMockData();
            return;
        }

        this.initializeApiConnection();
    }

    ngOnDestroy() {
        clearInterval(this.refresh);
    }

    /**
     * Initializes connection to the mock API
     */
    private initializeMockData(): void {
        this.getMockConsumption();

        this.refresh = setInterval(() => {
            this.week = {
                dayname: this._apiService.getDayname(7),
                date: this._apiService.getDate(7)
            };
            this.getMockConsumption();
        }, 10000);
        return;

    }

    /**
     * Initializes connection to live API
     */
    private initializeApiConnection(): void {
        this.getConsumption();
        this.refresh = setInterval(() => {
            this.week = {
                dayname: this._apiService.getDayname(7),
                date: this._apiService.getDate(7)
            };

            this.getConsumption();
        }, 10000);
    }


    /**
     * Get live consumption-alert data
     */
    getConsumption() {
        this._apiService.getDayConsumption(0).subscribe(
            (data: any) => {
                if ('data' in data) {
                    let consumption: number = 0;
                    let costs: number = 0;

                    this.today.hours = [];

                    for (let con of data.data) {

                        if (('measured' in con) && ('cost_measured' in con)) {
                            consumption += con.measured;
                            costs += con.cost_measured;

                            this.today.hours.push({
                                hour: new Date(con.timestamp).getHours(),
                                kwh: (con.measured / 1000).toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
                                costs: con.cost_measured.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                            });
                        }

                    }

                    this.today.hours.reverse();

                    this.today.kwh = (consumption / 1000).toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2});
                    this.today.costs = costs.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2});

                    this.getTrend(consumption);
                }

            }
        );
    }

    /**
     * Get Mock consumption-alert data
     */
    private getMockConsumption(): void {
        this._mockData.getDayConsumption(0).subscribe(
            (data) => {
                if ('data' in data) {
                    // console.log('detail data', data);
                    let consumption: number = 0;
                    let costs: number = 0;

                    this.today.hours = [];

                    for (let con of data.data) {

                        if (('measured' in con) && ('cost_measured' in con)) {
                            consumption += con.measured;
                            costs += con.cost_measured;

                            this.today.hours.push({
                                hour: new Date(con.timestamp).getHours(),
                                kwh: (con.measured / 1000).toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
                                costs: con.cost_measured.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                            });

                        }

                    }

                    this.today.hours.reverse();

                    this.today.kwh = (consumption / 1000).toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2});
                    this.today.costs = costs.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2});

                    // this.getTrend(consumption-alert);
                    this.getMockTrend(consumption);
                }

            }
        );
    }

    /**
     * Get the current Trend based on last weeks data
     * @param consumption_today
     */
    private getTrend(consumption_today) {
        this._apiService.getDayConsumption(7).subscribe(
            (data: any) => {
                if ('data' in data) {
                    this.calculateTrend(data, consumption_today);
                }
            }
        );
    }

    /**
     * Get Mock trend
     */
    private getMockTrend(consumption_today): void {
        this._mockData.getDayConsumption(7).subscribe(
            (data) => {
                this.calculateTrend(data, consumption_today);
            }
        );
    }

    /**
     * Calculates the current trend based on todays summed consumption-alert and the collected dataset from a week ago
     * @param data
     * @param today
     */
    private calculateTrend(data, today): void {
        let consumption: number = 0;
        let costs: number = 0;
        let now: any = new Date();

        for (let con of data.data) {
            let date: any = new Date(con.timestamp);
            if (((now.getMinutes() <= 29) && (date.getHours() <= now.getHours())) || ((now.getMinutes() >= 30) && (date.getHours() <= (now.getHours() + 1)))) {
                if (('measured' in con) && ('cost_measured' in con)) {
                    consumption += con.measured;
                    costs += con.cost_measured;
                }
            }
        }

        this.history = {
            kwh: (consumption / 1000).toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
            costs: costs.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})
        };

        if (today > consumption) {
            let percent = Math.round(((today - consumption) / consumption) * 100);
            let scale = 0;

            if (percent > 80) {
                scale = 20;
            } else {
                scale = 100 - percent;
            }

            this.trend = {
                direction: 1,
                percent: percent,
                scale: {
                    left: scale / 100,
                    right: 1
                }
            };
        } else if (today < consumption) {
            let percent = Math.round((1 - (today / consumption)) * 100);
            let scale = 0;

            if (percent > 80) {
                scale = 20;
            } else {
                scale = 100 - percent;
            }

            this.trend = {
                direction: -1,
                percent: percent,
                scale: {
                    left: 1,
                    right: scale / 100
                }
            };
        } else {
            this.trend = {
                direction: 0,
                percent: 0,
                scale: {
                    left: 1,
                    right: 1
                }
            };
        }
    }

}
