import {Component, OnInit, OnDestroy} from '@angular/core';
import {Title} from '@angular/platform-browser';

import {ApiService} from '../services/api.service';
import {UserService} from '../user.service';
import {getProviderIcon, getProviderIconClass, getProviderMenuName} from '../ProivderUtil';

@Component({
    selector: 'iona-app',
    templateUrl: './privacy.component.html',
    viewProviders: [ApiService]
})

export class PrivacyComponent implements OnInit, OnDestroy {

    refresh: any = [];

    provider: number = 0;
    providername: string = '';
    providerclass: string = '';
    providerMenuName: string = null;
    username: string = null;

    version: string = '0.0.0';
    changelog: any = [];
    logs: boolean = false;

    constructor(private _titleService: Title,
                public _apiService: ApiService,
                private _userService: UserService) {
    }

    ngOnInit() {
        this._titleService.setTitle('Datenschutz | iONA');

        this.username = this._userService.getActiveUserName();
        this.providername = this._userService.getActiveUserProvider();
        this.providerclass = getProviderIconClass(this.providername);
        this.providerMenuName = getProviderMenuName(this.providername);

        this.getVersion();
        this.getChangelog();

        if (this._apiService.isDemoMode()) {
            return;
        }

        this._apiService.checkToken();
        this._apiService.checkMeterStatus();

        this.refresh = setInterval(() => {
            this._apiService.checkToken();
        }, 10000);

    }

    ngOnDestroy() {
        clearInterval(this.refresh);
    }

    getVersion() {
        this._apiService.getChangelog().subscribe(
            (data: any) => {
                if (0 in data) {
                    this.version = data[0].version;
                }
            }
        );
    }

    getChangelog() {
        this._apiService.getChangelog().subscribe(
            (data: any) => {
                this.changelog = data;
            }
        );
    }

    showChangelog() {
        this.logs = true;
    }

    hideChangelog() {
        this.logs = false;
    }

}
