<div class="sidebar-head counter-wrap">

    <div class="btn-wrap fltrt">
        <button type="button" angulartics2On="click" angularticsCategory="Detailansichten"
                angularticsAction="detail_info_text" angularticsLabel="Zählerstand" class="icon-info">
            <span>Hilfe</span>
        </button>
        <button type="button" class="icon-close">
            <span>Schließen</span>
        </button>
    </div>

    <h4>Zählerstand</h4>

    <div class="meter-wrap">
        <div class="kwh">{{ meter.kwh }}
            <small>kWh</small>
        </div>
        <div *ngIf="meter.id && meter.id.length > 0" class="meter-id">
            <div class="label m-r-s">Zählernummer</div>
            <strong>{{ meter.id }}</strong>
        </div>
    </div>

    <div class="connected">
        <div class="connection-container">
            <div class="p-r-s">
                <app-connection-indicator [value]="connection_quality"
                                          [disabled]="meter_status!== 'connected'"
                                          container_height="24px"
                                          [invert_colors]="true"
                                          [config]="connection_config">
                </app-connection-indicator>
            </div>
            <h4 class="connection-text">
                <!--                {{ meter_connected ? 'Verbunden' : 'Getrennt' }}-->
                {{determineMeterStatusMessage()}}
            </h4>
        </div>
    </div>

</div>

<div class="myDevices-content">

    <div class="msg-wrap hidden">
        <div class="msg info icon-info">
            <button class="icon-close" type="button">
                <span>Schließen</span>
            </button>
            <h3>Zählerstand: Was ist das?</h3>
            <p>Der Weg in den Keller bleibt Ihnen erspart: Rufen Sie jederzeit bequem Ihren aktuellen Zählerstand ab.
                Wetten, dass iONA sich an jeden Zählerstand erinnern kann? Probieren Sie es aus!</p>
        </div>
    </div>

    <div class="meter-content-wrap">
        <h4>Zählerstand zum Stichtag</h4>
        <p>Hier können Sie Ihren Zählerstand zum Ende eines bestimmten Stichtags abrufen. Einfach Wunschdatum eingeben
            und Zählerstand ablesen.</p>
        <fieldset class="date-select">
            <p>Ihr Zählerstand zum</p>
            <div class="grid three clearfix">
                <div class="box">
                    <label for="day">Tag</label>
                    <select id="day" (change)="setDate($event.target.value, 'day')" angulartics2On="change"
                            angularticsCategory="Detailansichten" angularticsAction="detail_meter_historic">
                        <option *ngFor="let day of loop(1, 31)" value="{{ day }}"
                                [selected]="search.date.day === day">{{ day }}</option>
                    </select>
                </div>
                <div class="box">
                    <label for="month">Monat</label>
                    <select id="month" (change)="setDate($event.target.value, 'month')" angulartics2On="change"
                            angularticsCategory="Detailansichten" angularticsAction="detail_meter_historic">
                        <option *ngFor="let month of _apiService.MONTHS; let i = index" value="{{ i + 1 }}"
                                [selected]="search.date.month === (i + 1)">{{ month }}</option>
                    </select>
                </div>
                <div class="box">
                    <label for="year">Jahr</label>
                    <select id="year" (change)="setDate($event.target.value, 'year')" angulartics2On="change"
                            angularticsCategory="Detailansichten" angularticsAction="detail_meter_historic">
                        <option *ngFor="let year of loop(year - 4, 5)" value="{{ year }}"
                                [selected]="search.date.year === year">{{ year }}</option>
                    </select>
                </div>
            </div>
        </fieldset>
        <div class="meter-result-wrap" *ngIf="search.kwh > 0">
            <div class="meter-result">{{ search.kwh }} <span>kWh</span></div>
        </div>
    </div>
</div>
