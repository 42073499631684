/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "angulartics2";
import * as i3 from "../services/globals.service";
import * as i4 from "./login.component";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../services/api.service";
import * as i7 from "@angular/router";
import * as i8 from "../mock-data.service";
import * as i9 from "ngx-toastr";
import * as i10 from "../user.service";
var styles_LoginComponent = [];
var RenderType_LoginComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoginComponent, data: {} });
export { RenderType_LoginComponent as RenderType_LoginComponent };
export function View_LoginComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 30, "div", [["id", "login"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 29, "fieldset", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 28, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 4).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("submit" === en)) {
        var pd_2 = (_co.login(i0.ɵnov(_v, 13).value, i0.ɵnov(_v, 17).value) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 16384, null, 0, i1.ɵangular_packages_forms_forms_z, [], null, null), i0.ɵdid(4, 4210688, null, 0, i1.NgForm, [[8, null], [8, null]], null, null), i0.ɵprd(2048, null, i1.ControlContainer, null, [i1.NgForm]), i0.ɵdid(6, 16384, null, 0, i1.NgControlStatusGroup, [[4, i1.ControlContainer]], null, null), (_l()(), i0.ɵeld(7, 0, null, null, 0, "div", [["class", "login-logo"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "div", [["class", "app-name"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["iONA"])), (_l()(), i0.ɵeld(10, 0, null, null, 3, "div", [["class", "formItem"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "label", [["for", "username"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["E-Mail-Adresse*"])), (_l()(), i0.ɵeld(13, 0, [["username", 1]], null, 0, "input", [["class", "formText"], ["id", "username"], ["placeholder", "E-Mail-Adresse"], ["type", "text"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 6, "div", [["class", "formItem"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 1, "label", [["for", "password"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Passwort*"])), (_l()(), i0.ɵeld(17, 0, [["password", 1]], null, 0, "input", [["class", "formText"], ["id", "password"], ["placeholder", "Passwort"], ["type", "password"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 2, "div", [["class", "forget-pw"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 1, "a", [["href", "#/passwort-vergessen"], ["title", "Passwort vergessen?"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setPwForgotMail(i0.ɵnov(_v, 13).value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Passwort vergessen?"])), (_l()(), i0.ɵeld(21, 0, null, null, 1, "button", [["class", "formSubmit"], ["type", "submit"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Anmelden"])), (_l()(), i0.ɵeld(23, 0, null, null, 4, "div", [["class", "register"]], null, null, null, null, null)), (_l()(), i0.ɵeld(24, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Sie haben noch keinen Account?"])), (_l()(), i0.ɵeld(26, 0, null, null, 1, "a", [["href", "#/registrieren"], ["title", "Jetzt registrieren"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Jetzt registrieren"])), (_l()(), i0.ɵeld(28, 0, null, null, 2, "div", [["angulartics2On", "click"], ["angularticsAction", "login_demo_mode_start"], ["angularticsCategory", "Login"], ["class", "demo"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.continueWithDemoMode() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(29, 1064960, null, 0, i2.Angulartics2On, [i0.ElementRef, i2.Angulartics2, i0.Renderer2], { angulartics2On: [0, "angulartics2On"], angularticsAction: [1, "angularticsAction"], angularticsCategory: [2, "angularticsCategory"] }, null), (_l()(), i0.ɵted(-1, null, ["Demo-Modus "])), (_l()(), i0.ɵeld(31, 0, null, null, 2, "div", [["class", "bg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(32, 0, [["videoElement", 1]], null, 1, "video", [["autoplay", ""], ["height", "100%"], ["loop", ""], ["muted", "muted"], ["width", "100%"]], null, [[null, "loadeddata"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("loadeddata" === en)) {
        var pd_0 = (_co.onBgVideoLoad($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(33, 0, null, null, 0, "source", [["src", "assets/media/background.mp4"], ["type", "video/mp4"]], null, null, null, null, null))], function (_ck, _v) { var currVal_7 = "click"; var currVal_8 = "login_demo_mode_start"; var currVal_9 = "Login"; _ck(_v, 29, 0, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 6).ngClassValid; var currVal_5 = i0.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 6).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_LoginComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "iona-app", [], null, null, null, View_LoginComponent_0, RenderType_LoginComponent)), i0.ɵprd(512, null, i3.Globals, i3.Globals, []), i0.ɵdid(2, 114688, null, 0, i4.LoginComponent, [i5.Title, i6.ApiService, i3.Globals, i7.Router, i8.MockDataService, i9.ToastrService, i10.UserService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var LoginComponentNgFactory = i0.ɵccf("iona-app", i4.LoginComponent, View_LoginComponent_Host_0, {}, {}, []);
export { LoginComponentNgFactory as LoginComponentNgFactory };
