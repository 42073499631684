<div class="block block-tile large">
    <div class="inner">

        <h3>Abschlags-Check</h3>
        <ng-container *ngIf="data_available; else subtitle_empty">
            <h4 *ngIf="finance.trend < 0">Etwa <strong>{{ finance.amount }} &euro;</strong> Gutschrift</h4>
            <h4 *ngIf="finance.trend === 0"><strong>Verbrauch passt genau</strong></h4>
            <h4 *ngIf="finance.trend > 0">Etwa <strong>{{ finance.amount }} &euro;</strong> Nachzahlung</h4>
        </ng-container>

        <ng-template #subtitle_empty>
            <h4>Daten werden noch gesammelt</h4>
        </ng-template>

        <ng-container *ngIf="data_available; else empty_state">
            <div #container class="invoice-status-wrap">
                <!--            <div #container class="container">-->
                <div class="invoice-status circle" [class.credit]="finance.trend < 0"
                     [class.optimal]="finance.trend === 0" [class.additional]="finance.trend > 0">

                    <i [class.icon-piggybank]="finance.trend < 0"
                       [class.icon-thumbs-up]="finance.trend === 0"
                       [class.icon-alert]="finance.trend > 0">
                    </i>

                    <div class="status-circle circle"
                         [style.width.%]="finance.trend < 0 ? finance.percent : 100"
                         [style.height.%]="finance.trend < 0 ? finance.percent : 100">
                    </div>

                    <div class="status-circle-outline circle"
                         [style.width.%]="finance.trend > 0 ? finance.percent : 100"
                         [style.height.%]="finance.trend > 0 ? finance.percent : 100">
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-template #empty_state>
            <div class="animation-container">
                <lottie-animation-view
                    [options]="lotti_config"
                    (animCreated)="animationCreated">
                </lottie-animation-view>
            </div>
        </ng-template>

        <a href="javascript:;" angulartics2On="click" angularticsCategory="Screens" angularticsAction="screen_view"
           angularticsLabel="screen: Abschlags-Check; previous_screen: Übersicht"
           (click)="firstDetailViewAnalytics()"
           title="Details anzeigen" class="finance-details icon-arrow-rt">
            <span>Details anzeigen</span>
        </a>

    </div>
</div>
