import * as $ from 'jquery';

import {NgModule} from '@angular/core';
import {LottieAnimationViewModule} from 'ng-lottie';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';

import {AppComponent} from './app.component';
import {routedComponents, AppRoutingModule} from './app-routing.module';

import {ChartModule} from 'angular-highcharts';

import {TextMaskModule} from 'angular2-text-mask';
import {SortablejsModule} from 'angular-sortablejs';
import {ToastrModule} from 'ngx-toastr';

import {Angulartics2, Angulartics2Module} from 'angulartics2';
import {Angulartics2GoogleAnalytics} from 'angulartics2/ga';

import {LiveWidget} from './charts/live/widget.live';
import {LiveDetails} from './charts/live/details.live';
import {ConsumerWidget} from './charts/consumer/widget.consumer';
import {ConsumerDetails} from './charts/consumer/details.consumer';
import {TodayWidget} from './charts/today/widget.today';
import {TodayDetails} from './charts/today/details.today';
import {CompareWidget} from './charts/compare/widget.compare';
import {CompareDetails} from './charts/compare/details.compare';
import {MeterWidget} from './charts/meter/widget.meter';
import {MeterDetails} from './charts/meter/details.meter';
import {FinanceWidget} from './charts/finance/widget.finance';
import {FinanceDetails} from './charts/finance/details.finance';
import {ConsumptionAlertWidget} from './charts/consumption-alert/widget.consumption';
import {ConsumptionDetails} from './charts/consumption-alert/details.consumption';
import {ApiService} from './services/api.service';
import {MockDataService} from './mock-data.service';

import {InlineSVGModule} from 'ng-inline-svg';
import {HttpClientModule} from '@angular/common/http';
import {ConnectionIndicatorComponent} from './connection-indicator/connection-indicator.component';
import {HeightControlDirective} from './height-control.directive';
import {OverlayComponent} from './overlay/overlay.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {OverlayService} from './overlay/overlay.service';
import {UserService} from './user.service';
import {PowerCheckerWidget} from './charts/power_checker/widget.powerchecker';
import {PowerCheckerDetails} from './charts/power_checker/details.powerchecker';
import {PowerSwitchComponent} from './charts/power_checker/power-switch/power-switch.component';
import {MovableDirective} from './movable.directive';
import {NguCarouselModule} from '@ngu/carousel';
import {NgxHmCarouselModule} from 'ngx-hm-carousel';
import {PowercheckerService} from './powerchecker.service';
import {RemainingTimeIndicatorComponent} from './remaining-time-indicator/remaining-time-indicator.component';
import {LottieModule} from 'ngx-lottie';
import player from 'lottie-web';

declare var require: any;

/**
 * Globale Highchart-Options
 */
const Highcharts = require('highcharts');
Highcharts.setOptions({
    global: {
        timezoneOffset: new Date().getTimezoneOffset()
    },
    lang: {
        thousandsSep: '.',
        decimalPoint: ',',
        loading: 'Auswertung wird geladen...',
        months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
        weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
        shortWeekdays: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam']
    }
});

export function playerFactory() {
    return player;
}

@NgModule({
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ChartModule,
        TextMaskModule,
        SortablejsModule.forRoot({
            animation: 150
        }),
        ToastrModule.forRoot({
            timeOut: 3000,
            maxOpened: 2,
            positionClass: 'toast-bottom-right'
        }),
        Angulartics2Module.forRoot([Angulartics2GoogleAnalytics], {
            ga: {
                anonymizeIp: true
            }
        }),
        LottieAnimationViewModule.forRoot(),
        HttpClientModule,
        InlineSVGModule.forRoot({baseUrl: 'assets/images/'}),
        FlexLayoutModule,
        NguCarouselModule,
        NgxHmCarouselModule,
        LottieModule.forRoot({player: playerFactory})
    ],
    declarations: [
        AppComponent,
        routedComponents,
        LiveWidget,
        LiveDetails,
        ConsumerWidget,
        ConsumerDetails,
        TodayWidget,
        TodayDetails,
        CompareWidget,
        CompareDetails,
        MeterWidget,
        MeterDetails,
        FinanceWidget,
        FinanceDetails,
        ConsumptionAlertWidget,
        ConsumptionDetails,
        ConnectionIndicatorComponent,
        HeightControlDirective,
        OverlayComponent,
        PowerCheckerWidget,
        PowerCheckerDetails,
        PowerSwitchComponent,
        MovableDirective,
        RemainingTimeIndicatorComponent,

    ],
    providers: [ApiService, MockDataService, OverlayService, UserService, PowercheckerService],
    entryComponents: [],
    bootstrap: [AppComponent]
})

export class AppModule {
}
