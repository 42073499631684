<div class="sidebar-head">
    <div class="btn-wrao fltrt">
        <button type="button" angulartics2On="click" angularticsCategory="Detailansichten"
                angularticsAction="detail_info_text" angularticsLabel="Meine Geräte" class="icon-info">
            <span>Hilfe</span>
        </button>
        <button type="button" class="icon-close">
            <span>Schließen</span>
        </button>
    </div>
    <h2>Meine Geräte</h2>
</div>

<div class="myDevices-content">

    <div class="msg-wrap hidden">
        <div class="msg info icon-info">
            <button type="button" class="icon-close">
                <span>Schließen</span>
            </button>
            <h3>Meine Geräte: Was ist das?</h3>
            <p>Gibt es einen Stromfresser in Ihrem Haushalt? Finden Sie es mit iONA heraus. Erkennen Sie, wie viel Ihre
                einzelnen Geräte verbrauchen und welche Kosten dabei entstehen. Wagen Sie einen Blick in die
                Vergangenheit mit der zweiten Detailansicht.</p>
        </div>
    </div>

    <div class="myDevices-options">
        <div class="view-options clearfix">
            <a href="javascript:;" (click)="setMode(1)" title="Monatsübersicht" [class.active]="mode === 1">Monatsübersicht</a>
            <a href="javascript:;" (click)="setMode(2)" title="Jahresübersicht" [class.active]="mode === 2">Jahresübersicht</a>
        </div>
    </div>

    <div class="myDevices-pager clearfix">
        <button type="button" (click)="positionBack()" [disabled]="disabled" title="Zurück" class="icon-back fltlft">
            <span>Zurück</span>
        </button>

        <button type="button" (click)="positionForward()" [disabled]="disabled || offset === 0" title="Weiter"
                class="icon-forward fltrt"><span>Weiter</span>
        </button>

        <div class="current-month">{{ date }}</div>
    </div>

    <!-- Appliance list-->
    <ng-container *ngIf="nilmdata !== null && nilmdata !== undefined">
        <div class="chart-wrap" [hidden]="!showConsumers">
            <div [chart]="chart" id="chart-consumer-details"></div>
            <div class="chart-stat">
                <div *ngFor="let con of consumer" class="chart-entry clearfix" [style.backgroundColor]="con.color">

                    <ng-container *ngIf="determineProfileCompleteness(con.name.toLowerCase()); else nilm">
                        <div [class]="con.name !== 'Other' ? ('ico icon-' + con.name|lowercase): 'ico'"></div>
                    </ng-container>

                    <ng-template #nilm>
                        <div [class]="con.name !== 'Other' ? ('ico icon-' + con.name|lowercase) + ' iic' : 'ico'">
                            <i class="boeppel-list"></i>
                        </div>
                    </ng-template>

                    <div class="name">{{ translate(con.name) }}</div>
                    <div class="wastage">{{ con.kwh }} kWh</div>
                    <div class="price">{{ con.cost }}</div>
                </div>
            </div>
        </div>
    </ng-container>

    <div class="chart-wrap" [hidden]="showConsumers">
        <div class="svg-wrap">
            <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg"
                 xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 264 246">
                <style type="text/css">
                    .st0 {
                        fill: #CC0099;
                        fill-opacity: 0.4;
                    }

                    .st1 {
                        fill: #CC0099;
                        fill-opacity: 0.3373;
                    }

                    .st2 {
                        fill: #CC0099;
                        fill-opacity: 0.5098;
                    }

                    .st3 {
                        fill: #CC0099;
                        fill-opacity: 0.3804;
                    }

                    .st4 {
                        fill: #CC0099;
                        fill-opacity: 0.4588;
                    }

                    .st5 {
                        fill: #CC0099;
                        fill-opacity: 0.5373;
                    }

                    .st6 {
                        fill: #CC0099;
                        fill-opacity: 0.3255;
                    }
                </style>
                <polygon class="st0" points="131.9,16.5 50.9,102.5 131.9,228.5 212.9,102.5 "/>
                <path class="st1" d="M27.3,112.7l53.4,8.7l48.1,73.8L27.3,112.7z"/>
                <path class="st2" d="M22.4,213.9l17.2-15.3l75.4,40.1L22.4,213.9z"/>
                <path class="st3" d="M75.5,32.3l17.6-17.7l31.9,90.8L75.5,32.3z"/>
                <path class="st4" d="M210.3,197l41.7,16.3l-107.9,17.2L210.3,197z"/>
                <path class="st5" d="M185.1,31L164.3,8.4L131.2,132L185.1,31z"/>
                <path class="st6" d="M226.3,94.4l-32.4-24.1l-35.7,103.1L226.3,94.4z"/>
            </svg>
            <strong>Nicht verfügbar</strong>
        </div>
    </div>
</div>
