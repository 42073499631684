import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PowercheckerService {

    public stateChange: Subject<boolean> = new Subject<boolean>();

    constructor() {
    }

}
