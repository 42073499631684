<div class="block block-tile large">
    <div class="inner">
        <h3>Verbrauchsalarm</h3>
        <!--        <h4 *ngIf="currentZone > 0">Der Vebrauchs-Alarm ist aktiviert</h4>-->
        <!--        <h4 *ngIf="currentZone < 1">Der Vebrauchs-Alarm ist deaktiviert</h4>-->

        <figure class="alert-wrap">
            <ng-container *ngIf="currentZone === ZONE_HYPERACTIVE; else alt">
                <img class="alert-image"
                     src="assets/images/consumption-alert-images/above/ic-abovevalue.png"
                     alt="Verbrauchs-Alarm aktiviert"
                     title="Verbrauchs-Alarm aktiviert"/>
            </ng-container>
            <ng-template #alt>
                <img class="alert-image"
                     src="assets/images/consumption-alert-images/below/ic-belowvalue.png"
                     alt="Verbrauchs-Alarm deaktiviert"
                     title="Verbrauchs-Alarm deaktiviert"/>
            </ng-template>
        </figure>


        <div class="consumption-alert-info m-t-s" *ngIf="showSinceInfo && showPowerInfo"
             fxLayout="row">
            <div class="since p-r-s">
                Maximum der letzten 7 Tage
                <!--                {{since}}-->
            </div>

            <div class="power">
                <!--                {{currentZone === ZONE_HYPERACTIVE ? 'über' : 'unter' }}-->
                <span class="bold">{{power}}</span>
            </div>
        </div>


        <a title="Details anzeigen" class="consumption-details icon-arrow-rt" href="javascript:;"
           angulartics2On="click"
           angularticsCategory="Screens"
           angularticsAction="screen_view"
           angularticsLabel="screen: Verbrauchs-Alarm; previous_screen: Übersicht"
           (click)="firstDetailViewAnalytics()">
            <span>Details anzeigen</span>
        </a>
    </div>
</div>
