import {Component, OnInit, OnDestroy} from '@angular/core';

import {ApiService} from '../../services/api.service';
import {Globals} from '../../services/globals.service';
import {MockDataService} from '../../mock-data.service';

@Component({
    selector: 'today-widget',
    templateUrl: './widget.today.html',
    viewProviders: [],
    providers: [Globals]
})

export class TodayWidget implements OnInit, OnDestroy {

    refresh: any = [];

    week: any = {
        dayname: null,
        date: null
    };

    today: any = {
        kwh: 0,
        costs: '0,00'
    };

    history: any = {
        kwh: 0,
        costs: '0,00'
    };

    trend: any = {
        direction: 0,
        percent: 0,
        scale: {
            left: 1,
            right: 1
        }
    };

    showDiagrams: boolean = true;

    constructor(private _apiService: ApiService,
                private _globals: Globals,
                private _mockData: MockDataService) {
    }

    ngOnInit() {
        this.week = {
            dayname: this._apiService.getDayname(7),
            date: this._apiService.getDate(7)
        };

        if (this._apiService.isDemoMode()) {
            this.initializeMockData();
            return;
        }

        this.initializeApiConnection();
    }

    ngOnDestroy() {
        clearInterval(this.refresh);
    }

    /**
     * Initializes the API / Mock data connections
     */
    private initializeMockData(): void {
        this.getMockConsumption();

        this.refresh = setInterval(() => {
            this.week = {
                dayname: this._apiService.getDayname(7),
                date: this._apiService.getDate(7)
            };
            this.getMockConsumption();
        }, 10000);
        return;
    }

    /**
     * Initializes connection to live API
     */
    private initializeApiConnection(): void {

        this.getConsumption();
        this.refresh = setInterval(() => {
            this.week = {
                dayname: this._apiService.getDayname(7),
                date: this._apiService.getDate(7)
            };

            this.getConsumption();
        }, 10000);

    }

    /**
     * Verbrauchs-Daten von API holen
     */
    getConsumption() {
        this._apiService.getConsumption(0).subscribe(
            (data: any) => {
                if ('data' in data) {
                    this.showDiagrams = true;

                    let costsFormated = '';
                    // if (typeof data.data[0].cost_measured != 'undefined') {
                    if (data.data[0].cost_measured !== null || data.data[0].cost_measured !== undefined) {
                        costsFormated = data.data[0].cost_measured.toLocaleString('de-DE', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        });
                    }

                    this.today = {
                        kwh: (data.data[0].measured / 1000).toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
                        costs: costsFormated
                    };


                    this.getTrend(data.data[0].measured);
                } else {
                    this.showDiagrams = false;
                }
            },
            () => {
                this.showDiagrams = false;
            }
        );
    }


    /**
     * Gather mock consumption-alert data
     */
    public getMockConsumption(): void {
        this._mockData.getDayConsumption(0).subscribe(
            (data) => {
                if ('data' in data) {
                    // console.log('detail data', data);
                    let consumption: number = 0;
                    let costs: number = 0;

                    this.today.hours = [];

                    for (let con of data.data) {

                        if (('measured' in con) && ('cost_measured' in con)) {
                            consumption += con.measured;
                            costs += con.cost_measured;

                            this.today.hours.push({
                                hour: new Date(con.timestamp).getHours(),
                                kwh: (con.measured / 1000).toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
                                costs: con.cost_measured.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                            });
                        }

                    }

                    this.today.hours.reverse();

                    this.today.kwh = (consumption / 1000).toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2});
                    this.today.costs = costs.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2});

                    // this.getTrend(consumption-alert);
                    this.getMockTrend(consumption);
                }
            }
        );
    }

    /**
     * Trend berechnen
     *
     * @param today
     */
    getTrend(today) {
        this._apiService.getDayConsumption(7).subscribe(
            (data: any) => {
                if ('data' in data) {
                    this.calculateTrend(data, today);
                }
            }
        );
    }

    /**
     * Calculate the current trend based on mock data
     * @param today
     */
    public getMockTrend(today): void {
        this._mockData.getDayConsumption(7).subscribe(
            (data) => {
                this.calculateTrend(data, today);
            }
        );
    }

    /**
     * Calculates the current trend based on todays summed consumption-alert and the collected dataset from a week ago
     * @param data
     * @param today
     */
    private calculateTrend(data, today): void {
        let consumption: number = 0;
        let costs: number = 0;
        let now: any = new Date();

        for (let con of data.data) {
            let date: any = new Date(con.timestamp);
            if (((now.getMinutes() <= 29) && (date.getHours() <= now.getHours())) || ((now.getMinutes() >= 30) && (date.getHours() <= (now.getHours() + 1)))) {
                if (('measured' in con) && ('cost_measured' in con)) {
                    consumption += con.measured;
                    costs += con.cost_measured;
                }
            }
        }

        this.history = {
            kwh: (consumption / 1000).toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
            costs: costs.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})
        };

        if (today > consumption) {
            let percent = Math.round(((today - consumption) / consumption) * 100);
            let scale = 0;

            if (percent > 80) {
                scale = 20;
            } else {
                scale = 100 - percent;
            }

            this.trend = {
                direction: 1,
                percent: percent,
                scale: {
                    left: scale / 100,
                    right: 1
                }
            };
        } else if (today < consumption) {
            let percent = Math.round((1 - (today / consumption)) * 100);
            let scale = 0;

            if (percent > 80) {
                scale = 20;
            } else {
                scale = 100 - percent;
            }

            this.trend = {
                direction: -1,
                percent: percent,
                scale: {
                    left: 1,
                    right: scale / 100
                }
            };
        } else {
            this.trend = {
                direction: 0,
                percent: 0,
                scale: {
                    left: 1,
                    right: 1
                }
            };
        }
    }

    /**
     * Analytic stuff
     */
    firstDetailViewAnalytics() {
        if (!(this._globals.getFirstDetailsViewed())) {
            this._apiService._analytics.eventTrack.next({
                action: 'first_detail_view',
                properties: {
                    category: 'Screens',
                    label: 'Screen: Today-Details'
                }
            });
        }
        this._globals.setFirstDetailsViews();
    }

}
