/**
 * Contains general application-wide constants.
 */
import {ConnectionIndicatorConfig} from './connection-indicator/connection-indicator.component';

/**
 * Constants
 */
export const constants = {
    api: {
        urls: {
            auth: 'https://live.innogy.2ndlab.de/auth',
            auth_wla: 'https://iona-dev.wla-backend.de/auth',
            base: 'https://api.n2g-iona.net/v2/'
        },
        routes: {
            instantaneous: 'instantaneous',
            homeState: {
                current: 'home_state/current',
                config: 'home_state/config'
            },
            power: 'power',
            disaggregation: {
                history: 'disaggregation/history'
            },
            electricity: {
                consumption: {
                    this: 'electricity/consumption',
                    compare: 'electricity/consumption/compare'
                },
                bill: {
                    prediction: 'electricity/bill/prediction'
                }
            },
            initialization: 'initialisation',
            profile: {
                this: 'profile',
                attributes: 'profile/attributes'
            },
            registration: {
                email: 'registration/email',
                setPassword: 'registration/setpassword',
                resetPassword: 'registration/resetpassword',
                voucher: 'registration/voucher',
                noGateway: 'registration/no-gateway',
                onboard: 'registration/onboard',
                optIn: 'registration/opt-in',
                online: 'registration/online',
                model: 'registration/model'
            },
            meter: {
                status: 'meter/status',
            },
            nilm: {
                status: 'nilm/status'
            },
            plug: {
                relay: 'plug/relay'
            },
            configuration: {
                dashboard: 'configuration/dashboard'
            },
            iona: {
                happyHour: {
                    this: 'iona/happyhour',
                    participation: 'iona/happyhour/participation',
                    schedule: 'iona/happyhour/schedule',
                    consumption: {
                        electricity: {
                            days: 'iona/happyhour/consumption-alert/electricity/days',
                            weeks: 'iona/happyhour/consumption-alert/electricity/weeks',
                            months: 'iona/happyhour/consumption-alert/electricity/months',
                            years: 'iona/happyhour/consumption-alert/electricity/years',
                        }
                    }
                }
            }
        },
    },
    assets: {
        demo: 'assets/data/demo/'
    },
    demo: {
        files: {
            bill_prediction: 'bill_prediction',
            bill_prediction_test: 'bill_prediction_test',
            consumption: 'consumption',
            consumption_days: 'consumption_days',
            consumption_hours: 'consumption_hours',
            consumption_hours_last_week: 'consumption_hours_last_week',
            consumption_months: 'consumption_months',
            disagregation_history: 'disagregation_history',
            initialization: 'initialisation',
            initialization_test: 'initialisation_test',
            home_state_current: 'home_state_current',
            home_state_config: 'home_state_config',
            nilm_status: 'nilm_status',
            nilm_status_test: 'nilm_status_test',
            power_10_seconds: 'power_10_seconds',
            power_15_minutes: 'power_15_minutes',
            power_1_minute: 'power_1_minute',
            power_5_seconds: 'power_5_seconds',
            power_5_seconds_last_10_minutes: 'power_5_seconds_last_10_minutes',
            profile: 'profile',
            profile_attributes: 'profile_attributes',
            instantaneous: 'instantaneous',
            meter_status: 'meter_status',
            registration_online: 'registration_online',
            registration_online_test: 'registration_online_test',
            registration_model: 'registration_model',
            registration_model_test: 'registration_model_test',
            plug_relay: 'plug_relay',
            plug_relay_test: 'plug_relay_test',
        }
    },
    application: {
        states: {
            none: 'none',
            demo: 'demo',
            live: 'live'
        },
        storage: {
            keys: {
                app_mode: 'app-mode',
                app_expire: 'app-expire',
                providername: 'us,er-provider'
            }
        },
        devices: {
            plug: 'IONA4911',
            box: 'IONA4910',
        }
    }
};

export const static_links = {
    settings: {
        innogy: 'https://iam.innogy.com/fuer-zuhause/meine-innogy',
        enviam: 'https://www.enviam.de/privatkunden/Meine-enviaM#/',
        eprimo: 'https://www.eprimo.de/service/account/auth/login',
        energis: 'https://www.energis.de',
        lew: 'https://kundenkonto.lew.de/',
        'e.on': 'https://www.eon.de/de/meineon/start.html'
    },
    privacy: {
        innogy: 'https://www.iona-energy.com/iONA/datenschutz',
        enviam: 'https://www.iona-energy.com/-/media/iONA/documents/datenschutzinformation-enviam.pdf',
        eprimo: 'assets/downloads/eprimo-datenschutz.pdf',
        energis: 'https://www.iona-energy.com/-/media/iONA/documents/iona-energis/datenschutzinformation.pdf',
        lew: 'https://www.iona-energy.com/-/media/iONA/documents/iona-lew/datenschutzinformation.pdf',
        'e.on': 'https://www.iona-energy.com/iONA/datenschutz',
    },
    finance: {
        innogy: 'https://www.innogy.com/web/cms/de/3157502/fuer-zuhause/meine-innogy/abschlaege/?redirectToOnSecuritySuccess=%2Fweb%2Fcms%2Fde%2F3157576%2Ffuer-zuhause%2Fmeine-innogy%2Fservices%2Fabschlaege%2F',
        enviam: 'https://www.enviam.de/privatkunden/Meine-enviaM#/',
        eprimo: 'https://www.eprimo.de/service/account/auth/login',
        energis: 'http://www.energis.de',
        lew: 'https://kundenkonto.lew.de/abschlaege/',
        'e.on': 'https://www.eon.de/de/meineon/start.html'
    }
};

/**
 * Meter connection config
 */
export const meter_connection_config: ConnectionIndicatorConfig = {
    fill: true,
    ranges: [
        {
            max: -1000,
            min: -117,
        },
        {
            max: -117,
            min: -114,
        },
        {
            max: -114,
            min: -1,
        }
    ]
};

export const wifi_connection_config: ConnectionIndicatorConfig = {
    fill: true,
    ranges: [
        {
            max: -1000,
            min: -80,
        },
        {
            max: -79,
            min: -66,
        },
        {
            max: -65,
            min: -1,
        }
    ]
};
